import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction } from 'common/HOCs';
import { updateHealthJourneyItemInit } from 'services/database/actions/healthJourney';
import { cyclesOfCareModuleDefinitionsSelector } from 'services/database/actions/definitions';
import HealthJourney from './HealthJourney';

export default compose(
  withAction({
    actionName: 'updateHealthJourneyItem',
    action: updateHealthJourneyItemInit,
  }),
  connect((state) => ({
    cycleOfCareModuleDefinitions: cyclesOfCareModuleDefinitionsSelector(state),
  }))
)(HealthJourney);

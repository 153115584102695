import { call, put } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { deleteHealthPlanServiceSuccess } from 'services/database/actions/healthPlanServices';
import request from './request';

export default function* deleteSaga({ query, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: query.definition }));
    yield call(request, {
      query,
    });
    yield put(deleteHealthPlanServiceSuccess({ data: query }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  } finally {
    yield put(setInlineLoadingOff({ data: query.definition }));
  }
}

import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { updateGoalProgressReviewSuccess } from 'services/database/actions/goalProgressReviews';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  const ext = query.notesUpdate ? '-notes' : '-progress';
  try {
    yield put(
      setInlineLoadingOn({
        data: query.goalProgressReviewId + ext,
      })
    );
    const responseData = yield call(request, { query, data });
    yield put(updateGoalProgressReviewSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  } finally {
    yield put(
      setInlineLoadingOff({
        data: query.goalProgressReviewId + ext,
      })
    );
  }
}

import { isNull } from 'lodash';

export default function (values) {
  return {
    // DISCUSS: better way to handle providerId surely?
    provider: isNull(values.providerId)
      ? values.providerId
      : Number(values.providerId),
    tasks: values.tasks,
    numberOfVisits: values.numberOfVisits,
  };
}

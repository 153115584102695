import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  handleSubmit,
} from 'common/data/healthPlanServices/update';
import { updateHealthPlanServiceInit } from 'services/database/actions/healthPlanServices';
import {
  fetchProvidersInit,
  providersSelector,
  resetProviders,
} from 'services/database/actions/providers';
import { serviceTaskDefinitionsSelector } from 'services/database/actions/definitions';
import UpdateHealthPlanService from './UpdateHealthPlanService';

export default compose(
  withAction({
    actionName: 'fetchProviders',
    action: fetchProvidersInit,
  }),
  withAction({
    actionName: 'resetProviders',
    action: resetProviders,
  }),
  withAction({
    actionName: 'updateHealthPlanService',
    action: updateHealthPlanServiceInit,
  }),
  connect((state, { healthPlanService }) => ({
    providers: providersSelector(state),
    taskDefinitions: serviceTaskDefinitionsSelector(
      state,
      healthPlanService.definition
    ),
  })),
  withFormik({ mapPropsToValues, handleSubmit, enableReinitialize: true })
)(UpdateHealthPlanService);

import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

const styles = () => ({
  '@global': {
    html: {
      fontSize: '20px',
    },
    body: {
      minHeight: '100vh',
      fontSize: '0.7rem',
      lineHeight: ' 0.85rem',
      cursor: 'default',
    },
    a: {
      textDecoration: 'none',
    },
  },
});

function MyCssBaseline() {
  return null;
}

MyCssBaseline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyCssBaseline);

import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function (data) {
  return http
    .post('/token/', {
      data: {
        email: data.email,
        password: data.password,
      },
    })
    .catch(handleHTTPError);
}

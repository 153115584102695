export default {
  0: 'Health Goals',
  1: 'Clinical Data',
  2: 'Health Services',
  3: 'Health Team',
  4: 'Cycle of Care',
  5: 'Health Actions',
  6: 'Progress',
  7: 'Feedback',
  8: 'Summary',
};

import {
  FETCH_HEALTH_PLAN_GOALS_INIT,
  FETCH_HEALTH_PLAN_GOALS_SUCCESS,
  CREATE_HEALTH_PLAN_GOAL_INIT,
  CREATE_HEALTH_PLAN_GOAL_SUCCESS,
  DELETE_HEALTH_PLAN_GOAL_INIT,
  DELETE_HEALTH_PLAN_GOAL_SUCCESS,
  RESET_HEALTH_PLAN_GOALS,
} from './actionNames';

export function fetchHealthPlanGoalsInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_GOALS_INIT, ...actionParams };
}

export function fetchHealthPlanGoalsSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_GOALS_SUCCESS, data };
}

export function createHealthPlanGoalInit(actionParams) {
  return { type: CREATE_HEALTH_PLAN_GOAL_INIT, ...actionParams };
}

export function createHealthPlanGoalSuccess({ data }) {
  return { type: CREATE_HEALTH_PLAN_GOAL_SUCCESS, data };
}

export function deleteHealthPlanGoalInit(actionParams) {
  return { type: DELETE_HEALTH_PLAN_GOAL_INIT, ...actionParams };
}

export function deleteHealthPlanGoalSuccess({ data }) {
  return { type: DELETE_HEALTH_PLAN_GOAL_SUCCESS, data };
}

export function resetHealthPlanGoals(actionParams) {
  return { type: RESET_HEALTH_PLAN_GOALS, actionParams };
}

export default function () {
  return {
    firstName: '',
    lastName: '',
    email: '',
    ahpraNumber: '',
    userType: '',
    providerType: '',
  };
}

import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  definition: PropTypes.number.isRequired,
  goalProgressBarrier: PropTypes.number.isRequired,
  goalProgressReview: PropTypes.number.isRequired,
  healthPlanReview: PropTypes.number.isRequired,
  healthPlan: PropTypes.number.isRequired,
};

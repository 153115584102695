import normalizeData from 'services/backend/dataNormalizer/normalizeData';

const adaptResponseData = function (definitions) {
  return {
    actions: normalizeData(definitions.actions, 'actions'),
    adminItems: normalizeData(definitions.adminItems, 'adminItems'),
    barriers: normalizeData(definitions.barriers, 'barriers'),
    billableItems: normalizeData(definitions.billableItems, 'billableItems'),
    conditions: normalizeData(definitions.conditions, 'conditions'),
    cycleOfCareItems: normalizeData(
      definitions.cycleOfCareItems,
      'cycleOfCareItems'
    ),
    cycleOfCareModules: normalizeData(
      definitions.cycleOfCareModules,
      'cycleOfCareModules'
    ),
    goalBarriers: normalizeData(definitions.goalBarriers, 'goalBarriers'),
    goals: normalizeData(definitions.goals, 'goals'),
    measurements: normalizeData(definitions.measurements, 'measurements'),
    services: normalizeData(definitions.services, 'services'),
    solutions: normalizeData(definitions.solutions, 'solutions'),
    tasks: normalizeData(definitions.tasks, 'tasks'),
  };
};

export default adaptResponseData;

import { put, call } from 'redux-saga/effects';
import { createHealthPlanSuccess } from 'services/database/actions/healthPlans';
import request from './request';

export default function* createSaga({ data, resolve, reject }) {
  try {
    const responseData = yield call(request, { data });
    yield put(createHealthPlanSuccess({ healthPlanId: responseData.id }));
    yield call(resolve, { healthPlan: responseData });
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  patient: PropTypes.number.isRequired,
  definition: PropTypes.number.isRequired,
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
};

import {
  FETCH_PROVIDERS_INIT,
  FETCH_PROVIDERS_SUCCESS,
  ADD_PROVIDER_INIT,
  ADD_PROVIDER_ERROR,
  UPDATE_PROVIDER_INIT,
  UPDATE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_ERROR,
  RESET_PROVIDERS,
} from './actionNames';

export function fetchProvidersInit(actionParams) {
  return { type: FETCH_PROVIDERS_INIT, ...actionParams };
}

export function fetchProvidersSuccess({ data }) {
  return { type: FETCH_PROVIDERS_SUCCESS, data };
}

export function addProviderInit(actionParams) {
  return { type: ADD_PROVIDER_INIT, ...actionParams };
}

export function addProviderError({ error }) {
  return { type: ADD_PROVIDER_ERROR, error };
}

export function updateProviderInit(actionParams) {
  return { type: UPDATE_PROVIDER_INIT, ...actionParams };
}

export function updateProviderSuccess({ data }) {
  return { type: UPDATE_PROVIDER_SUCCESS, data };
}

export function updateProviderError({ error }) {
  return { type: UPDATE_PROVIDER_ERROR, error };
}

export function resetProviders(actionParams) {
  return { type: RESET_PROVIDERS, ...actionParams };
}

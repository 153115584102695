import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { healthPlan, createHealthPlanMeasurement, onClose } }
) {
  const handleOnSuccess = () => {
    onClose();
  };

  return createHealthPlanMeasurement({
    query: { healthPlanId: healthPlan.id },
    data: mapValuesToProps(values, healthPlan),
  }).then(handleOnSuccess);
}

import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { goalProgressReview, updateGoalProgressReview } }
) {
  return updateGoalProgressReview({
    query: {
      healthPlanId: goalProgressReview.healthPlan,
      healthPlanReviewId: goalProgressReview.healthPlanReview,
      goalProgressReviewId: goalProgressReview.id,
      notesUpdate: values.notes !== values.initialNotes,
    },
    data: mapValuesToProps(values),
  });
}

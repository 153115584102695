import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import { FETCH_PATIENTS_SUCCESS, RESET_PATIENTS } from './actionNames';

const initialState = {
  patientList: {},
  patientCount: 0,
};

function handleFetchPatientsSuccess(
  state,
  { data: { patientList, patientCount } }
) {
  const nextState = produce(state, (draftState) => {
    draftState.patientList = patientList;
    draftState.patientCount = patientCount;
  });
  return { ...state, ...nextState };
}

function handleResetPatients() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_PATIENTS_SUCCESS]: handleFetchPatientsSuccess,
  [RESET_PATIENTS]: handleResetPatients,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/notifications/fetch';
import updateSaga from 'services/backend/actions/notifications/update';
// TODO: Implement this?
// import fetchCountUnreadSaga from 'services/backend/actions/notifications/fetchCountUnread';
import {
  FETCH_NOTIFICATIONS_INIT,
  UPDATE_NOTIFICATION_INIT,
  // FETCH_NOTIFICATION_COUNT_UNREAD_INIT,
} from './actionNames';

function* notificationsFlowSaga() {
  yield all([
    takeLatest(FETCH_NOTIFICATIONS_INIT, fetchSaga),
    takeLatest(UPDATE_NOTIFICATION_INIT, updateSaga),
    // takeLatest(FETCH_NOTIFICATION_COUNT_UNREAD_INIT, fetchCountUnreadSaga),
  ]);
}

export default notificationsFlowSaga;

import { buildReducer } from 'common/utils/redux';
import { FETCH_DASHBOARD_SUCCESS, RESET_DASHBOARD } from './actionNames';

const initialState = {};

function handleFetchDashboardSuccess(state, { data }) {
  return { ...state, ...data };
}

function handleResetDashboard() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_DASHBOARD_SUCCESS]: handleFetchDashboardSuccess,
  [RESET_DASHBOARD]: handleResetDashboard,
};

export default buildReducer(initialState, ACTION_HANDLERS);

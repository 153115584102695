import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  {
    props: {
      patient,
      healthPlan,
      healthPlanMeasurement,
      createPatientMeasurement,
    },
  }
) {
  return createPatientMeasurement({
    query: {
      patientId: patient.id,
      healthPlanMeasurementId: healthPlanMeasurement.id,
    },
    data: mapValuesToProps(values, {
      patient,
      healthPlan,
      measurement: healthPlanMeasurement.definition,
    }),
  });
}

import {
  isArray,
  isDate,
  isEmpty,
  isNil,
  isNull,
  isNaN,
  isUndefined,
} from 'lodash';
import { HEALTH_PLAN_STATUSES } from 'common/constants';

export function renderObject(object) {
  return object;
}

function _renderObjectKey(object, keys, separators = ' ') {
  if (isEmpty(object) || !isArray(keys) || isEmpty(keys)) {
    return '';
  }
  let string = '';
  for (let idx in keys) {
    let key = keys[idx],
      value = object[key],
      separator = isArray(separators)
        ? separators[idx % separators.length]
        : separators;
    if (isNil(value)) {
      return '';
    }

    string += value;
    string += idx < keys.length - 1 ? separator : '';
  }

  return string;
}

export function renderFirstName(object) {
  return _renderObjectKey(object, ['firstName']);
}

export function renderLastName(object) {
  return _renderObjectKey(object, ['lastName']);
}

export function renderFullName(object) {
  return _renderObjectKey(object, ['firstName', 'lastName']);
}

export function renderLabel(object) {
  return _renderObjectKey(object, ['label']);
}

function _renderDate(date, format) {
  if (!isDate(date) && isEmpty(date)) {
    return '';
  }

  const dateParsed = Date.parse(date);
  if (isNaN(dateParsed)) {
    return '';
  }

  const dateObject = new Date(dateParsed);

  return dateObject.toLocaleDateString('en-AU', format);
}

export function renderDate(date, format) {
  return _renderDate(
    date,
    !isUndefined(format)
      ? format
      : {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        }
  );
}

export function renderDateTime(date) {
  return _renderDate(date, {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
}

export function renderDateForSubmit(date) {
  // BUG: changes the date, why take the timezone stuff?
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return date.toISOString().split('T')[0];
}

export function renderPracticeName(user) {
  if (isEmpty(user.practices)) {
    return '';
  }
  return user.practices[0].name;
}

export function renderPhoneNumber(phoneNumber) {
  if (isNil(phoneNumber)) {
    return '';
  }

  const cleanedPhoneNumber = phoneNumber.replace(/\s/g, '');

  switch (cleanedPhoneNumber) {
    case cleanedPhoneNumber.match(/^04\d{8}$/)?.input:
      return cleanedPhoneNumber.replace(
        /^(04\d{2})(\d{3})(\d{3})$/,
        '$1 $2 $3'
      );
    case cleanedPhoneNumber.match(/^\+614\d{8}$/)?.input:
      return cleanedPhoneNumber.replace(
        /^(\+61)(4\d{2})(\d{3})(\d{3})$/,
        '$1 $2 $3 $4'
      );
    case cleanedPhoneNumber.match(/^0\d{9}$/)?.input:
      return cleanedPhoneNumber.replace(/^(0\d)(\d{4})(\d{4})$/, '$1 $2 $3');
    case cleanedPhoneNumber.match(/^\d{8}$/)?.input:
      return cleanedPhoneNumber.replace(/^(\d{4})(\d{4})$/, '$1 $2');
    default:
      return cleanedPhoneNumber;
  }
}

export function renderHealthPlanStatus(healthPlan) {
  const status = !isNull(healthPlan) && healthPlan.status,
    isReview = !isNull(healthPlan) && healthPlan.isReview;
  switch (status) {
    case HEALTH_PLAN_STATUSES.IN_PROGRESS:
      if (isReview) {
        return 'Review In Progress';
      }
      return 'In Progress';
    case HEALTH_PLAN_STATUSES.SHARED:
      if (isReview) {
        return 'Review Shared';
      }
      return 'Shared';
    case HEALTH_PLAN_STATUSES.FINALISED:
      if (isReview) {
        return 'Review Finalised';
      }
      return 'Finalised';
    default:
      return 'Not Yet Created';
  }
}

export function renderHealthPlanButton(healthJourney, healthPlan, isNurse) {
  if (isEmpty(healthJourney)) {
    return 'Start Health Journey';
  }
  const status = !isNull(healthPlan) && healthPlan.status,
    isReview = !isNull(healthPlan) && healthPlan.isReview;
  switch (status) {
    case HEALTH_PLAN_STATUSES.IN_PROGRESS:
      return !isReview ? 'Continue Health Plan' : 'Continue Review';
    case HEALTH_PLAN_STATUSES.SHARED:
      return isNurse
        ? !isReview
          ? 'View Health Plan'
          : 'View Review'
        : !isReview
        ? 'Finalise Health Plan'
        : 'Finalise Review';
    case HEALTH_PLAN_STATUSES.FINALISED:
      return 'Conduct Review';
    default:
      return 'Start Health Journey';
  }
}

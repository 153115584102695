import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_HEALTH_PLAN_REVIEWS_SUCCESS,
  RESET_HEALTH_PLAN_REVIEWS,
  CREATE_HEALTH_PLAN_REVIEW_SUCCESS,
} from './actionNames';
import { isEmpty } from 'lodash';
import { UPDATE_GOAL_PROGRESS_REVIEW_SUCCESS } from '../../goalProgressReviews/redux/actionNames';
import {
  CREATE_GOAL_PROGRESS_BARRIER_SUCCESS,
  DELETE_GOAL_PROGRESS_BARRIER_SUCCESS,
} from '../../goalProgressBarriers/redux/actionNames';
import {
  CREATE_GOAL_PROGRESS_SOLUTION_SUCCESS,
  DELETE_GOAL_PROGRESS_SOLUTION_SUCCESS,
} from '../../goalProgressSolutions/redux/actionNames';

const initialState = {
  healthPlanCurrentReview: {},
  healthPlanPreviousReviews: [],
};

function handleFetchHealthPlanReviewsSuccess(
  state,
  { data: { healthPlanCurrentReview, healthPlanPreviousReviews } }
) {
  const dataToStore = {
    healthPlanCurrentReview,
    healthPlanPreviousReviews,
  };
  return { ...state, ...dataToStore };
}

function handleCreateHealthPlanReviewSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    !isEmpty(draftState.healthPlanCurrentReview) &&
      draftState.healthPlanPreviousReviews.push(
        draftState.healthPlanCurrentReview
      );
    draftState.healthPlanCurrentReview = data;
  });
  return { ...state, ...nextState };
}

function handleUpdateGoalProgressReviewSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.healthPlanCurrentReview.goalProgressReviews[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleCreateGoalProgressBarrierSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.healthPlanCurrentReview.goalProgressReviews[
      data.goalProgressReview
    ].goalProgressBarriers[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleDeleteGoalProgressBarrierSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    delete draftState.healthPlanCurrentReview.goalProgressReviews[
      data.goalProgressReviewId
    ].goalProgressBarriers[data.goalProgressBarrierId];
  });
  return nextState;
}

function handleCreateGoalProgressSolutionSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.healthPlanCurrentReview.goalProgressReviews[
      data.goalProgressReview
    ].goalProgressBarriers[data.goalProgressBarrier].goalProgressSolutions[
      data.id
    ] = data;
  });
  return { ...state, ...nextState };
}

function handleDeleteGoalProgressSolutionSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    delete draftState.healthPlanCurrentReview.goalProgressReviews[
      data.goalProgressReviewId
    ].goalProgressBarriers[data.goalProgressBarrierId].goalProgressSolutions[
      data.goalProgressSolutionId
    ];
  });
  return { ...state, ...nextState };
}

function handleResetHealthPlanReviews() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_REVIEWS_SUCCESS]: handleFetchHealthPlanReviewsSuccess,
  [CREATE_HEALTH_PLAN_REVIEW_SUCCESS]: handleCreateHealthPlanReviewSuccess,
  [UPDATE_GOAL_PROGRESS_REVIEW_SUCCESS]: handleUpdateGoalProgressReviewSuccess,
  [CREATE_GOAL_PROGRESS_BARRIER_SUCCESS]:
    handleCreateGoalProgressBarrierSuccess,
  [DELETE_GOAL_PROGRESS_BARRIER_SUCCESS]:
    handleDeleteGoalProgressBarrierSuccess,
  [CREATE_GOAL_PROGRESS_SOLUTION_SUCCESS]:
    handleCreateGoalProgressSolutionSuccess,
  [DELETE_GOAL_PROGRESS_SOLUTION_SUCCESS]:
    handleDeleteGoalProgressSolutionSuccess,
  [RESET_HEALTH_PLAN_REVIEWS]: handleResetHealthPlanReviews,
};

export default buildReducer(initialState, ACTION_HANDLERS);

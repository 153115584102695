import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { healthPlanMeasurement, updateHealthPlanMeasurement } }
) {
  return updateHealthPlanMeasurement({
    query: {
      healthPlanId: healthPlanMeasurement.healthPlan,
      healthPlanMeasurementId: healthPlanMeasurement.id,
    },
    data: mapValuesToProps(values, healthPlanMeasurement.definition),
  });
}

export default {
  DASHBOARD: '/',
  NOTIFICATIONS: '/notifications',
  LOGIN: '/login',
  SETTINGS: '/settings',
  PROVIDERS: '/providers',
  PATIENTS: '/patients',
  PATIENT_DASHBOARD: '/patient/:patientId/dashboard',
  PATIENT_HEALTH_PLAN: '/patient/:patientId/health-plan',
};

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Oops from 'common/assets/graphics/oops.svg';
import { Button } from 'common/components/buttons';
import { FlexBox } from 'common/components/layouts';
import GlobalTheme from 'common/styles/global';
import { ImageContainer as StyledImageContainer } from './styles';

const ErrorPage = ({ onClick }) => {
  return (
    <FlexBox
      height={`calc(100vh - ${GlobalTheme.constants.MuiAppBar.height})`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <StyledImageContainer src={Oops} />
      <Typography variant="h2" paragraph>
        Something went wrong.
      </Typography>
      <Button label="Go Back" onClick={onClick} />
    </FlexBox>
  );
};

ErrorPage.defaultProps = {};
ErrorPage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ErrorPage;

import React, { useEffect } from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { GoalsCard } from 'common/components/layouts';
import { Button } from 'common/components/buttons';
import { Form, SelectField, MockInput } from 'common/components/form';
import { PROVIDER_TYPE_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanPropType,
  HealthPlanServicePropType,
  ProviderPropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';
import 'simplebar/dist/simplebar.min.css';

const UpdateHealthPlanPracticeStaff = ({
  open,
  healthPlan,
  isDoctor,
  healthProfessional,
  providers,
  healthPlanServices,
  goalDefinitions,
  values,
  values: { coach },
  setValues,
  fetchProviders,
  resetProviders,
  handleSubmit,
  onClose,
  readOnly,
}) => {
  useEffect(() => {
    // TODO: Update this to get only users at same practice?
    fetchProviders({
      query: { providerTypes: [healthProfessional.providerType] },
    });
    return () => {
      resetProviders();
    };
    // eslint-disable-next-line
  }, []);

  // BUG: Error thrown due to withFormikFieldProps: Warning: React does not recognize the `setValue` prop on a DOM element.
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {!isDoctor
          ? 'Health Coach'
          : PROVIDER_TYPE_NAMES[healthProfessional.providerType]}
      </DialogTitle>
      <SimpleBar style={{ maxHeight: '100%', width: '100%' }}>
        <DialogContent>
          <Box>
            {isDoctor && (
              <MockInput
                label="Provider"
                value={renderFullName(healthPlan.generalPractitioner)}
              />
            )}
            {!isDoctor && (
              <Form>
                <SelectField
                  name="coach"
                  label="Provider"
                  // DISCUSS: need select to return an int, can toInt be applied at the select level?
                  value={coach}
                  options={providers}
                  renderSelectLabel={renderFullName}
                  setValues={setValues}
                  values={values}
                  margin="dense"
                  disabled={readOnly}
                />
              </Form>
            )}
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              {sortBy(healthPlanServices, ['createdAt']).map(
                (healthPlanService, idx) => (
                  <Grid key={idx} item sm={6} xs={12}>
                    <GoalsCard
                      title={`Service #${idx + 1}`}
                      content={healthPlanService.definition.name}
                      goals={healthPlanService.healthPlanGoals.map(
                        (healthPlanGoal) =>
                          goalDefinitions[healthPlanGoal.definition]
                      )}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        </DialogContent>
      </SimpleBar>
      <DialogActions>
        <Button
          label="close"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        />
        {!readOnly && !isDoctor && (
          <Button label="update" onClick={handleSubmit} />
        )}
      </DialogActions>
    </Dialog>
  );
};

UpdateHealthPlanPracticeStaff.defaultProps = {
  providers: {},
};
UpdateHealthPlanPracticeStaff.propTypes = {
  open: PropTypes.bool.isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  isDoctor: PropTypes.bool.isRequired,
  healthProfessional: PropTypes.shape(ProviderPropType).isRequired,
  providers: PropTypes.objectOf(PropTypes.shape(ProviderPropType)),
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      definition: PropTypes.shape(ServicePropType),
    })
  ).isRequired,
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
  values: PropTypes.shape({ coach: PropTypes.number }).isRequired,
  setValues: PropTypes.func.isRequired,
  fetchProviders: PropTypes.func.isRequired,
  resetProviders: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default UpdateHealthPlanPracticeStaff;

import { compose } from 'redux';
import { withFormik } from 'formik';
import {
  withAction,
  withPropsMapping,
  // withFormErrorHandler,
} from 'common/HOCs';
import {
  validationSchema,
  mapPropsToValues,
  handleSubmit,
} from 'common/data/healthPlanServices/create';
import { createHealthPlanServiceInit } from 'services/database/actions/healthPlanServices';
import HealthPlanServiceAdd from './HealthPlanServiceAdd';

export default compose(
  withAction({
    actionName: 'createHealthPlanService',
    action: createHealthPlanServiceInit,
  }),
  withPropsMapping((props) => ({
    availableHealthPlanServices: Object.values(props.serviceDefinitions).filter(
      (service) =>
        !props.healthPlanServices.some(
          (healthPlanService) =>
            service.isShortlisted ||
            healthPlanService.definition.id === service.id
        )
    ),
    ...props,
  })),
  withFormik({ validationSchema, mapPropsToValues, handleSubmit })
  // withFormErrorHandler
)(HealthPlanServiceAdd);

import React from 'react';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography } from '@mui/material';
import { isOverdue, renderDate } from 'common/utils';
import { FlexBox } from 'common/components/layouts';
import {
  GOAL_PROGRESS_REVIEW_STATUSES,
  HEALTH_JOURNEY_STATUSES,
} from 'common/constants';
import {
  HealthPlanPropType,
  PatientPropType,
  UserPropType,
  HealthPlanReviewPropType,
} from 'common/propTypes';
import { isUserDoctor } from 'common/utils';
import { patientProfilePath } from 'routing/utils/paths';
import UpdateHealthPlanReviewStatusButton from './components/UpdateStatusButton/UpdateHealthPlanReviewStatusButtonContainer';
import UpdateHealthPlanStatusButton from './components/UpdateStatusButton/UpdateHealthPlanStatusButtonContainer';

const HealthPlanManagementTab = ({
  user,
  patient,
  healthPlan,
  healthPlanReview,
  isHealthPlanReview,
  healthJourney,
  history,
  readOnly,
}) => {
  const nextReview =
    !isEmpty(healthJourney) &&
    Object.values(healthJourney.healthJourneyItems).find(
      (item) => item.status === HEALTH_JOURNEY_STATUSES.UPCOMING
      // (item) => new Date(item.dueAt) > new Date()
    ).dueAt;

  const submitLabel = isUserDoctor(user) ? 'Finalise' : 'Share',
    submitEnabled =
      !readOnly &&
      (!isHealthPlanReview ||
        (!isUndefined(healthPlanReview.goalProgressReviews) &&
          !Object.values(healthPlanReview.goalProgressReviews).some(
            (goalProgressReview) =>
              goalProgressReview.progress !==
                GOAL_PROGRESS_REVIEW_STATUSES.FULLY &&
              isEmpty(goalProgressReview.goalProgressBarriers)
          )));

  const handleOnSuccess = () => {
    history.push(patientProfilePath({ patientId: patient.id }));
  };

  return (
    <Box my={8} ml="2.5rem">
      <FlexBox
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mb={2}
      >
        <Typography variant="h3">Next Health Plan Review Due</Typography>
        <Typography
          color={isOverdue(nextReview) ? 'error' : 'secondary'}
          variant="h2"
        >
          {renderDate(nextReview, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })}
        </Typography>
      </FlexBox>
      {!readOnly && (
        <FlexBox justifyContent="flex-end">
          <Tooltip
            title={
              isHealthPlanReview &&
              !isEmpty(healthPlanReview.goalProgressReviews) &&
              Object.values(healthPlanReview.goalProgressReviews).some(
                (goalProgressReview) =>
                  goalProgressReview.progress !==
                    GOAL_PROGRESS_REVIEW_STATUSES.FULLY &&
                  isEmpty(goalProgressReview.goalProgressBarriers)
              )
                ? `Cannot ${submitLabel.toLowerCase()} Health Plan Review without reviewing all Health Goals`
                : `Unable to ${submitLabel.toLowerCase()} Health Plan${
                    isHealthPlanReview && ' Review'
                  }`
            }
            placement="top-end"
            disableHoverListener={submitEnabled}
          >
            <span>
              {isHealthPlanReview ? (
                <UpdateHealthPlanReviewStatusButton
                  user={user}
                  healthPlanReview={healthPlanReview}
                  label={submitLabel}
                  onSuccess={handleOnSuccess}
                  disabled={!submitEnabled}
                />
              ) : (
                <UpdateHealthPlanStatusButton
                  user={user}
                  healthPlan={healthPlan}
                  label={submitLabel}
                  onSuccess={handleOnSuccess}
                  disabled={!submitEnabled}
                />
              )}
            </span>
          </Tooltip>
        </FlexBox>
      )}
    </Box>
  );
};

HealthPlanManagementTab.propTypes = {
  user: PropTypes.shape(UserPropType).isRequired,
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanReview: PropTypes.shape(HealthPlanReviewPropType),
  isHealthPlanReview: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanManagementTab;

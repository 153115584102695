import { isNil } from 'lodash';
import { createSelector } from 'reselect';

const selectPatient = (state) => state.patient;
const selectPatientError = (state) => state.patient.errors;

export const patientSelector = createSelector(
  [selectPatient],
  (patient) => patient
);

export const patientIdSelector = createSelector(
  [selectPatient],
  (patient) => !isNil(patient) && patient.id
);

export const patientErrorSelector = createSelector(
  [selectPatientError],
  (errors) => errors
);

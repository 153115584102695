export default {
  NSW: 'NSW', //'New South Wales',
  VIC: 'VIC', //'Victoria',
  QLD: 'QLD', //'Queensland',
  SA: 'SA', //'South Australia',
  WA: 'WA', //'Western Australia',
  TAS: 'TAS', //'Tasmania',
  NT: 'NT', //'Northern Territory',
  ACT: 'ACT', //'Australian Capital Territory',
};

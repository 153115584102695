import PropTypes from 'prop-types';

export default {
  id: PropTypes.number,
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
};

import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_HEALTH_PLAN_SERVICES_SUCCESS,
  CREATE_HEALTH_PLAN_SERVICE_SUCCESS,
  UPDATE_HEALTH_PLAN_SERVICE_SUCCESS,
  DELETE_HEALTH_PLAN_SERVICE_SUCCESS,
  RESET_HEALTH_PLAN_SERVICES,
} from './actionNames';

const initialState = {};

function handleFetchHealthPlanServicesSuccess(state, { data }) {
  return data;
}

function handleCreateHealthPlanServiceSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
    data.includedServices.map((service) => (draftState[service.id] = service));
  });
  return { ...state, ...nextState };
}

function handleUpdateHealthPlanServiceSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleDeleteHealthPlanServiceSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    delete draftState[data.healthPlanServiceId];
    data.includesServices.map(
      (includedServiceId) => delete draftState[includedServiceId]
    );
  });
  return nextState;
}

function handleResetHealthPlanServices() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_SERVICES_SUCCESS]: handleFetchHealthPlanServicesSuccess,
  [CREATE_HEALTH_PLAN_SERVICE_SUCCESS]: handleCreateHealthPlanServiceSuccess,
  [UPDATE_HEALTH_PLAN_SERVICE_SUCCESS]: handleUpdateHealthPlanServiceSuccess,
  [DELETE_HEALTH_PLAN_SERVICE_SUCCESS]: handleDeleteHealthPlanServiceSuccess,
  [RESET_HEALTH_PLAN_SERVICES]: handleResetHealthPlanServices,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({
  query: {
    healthPlanId,
    healthPlanReviewId,
    goalProgressReviewId,
    goalProgressBarrierId,
    goalProgressSolutionId,
  },
}) {
  return http
    .delete(
      `/health_plans/${healthPlanId}/reviews/${healthPlanReviewId}/goal_progress_reviews/${goalProgressReviewId}/goal_progress_barriers/${goalProgressBarrierId}/goal_progress_solutions/${goalProgressSolutionId}/`
    )
    .catch(handleHTTPError);
}

const defaultReducerActionHandler = (state) => state;

export default function (initialState, actionHandlers) {
  return function (state = initialState, action) {
    return (actionHandlers[action.type] || defaultReducerActionHandler)(
      state,
      action
    );
  };
}

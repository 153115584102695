import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchPatientConditionsInit,
  patientConditionsSelector,
  resetPatientConditions,
} from 'services/database/actions/patientConditions';
import { conditionDefinitionsSelector } from 'services/database/actions/definitions';
import PatientConditions from './PatientConditions';

export default compose(
  withAction({
    actionName: 'fetchPatientConditions',
    action: fetchPatientConditionsInit,
  }),
  withAction({
    actionName: 'resetPatientConditions',
    action: resetPatientConditions,
  }),
  connect((state) => ({
    // TODO: Move up to HealthPlanPage and pass down?
    patientConditions: patientConditionsSelector(state),
    conditionsDefinitions: conditionDefinitionsSelector(state),
  })),
  withPropsMapping((props) => ({
    ...props,
    patientConditions: Object.values(props.patientConditions).map(
      (patientCondition) => ({
        ...props.conditionsDefinitions[patientCondition.definition],
        ...patientCondition,
      })
    ),
  }))
)(PatientConditions);

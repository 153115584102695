import { compose } from 'redux';
import { withAction, withHealthPlanGoals, withPropsMapping } from 'common/HOCs';
import {
  fetchHealthPlanActionsInit,
  resetHealthPlanActions,
} from 'services/database/actions/healthPlanActions';
import HealthPlanActions from './HealthPlanActions';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanActions',
    action: fetchHealthPlanActionsInit,
  }),
  withAction({
    actionName: 'resetHealthPlanActions',
    action: resetHealthPlanActions,
  }),
  withHealthPlanGoals,
  withPropsMapping((props) => ({
    availableHealthPlanActions: Object.values(props.actionDefinitions).filter(
      (action) =>
        Object.values(props.healthPlanGoals).some((healthPlanGoal) =>
          healthPlanGoal.actions.includes(action.id)
        ) &&
        !Object.values(props.healthPlanActions).some(
          (healthPlanAction) => healthPlanAction.definition.id === action.id
        )
    ),
    ...props,
  }))
)(HealthPlanActions);

import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import {
  updateProviderSuccess,
  updateProviderError,
} from 'services/database/actions/providers';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: query.providerId }));
    const responseData = yield call(request, {
      query,
      data,
    });
    yield put(updateProviderSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield put(updateProviderError({ error }));
    yield call(reject, { error: error.data });
  } finally {
    yield put(setInlineLoadingOff({ data: query.providerId }));
  }
}

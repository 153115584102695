import React, { useEffect } from 'react';
import { isEmpty, isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
} from 'common/constants';
import {
  CycleOfCareItemPropType,
  CycleOfCareModulePropType,
  PatientCycleOfCareItemPropType,
  PatientCycleOfCareModulePropType,
  PatientPropType,
} from 'common/propTypes';
import PatientCycleOfCareModule from './components/PatientCycleOfCareModule';

const PatientCyclesOfCareTab = ({
  patient,
  activePatientCycleOfCareModules,
  fetchPatientCycleOfCareModules,
  resetPatientCycleOfCareModules,
  handleGoToSection,
}) => {
  useEffect(() => {
    !isNil(patient.id) &&
      fetchPatientCycleOfCareModules({ query: { patientId: patient.id } });
    return () => resetPatientCycleOfCareModules();
    // eslint-disable-next-line
  }, []);

  const sectionId = HEALTH_PLAN_SECTIONS.CYCLE_OF_CARE;

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      {isEmpty(activePatientCycleOfCareModules) ? (
        <Typography variant="h4" align="center">
          No cycles of care have been added
        </Typography>
      ) : (
        sortBy(activePatientCycleOfCareModules, ['id']).map(
          (patientCycleOfCareModule, idx) => (
            <PatientCycleOfCareModule
              key={idx}
              patientCycleOfCareModule={patientCycleOfCareModule}
            />
          )
        )
      )}
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

PatientCyclesOfCareTab.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  activePatientCycleOfCareModules: PropTypes.arrayOf(
    PropTypes.shape({
      ...PatientCycleOfCareModulePropType,
      patientCycleOfCareItems: PropTypes.arrayOf(
        PropTypes.shape({
          ...PatientCycleOfCareItemPropType,
          definition: PropTypes.shape(CycleOfCareItemPropType),
        })
      ),
      definition: PropTypes.shape(CycleOfCareModulePropType),
    })
  ),
  fetchPatientCycleOfCareModules: PropTypes.func.isRequired,
  resetPatientCycleOfCareModules: PropTypes.func.isRequired,
  handleGoToSection: PropTypes.func.isRequired,
};

export default PatientCyclesOfCareTab;

import { MEASUREMENT_KINDS } from 'common/constants';
import { parseMeasurementData } from 'common/utils';

export default function (values, measurement) {
  const isSplitValue = measurement.kind === MEASUREMENT_KINDS.SPLIT_VALUE,
    firstValueTarget = values.target.split('/')[0],
    secondValueTarget = isSplitValue ? values.target.split('/')[1] : null;

  return {
    valueTarget: parseMeasurementData(firstValueTarget, measurement.valueType),
    secondValueTarget: isSplitValue
      ? parseMeasurementData(secondValueTarget, measurement.valueType)
      : null,
  };
}

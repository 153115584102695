import { compose } from 'redux';
import { connect } from 'react-redux';
import { healthJourneySelector } from 'services/database/actions/healthJourney';
import HealthPlanManagementTab from './HealthPlanManagementTab';

export default compose(
  connect((state) => ({
    healthJourney: healthJourneySelector(state),
  }))
)(HealthPlanManagementTab);

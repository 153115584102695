import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';
import { Button } from 'common/components/buttons';
import { DatePicker, Input, SelectField } from 'common/components/form';
import { SEX, STATE } from 'common/constants';
import { UserPropType, ProviderPropType } from 'common/propTypes';
import GlobalTheme from 'common/styles/global';
import { isUserDoctor, renderObject, renderFullName } from 'common/utils';
import ConfirmationDialog from './components/ConfirmationDialog';
import ChangePasswordModal from './components/ChangePasswordModal';

const PatientProfile = ({
  user,
  providers,
  values: {
    firstName,
    lastName,
    phoneNumber,
    dateOfBirth,
    sex,
    email,
    street,
    city,
    state,
    postcode,
    generalPractitioner,
  },
  values,
  initialValues,
  setValues,
  handleSubmit,
  inlineLoading,
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false),
    handleToggleConfirmationDialog = () => {
      setShowConfirmationDialog(!showConfirmationDialog);
    };

  const [showChangePassword, setShowChangePassword] = useState(false),
    handleToggleChangePasswordDialog = () => {
      setShowChangePassword(!showChangePassword);
    };

  const setDateOfBirth = (value) => {
    setValues({
      ...values,
      dateOfBirth: value,
    });
  };

  const handleOnClick = () => {
    generalPractitioner !== initialValues.generalPractitioner &&
    isUserDoctor(user)
      ? handleToggleConfirmationDialog()
      : handleSubmit();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <Input
            label="First Name"
            name="firstName"
            value={firstName}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            label="Last Name"
            name="lastName"
            value={lastName}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            label="Email"
            name="email"
            value={email}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            label="Phone Number"
            name="phoneNumber"
            value={phoneNumber}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <DatePicker
            id="date-of-birth-date-picker"
            name="dateOfBirth"
            label="Date of Birth"
            value={dateOfBirth}
            onChange={setDateOfBirth}
            maxDate={new Date()}
            margin="none"
            dateOfBirth
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <SelectField
            name="sex"
            label="Sex"
            value={sex}
            options={SEX}
            renderSelectLabel={renderObject}
            sortByIndex
            setValues={setValues}
            values={values}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectField
            name="generalPractitioner"
            label="Doctor"
            value={generalPractitioner}
            options={providers}
            renderSelectLabel={renderFullName}
            setValues={setValues}
            values={values}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label="Street"
            name="street"
            value={street}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            label="Suburb"
            name="city"
            value={city}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <SelectField
            name="state"
            label="State"
            value={state}
            options={STATE}
            renderSelectLabel={renderObject}
            setValues={setValues}
            values={values}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <Input
            label="Postcode"
            name="postcode"
            value={postcode}
            margin="none"
            disabled={inlineLoading}
          />
        </Grid>
      </Grid>

      {showConfirmationDialog && (
        <ConfirmationDialog
          open={showConfirmationDialog}
          generalPractitioner={providers[generalPractitioner]}
          onSubmit={handleSubmit}
          onClose={handleToggleConfirmationDialog}
          loading={inlineLoading}
        />
      )}

      {showChangePassword && (
        <ChangePasswordModal
          open={showChangePassword}
          onClose={handleToggleChangePasswordDialog}
          loading={inlineLoading}
        />
      )}

      <Stack
        direction="row"
        sx={{ marginTop: GlobalTheme.spacing(3), justifyContent: 'flex-end' }}
      >
        <Button
          label="change password"
          color="secondary"
          variant="text"
          onClick={handleToggleChangePasswordDialog}
          disabled={inlineLoading}
        />
        <Button
          label="update"
          type="submit"
          onClick={handleOnClick}
          disabled={isEqual(values, initialValues) || inlineLoading}
        />
      </Stack>
    </>
  );
};

PatientProfile.defaultProps = { providers: {} };
PatientProfile.propTypes = {
  user: PropTypes.shape(UserPropType),
  providers: PropTypes.objectOf(PropTypes.shape(ProviderPropType)),
  values: PropTypes.shape({
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    dateOfBirth: PropTypes.instanceOf(Date),
    sex: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string,
    generalPractitioner: PropTypes.string,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inlineLoading: PropTypes.bool.isRequired,
};

export default PatientProfile;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { CenteredBox } from 'common/components/layouts';
import { Card as StyledCard } from './styles';

const CenteredCard = ({ children, ...props }) => (
  <Box height="100vh">
    <CenteredBox>
      <StyledCard {...props}>{children}</StyledCard>
    </CenteredBox>
  </Box>
);

CenteredCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CenteredCard;

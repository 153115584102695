import PropTypes from 'prop-types';
import { MEASUREMENT_KINDS } from 'common/constants';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  kind: PropTypes.oneOf([
    MEASUREMENT_KINDS.SINGLE_VALUE,
    MEASUREMENT_KINDS.SPLIT_VALUE,
  ]).isRequired,
  unit: PropTypes.string,
  isTargetEditable: PropTypes.bool.isRequired,
  isPathologyTest: PropTypes.bool.isRequired,
};

// import produce from 'immer';
import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import { CREATE_PATIENT_MEASUREMENT_SUCCESS } from 'services/database/actions/patientMeasurements/redux/actionNames';
import { USER_LOGOUT_INIT } from 'services/database/actions/auth/redux/actionNames';
import {
  CREATE_HEALTH_PLAN_MEASUREMENT_SUCCESS,
  DELETE_HEALTH_PLAN_MEASUREMENT_SUCCESS,
  FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS,
  UPDATE_HEALTH_PLAN_MEASUREMENT_SUCCESS,
  RESET_HEALTH_PLAN_MEASUREMENTS,
} from './actionNames';

const initialState = {};

function handleFetchHealthPlanMeasurementsSuccess(state, { data }) {
  return data;
}

function handleCreateOrUpdateHealthPlanMeasurementSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleDeleteHealthPlanMeasurementSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    delete draftState[data.healthPlanMeasurementId];
  });
  return nextState;
}

// DISCUSS: can't merge query into data as this action is fired in patientMeasurements too (requires data)
function handleCreatePatientMeasurementSuccess(state, { data, query }) {
  const nextState = produce(state, (draftState) => {
    draftState[query.healthPlanMeasurementId].lastMeasurement = data.id;
  });
  return { ...state, ...nextState };
}

function handleResetHealthPlanMeasurements() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS]:
    handleFetchHealthPlanMeasurementsSuccess,
  [CREATE_HEALTH_PLAN_MEASUREMENT_SUCCESS]:
    handleCreateOrUpdateHealthPlanMeasurementSuccess,
  [UPDATE_HEALTH_PLAN_MEASUREMENT_SUCCESS]:
    handleCreateOrUpdateHealthPlanMeasurementSuccess,
  [DELETE_HEALTH_PLAN_MEASUREMENT_SUCCESS]:
    handleDeleteHealthPlanMeasurementSuccess,
  [CREATE_PATIENT_MEASUREMENT_SUCCESS]: handleCreatePatientMeasurementSuccess,
  [RESET_HEALTH_PLAN_MEASUREMENTS]: handleResetHealthPlanMeasurements,
  [USER_LOGOUT_INIT]: handleResetHealthPlanMeasurements,
};

export default buildReducer(initialState, ACTION_HANDLERS);

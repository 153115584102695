import React, { useState } from 'react';
import {
  CardActionArea,
  CardContent,
  CardActions,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { FlexBox, ContentBox } from 'common/components/layouts';
import { Card as StyledCard } from './styles';

const ExpandableCard = ({
  children,
  cardContent,
  cardActions,
  expanded,
  onCollapse,
  ...props
}) => {
  const [expand, setExpand] = useState(expanded);
  const handleExpandClick = () => {
    setExpand(!expand);

    if (expand && onCollapse) {
      onCollapse();
    }
  };

  return (
    <StyledCard expand={expand} {...props}>
      <CardActionArea onClick={handleExpandClick}>
        <CardContent>
          <FlexBox
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <ContentBox display="inherit" overflow="hidden">
              {cardContent}
            </ContentBox>
            {expand ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )}
          </FlexBox>
        </CardContent>
      </CardActionArea>
      {cardActions && (
        <CardActions>
          <FlexBox flexDirection="row" flexWrap="nowrap" flexGrow={1}>
            {cardActions}
          </FlexBox>
        </CardActions>
      )}
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </StyledCard>
  );
};

ExpandableCard.defaultProps = {
  expanded: false,
};
ExpandableCard.propTypes = {
  cardContent: PropTypes.node.isRequired,
  cardActions: PropTypes.node,
  expanded: PropTypes.bool,
  onCollapse: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default ExpandableCard;

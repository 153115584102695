import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_NOTIFICATION_COUNT_UNREAD_SUCCESS,
  FETCH_NOTIFICATIONS_SUCCESS,
  RESET_NOTIFICATIONS,
} from './actionNames';

const initialState = {
  notificationList: [],
  countUnread: 0,
};

function handleFetchNotificationCountUnreadSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.countUnread = data.countUnread;
  });
  return { ...state, ...nextState };
}

function handleFetchNotificationsSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.notificationList = data;
  });
  return { ...state, ...nextState };
}

function handleResetNotifications(state) {
  const nextState = produce(state, (draftState) => {
    draftState.notificationList = [];
  });
  return { ...state, ...nextState };
}

const ACTION_HANDLERS = {
  [FETCH_NOTIFICATION_COUNT_UNREAD_SUCCESS]:
    handleFetchNotificationCountUnreadSuccess,
  [FETCH_NOTIFICATIONS_SUCCESS]: handleFetchNotificationsSuccess,
  [RESET_NOTIFICATIONS]: handleResetNotifications,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { createGoalProgressBarrierSuccess } from 'services/database/actions/goalProgressBarriers';
import request from './request';

// rename to request, createSaga, simple params, all call resolve/reject, reject pass in the error: error.data
// use finally to turn off loading
export default function* createSaga({ query, data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: data.definition }));
    const responseData = yield call(request, { query, data });
    yield put(createGoalProgressBarrierSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  } finally {
    yield put(setInlineLoadingOff({ data: data.definition }));
  }
}

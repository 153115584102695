import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { updateHealthPlanMeasurementSuccess } from 'services/database/actions/healthPlanMeasurements';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: query.healthPlanMeasurementId }));
    const responseData = yield call(request, { query, data });
    yield put(updateHealthPlanMeasurementSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, error);
  } finally {
    yield put(setInlineLoadingOff({ data: query.healthPlanMeasurementId }));
  }
}

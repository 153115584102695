import { put, call } from 'redux-saga/effects';
import { updateNotificationSuccess } from 'services/database/actions/notifications';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  try {
    yield call(request, { query, data });
    yield put(updateNotificationSuccess());
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

import {
  FETCH_HEALTH_PLAN_ACTIONS_INIT,
  FETCH_HEALTH_PLAN_ACTIONS_SUCCESS,
  CREATE_HEALTH_PLAN_ACTION_INIT,
  CREATE_HEALTH_PLAN_ACTION_SUCCESS,
  DELETE_HEALTH_PLAN_ACTION_INIT,
  DELETE_HEALTH_PLAN_ACTION_SUCCESS,
  RESET_HEALTH_PLAN_ACTIONS,
} from './actionNames';

export function fetchHealthPlanActionsInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_ACTIONS_INIT, ...actionParams };
}

export function fetchHealthPlanActionsSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_ACTIONS_SUCCESS, data };
}

export function createHealthPlanActionInit(actionParams) {
  return { type: CREATE_HEALTH_PLAN_ACTION_INIT, ...actionParams };
}

export function createHealthPlanActionSuccess({ data }) {
  return { type: CREATE_HEALTH_PLAN_ACTION_SUCCESS, data };
}

export function deleteHealthPlanActionInit(actionParams) {
  return { type: DELETE_HEALTH_PLAN_ACTION_INIT, ...actionParams };
}

export function deleteHealthPlanActionSuccess({ data }) {
  return { type: DELETE_HEALTH_PLAN_ACTION_SUCCESS, data };
}

export function resetHealthPlanActions(actionParams) {
  return { type: RESET_HEALTH_PLAN_ACTIONS, actionParams };
}

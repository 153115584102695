import {
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_LOADING_INLINE_ON,
  SET_LOADING_INLINE_OFF,
  RESET_LOADERS,
} from './actionNames';

export function setLoadingOn(actionParams) {
  return { type: SET_LOADING_ON, ...actionParams };
}

export function setLoadingOff(actionParams) {
  return { type: SET_LOADING_OFF, ...actionParams };
}

export function setInlineLoadingOn({ data }) {
  return { type: SET_LOADING_INLINE_ON, data };
}

export function setInlineLoadingOff({ data }) {
  return { type: SET_LOADING_INLINE_OFF, data };
}

export function resetPageLoaders(actionParams) {
  return { type: RESET_LOADERS, ...actionParams };
}

import React, { useState } from 'react';
import { isUndefined, isNull, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import {
  Card,
  ExpandableCard,
  FlexBox,
  Tabs,
  Tab,
  TabPanel,
} from 'common/components/layouts';
import {
  GOAL_PROGRESS_REVIEW_STATUSES,
  GOAL_PROGRESS_REVIEW_STATUS_LABELS,
} from 'common/constants';
import {
  BarrierPropType,
  GoalPropType,
  SolutionPropType,
  HealthPlanReviewPropType,
  GoalProgressReviewPropType,
} from 'common/propTypes';
import { renderDate } from 'common/utils';
import GlobalTheme from 'common/styles/global';

const PreviousHealthPlanReviews = ({
  previousHealthPlanReviews,
  goalProgressReview,
  barrierDefinitions,
  solutionDefinitions,
}) => {
  const orderedHealthPlanReviews = sortBy(previousHealthPlanReviews, [
      'finalisedAt',
    ]),
    [activeTab, setActiveTab] = useState(
      orderedHealthPlanReviews[orderedHealthPlanReviews.length - 1].id
    );

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Typography variant="h3">Previous Reviews</Typography>
          <Typography variant="h6" color="textSecondary">
            {orderedHealthPlanReviews.length} Review
            {orderedHealthPlanReviews.length > 1 ? 's' : ''}
          </Typography>
        </Box>
      }
    >
      <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable">
        {orderedHealthPlanReviews.map((value) => (
          <Tab
            key={value.id}
            label={renderDate(value.finalisedAt)}
            value={value.id}
          />
        ))}
      </Tabs>
      {orderedHealthPlanReviews.map((healthPlanReview, idx) => {
        const historicGoalProgressReview = !isUndefined(healthPlanReview)
            ? Object.values(healthPlanReview.goalProgressReviews).find(
                (historicGoalProgressReview) =>
                  historicGoalProgressReview.goalDefinition ===
                  goalProgressReview.goalDefinition.id
              )
            : undefined,
          historicGoalProgressReviewLabel = !isUndefined(
            historicGoalProgressReview
          )
            ? GOAL_PROGRESS_REVIEW_STATUS_LABELS[
                historicGoalProgressReview.progress
              ]
            : undefined;

        return (
          <TabPanel
            key={idx}
            value={activeTab}
            index={healthPlanReview.id}
            component={React.Fragment}
          >
            {isUndefined(historicGoalProgressReview) ||
            historicGoalProgressReview.progress ===
              GOAL_PROGRESS_REVIEW_STATUSES.INITIAL ? (
              <FlexBox justifyContent="center">
                <Typography variant="h4">
                  No progress data was provided
                </Typography>
              </FlexBox>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Card>
                      <Typography variant="h5" color="textSecondary">
                        Status
                      </Typography>
                      <Typography
                        variant="h2"
                        style={{
                          color:
                            GlobalTheme.palette[
                              historicGoalProgressReviewLabel.color
                            ].main,
                        }}
                      >
                        {historicGoalProgressReviewLabel.label} Achieved
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
                <>
                  {Object.values(
                    historicGoalProgressReview.goalProgressBarriers
                  ).map((goalProgressBarrier, idx) => {
                    const barrier =
                      barrierDefinitions[goalProgressBarrier.definition];

                    return (
                      <Card key={idx}>
                        {/* TODO: Review FlexBox everywhere */}
                        <Box mb={2}>
                          <Typography variant="h5" color="textSecondary">
                            Barrier #{idx + 1}
                          </Typography>
                          <Typography variant="h2">{barrier.name}</Typography>
                        </Box>
                        <Grid container spacing={3}>
                          {Object.values(
                            goalProgressBarrier.goalProgressSolutions
                          ).map((goalProgressSolution, idx) => {
                            const solution =
                              solutionDefinitions[
                                goalProgressSolution.definition
                              ];

                            return (
                              <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
                                <Card>
                                  <Typography
                                    variant="h5"
                                    color="textSecondary"
                                  >
                                    Solution #{idx + 1}
                                  </Typography>
                                  <Typography variant="h4">
                                    {solution.name}
                                  </Typography>
                                </Card>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Card>
                    );
                  })}
                </>
                <Card>
                  <Typography variant="h5" color="textSecondary">
                    Notes
                  </Typography>
                  <Typography variant="h4">
                    {!isNull(historicGoalProgressReview.notes)
                      ? historicGoalProgressReview.notes
                      : 'No notes recorded'}
                  </Typography>
                </Card>
              </>
            )}
          </TabPanel>
        );
      })}
    </ExpandableCard>
  );
};

PreviousHealthPlanReviews.defaultProps = {};
PreviousHealthPlanReviews.propTypes = {
  previousHealthPlanReviews: PropTypes.arrayOf(
    PropTypes.shape(HealthPlanReviewPropType)
  ).isRequired,
  goalProgressReview: PropTypes.shape({
    ...GoalProgressReviewPropType,
    goalDefinition: PropTypes.shape(GoalPropType),
  }).isRequired,
  barrierDefinitions: PropTypes.objectOf(PropTypes.shape(BarrierPropType))
    .isRequired,
  solutionDefinitions: PropTypes.objectOf(PropTypes.shape(SolutionPropType))
    .isRequired,
};

export default PreviousHealthPlanReviews;

import {
  FETCH_PATIENTS_INIT,
  FETCH_PATIENTS_SUCCESS,
  RESET_PATIENTS,
} from './actionNames';

export function fetchPatientsInit(actionParams) {
  return { type: FETCH_PATIENTS_INIT, ...actionParams };
}

export function fetchPatientsSuccess({ data }) {
  return { type: FETCH_PATIENTS_SUCCESS, data };
}

export function resetPatients(actionParams) {
  return { type: RESET_PATIENTS, ...actionParams };
}

import { MEASUREMENT_KINDS } from 'common/constants';

export default function ({ healthPlanMeasurement }) {
  const isSplitValue =
    healthPlanMeasurement.definition.kind === MEASUREMENT_KINDS.SPLIT_VALUE;

  return {
    isSplitValue: isSplitValue,
    valueType: healthPlanMeasurement.definition.valueType,
    value: '',
  };
}

import { CircularProgress as MuiCircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')({
  position: 'relative',
});

export const CircularProgress = styled(MuiCircularProgress)(({ size }) => ({
  position: 'absolute',
  top: '50%',
  marginTop: `-${size / 2}px`,
  right: `${size / 2}px`,
}));

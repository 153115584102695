import React, { useState } from 'react';
import { isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import {
  TitleBox,
  FlexBox,
  Tabs,
  Tab,
  TabPanel,
} from 'common/components/layouts';
import {
  HEALTH_PLAN_STATUSES,
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
  HEALTH_PLAN_REVIEW_SECTIONS,
  HEALTH_PLAN_REVIEW_FULL_SECTIONS,
} from 'common/constants';
import {
  ActionPropType,
  BarrierPropType,
  CycleOfCareItemPropType,
  CycleOfCareModulePropType,
  ServicePropType,
  HealthPlanActionPropType,
  HealthPlanPropType,
  PatientCycleOfCareItemPropType,
  PatientCycleOfCareModulePropType,
  PatientPropType,
  UserPropType,
  SolutionPropType,
} from 'common/propTypes';
import {
  isUserDoctor,
  isUserNurse,
  renderFullName,
  renderHealthPlanStatus,
} from 'common/utils';
import { patientProfilePath } from 'routing/utils/paths';
import ClinicalData from './components/ClinicalData';
import GoalProgressReviews from './components/GoalProgressReviews';
import HealthPlanActions from './components/HealthPlanActions';
import HealthPlanGoals from './components/HealthPlanGoals';
import HealthPlanServices from './components/HealthPlanServices';
import HealthPlanTeam from './components/HealthPlanTeam';
import HealthPlanFeedback from './components/HealthPlanFeedback';
import PatientCyclesOfCare from './components/PatientCyclesOfCare';
import NavStepper from './components/NavStepper';
import Summary from './components/Summary';

const HealthPlanPage = ({
  user,
  patient,
  healthPlan,
  healthPlanActions,
  patientCycleOfCareModules,
  actionDefinitions,
  barrierDefinitions,
  serviceDefinitions,
  solutionDefinitions,
  cycleOfCareModuleDefinitions,
  history,
  loading,
}) => {
  const { state } = useLocation();
  const [isHealthPlanReviewFull, setIsHealthPlanReviewFull] = useState(false),
    toggleIsHealthPlanReviewFull = () =>
      setIsHealthPlanReviewFull(!isHealthPlanReviewFull);

  // needs to handle whether first HP, review or annual review
  // patient & healthPlan props from selectors
  // contains the heading info
  // contains the tabs & handles switching tabs to show new view
  const isHealthPlanReview =
      !isNull(patient.healthPlan) && patient.healthPlan.isReview,
    displayDict = !isHealthPlanReview
      ? HEALTH_PLAN_SECTIONS
      : isHealthPlanReviewFull
      ? HEALTH_PLAN_REVIEW_FULL_SECTIONS
      : HEALTH_PLAN_REVIEW_SECTIONS,
    [activeTab, setActiveTab] = useState(
      !isUndefined(state) && state.jumpToSummary
        ? displayDict.SUMMARY
        : displayDict.HEALTH_GOALS
    );

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  // Set readOnly based on userType and HealthPlan/HealthPlanReview status
  const readOnly = isUserDoctor(user)
      ? isHealthPlanReview
        ? isNull(patient.healthPlan.status) ||
          patient.healthPlan.status === HEALTH_PLAN_STATUSES.FINALISED
        : isNull(healthPlan.status) ||
          healthPlan.status === HEALTH_PLAN_STATUSES.FINALISED
      : isUserNurse(user)
      ? isHealthPlanReview
        ? isNull(patient.healthPlan.status) ||
          patient.healthPlan.status >= HEALTH_PLAN_STATUSES.SHARED
        : isNull(healthPlan.status) ||
          healthPlan.status >= HEALTH_PLAN_STATUSES.SHARED
      : true,
    buttonLabel = isHealthPlanReviewFull
      ? 'Show Review Only'
      : 'Show Full Health Plan';

  const handleOnClick = () => {
      history.push(
        patientProfilePath({
          patientId: patient.id,
        })
      );
    },
    handleToggleFullView = () => {
      isHealthPlanReviewFull &&
        !Object.values(HEALTH_PLAN_REVIEW_SECTIONS).includes(activeTab) &&
        setActiveTab(HEALTH_PLAN_SECTIONS.HEALTH_GOALS);
      toggleIsHealthPlanReviewFull();
    };

  return (
    <>
      <TitleBox
        contentLeft={
          <FlexBox flexDirection="column">
            <FlexBox>
              <Typography variant="h1" style={{ marginRight: '1rem' }}>
                {renderFullName(patient)}
              </Typography>
              <Typography
                variant="h1"
                color="primary"
                style={{ marginRight: '1rem' }}
              >
                {isHealthPlanReview ? 'Health Plan Review' : 'New Health Plan'}
              </Typography>
            </FlexBox>
            <Typography variant="h3" color="textSecondary">
              {renderHealthPlanStatus(patient.healthPlan)}
            </Typography>
          </FlexBox>
        }
        contentRight={
          <FlexBox flexDirection="column">
            {isHealthPlanReview && (
              <Button
                label={buttonLabel}
                color="primary"
                onClick={handleToggleFullView}
              />
            )}
            <Button
              label="Back to Profile"
              variant="outlined"
              color="secondary"
              onClick={handleOnClick}
            />
          </FlexBox>
        }
      />

      <Tabs value={activeTab} onChange={handleTabChange}>
        {Object.entries(displayDict).map(([key, value]) => (
          <Tab
            key={key}
            label={HEALTH_PLAN_SECTION_NAMES[value]}
            value={value}
          />
        ))}
      </Tabs>
      <TabPanel value={activeTab} index={HEALTH_PLAN_SECTIONS.HEALTH_GOALS}>
        <HealthPlanGoals
          patient={patient}
          healthPlan={healthPlan}
          readOnly={readOnly}
        />
        <NavStepper
          nextSectionId={HEALTH_PLAN_SECTIONS.CLINICAL_DATA}
          handleTabChange={handleTabChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={HEALTH_PLAN_SECTIONS.CLINICAL_DATA}>
        <ClinicalData
          patient={patient}
          healthPlan={healthPlan}
          readOnly={readOnly}
        />
        <NavStepper
          previousSectionId={HEALTH_PLAN_REVIEW_SECTIONS.HEALTH_GOALS}
          nextSectionId={
            isHealthPlanReview && !isHealthPlanReviewFull
              ? HEALTH_PLAN_SECTIONS.CYCLE_OF_CARE
              : HEALTH_PLAN_SECTIONS.HEALTH_SERVICES
          }
          handleTabChange={handleTabChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={HEALTH_PLAN_SECTIONS.HEALTH_SERVICES}>
        <HealthPlanServices
          patient={patient}
          healthPlan={healthPlan}
          serviceDefinitions={serviceDefinitions}
          readOnly={readOnly}
        />
        <NavStepper
          previousSectionId={HEALTH_PLAN_REVIEW_SECTIONS.CLINICAL_DATA}
          nextSectionId={HEALTH_PLAN_SECTIONS.HEALTH_TEAM}
          handleTabChange={handleTabChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={HEALTH_PLAN_SECTIONS.HEALTH_TEAM}>
        <HealthPlanTeam
          user={user}
          patient={patient}
          healthPlan={healthPlan}
          serviceDefinitions={serviceDefinitions}
          readOnly={readOnly}
          loading={loading}
        />
        <NavStepper
          previousSectionId={HEALTH_PLAN_SECTIONS.HEALTH_SERVICES}
          nextSectionId={HEALTH_PLAN_SECTIONS.CYCLE_OF_CARE}
          handleTabChange={handleTabChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={HEALTH_PLAN_SECTIONS.CYCLE_OF_CARE}>
        <PatientCyclesOfCare
          patient={patient}
          healthPlan={healthPlan}
          patientCycleOfCareModules={patientCycleOfCareModules}
          cycleOfCareModuleDefinitions={cycleOfCareModuleDefinitions}
          readOnly={readOnly}
        />
        <NavStepper
          previousSectionId={
            isHealthPlanReview && !isHealthPlanReviewFull
              ? HEALTH_PLAN_SECTIONS.CLINICAL_DATA
              : HEALTH_PLAN_SECTIONS.HEALTH_TEAM
          }
          nextSectionId={
            isHealthPlanReview && !isHealthPlanReviewFull
              ? HEALTH_PLAN_REVIEW_FULL_SECTIONS.PROGRESS
              : HEALTH_PLAN_SECTIONS.HEALTH_ACTIONS
          }
          handleTabChange={handleTabChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={HEALTH_PLAN_SECTIONS.HEALTH_ACTIONS}>
        <HealthPlanActions
          patient={patient}
          healthPlan={healthPlan}
          healthPlanActions={healthPlanActions}
          actionDefinitions={actionDefinitions}
          readOnly={readOnly}
        />
        <NavStepper
          previousSectionId={HEALTH_PLAN_REVIEW_SECTIONS.CYCLE_OF_CARE}
          nextSectionId={
            isHealthPlanReview
              ? HEALTH_PLAN_REVIEW_FULL_SECTIONS.PROGRESS
              : HEALTH_PLAN_SECTIONS.FEEDBACK
          }
          handleTabChange={handleTabChange}
        />
      </TabPanel>
      {isHealthPlanReview && (
        <TabPanel
          value={activeTab}
          index={HEALTH_PLAN_REVIEW_FULL_SECTIONS.PROGRESS}
        >
          <GoalProgressReviews
            patient={patient}
            healthPlan={healthPlan}
            barrierDefinitions={barrierDefinitions}
            solutionDefinitions={solutionDefinitions}
            readOnly={readOnly}
          />
          <NavStepper
            previousSectionId={
              !isHealthPlanReviewFull
                ? HEALTH_PLAN_SECTIONS.CYCLE_OF_CARE
                : HEALTH_PLAN_SECTIONS.HEALTH_ACTIONS
            }
            nextSectionId={HEALTH_PLAN_SECTIONS.FEEDBACK}
            handleTabChange={handleTabChange}
          />
        </TabPanel>
      )}
      <TabPanel value={activeTab} index={HEALTH_PLAN_SECTIONS.FEEDBACK}>
        <HealthPlanFeedback
          patient={patient}
        />
        <NavStepper
          previousSectionId={isHealthPlanReview
            ? HEALTH_PLAN_REVIEW_FULL_SECTIONS.PROGRESS
            : HEALTH_PLAN_SECTIONS.HEALTH_ACTIONS}
          nextSectionId={HEALTH_PLAN_SECTIONS.SUMMARY}
          handleTabChange={handleTabChange}
        />
      </TabPanel>
      <TabPanel
        value={activeTab}
        index={HEALTH_PLAN_SECTIONS.SUMMARY}
        component={React.Fragment}
      >
        <Summary
          user={user}
          patient={patient}
          healthPlan={healthPlan}
          healthPlanActions={healthPlanActions}
          patientCycleOfCareModules={patientCycleOfCareModules}
          barrierDefinitions={barrierDefinitions}
          serviceDefinitions={serviceDefinitions}
          solutionDefinitions={solutionDefinitions}
          isHealthPlanReview={isHealthPlanReview}
          isHealthPlanReviewFull={isHealthPlanReviewFull}
          history={history}
          handleTabChange={handleTabChange}
          readOnly={readOnly}
        />
      </TabPanel>
    </>
  );
};

HealthPlanPage.defaultProps = {
  healthPlanActions: [],
  patientCycleOfCareModules: [],
  actionDefinitions: {},
  barrierDefinitions: {},
  serviceDefinitions: {},
  solutionDefinitions: {},
  cycleOfCareModuleDefinitions: {},
};
HealthPlanPage.propTypes = {
  user: PropTypes.shape(UserPropType).isRequired,
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanActions: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanActionPropType,
      healthPlan: PropTypes.number,
      definition: PropTypes.shape(ActionPropType),
    })
  ),
  patientCycleOfCareModules: PropTypes.arrayOf(
    PropTypes.shape({
      ...PatientCycleOfCareModulePropType,
      patientCycleOfCareItems: PropTypes.arrayOf(
        PropTypes.shape({
          ...PatientCycleOfCareItemPropType,
          definition: PropTypes.shape(CycleOfCareItemPropType),
        })
      ),
      definition: PropTypes.shape(CycleOfCareModulePropType),
    })
  ),
  actionDefinitions: PropTypes.objectOf(PropTypes.shape(ActionPropType)),
  barrierDefinitions: PropTypes.objectOf(PropTypes.shape(BarrierPropType)),
  serviceDefinitions: PropTypes.objectOf(PropTypes.shape(ServicePropType)),
  solutionDefinitions: PropTypes.objectOf(PropTypes.shape(SolutionPropType)),
  cycleOfCareModuleDefinitions: PropTypes.objectOf(
    PropTypes.shape(CycleOfCareModulePropType)
  ),
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default HealthPlanPage;

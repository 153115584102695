import React, { useState } from 'react';
import { isEmpty, isNull, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import {
  Box,
  Grow,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { Button } from 'common/components/buttons';
import { Checkbox } from 'common/components/form';
import { ExpandableCard, FlexBox } from 'common/components/layouts';
import {
  CYCLE_OF_CARE_ITEM_STATUSES,
  CYCLE_OF_CARE_MODULE_STATUSES,
  CYCLE_OF_CARE_MODULE_STATUS_NAMES,
} from 'common/constants';
import {
  CycleOfCareItemPropType,
  CycleOfCareModulePropType,
  PatientCycleOfCareItemPropType,
  PatientCycleOfCareModulePropType,
} from 'common/propTypes';
import { handleSubmit as handleDeleteModule } from 'common/data/patientCycleOfCareModules/delete';
import PatientCycleOfCareItemUpdate from './components/PatientCycleOfCareItemUpdate';
import PatientCycleOfCareItem from './components/PatientCycleOfCareItem';

const PatientCycleOfCareModule = ({
  patientCycleOfCareModule,
  deletePatientCycleOfCareModule,
  inlineLoading,
  readOnly,
}) => {
  const status = patientCycleOfCareModule.patientCycleOfCareItems.every(
    (item) => item.status === CYCLE_OF_CARE_ITEM_STATUSES.COMPLETE
  )
    ? CYCLE_OF_CARE_MODULE_STATUSES.COMPLETE
    : patientCycleOfCareModule.patientCycleOfCareItems.some(
        (item) => !isNull(item.completedAt)
      )
    ? CYCLE_OF_CARE_MODULE_STATUSES.IN_PROGRESS
    : CYCLE_OF_CARE_MODULE_STATUSES.NOT_YET_STARTED;

  const listHeadings = [
    { title: 'Check', align: 'left' },
    { title: 'Status', align: 'center' },
    { title: 'Frequency', align: 'center' },
    { title: 'Last Completed', align: 'center' },
    { title: 'Next Due', align: 'center' },
  ];

  const [
      showPatientCycleOfCareItemUpdate,
      setShowPatientCycleOfCareItemUpdate,
    ] = useState(false),
    handleToggleUpdate = () => {
      setShowPatientCycleOfCareItemUpdate(!showPatientCycleOfCareItemUpdate);
    };

  const [selected, setSelected] = React.useState([]),
    handleSelectAllClick = ({ target: { checked } }) => {
      checked
        ? setSelected(patientCycleOfCareModule.patientCycleOfCareItems)
        : setSelected([]);
    },
    handleSelectClick = (patientCycleOfCareItem, checked) => {
      if (checked) {
        setSelected([...selected, patientCycleOfCareItem]);
      } else {
        setSelected(
          selected.filter(
            (selection) => selection.id !== patientCycleOfCareItem.id
          )
        );
      }
    };

  const handleToggleSelectedUpdate = () => {
    setShowPatientCycleOfCareItemUpdate(!showPatientCycleOfCareItemUpdate);
    setSelected([]);
  };

  const handleOnDeleteModule = () => {
    handleDeleteModule({
      patientCycleOfCareModule,
      deletePatientCycleOfCareModule,
    });
  };

  return (
    <>
      <ExpandableCard
        cardContent={
          <Box>
            <Typography variant="h2">
              {patientCycleOfCareModule.definition.name}
            </Typography>
            <Typography
              variant="h6"
              color={
                status === CYCLE_OF_CARE_MODULE_STATUSES.COMPLETE
                  ? 'primary'
                  : status === CYCLE_OF_CARE_MODULE_STATUSES.NOT_YET_STARTED
                  ? 'error'
                  : 'textSecondary'
              }
            >
              {CYCLE_OF_CARE_MODULE_STATUS_NAMES[status]}
            </Typography>
          </Box>
        }
      >
        <Table className="dense">
          <TableHead>
            <TableRow>
              {!readOnly && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selected.length ===
                      patientCycleOfCareModule.patientCycleOfCareItems.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {listHeadings.map((heading, idx) => (
                <TableCell key={idx} align={heading.align}>
                  {heading.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(patientCycleOfCareModule.patientCycleOfCareItems, [
              'id',
            ]).map((patientCycleOfCareItem, idx) => (
              <PatientCycleOfCareItem
                key={idx}
                selected={selected.includes(patientCycleOfCareItem)}
                setSelected={handleSelectClick}
                patientCycleOfCareItem={patientCycleOfCareItem}
                loading={inlineLoading.includes(patientCycleOfCareItem.id)}
                readOnly={readOnly}
              />
            ))}
          </TableBody>
        </Table>
        {!readOnly && (
          <FlexBox flexDirection="row" justifyContent="space-between" mt="1rem">
            <Button
              label="Remove Module"
              color="error"
              onClick={handleOnDeleteModule}
              disabled={!isEmpty(selected)}
            />
            {!isEmpty(selected) && showPatientCycleOfCareItemUpdate && (
              <PatientCycleOfCareItemUpdate
                open={showPatientCycleOfCareItemUpdate}
                patientCycleOfCareItems={selected}
                onClose={handleToggleSelectedUpdate}
              />
            )}
            <Grow in={!isEmpty(selected)}>
              <div>
                <Button
                  label="Update Check"
                  color="secondary"
                  onClick={handleToggleUpdate}
                />
              </div>
            </Grow>
          </FlexBox>
        )}
      </ExpandableCard>
    </>
  );
};

PatientCycleOfCareModule.defaultProps = {
  inlineLoading: [],
};
PatientCycleOfCareModule.propTypes = {
  patientCycleOfCareModule: PropTypes.shape({
    ...PatientCycleOfCareModulePropType,
    definition: PropTypes.shape(CycleOfCareModulePropType),
    patientCycleOfCareItems: PropTypes.arrayOf(
      PropTypes.shape({
        ...PatientCycleOfCareItemPropType,
        definition: PropTypes.shape(CycleOfCareItemPropType),
      })
    ),
  }).isRequired,
  deletePatientCycleOfCareModule: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array,
  readOnly: PropTypes.bool.isRequired,
};

export default PatientCycleOfCareModule;

import {
  CREATE_GOAL_PROGRESS_SOLUTION_INIT,
  CREATE_GOAL_PROGRESS_SOLUTION_SUCCESS,
  DELETE_GOAL_PROGRESS_SOLUTION_INIT,
  DELETE_GOAL_PROGRESS_SOLUTION_SUCCESS,
} from './actionNames';

export function createGoalProgressSolutionInit(actionParams) {
  return { type: CREATE_GOAL_PROGRESS_SOLUTION_INIT, ...actionParams };
}

export function createGoalProgressSolutionSuccess({ data }) {
  return { type: CREATE_GOAL_PROGRESS_SOLUTION_SUCCESS, data };
}

export function deleteGoalProgressSolutionInit(actionParams) {
  return {
    type: DELETE_GOAL_PROGRESS_SOLUTION_INIT,
    ...actionParams,
  };
}

export function deleteGoalProgressSolutionSuccess({ data }) {
  return { type: DELETE_GOAL_PROGRESS_SOLUTION_SUCCESS, data };
}

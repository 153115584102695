import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({
  query: { patientId, patientCycleOfCareItemId },
  data,
}) {
  return http
    .patch(
      `/patients/${patientId}/cycles_of_care_item/${patientCycleOfCareItemId}/`,
      {
        data,
      }
    )
    .catch(handleHTTPError);
}

import { isUndefined } from 'lodash';
import { TableCell as MuiTableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableCell = styled(MuiTableCell)(({ theme, color }) => ({
  color: !isUndefined(theme.palette[color]) ? theme.palette[color].main : color,
  backgroundColor: '#fff',
  borderBottom: 'unset',
  '&:first-of-type': {
    borderTopLeftRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
  },
  '&:last-of-type': {
    borderTopRightRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
  },
}));

import { useEffect } from 'react';

// DISCUSS: doesn't seem to be working
export default function ({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo({ top: 0 }));

    return () => unlisten();
  }, [history]);

  return null;
}

import React from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Typography, Tooltip } from '@mui/material';
import { FlexBox, ColorIndicator } from 'common/components/layouts';
import { Chip as StyledChip } from './styles';

// TODO: sortBy createdAt
const GoalChips = ({ goals, variant, dense }) => (
  <FlexBox
    alignItems="center"
    flexWrap={!dense ? 'wrap' : 'nowrap'}
    overflow={!dense ? 'hidden' : 'unset'}
    mb={dense ? 0 : 2}
  >
    {sortBy(goals, ['id']).map((goal, idx) =>
      !dense ? (
        <StyledChip
          key={idx}
          avatar={<ColorIndicator color={goal.color} dense />}
          label={
            <Typography variant={variant} color="textSecondary" noWrap>
              {goal.name}
            </Typography>
          }
          size="small"
        />
      ) : (
        <Tooltip key={idx} title={goal.name} placement="top-start">
          <span>
            <ColorIndicator color={goal.color} />
          </span>
        </Tooltip>
      )
    )}
  </FlexBox>
);

GoalChips.defaultProps = {
  variant: 'h6',
  dense: false,
};
GoalChips.propTypes = {
  goals: PropTypes.array,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2',
  ]),
  dense: PropTypes.bool,
};

export default GoalChips;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { patientsPath } from 'routing/utils/paths';
import { Typography } from '@mui/material';
import {
  UserPropType,
  DashboardPatientsPropType,
  DashboardHealthPlansPropType,
} from 'common/propTypes';
import {
  insertIf,
  isUserDoctor,
  isUserNurse,
  renderFullName,
} from 'common/utils';
import { ClickableCard, FlexBox, TitleBox } from 'common/components/layouts';

const DashboardPage = ({
  user,
  patients,
  healthPlans,
  fetchDashboard,
  resetDashboard,
}) => {
  useEffect(() => {
    fetchDashboard();
    return () => resetDashboard();
  }, [fetchDashboard, resetDashboard]);

  const linkOptions = [
    {
      title: 'Overdue Reviews',
      count: healthPlans.overdue,
      color: 'error',
      to: { pathname: patientsPath(), state: { status: 'overdue' } },
    },
    ...insertIf(isUserDoctor(user), {
      title: 'Health Plans to Finalise',
      count: healthPlans.toFinalise,
      color: 'warning',
      to: { pathname: patientsPath(), state: { status: 'shared' } },
    }),
    ...insertIf(isUserNurse(user), {
      title: 'Health Plans In Progress',
      count: healthPlans.inProgress,
      color: 'warning',
      to: { pathname: patientsPath(), state: { status: 'in_progress' } },
    }),
    {
      title: 'Upcoming Reviews',
      count: healthPlans.upcoming,
      color: 'success',
      to: { pathname: patientsPath(), state: { status: 'upcoming' } },
    },
    {
      title: 'All Patients',
      count: patients.total,
      color: 'primary',
      to: patientsPath,
    },
  ];

  return (
    <>
      <TitleBox
        contentLeft={
          <Typography variant="h1" color="primary">
            Welcome, {renderFullName(user)}
          </Typography>
        }
      />
      <Typography variant="h2" paragraph>
        Health Plans
      </Typography>
      {linkOptions.map((link, idx) => (
        <ClickableCard
          key={idx}
          color={link.color}
          component={Link}
          to={link.to}
        >
          <FlexBox alignItems="center" justifyContent="space-between">
            <Typography variant="h4">{link.title}</Typography>
            <Typography variant="h3">{link.count}</Typography>
          </FlexBox>
        </ClickableCard>
      ))}
    </>
  );
};

DashboardPage.defaultProps = {
  patients: {
    total: 0,
  },
  healthPlans: {
    inProgress: 0,
    toFinalise: 0,
    upcoming: 0,
    overdue: 0,
    total: 0,
  },
};
DashboardPage.propTypes = {
  user: PropTypes.shape(UserPropType).isRequired,
  patients: PropTypes.shape(DashboardPatientsPropType),
  healthPlans: PropTypes.shape(DashboardHealthPlansPropType),
  fetchDashboard: PropTypes.func.isRequired,
  resetDashboard: PropTypes.func.isRequired,
};

export default DashboardPage;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { HealthPlanPropType, PatientPropType } from 'common/propTypes';
import { renderFullName } from 'common/utils';
import PatientConditions from './components/PatientConditions';
import HealthPlanMeasurements from './components/HealthPlanMeasurements';

const ClinicalData = ({ patient, healthPlan, readOnly }) => {
  return (
    <>
      <Typography color="textSecondary" paragraph>
        Add clinical data relevant to {renderFullName(patient)}'s Health Goals.
      </Typography>
      <PatientConditions
        patient={patient}
        healthPlan={healthPlan}
        readOnly={readOnly}
      />
      <HealthPlanMeasurements
        patient={patient}
        healthPlan={healthPlan}
        readOnly={readOnly}
      />
    </>
  );
};

ClinicalData.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default ClinicalData;

import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS,
  CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS,
  RESET_PATIENT_CYCLE_OF_CARE_MODULES,
  DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS,
} from './actionNames';
import { UPDATE_PATIENT_CYCLE_OF_CARE_ITEM_SUCCESS } from 'services/database/actions/patientCycleOfCareItems/redux/actionNames';

const initialState = {};

function handleFetchCycleOfCareModulesSuccess(state, { data }) {
  return { ...state, ...data };
}

function handleCreateCycleOfCareModuleSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleDeleteCycleOfCareModuleSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id].isActive = false;
  });
  return nextState;
}

function handleUpdateCycleOfCareItemSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.patientCycleOfCareModule].patientCycleOfCareItems[data.id] =
      data;
  });
  return { ...state, ...nextState };
}

function handleResetCyclesOfCareGoals() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS]:
    handleFetchCycleOfCareModulesSuccess,
  [CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS]:
    handleCreateCycleOfCareModuleSuccess,
  [DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS]:
    handleDeleteCycleOfCareModuleSuccess,
  [UPDATE_PATIENT_CYCLE_OF_CARE_ITEM_SUCCESS]:
    handleUpdateCycleOfCareItemSuccess,
  [RESET_PATIENT_CYCLE_OF_CARE_MODULES]: handleResetCyclesOfCareGoals,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/healthPlanMeasurements/create';
import { createHealthPlanMeasurementInit } from 'services/database/actions/healthPlanMeasurements';
import HealthPlanMeasurementAdd from './HealthPlanMeasurementAdd';

export default compose(
  withAction({
    actionName: 'createHealthPlanMeasurement',
    action: createHealthPlanMeasurementInit,
  }),
  withFormik({ mapPropsToValues, validationSchema, handleSubmit })
)(HealthPlanMeasurementAdd);

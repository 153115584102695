import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import {
  FlexBox,
  ContentBox,
  ExpandableCard,
  GoalChips,
} from 'common/components/layouts';
import { handleSubmit as handleDelete } from 'common/data/healthPlanActions/delete';
import {
  ActionPropType,
  GoalPropType,
  HealthPlanActionPropType,
  HealthPlanGoalPropType,
  PatientPropType,
} from 'common/propTypes';

const HealthPlanAction = ({
  index,
  patient,
  healthPlanAction,
  healthPlanActionGoals,
  deleteHealthPlanAction,
  readOnly,
}) => {
  const handleOnDelete = () => {
    handleDelete({ patient, healthPlanAction, deleteHealthPlanAction });
  };

  return (
    <ExpandableCard
      cardContent={
        <FlexBox flexDirection="column">
          <FlexBox alignItems="baseline">
            <ContentBox width="max-content" mr={1}>
              <Typography variant="h5" color="textSecondary">
                Action #{index}
              </Typography>
            </ContentBox>
            <GoalChips goals={healthPlanActionGoals} dense />
          </FlexBox>
          <Typography variant="h2">
            {healthPlanAction.definition.name}
          </Typography>
        </FlexBox>
      }
    >
      <Typography
        dangerouslySetInnerHTML={{
          __html: healthPlanAction.definition.description,
        }}
        paragraph
      />
      {!readOnly && (
        <Box mt="1rem">
          <Button label="Remove" color="error" onClick={handleOnDelete} />
        </Box>
      )}
    </ExpandableCard>
  );
};

HealthPlanAction.propTypes = {
  index: PropTypes.number.isRequired,
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlanAction: PropTypes.shape({
    ...HealthPlanActionPropType,
    healthPlan: PropTypes.number,
    definition: PropTypes.shape(ActionPropType),
  }).isRequired,
  healthPlanActionGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
  deleteHealthPlanAction: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanAction;

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { FlexBox } from 'common/components/layouts';

const ComponentLoader = ({ color, size }) => (
  <FlexBox alignItems="center" justifyContent="center">
    <CircularProgress color={color} size={size} />
  </FlexBox>
);

ComponentLoader.defaultProps = {
  color: 'primary',
  size: 30,
};
ComponentLoader.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  size: PropTypes.number,
};

export default ComponentLoader;

import { all, takeLatest } from 'redux-saga/effects';
import loginSaga from 'services/backend/actions/auth/login';
import logoutSaga from 'services/backend/actions/auth/logout';
import { USER_LOGIN_INIT, USER_LOGOUT_INIT } from './actionNames';

function* authFlowSaga() {
  yield all([takeLatest(USER_LOGIN_INIT, loginSaga)]);
  yield all([takeLatest(USER_LOGOUT_INIT, logoutSaga)]);
}

export default authFlowSaga;

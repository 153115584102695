import { Step, StepLabel, Typography } from '@mui/material';
import GlobalTheme from 'common/styles/global';

export default function ({ key, item, labelProps }) {
  return (
    <Step key={key} sx={{ minWidth: GlobalTheme.spacing(20) }}>
      <StepLabel {...labelProps}>
        <Typography color={item.overdue && 'error'}>{item.name}</Typography>
      </StepLabel>
    </Step>
  );
}

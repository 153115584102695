import { all, takeEvery } from 'redux-saga/effects';
import createSaga from 'services/backend/actions/goalProgressBarriers/create';
import deleteSaga from 'services/backend/actions/goalProgressBarriers/delete';
import {
  CREATE_GOAL_PROGRESS_BARRIER_INIT,
  DELETE_GOAL_PROGRESS_BARRIER_INIT,
} from './actionNames';

function* goalProgressBarriersFlowSaga() {
  yield all([
    takeEvery(CREATE_GOAL_PROGRESS_BARRIER_INIT, createSaga),
    takeEvery(DELETE_GOAL_PROGRESS_BARRIER_INIT, deleteSaga),
  ]);
}

export default goalProgressBarriersFlowSaga;

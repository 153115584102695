import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import {
  BarrierPropType,
  GoalPropType,
  SolutionPropType,
  HealthPlanReviewPropType,
  PatientPropType,
  HealthPlanPropType,
} from 'common/propTypes';
import { renderFirstName } from 'common/utils';
import GoalProgressReview from './components/GoalProgressReview';

const GoalProgressReviews = ({
  patient,
  healthPlan,
  currentHealthPlanReview,
  previousHealthPlanReviews,
  barrierDefinitions,
  goalDefinitions,
  solutionDefinitions,
  fetchHealthPlanReviews,
  resetHealthPlanReviews,
  readOnly,
}) => {
  useEffect(() => {
    fetchHealthPlanReviews({ query: { healthPlanId: healthPlan.id } });
    return () => resetHealthPlanReviews();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography color="textSecondary" paragraph>
        Select a Health Goal to log {renderFirstName(patient)}'s progress.
      </Typography>

      {!isEmpty(currentHealthPlanReview) &&
        Object.values(currentHealthPlanReview.goalProgressReviews).map(
          (goalProgressReview, idx) => (
            <GoalProgressReview
              key={idx}
              index={idx + 1}
              patient={patient}
              previousHealthPlanReviews={previousHealthPlanReviews}
              goalProgressReview={{
                ...goalProgressReview,
                goalDefinition:
                  goalDefinitions[goalProgressReview.goalDefinition],
              }}
              barrierDefinitions={barrierDefinitions}
              solutionDefinitions={solutionDefinitions}
              readOnly={readOnly}
            />
          )
        )}
    </>
  );
};

GoalProgressReviews.defaultProps = {
  currentHealthPlanReview: {},
  previousHealthPlanReviews: [],
};
GoalProgressReviews.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  currentHealthPlanReview: PropTypes.shape(HealthPlanReviewPropType),
  previousHealthPlanReviews: PropTypes.arrayOf(
    PropTypes.shape(HealthPlanReviewPropType)
  ),
  barrierDefinitions: PropTypes.objectOf(PropTypes.shape(BarrierPropType))
    .isRequired,
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
  solutionDefinitions: PropTypes.objectOf(PropTypes.shape(SolutionPropType))
    .isRequired,
  fetchHealthPlanReviews: PropTypes.func.isRequired,
  resetHealthPlanReviews: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default GoalProgressReviews;

export default function () {
  return {
    firstName: '',
    lastName: '',
    email: '',
    medicareNumber: '',
    generalPractitioner: '',
    phoneNumber: '',
    dateOfBirth: null,
    sex: '',
    street: '',
    city: '',
    state: '',
    postcode: '',
  };
}

import { put, call } from 'redux-saga/effects';
import { createHealthJourneySuccess } from 'services/database/actions/healthJourney';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import request from './request';

export default function* createSaga({ query, data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: 'healthJourney-create' }));
    const responseData = yield call(request, { query, data });
    yield put(createHealthJourneySuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    return error;
  } finally {
    yield put(setInlineLoadingOff({ data: 'healthJourney-create' }));
  }
}

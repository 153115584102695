import React from 'react';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import { Box as StyledBox, Checkbox as StyledCheckbox } from './styles';

const Checkbox = ({
  name,
  label,
  checked,
  onChange,
  disabled,
  variant,
  margin,
  padding,
  color,
  errorMessage,
  loading,
  ...props
}) => (
  <FormControl margin={margin} error={!isUndefined(errorMessage)} {...props}>
    <FormControlLabel
      label={<Typography variant={variant}>{label}</Typography>}
      control={
        loading ? (
          <StyledBox padding={padding}>
            <CircularProgress color={color} size={20} />
          </StyledBox>
        ) : (
          <StyledCheckbox
            name={name}
            checked={checked}
            onChange={onChange}
            size="small"
            padding={padding}
            color={color}
            error={!isUndefined(errorMessage)}
          />
        )
      }
      disabled={disabled}
      style={{ marginLeft: 'unset' }}
    />
    <FormHelperText style={{ marginLeft: '28px' }}>
      {errorMessage}
    </FormHelperText>
  </FormControl>
);

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  variant: 'body2',
  margin: 'none',
  padding: 'normal',
  color: 'primary',
};
Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  padding: PropTypes.oneOf(['dense', 'none', 'normal']),
};

export default Checkbox;

export default function ({ goalProgressSolution, deleteGoalProgressSolution }) {
  return deleteGoalProgressSolution({
    query: {
      healthPlanId: goalProgressSolution.healthPlan,
      healthPlanReviewId: goalProgressSolution.healthPlanReview,
      goalProgressReviewId: goalProgressSolution.goalProgressReview,
      goalProgressBarrierId: goalProgressSolution.goalProgressBarrier,
      goalProgressSolutionId: goalProgressSolution.id,
      definitionId: goalProgressSolution.definition,
    },
  });
}

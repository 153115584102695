import {
  FETCH_NOTIFICATIONS_INIT,
  FETCH_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_INIT,
  UPDATE_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_COUNT_UNREAD_INIT,
  FETCH_NOTIFICATION_COUNT_UNREAD_SUCCESS,
  RESET_NOTIFICATIONS,
} from './actionNames';

export function fetchNotificationsInit(actionParams) {
  return { type: FETCH_NOTIFICATIONS_INIT, ...actionParams };
}

export function fetchNotificationsSuccess({ data }) {
  return { type: FETCH_NOTIFICATIONS_SUCCESS, data };
}

export function updateNotificationInit(actionParams) {
  return { type: UPDATE_NOTIFICATION_INIT, ...actionParams };
}

export function updateNotificationSuccess() {
  return { type: UPDATE_NOTIFICATION_SUCCESS };
}

export function fetchNotificationCountUnreadInit(actionParams) {
  return { type: FETCH_NOTIFICATION_COUNT_UNREAD_INIT, ...actionParams };
}

export function fetchNotificationCountUnreadSuccess({ data }) {
  return { type: FETCH_NOTIFICATION_COUNT_UNREAD_SUCCESS, data };
}

export function resetNotifications(actionParams) {
  return { type: RESET_NOTIFICATIONS, ...actionParams };
}

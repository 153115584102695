import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Typography } from '@mui/material';
import { Form, Input } from 'common/components/form';
import { MEASUREMENT_VALUE_TARGET_TYPE_LABELS } from 'common/constants';
import {
  HealthPlanMeasurementPropType,
  MeasurementPropType,
  PatientMeasurementPropType,
} from 'common/propTypes';

const HealthPlanMeasurementInput = ({
  healthPlanMeasurement,
  values: { target },
  initialValues: { target: initialTarget },
  handleSubmit,
  readOnly,
}) => {
  const handleOnBlur = () => {
    target !== initialTarget && handleSubmit();
  };

  return (
    <Form>
      <Input
        name="target"
        value={target}
        inputProps={{ style: { textAlign: 'right' } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" disableTypography>
              <Typography variant="h4" color="textSecondary">
                {
                  MEASUREMENT_VALUE_TARGET_TYPE_LABELS[
                    healthPlanMeasurement.definition.valueTargetType
                  ]
                }
              </Typography>
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          style: {
            textAlign: 'right',
          },
        }}
        margin="dense"
        onBlur={handleOnBlur}
        disabled={readOnly}
        preventDefault
      />
    </Form>
  );
};

HealthPlanMeasurementInput.propTypes = {
  healthPlanMeasurement: PropTypes.shape({
    ...HealthPlanMeasurementPropType,
    healthPlan: PropTypes.number,
    lastMeasurement: PropTypes.shape(PatientMeasurementPropType),
    definition: PropTypes.shape(MeasurementPropType),
  }).isRequired,
  values: PropTypes.shape({ target: PropTypes.string }).isRequired,
  initialValues: PropTypes.shape({ target: PropTypes.string }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanMeasurementInput;

import { compile } from 'path-to-regexp';
import paths from '../paths';

export const loginPath = compile(paths.LOGIN);
export const dashboardPath = compile(paths.DASHBOARD);
export const settingsPath = compile(paths.SETTINGS);
export const providersPath = compile(paths.PROVIDERS);
export const patientsPath = compile(paths.PATIENTS);
export const patientProfilePath = compile(paths.PATIENT_DASHBOARD);
export const patientHealthPlanPath = compile(paths.PATIENT_HEALTH_PLAN);

// export const healthPlanHealthGoalsPath = compile(
//   paths.HEALTH_PLAN_HEALTH_GOALS
// );
// export const healthPlanClinicalDataPath = compile(
//   paths.HEALTH_PLAN_CLINICAL_DATA
// );
// export const healthPlanHealthActionsPath = compile(
//   paths.HEALTH_PLAN_HEALTH_ACTIONS
// );
// export const healthPlanHealthServicesPath = compile(
//   paths.HEALTH_PLAN_HEALTH_SERVICES
// );
// export const healthPlanHealthTeamPath = compile(paths.HEALTH_PLAN_HEALTH_TEAM);
// export const healthPlanCycleOfCarePath = compile(
//   paths.HEALTH_PLAN_CYCLE_OF_CARE
// );
// export const healthPlanHealthChecksPath = compile(
//   paths.HEALTH_PLAN_HEALTH_CHECKS
// );
// export const healthPlanSummaryPath = compile(paths.HEALTH_PLAN_SUMMARY);

// export const healthPlanReviewHealthGoalsPath = compile(
//   paths.HEALTH_PLAN_REVIEW_HEALTH_GOALS
// );
// export const healthPlanReviewClinicalDataPath = compile(
//   paths.HEALTH_PLAN_REVIEW_CLINICAL_DATA
// );
// export const healthPlanReviewHealthActionsPath = compile(
//   paths.HEALTH_PLAN_REVIEW_HEALTH_ACTIONS
// );
// export const healthPlanReviewHealthServicesPath = compile(
//   paths.HEALTH_PLAN_REVIEW_HEALTH_SERVICES
// );
// export const healthPlanReviewHealthTeamPath = compile(
//   paths.HEALTH_PLAN_REVIEW_HEALTH_TEAM
// );
// export const healthPlanReviewCycleOfCarePath = compile(
//   paths.HEALTH_PLAN_REVIEW_CYCLE_OF_CARE
// );
// export const healthPlanReviewHealthChecksPath = compile(
//   paths.HEALTH_PLAN_REVIEW_HEALTH_CHECKS
// );
// export const healthPlanReviewSummaryPath = compile(
//   paths.HEALTH_PLAN_REVIEW_SUMMARY
// );
// export const healthPlanReviewProgressPath = compile(
//   paths.HEALTH_PLAN_REVIEW_PROGRESS
// );

export const notificationsPath = compile(paths.NOTIFICATIONS);

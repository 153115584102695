import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patientCycleOfCareModules/create';
import { createPatientCycleOfCareModuleInit } from 'services/database/actions/patientCycleOfCareModules';
import PatientCycleOfCareModuleAdd from './PatientCycleOfCareModuleAdd';

export default compose(
  withAction({
    actionName: 'createPatientCycleOfCareModule',
    action: createPatientCycleOfCareModuleInit,
  }),
  withFormik({ mapPropsToValues, validationSchema, handleSubmit })
)(PatientCycleOfCareModuleAdd);

import GreycliffCFRegularWoff2 from 'common/assets/fonts/greycliffcf-regular-webfont.woff2';
import GreycliffCFRegularWoff from 'common/assets/fonts/greycliffcf-regular-webfont.woff';
import GreycliffCFBoldWoff2 from 'common/assets/fonts/greycliffcf-bold-webfont.woff2';
import GreycliffCFBoldWoff from 'common/assets/fonts/greycliffcf-bold-webfont.woff';

export const greycliffCF = [
  {
    fontFamily: 'GreycliffCF',
    src: `url(${GreycliffCFRegularWoff2}) format('woff2'), url(${GreycliffCFRegularWoff}) format('woff')`,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  {
    fontFamily: 'GreycliffCF',
    src: `url(${GreycliffCFBoldWoff2}) format('woff2'), url(${GreycliffCFBoldWoff}) format('woff')`,
    fontStyle: 'normal',
    fontWeight: 700,
  },
];

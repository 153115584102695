import { isUndefined } from 'lodash';
import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  {
    props: {
      patient,
      inactivePatientCycleOfCareModules,
      createPatientCycleOfCareModule,
    },
  }
) {
  const handleOnSuccess = () => {},
    handleOnError = () => {};

  const inactiveModule = inactivePatientCycleOfCareModules.find(
    (patientCycleOfCareModule) =>
      patientCycleOfCareModule.definition.id === Number(values.definitionId)
  );

  return createPatientCycleOfCareModule({
    query: {
      patientId: patient.id,
      inactiveModuleId: !isUndefined(inactiveModule) && inactiveModule.id,
    },
    data: isUndefined(inactiveModule)
      ? mapValuesToProps(values)
      : { isActive: true },
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

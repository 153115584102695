import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Form, Input, SelectField } from 'common/components/form';
import {
  PROVIDER_GROUP_NAMES,
  PROVIDER_TYPES_BY_GROUP,
} from 'common/constants';
import { renderObject } from 'common/utils';
import { Button } from 'common/components/buttons';
import { isEmpty } from 'lodash';

const AddProvider = ({
  open,
  values: { firstName, lastName, email, ahpraNumber, userType, providerType },
  values,
  setValues,
  handleSubmit,
  onClose,
  inlineLoading,
}) => {
  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>Add Provider</DialogTitle>
      <DialogContent>
        <Form>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <Input
                label="First Name"
                name="firstName"
                value={firstName}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Input
                label="Last Name"
                name="lastName"
                value={lastName}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <Input
                label="Email"
                name="email"
                value={email}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Input
                label="Ahpra Number"
                name="ahpraNumber"
                value={ahpraNumber}
                placeholder="eg AAA1234567890"
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                name="userType"
                label="Provider Group"
                value={userType}
                options={PROVIDER_GROUP_NAMES}
                renderSelectLabel={renderObject}
                sortByIndex
                setValues={setValues}
                values={values}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectField
                name="providerType"
                label="Provider Type"
                value={providerType}
                options={
                  !isEmpty(userType) ? PROVIDER_TYPES_BY_GROUP[userType] : {}
                }
                renderSelectLabel={renderObject}
                sortByIndex
                setValues={setValues}
                values={values}
                margin="none"
                disabled={isEmpty(userType) || !isEmpty(inlineLoading)}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="cancel"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={!isEmpty(inlineLoading)}
        />
        <Button
          label="Add Provider"
          color="primary"
          onClick={handleSubmit}
          disabled={!isEmpty(inlineLoading)}
        />
      </DialogActions>
    </Dialog>
  );
};

AddProvider.defaultProps = {};
AddProvider.propTypes = {
  open: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    ahpraNumber: PropTypes.string,
    userType: PropTypes.string,
    providerType: PropTypes.string,
  }),
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array.isRequired,
};

export default AddProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Checkbox } from 'common/components/form';
import { TableRow, TableCell } from 'common/components/layouts';
import {
  CYCLE_OF_CARE_FREQUENCIES,
  CYCLE_OF_CARE_ITEM_STATUSES,
  CYCLE_OF_CARE_ITEM_STATUS_NAMES,
} from 'common/constants';
import {
  CycleOfCareItemPropType,
  PatientCycleOfCareItemPropType,
} from 'common/propTypes';
import { renderDate } from 'common/utils';

const PatientCycleOfCareItem = ({
  patientCycleOfCareItem,
  selected,
  setSelected,
  loading,
  readOnly,
}) => {
  const isOverdue =
      patientCycleOfCareItem.status === CYCLE_OF_CARE_ITEM_STATUSES.OVERDUE,
    isUpcoming =
      patientCycleOfCareItem.status === CYCLE_OF_CARE_ITEM_STATUSES.UPCOMING;

  const handleOnClick = () => {
    setSelected(patientCycleOfCareItem, !selected);
  };

  return (
    <TableRow
      color={isOverdue ? 'error' : isUpcoming ? 'warning' : 'success'}
      onClick={handleOnClick}
      disabled={readOnly}
      dense
    >
      {!readOnly && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} padding="dense" loading={loading} />
        </TableCell>
      )}
      <TableCell align="left">
        <Typography>{patientCycleOfCareItem.definition.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography color={isOverdue ? 'error' : 'textPrimary'}>
          {CYCLE_OF_CARE_ITEM_STATUS_NAMES[patientCycleOfCareItem.status]}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {CYCLE_OF_CARE_FREQUENCIES[patientCycleOfCareItem.frequency]}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {renderDate(patientCycleOfCareItem.completedAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color={isOverdue ? 'error' : 'textPrimary'}>
          {renderDate(patientCycleOfCareItem.nextCheckDue)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

PatientCycleOfCareItem.propTypes = {
  patientCycleOfCareItem: PropTypes.shape({
    ...PatientCycleOfCareItemPropType,
    definition: PropTypes.shape(CycleOfCareItemPropType),
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default PatientCycleOfCareItem;

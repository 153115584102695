import { patientHealthPlanPath } from 'routing/utils/paths';

export default function ({ healthPlan, createHealthPlanReview, history }) {
  const handleOnSuccess = () => {
    history.push(patientHealthPlanPath({ patientId: healthPlan.patient }));
  };

  return createHealthPlanReview({
    query: { healthPlanId: healthPlan.id },
    data: { healthPlan: healthPlan.id },
  }).then(handleOnSuccess);
}

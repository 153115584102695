const adaptResponseData = function (patient) {
  return {
    id: patient.id,
    firstName: patient.firstName,
    lastName: patient.lastName,
    email: patient.email,
    generalPractitioner: patient.patientProfile.generalPractitioner,
    practice: patient.patientProfile.practice,
    dateOfBirth: patient.patientProfile.dateOfBirth,
    medicareNumber: patient.patientProfile.medicareNumber,
    phoneNumber: patient.patientProfile.phoneNumber,
    street: patient.patientProfile.street,
    city: patient.patientProfile.city,
    state: patient.patientProfile.state,
    postcode: patient.patientProfile.postcode,
    sex: patient.patientProfile.sex,
    healthPlan: patient.healthPlan && {
      status: patient.healthPlan.status,
      isReview: patient.healthPlan.isReview,
      nextReview: patient.healthPlan.nextReview,
    },
  };
};

export default adaptResponseData;

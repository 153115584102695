import { MEASUREMENT_KINDS } from 'common/constants';
import { isNull } from 'lodash';

export default function ({ healthPlanMeasurement }) {
  const isSplitValue =
    healthPlanMeasurement.definition.kind === MEASUREMENT_KINDS.SPLIT_VALUE;

  return {
    isSplitValue: isSplitValue,
    valueType: healthPlanMeasurement.definition.valueType,
    target: !isSplitValue
      ? healthPlanMeasurement.valueTarget || ''
      : !isNull(healthPlanMeasurement.valueTarget) &&
        !isNull(healthPlanMeasurement.secondValueTarget)
      ? `${healthPlanMeasurement.valueTarget} / ${healthPlanMeasurement.secondValueTarget}`
      : '',
  };
}

import {
  FETCH_HEALTH_JOURNEY_INIT,
  FETCH_HEALTH_JOURNEY_SUCCESS,
  CREATE_HEALTH_JOURNEY_INIT,
  CREATE_HEALTH_JOURNEY_SUCCESS,
  UPDATE_HEALTH_JOURNEY_ITEM_INIT,
  UPDATE_HEALTH_JOURNEY_ITEM_SUCCESS,
  RESET_HEALTH_JOURNEY,
} from './actionNames';

export function fetchHealthJourneyInit(actionParams) {
  return { type: FETCH_HEALTH_JOURNEY_INIT, ...actionParams };
}

export function fetchHealthJourneySuccess({ data }) {
  return { type: FETCH_HEALTH_JOURNEY_SUCCESS, data };
}

export function createHealthJourneyInit(actionParams) {
  return { type: CREATE_HEALTH_JOURNEY_INIT, ...actionParams };
}

export function createHealthJourneySuccess({ data }) {
  return { type: CREATE_HEALTH_JOURNEY_SUCCESS, data };
}

export function updateHealthJourneyItemInit(actionParams) {
  return { type: UPDATE_HEALTH_JOURNEY_ITEM_INIT, ...actionParams };
}

export function updateHealthJourneyItemSuccess({ data }) {
  return { type: UPDATE_HEALTH_JOURNEY_ITEM_SUCCESS, data };
}

export function resetHealthJourney(actionParams) {
  return { type: RESET_HEALTH_JOURNEY, actionParams };
}

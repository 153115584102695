import {
  FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT,
  FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS,
  UPDATE_HEALTH_PLAN_ADMIN_ITEM_INIT,
  UPDATE_HEALTH_PLAN_ADMIN_ITEM_SUCCESS,
  RESET_HEALTH_PLAN_ADMIN_ITEMS,
} from './actionNames';

export function fetchHealthPlanAdminItemsInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT, ...actionParams };
}

export function fetchHealthPlanAdminItemsSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS, data };
}

export function updateHealthPlanAdminItemInit(actionParams) {
  return { type: UPDATE_HEALTH_PLAN_ADMIN_ITEM_INIT, ...actionParams };
}

export function updateHealthPlanAdminItemSuccess({ data }) {
  return { type: UPDATE_HEALTH_PLAN_ADMIN_ITEM_SUCCESS, data };
}

export function resetHealthPlanAdminItems(actionParams) {
  return { type: RESET_HEALTH_PLAN_ADMIN_ITEMS, ...actionParams };
}

import {
  UPDATE_PATIENT_CYCLE_OF_CARE_ITEM_INIT,
  UPDATE_PATIENT_CYCLE_OF_CARE_ITEM_SUCCESS,
} from './actionNames';

export function updatePatientCycleOfCareItemInit(actionParams) {
  return { type: UPDATE_PATIENT_CYCLE_OF_CARE_ITEM_INIT, ...actionParams };
}

export function updatePatientCycleOfCareItemSuccess({ data }) {
  return { type: UPDATE_PATIENT_CYCLE_OF_CARE_ITEM_SUCCESS, data };
}

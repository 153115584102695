import { compose } from 'redux';
import { withAction } from 'common/HOCs';
import { deleteHealthPlanGoalInit } from 'services/database/actions/healthPlanGoals';
import HealthPlanGoal from './HealthPlanGoal';

export default compose(
  withAction({
    actionName: 'deleteHealthPlanGoal',
    action: deleteHealthPlanGoalInit,
  })
)(HealthPlanGoal);

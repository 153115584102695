import { compose } from 'redux';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { withAction, withFormErrorHandler } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/providers/update';
import {
  updateProviderInit,
  providerErrorSelector,
} from 'services/database/actions/providers';
import UpdateProvider from './UpdateProvider';

export default compose(
  withAction({
    actionName: 'updateProvider',
    action: updateProviderInit,
  }),
  connect((state) => ({
    formErrorData: providerErrorSelector(state),
  })),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  }),
  withFormErrorHandler
)(UpdateProvider);

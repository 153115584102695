import {
  UPDATE_GOAL_PROGRESS_REVIEW_INIT,
  UPDATE_GOAL_PROGRESS_REVIEW_SUCCESS,
} from './actionNames';

export function updateGoalProgressReviewInit(actionParams) {
  return { type: UPDATE_GOAL_PROGRESS_REVIEW_INIT, ...actionParams };
}

export function updateGoalProgressReviewSuccess({ data }) {
  return { type: UPDATE_GOAL_PROGRESS_REVIEW_SUCCESS, data };
}

import { buildReducer } from 'common/utils/redux';
import { USER_LOGOUT_INIT } from 'services/database/actions/auth/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_FEEDBACK_SUCCESS,
  RESET_HEALTH_PLAN_FEEDBACK,
} from './actionNames';

const initialState = {};

function handleFetchHealthPlanFeedbackSuccess(state, { data }) {
  return data;
}

function handleResetHealthPlanFeedback() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_FEEDBACK_SUCCESS]: handleFetchHealthPlanFeedbackSuccess,
  [RESET_HEALTH_PLAN_FEEDBACK]: handleResetHealthPlanFeedback,
  [USER_LOGOUT_INIT]: handleResetHealthPlanFeedback,
};

export default buildReducer(initialState, ACTION_HANDLERS);

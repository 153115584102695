import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({
  query: { healthPlanId, healthPlanServiceId },
  data,
}) {
  return http
    .patch(`/health_plans/${healthPlanId}/services/${healthPlanServiceId}/`, {
      data,
    })
    .catch(handleHTTPError);
}

import React from 'react';
import getComponentDisplayName from './utils/getComponentDisplayName';

function withPropsMapping(mappingFunction) {
  const Container = (OriginalComponent) => {
    const Component = ({ ...props }) => {
      return <OriginalComponent {...mappingFunction(props)} />;
    };

    Component.displayName = `withPropsMapping(mappingFunction)(${getComponentDisplayName(
      OriginalComponent
    )})`;

    return Component;
  };

  return Container;
}

export default withPropsMapping;

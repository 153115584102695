import { MEASUREMENT_KINDS } from 'common/constants';
import { parseMeasurementData } from 'common/utils';

export default function (values, { patient, healthPlan, measurement }) {
  const isSplitValue = measurement.kind === MEASUREMENT_KINDS.SPLIT_VALUE,
    firstValue = values.value.split('/')[0],
    secondValue = isSplitValue ? values.value.split('/')[1] : null;

  return {
    patient: patient.id,
    definition: measurement.id,
    value: parseMeasurementData(firstValue, measurement.valueType),
    secondValue: isSplitValue
      ? parseMeasurementData(secondValue, measurement.valueType)
      : null,
    healthPlanReview: healthPlan.currentReview,
  };
}

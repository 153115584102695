import React from 'react';
import PropTypes from 'prop-types';
import { Button as StyledButton } from './styles';

const Button = ({
  label,
  color,
  variant,
  onClick,
  to,
  component,
  disabled,
  ...props
}) => (
  <StyledButton
    color={color}
    variant={variant}
    onClick={onClick}
    to={to}
    component={component}
    disabled={disabled}
    {...props}
  >
    {label}
  </StyledButton>
);

Button.defaultProps = {
  color: 'primary',
  variant: 'contained',
  disabled: false,
};
Button.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
  component: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Button;

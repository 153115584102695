import { schema } from 'normalizr';

const patientsSchema = new schema.Entity('patients', undefined, {
  idAttribute: 'id',
});
const patientsListSchema = [patientsSchema];

const healthTeamSchema = new schema.Entity('healthTeam', undefined, {
  idAttribute: 'id',
});
const healthTeamListSchema = [healthTeamSchema];

const actionsSchema = new schema.Entity('actions', undefined, {
  idAttribute: 'id',
});
const actionsListSchema = [actionsSchema];

const adminItemsSchema = new schema.Entity('adminItems', undefined, {
  idAttribute: 'id',
});
const adminItemsListSchema = [adminItemsSchema];

const barriersSchema = new schema.Entity('barriers', undefined, {
  idAttribute: 'id',
});
const barriersListSchema = [barriersSchema];

const billableItemsSchema = new schema.Entity('billableItems', undefined, {
  idAttribute: 'id',
});
const billableItemsListSchema = [billableItemsSchema];

const conditionsSchema = new schema.Entity('conditions', undefined, {
  idAttribute: 'id',
});
const conditionsListSchema = [conditionsSchema];

const cycleOfCareItemsSchema = new schema.Entity(
  'cycleOfCareItems',
  undefined,
  {
    idAttribute: 'id',
  }
);
const cycleOfCareItemsListSchema = [cycleOfCareItemsSchema];

const cycleOfCareModulesSchema = new schema.Entity(
  'cycleOfCareModules',
  undefined,
  {
    idAttribute: 'id',
  }
);
const cycleOfCareModulesListSchema = [cycleOfCareModulesSchema];

const goalBarriersSchema = new schema.Entity('goalBarriers', undefined, {
  idAttribute: 'id',
});
const goalBarriersListSchema = [goalBarriersSchema];

const goalsSchema = new schema.Entity('goals', undefined, {
  idAttribute: 'id',
});
const goalsListSchema = [goalsSchema];

const measurementsSchema = new schema.Entity('measurements', undefined, {
  idAttribute: 'id',
});
const measurementsListSchema = [measurementsSchema];

const servicesSchema = new schema.Entity('services', undefined, {
  idAttribute: 'id',
});
const servicesListSchema = [servicesSchema];

const solutionsSchema = new schema.Entity('solutions', undefined, {
  idAttribute: 'id',
});
const solutionsListSchema = [solutionsSchema];

const tasksSchema = new schema.Entity('tasks', undefined, {
  idAttribute: 'id',
});
const tasksListSchema = [tasksSchema];

const patientConditionsSchema = new schema.Entity(
  'patientConditions',
  undefined,
  { idAttribute: 'id' }
);
const patientConditionsListSchema = [patientConditionsSchema];

const patientMeasurementsSchema = new schema.Entity(
  'patientMeasurements',
  undefined,
  { idAttribute: 'id' }
);
const patientMeasurementsListSchema = [patientMeasurementsSchema];

const patientCycleOfCareItemsSchema = new schema.Entity(
  'patientCycleOfCareItems',
  undefined,
  { idAttribute: 'id' }
);
const patientCycleOfCareItemsListSchema = [patientCycleOfCareItemsSchema];

const patientCycleOfCareModulesSchema = new schema.Entity(
  'patientCycleOfCareModules',
  undefined,
  { idAttribute: 'id' }
);
const patientCycleOfCareModulesListSchema = [patientCycleOfCareModulesSchema];

const healthJourneyItemsSchema = new schema.Entity(
  'healthJourneyItems',
  undefined,
  { idAttribute: 'id' }
);
const healthJourneyItemsListSchema = [healthJourneyItemsSchema];

const healthPlanActionsSchema = new schema.Entity(
  'healthPlanActions',
  undefined,
  { idAttribute: 'id' }
);
const healthPlanActionsListSchema = [healthPlanActionsSchema];

const healthPlanAdminItemsSchema = new schema.Entity(
  'healthPlanAdminItems',
  undefined,
  { idAttribute: 'id' }
);
const healthPlanAdminItemsListSchema = [healthPlanAdminItemsSchema];

const healthPlanGoalsSchema = new schema.Entity('healthPlanGoals', undefined, {
  idAttribute: 'id',
});
const healthPlanGoalsListSchema = [healthPlanGoalsSchema];

const healthPlanMeasurementsSchema = new schema.Entity(
  'healthPlanMeasurements',
  undefined,
  { idAttribute: 'id' }
);
const healthPlanMeasurementsListSchema = [healthPlanMeasurementsSchema];

const healthPlanServicesSchema = new schema.Entity(
  'healthPlanServices',
  undefined,
  { idAttribute: 'id' }
);
const healthPlanServicesListSchema = [healthPlanServicesSchema];

const goalProgressReviewsSchema = new schema.Entity(
  'goalProgressReviews',
  undefined,
  { idAttribute: 'id' }
);
const goalProgressReviewsListSchema = [goalProgressReviewsSchema];

const goalProgressBarriersSchema = new schema.Entity(
  'goalProgressBarriers',
  undefined,
  { idAttribute: 'id' }
);
const goalProgressBarriersListSchema = [goalProgressBarriersSchema];

const goalProgressSolutionsSchema = new schema.Entity(
  'goalProgressSolutions',
  undefined,
  { idAttribute: 'id' }
);
const goalProgressSolutionsListSchema = [goalProgressSolutionsSchema];

export {
  patientsListSchema,
  healthTeamListSchema,
  actionsListSchema,
  adminItemsListSchema,
  barriersListSchema,
  billableItemsListSchema,
  conditionsListSchema,
  cycleOfCareItemsListSchema,
  cycleOfCareModulesListSchema,
  goalBarriersListSchema,
  goalsListSchema,
  measurementsListSchema,
  servicesListSchema,
  solutionsListSchema,
  tasksListSchema,
  patientConditionsListSchema,
  patientMeasurementsListSchema,
  patientCycleOfCareItemsListSchema,
  patientCycleOfCareModulesListSchema,
  healthJourneyItemsListSchema,
  healthPlanActionsListSchema,
  healthPlanAdminItemsListSchema,
  healthPlanGoalsListSchema,
  healthPlanMeasurementsListSchema,
  healthPlanServicesListSchema,
  goalProgressReviewsListSchema,
  goalProgressBarriersListSchema,
  goalProgressSolutionsListSchema,
};

import PropTypes from 'prop-types';

export default {
  id: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userType: PropTypes.number,
  providerProfile: PropTypes.shape({
    providerType: PropTypes.number,
    ahpraNumber: PropTypes.string,
  }),
};

import { http } from 'services/backend/http';
import adaptResponseData from './adaptResponseData';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query: { patientId }, data }) {
  return http
    .patch(`/patients/${patientId}/`, { data })
    .then(adaptResponseData)
    .catch(handleHTTPError);
}

import { isAfter } from 'date-fns';
import { isNull } from 'lodash';
import { MEASUREMENT_VALUE_TYPES, PROVIDER_TYPES } from 'common/constants';

export function insertIf(condition, ...elements) {
  return condition ? elements : [];
}

export function isOverdue(date) {
  if (isNull(date)) return false;
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return isAfter(now, new Date(date));
}

export function isUserDoctor(user) {
  return user.providerType === PROVIDER_TYPES.GENERAL_PRACTITIONER;
}

export function isUserNurse(user) {
  return user.providerType === PROVIDER_TYPES.PRACTICE_NURSE;
}

export function parseMeasurementData(value, valueType) {
  switch (valueType) {
    case MEASUREMENT_VALUE_TYPES.INTEGER:
    case MEASUREMENT_VALUE_TYPES.DECIMAL:
    case MEASUREMENT_VALUE_TYPES.STRING:
    default:
      return value;
    case MEASUREMENT_VALUE_TYPES.BOOLEAN:
      switch (value.toLowerCase()) {
        case 'yes':
        case 'true':
          return 'true'; // DISCUSS: Update backend to accept boolean?
        case 'no':
        case 'false':
        default:
          return 'false'; // DISCUSS: Update backend to accept boolean?
      }
  }
}

export function flattenObject(obj, result = {}) {
  for (const i in obj) {
    if (typeof obj[i] === 'object' && !Array.isArray(obj[i])) {
      const temp = flattenObject(obj[i]);
      for (const j in temp) {
        result[j] = temp[j];
      }
    } else {
      result[i] = obj[i];
    }
  }
  return result;
}

import { put, call } from 'redux-saga/effects';
import { fetchPatientSuccess } from 'services/database/actions/patient';
import request from './request';

export default function* fetchSaga({ query, resolve, reject }) {
  try {
    const responseData = yield call(request, {
      query,
    });
    yield put(fetchPatientSuccess({ data: responseData }));
    yield call(resolve, { responseData });
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

export default function (values, { props: { updateUserPassword, onClose } }) {
  const handleOnSuccess = () => {
      onClose();
    },
    handleOnError = () => {};
  return updateUserPassword({
    data: values,
  })
    .then(() => handleOnSuccess())
    .catch(() => handleOnError());
}

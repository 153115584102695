import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { healthPlanService, updateHealthPlanService, providers } }
) {
  const handleOnSuccess = () => {},
    handleOnError = () => {};

  return updateHealthPlanService({
    query: {
      healthPlanId: healthPlanService.healthPlan,
      healthPlanServiceId: healthPlanService.id,
      provider: providers[values.providerId], // required as needs to be manually added to the team redux store
    },
    data: mapValuesToProps(values),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

import { object, string } from 'yup';

export default () =>
  object().shape({
    firstName: string().required('This field is required.'),
    lastName: string().required('This field is required.'),
    email: string()
      .email('Must be a valid email address')
      .required('This field is required.'),
    ahpraNumber: string().required('This field is required.'),
    userType: string().required('This field is required.'),
    providerType: string().required('This field is required.'),
  });

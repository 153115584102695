import { isUndefined } from 'lodash';
import { TableRow as MuiTableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableRow = styled(MuiTableRow, {
  shouldForwardProp: (prop) => prop !== 'dense' && prop !== 'clickable',
})(({ theme, color, dense, clickable }) => ({
  boxShadow: 'rgba(0, 0, 0, 0.05) 0 0 1.25rem 0',
  height: dense ? '2.5rem' : '4rem',
  '&:hover': clickable && {
    cursor: 'pointer',
    '& td': {
      color: !isUndefined(theme.palette[color])
        ? theme.palette[color].contrastText
        : color,
      backgroundColor: !isUndefined(theme.palette[color])
        ? theme.palette[color].main
        : color,
      '& *': {
        color: !isUndefined(theme.palette[color])
          ? theme.palette[color].contrastText
          : color,
      },
    },
  },
}));

import React from 'react';
import { isNull, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { ExpandableCard, FlexBox } from 'common/components/layouts';
import {
  CYCLE_OF_CARE_ITEM_STATUSES,
  CYCLE_OF_CARE_MODULE_STATUSES,
  CYCLE_OF_CARE_MODULE_STATUS_NAMES,
} from 'common/constants';
import {
  CycleOfCareItemPropType,
  CycleOfCareModulePropType,
  PatientCycleOfCareItemPropType,
  PatientCycleOfCareModulePropType,
} from 'common/propTypes';
import PatientCycleOfCareItem from './components/PatientCycleOfCareItem';

const PatientCycleOfCareModule = ({ patientCycleOfCareModule }) => {
  const status = Object.values(
    patientCycleOfCareModule.patientCycleOfCareItems
  ).every((item) => item.status === CYCLE_OF_CARE_ITEM_STATUSES.COMPLETE)
    ? CYCLE_OF_CARE_MODULE_STATUSES.COMPLETE
    : Object.values(patientCycleOfCareModule.patientCycleOfCareItems).some(
        (item) => !isNull(item.completedAt)
      )
    ? CYCLE_OF_CARE_MODULE_STATUSES.IN_PROGRESS
    : CYCLE_OF_CARE_MODULE_STATUSES.NOT_YET_STARTED;

  const listHeadings = [
    { title: 'Check', align: 'left' },
    { title: 'Status', align: 'center' },
    { title: 'Frequency', align: 'center' },
    { title: 'Last Completed', align: 'center' },
    { title: 'Next Due', align: 'center' },
  ];

  return (
    <>
      <ExpandableCard
        cardContent={
          <FlexBox flexDirection="column">
            <Typography variant="h2">
              {patientCycleOfCareModule.definition.name}
            </Typography>
            <Typography
              variant="h6"
              color={
                status === CYCLE_OF_CARE_MODULE_STATUSES.COMPLETE
                  ? 'primary'
                  : status === CYCLE_OF_CARE_MODULE_STATUSES.NOT_YET_STARTED
                  ? 'error'
                  : 'textSecondary'
              }
            >
              {CYCLE_OF_CARE_MODULE_STATUS_NAMES[status]}
            </Typography>
          </FlexBox>
        }
      >
        <Table className="dense">
          <TableHead>
            <TableRow>
              {listHeadings.map((heading, idx) => (
                <TableCell key={idx} align={heading.align}>
                  {heading.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(
              Object.values(patientCycleOfCareModule.patientCycleOfCareItems),
              ['id']
            ).map((patientCycleOfCareItem, idx) => (
              <PatientCycleOfCareItem
                key={idx}
                patientCycleOfCareItem={patientCycleOfCareItem}
              />
            ))}
          </TableBody>
        </Table>
      </ExpandableCard>
    </>
  );
};

PatientCycleOfCareModule.defaultProps = {};

PatientCycleOfCareModule.propTypes = {
  patientCycleOfCareModule: PropTypes.shape({
    ...PatientCycleOfCareModulePropType,
    definition: PropTypes.shape(CycleOfCareModulePropType),
    patientCycleOfCareItems: PropTypes.arrayOf(
      PropTypes.shape({
        ...PatientCycleOfCareItemPropType,
        definition: PropTypes.shape(CycleOfCareItemPropType),
      })
    ),
  }).isRequired,
};

export default PatientCycleOfCareModule;

import React from 'react';
import { Image } from 'common/components/layouts';
import { styled } from '@mui/material/styles';

const Div = styled('div')(({ theme }) => ({
  width: theme.spacing(32),
  marginBottom: theme.spacing(8),
}));

export const ImageContainer = ({ src }) => (
  <Div>
    <Image src={src} />
  </Div>
);

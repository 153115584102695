import { buildReducer } from 'common/utils/redux';
import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT_INIT,
} from './actionNames';

const initialState = { errors: {} };

function handleSetUserLoggedInError(state, { data }) {
  return { ...state, errors: data };
}

function handleInitialState(state) {
  return initialState;
}

const ACTION_HANDLERS = {
  [USER_LOGIN_SUCCESS]: handleInitialState,
  [USER_LOGIN_ERROR]: handleSetUserLoggedInError,
  [USER_LOGOUT_INIT]: handleInitialState,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import { connect } from 'react-redux';
import { userSelector } from 'services/database/actions/user';

function withAuthenticatedUser(OriginalComponent) {
  const Container = connect((state) => ({
    user: userSelector(state),
  }))(OriginalComponent);

  return Container;
}

export default withAuthenticatedUser;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from 'routing/components/Router';
import ThemeProvider from 'common/styles/ThemeProvider';
import configureStore from './store';

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

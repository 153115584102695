import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/patientConditions/fetch';
import createSaga from 'services/backend/actions/patientConditions/create';
import deleteSaga from 'services/backend/actions/patientConditions/delete';
import {
  FETCH_PATIENT_CONDITIONS_INIT,
  CREATE_PATIENT_CONDITION_INIT,
  DELETE_PATIENT_CONDITION_INIT,
} from './actionNames';

function* patientConditionsFlowSaga() {
  yield all([
    takeLatest(FETCH_PATIENT_CONDITIONS_INIT, fetchSaga),
    takeEvery(CREATE_PATIENT_CONDITION_INIT, createSaga),
    takeEvery(DELETE_PATIENT_CONDITION_INIT, deleteSaga),
  ]);
}

export default patientConditionsFlowSaga;

import { normalize } from 'normalizr';
import {
  patientsListSchema,
  healthTeamListSchema,
  actionsListSchema,
  adminItemsListSchema,
  barriersListSchema,
  billableItemsListSchema,
  conditionsListSchema,
  cycleOfCareItemsListSchema,
  cycleOfCareModulesListSchema,
  goalBarriersListSchema,
  goalsListSchema,
  measurementsListSchema,
  servicesListSchema,
  solutionsListSchema,
  tasksListSchema,
  patientConditionsListSchema,
  patientMeasurementsListSchema,
  patientCycleOfCareItemsListSchema,
  patientCycleOfCareModulesListSchema,
  healthJourneyItemsListSchema,
  healthPlanActionsListSchema,
  healthPlanAdminItemsListSchema,
  healthPlanGoalsListSchema,
  healthPlanMeasurementsListSchema,
  healthPlanServicesListSchema,
  goalProgressReviewsListSchema,
  goalProgressBarriersListSchema,
  goalProgressSolutionsListSchema,
} from 'services/backend/dataNormalizer/schemas';

const normalizeData = function (data, objectName) {
  let normalizedData;
  switch (objectName) {
    case 'patients':
      normalizedData = normalize(data, patientsListSchema);
      return normalizedData.entities.patients || {};
    case 'healthTeam': // DISCUSS: Rename to providers?
      normalizedData = normalize(data, healthTeamListSchema);
      return normalizedData.entities.healthTeam || {};
    case 'actions':
      normalizedData = normalize(data, actionsListSchema);
      return normalizedData.entities.actions || {};
    case 'adminItems':
      normalizedData = normalize(data, adminItemsListSchema);
      return normalizedData.entities.adminItems || {};
    case 'barriers':
      normalizedData = normalize(data, barriersListSchema);
      return normalizedData.entities.barriers || {};
    case 'billableItems':
      normalizedData = normalize(data, billableItemsListSchema);
      return normalizedData.entities.billableItems || {};
    case 'conditions':
      normalizedData = normalize(data, conditionsListSchema);
      return normalizedData.entities.conditions || {};
    case 'cycleOfCareItems':
      normalizedData = normalize(data, cycleOfCareItemsListSchema);
      return normalizedData.entities.cycleOfCareItems || {};
    case 'cycleOfCareModules':
      normalizedData = normalize(data, cycleOfCareModulesListSchema);
      return normalizedData.entities.cycleOfCareModules || {};
    case 'goalBarriers':
      normalizedData = normalize(data, goalBarriersListSchema);
      return normalizedData.entities.goalBarriers || {};
    case 'goals':
      normalizedData = normalize(data, goalsListSchema);
      return normalizedData.entities.goals || {};
    case 'measurements':
      normalizedData = normalize(data, measurementsListSchema);
      return normalizedData.entities.measurements || {};
    case 'services':
      normalizedData = normalize(data, servicesListSchema);
      return normalizedData.entities.services || {};
    case 'solutions':
      normalizedData = normalize(data, solutionsListSchema);
      return normalizedData.entities.solutions || {};
    case 'tasks':
      normalizedData = normalize(data, tasksListSchema);
      return normalizedData.entities.tasks || {};
    case 'patientConditions':
      normalizedData = normalize(data, patientConditionsListSchema);
      return normalizedData.entities.patientConditions || {};
    case 'patientMeasurements':
      normalizedData = normalize(data, patientMeasurementsListSchema);
      return normalizedData.entities.patientMeasurements || {};
    case 'patientCycleOfCareItems':
      normalizedData = normalize(data, patientCycleOfCareItemsListSchema);
      return normalizedData.entities.patientCycleOfCareItems || {};
    case 'patientCycleOfCareModules':
      normalizedData = normalize(data, patientCycleOfCareModulesListSchema);
      return normalizedData.entities.patientCycleOfCareModules || {};
    case 'healthJourneyItems':
      normalizedData = normalize(data, healthJourneyItemsListSchema);
      return normalizedData.entities.healthJourneyItems || {};
    case 'healthPlanActions':
      normalizedData = normalize(data, healthPlanActionsListSchema);
      return normalizedData.entities.healthPlanActions || {};
    case 'healthPlanAdminItems':
      normalizedData = normalize(data, healthPlanAdminItemsListSchema);
      return normalizedData.entities.healthPlanAdminItems || {};
    case 'healthPlanGoals':
      normalizedData = normalize(data, healthPlanGoalsListSchema);
      return normalizedData.entities.healthPlanGoals || {};
    case 'healthPlanMeasurements':
      normalizedData = normalize(data, healthPlanMeasurementsListSchema);
      return normalizedData.entities.healthPlanMeasurements || {};
    case 'healthPlanServices':
      normalizedData = normalize(data, healthPlanServicesListSchema);
      return normalizedData.entities.healthPlanServices || {};
    case 'goalProgressReviews':
      normalizedData = normalize(data, goalProgressReviewsListSchema);
      return normalizedData.entities.goalProgressReviews || {};
    case 'goalProgressBarriers':
      normalizedData = normalize(data, goalProgressBarriersListSchema);
      return normalizedData.entities.goalProgressBarriers || {};
    case 'goalProgressSolutions':
      normalizedData = normalize(data, goalProgressSolutionsListSchema);
      return normalizedData.entities.goalProgressSolutions || {};
    default:
      return null;
  }
};

export default normalizeData;

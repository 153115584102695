import React from 'react';
import PropTypes from 'prop-types';
import { Box as StyledBox } from './styles';

const FlexBox = ({ children, ...props }) => {
  return <StyledBox {...props}>{children}</StyledBox>;
};

FlexBox.defaultProps = {};
FlexBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlexBox;

import React from 'react';
import { styled } from '@mui/material/styles';
import { Image } from 'common/components/layouts';

const Div = styled('div')({
  width: '15rem',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ImageContainer = ({ src }) => (
  <Div>
    <Image src={src} />
  </Div>
);

import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Box = styled(MuiBox)(
  ({ width, height, minWidth, maxWidth, minHeight, maxHeight, overflow }) => ({
    width: width,
    height: height,
    minWidth: minWidth,
    maxWidth: maxWidth,
    minHeight: minHeight,
    maxHeight: maxHeight,
    overflow: overflow,
  })
);

import { isUndefined } from 'lodash';
import {
  Button as MuiButton,
  CircularProgress as MuiCircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div')({
  position: 'relative',
});

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'dense' && prop !== 'loading',
})(
  ({
    theme,
    selected,
    loading,
    readOnly,
    color,
    dense,
    minWidth,
    maxWidth,
  }) => ({
    position: 'relative',
    height: '40.65px',
    margin: dense ? 0 : undefined,
    padding: '0 0.8rem',
    whiteSpace: 'inherit',
    minWidth: minWidth,
    maxWidth: maxWidth,
    '&.MuiButton-contained': {
      color: !isUndefined(theme.palette[color])
        ? theme.palette[color].contrastText
        : color,
      backgroundColor:
        selected && !loading && !isUndefined(theme.palette[color])
          ? theme.palette[color].main
          : color,
      '&.Mui-disabled': {
        backgroundColor:
          readOnly && selected && !isUndefined(theme.palette[color])
            ? theme.palette[color].main
            : color,
      },
      '&:not(.Mui-disabled)': {
        backgroundColor: !isUndefined(theme.palette[color])
          ? theme.palette[color].main
          : color,
        ':hover': {
          backgroundColor: !isUndefined(theme.palette[color])
            ? theme.palette[color].light
            : color,
        },
      },
    },
    '&.MuiButton-outlined:not(.Mui-disabled)': {
      color: theme.palette.text.secondary,
      ':hover': {
        color: !isUndefined(theme.palette[color])
          ? theme.palette[color].contrastText
          : color,
        backgroundColor: !isUndefined(theme.palette[color])
          ? theme.palette[color].main
          : color,
        borderColor: `${
          !isUndefined(theme.palette[color]) ? theme.palette[color].main : color
        } !important`,
      },
    },
  })
);

export const CircularProgress = styled(MuiCircularProgress)(({ size }) => ({
  position: 'absolute',
  top: '50%',
  marginTop: `-${size / 2}px`,
  right: `${size / 2}px`,
}));

import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({
  query: { healthPlanId, healthPlanReviewId },
  data,
}) {
  return http
    .patch(`/health_plans/${healthPlanId}/reviews/${healthPlanReviewId}/`, {
      data,
    })
    .catch(handleHTTPError);
}

import { MEASUREMENT_VALUE_TYPES } from 'common/constants';
import { boolean, number, object, string } from 'yup';

export default object().shape({
  valueType: number(),
  isSplitValue: boolean(),
  target: string()
    .trim('')
    .when('isSplitValue', {
      is: true,
      then: string()
        .matches(/^.+\/\s?\S+.*$/, 'Expected "/" separated values')
        .when('valueType', {
          is: MEASUREMENT_VALUE_TYPES.INTEGER,
          then: string().matches(
            /^((\d+)(\s?\/\s?)(\d+))$/i,
            'Expected a whole number'
          ),
        })
        .when('valueType', {
          is: MEASUREMENT_VALUE_TYPES.DECIMAL,
          then: string().matches(
            /^((\d+(\.\d+)?)(\s?\/\s?)(\d+(\.\d+)?))$/i,
            'Expected a decimal number'
          ),
        })
        .when('valueType', {
          is: MEASUREMENT_VALUE_TYPES.BOOLEAN,
          then: string().matches(
            /^(yes|no|true|false)(\s?\/\s?)(yes|no|true|false)$/i,
            'Expected yes, no, true, or false'
          ),
        }),
    })
    .when('isSplitValue', {
      is: false,
      then: string()
        .when('valueType', {
          is: MEASUREMENT_VALUE_TYPES.INTEGER,
          then: string().matches(/^(\d+)$/i, 'Expected a whole number'),
        })
        .when('valueType', {
          is: MEASUREMENT_VALUE_TYPES.DECIMAL,
          then: string().matches(
            /^(\d+(\.\d+)?)$/i,
            'Expected a decimal number'
          ),
        })
        .when('valueType', {
          is: MEASUREMENT_VALUE_TYPES.BOOLEAN,
          then: string().matches(
            /^(yes|no|true|false)$/i,
            'Expected yes, no, true, or false'
          ),
        }),
    })
    .required('This field is required'),
});

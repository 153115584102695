import React from 'react';
import { CardActionArea, CardActions, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { Card as StyledCard } from './styles';

const ClickableCard = ({
  children,
  cardActions,
  onClick,
  to,
  component,
  disabled,
  ...props
}) => {
  return (
    <StyledCard {...props}>
      <CardActionArea
        component={component}
        to={to}
        onClick={onClick}
        disabled={disabled}
      >
        <CardContent>{children}</CardContent>
        {cardActions && <CardActions>{cardActions}</CardActions>}
      </CardActionArea>
    </StyledCard>
  );
};

ClickableCard.defaultProps = {};
ClickableCard.propTypes = {
  children: PropTypes.node.isRequired,
  cardActions: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  component: PropTypes.object,
};

export default ClickableCard;

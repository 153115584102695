import { compose } from 'redux';
import { withAction } from 'common/HOCs';
import { deleteHealthPlanActionInit } from 'services/database/actions/healthPlanActions';
import HealthPlanAction from './HealthPlanAction';

export default compose(
  withAction({
    actionName: 'deleteHealthPlanAction',
    action: deleteHealthPlanActionInit,
  })
)(HealthPlanAction);

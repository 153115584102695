import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/user/fetch';
import updateSaga from 'services/backend/actions/user/update';
import updatePasswordSaga from 'services/backend/actions/user/updatePassword';
import {
  FETCH_USER_INIT,
  UPDATE_USER_INIT,
  UPDATE_USER_PASSWORD_INIT,
} from './actionNames';

function* userFlowSaga() {
  yield all([
    takeLatest(FETCH_USER_INIT, fetchSaga),
    takeLatest(UPDATE_USER_INIT, updateSaga),
    takeLatest(UPDATE_USER_PASSWORD_INIT, updatePasswordSaga),
  ]);
}

export default userFlowSaga;

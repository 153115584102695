export default function (values) {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    userType: values.userType,
    providerProfile: {
      providerType: values.providerType,
      ahpraNumber: values.ahpraNumber,
    },
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import {
  Card,
  ContentBox,
  FlexBox,
  GoalChips,
} from 'common/components/layouts';

const GoalsCard = ({ title, content, goals }) => (
  <Card height="100%">
    <FlexBox alignItems="baseline">
      <ContentBox width="max-content" mr={1}>
        <Typography variant="h5" color="textSecondary">
          {title}
        </Typography>
      </ContentBox>
      <GoalChips goals={goals} dense />
    </FlexBox>
    <Typography variant="h2">{content}</Typography>
  </Card>
);

GoalsCard.defaultProps = {};
GoalsCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  goals: PropTypes.array,
};

export default GoalsCard;

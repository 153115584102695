import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchHealthPlanServicesInit,
  resetHealthPlanServices,
  healthPlanServicesSelector,
} from 'services/database/actions/healthPlanServices';
import HealthPlanTeam from './HealthPlanTeam';
import { goalDefinitionsSelector } from 'services/database/actions/definitions';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanServices',
    action: fetchHealthPlanServicesInit,
  }),
  withAction({
    actionName: 'resetHealthPlanServices',
    action: resetHealthPlanServices,
  }),
  connect((state) => ({
    healthPlanServices: healthPlanServicesSelector(state),
    goalDefinitions: goalDefinitionsSelector(state),
  })),
  withPropsMapping((props) => ({
    ...props,
    healthPlanServices: Object.values(props.healthPlanServices).map(
      (healthPlanService) => ({
        ...healthPlanService,
        definition: props.serviceDefinitions[healthPlanService.definition],
      })
    ),
  }))
)(HealthPlanTeam);

import React, { useEffect, useState } from 'react';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import {
  DeletableCard,
  ExpandableCard,
  FlexBox,
} from 'common/components/layouts';
import { handleSubmit as handleDelete } from 'common/data/patientConditions/delete';
import {
  ConditionsPropType,
  PatientConditionsPropType,
  PatientPropType,
} from 'common/propTypes';
import PatientConditionAdd from './components/PatientConditionAdd';

const PatientConditions = ({
  patient,
  patientConditions,
  availablePatientConditions,
  fetchPatientConditions,
  deletePatientCondition,
  resetPatientConditions,
  readOnly,
}) => {
  useEffect(() => {
    // DISCUSS: isNil check?
    fetchPatientConditions({ query: { patientId: patient.id } });
    return () => resetPatientConditions();
    // eslint-disable-next-line
  }, []);

  const [showPatientConditionAdd, setShowPatientConditionAdd] = useState(false),
    handleToggleCreate = () => {
      setShowPatientConditionAdd(!showPatientConditionAdd);
    };

  const handleOnDelete = (patientCondition) => (e) => {
    handleDelete({ patientCondition, deletePatientCondition });
  };

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Typography variant="h2">Existing Conditions</Typography>
          <Typography variant="h6" color="textSecondary">
            Conditions: {patientConditions.length}
          </Typography>
        </Box>
      }
    >
      {isEmpty(patientConditions) && !showPatientConditionAdd ? (
        <Typography variant="h4" align="center">
          No patient conditions have been added
        </Typography>
      ) : (
        sortBy(patientConditions, ['createdAt']).map(
          (patientCondition, idx) => (
            <DeletableCard
              key={idx}
              onClick={handleOnDelete(patientCondition)}
              readOnly={readOnly}
            >
              <Typography variant="h5" color="textSecondary">
                Condition #{idx + 1}
              </Typography>
              <Typography variant="h2">{patientCondition.name}</Typography>
            </DeletableCard>
          )
        )
      )}
      {!readOnly && (
        <FlexBox flexDirection="column" mt="1rem">
          {showPatientConditionAdd && (
            <PatientConditionAdd
              open={showPatientConditionAdd}
              patient={patient}
              index={patientConditions.length + 1}
              availablePatientConditions={availablePatientConditions}
              onClose={handleToggleCreate}
            />
          )}
          <Button
            label="Add Condition"
            onClick={handleToggleCreate}
            disabled={isEmpty(availablePatientConditions)}
          />
        </FlexBox>
      )}
    </ExpandableCard>
  );
};

PatientConditions.defaultProps = {
  patientConditions: [],
  availablePatientConditions: [],
};
PatientConditions.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  patientConditions: PropTypes.arrayOf(
    PropTypes.shape({ ...ConditionsPropType, ...PatientConditionsPropType })
  ),
  availablePatientConditions: PropTypes.arrayOf(
    PropTypes.shape(ConditionsPropType)
  ),
  fetchPatientConditions: PropTypes.func.isRequired,
  deletePatientCondition: PropTypes.func.isRequired,
  resetPatientConditions: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default PatientConditions;

import PropTypes from 'prop-types';
import GoalProgressReviewPropType from './GoalProgressReviewPropType';

export default {
  id: PropTypes.number,
  healthPlan: PropTypes.number,
  status: PropTypes.number,
  isAnnualReview: PropTypes.bool,
  goalProgressReviews: PropTypes.objectOf(
    PropTypes.shape(GoalProgressReviewPropType)
  ),
  createdAt: PropTypes.string,
};

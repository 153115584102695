import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { TableRow, TableCell } from 'common/components/layouts';
import { HEALTH_PLAN_STATUSES } from 'common/constants';
import { PatientPropType } from 'common/propTypes';
import {
  isOverdue,
  renderDate,
  renderFullName,
  renderHealthPlanStatus,
} from 'common/utils';
import { patientProfilePath } from 'routing/utils/paths';

const Patient = ({ isNurse, patient, history }) => {
  const hasHealthPlan = !isEmpty(patient.healthPlan),
    isFinalised =
      hasHealthPlan &&
      patient.healthPlan.status === HEALTH_PLAN_STATUSES.FINALISED,
    hasNextReview = hasHealthPlan && !isEmpty(patient.healthPlan.nextReview),
    healthPlanOverdue =
      hasHealthPlan && isOverdue(patient.healthPlan.nextReview);

  const redirect = () => {
    history.push(patientProfilePath({ patientId: patient.id }));
  };

  return (
    <TableRow
      color={healthPlanOverdue ? 'error' : isFinalised ? 'success' : 'warning'}
      onClick={redirect}
    >
      <TableCell>{patient.firstName}</TableCell>
      <TableCell>{patient.lastName}</TableCell>
      {isNurse && (
        <TableCell>
          {renderFullName(patient.patientProfile.generalPractitioner)}
        </TableCell>
      )}
      <TableCell>{renderHealthPlanStatus(patient.healthPlan)}</TableCell>
      <TableCell
        color={healthPlanOverdue ? 'error' : hasNextReview ? 'success' : null}
      >
        {patient.healthPlan && patient.healthPlan.nextReview
          ? renderDate(patient.healthPlan.nextReview)
          : '-'}
      </TableCell>
    </TableRow>
  );
};

Patient.defaultProps = {
  isNurse: false,
  history: {},
};
Patient.propTypes = {
  isNurse: PropTypes.bool,
  patient: PropTypes.shape(PatientPropType).isRequired,
  history: PropTypes.object,
};

export default Patient;

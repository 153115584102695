import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction } from 'common/HOCs';
import {
  fetchHealthPlanFeedbackInit,
  resetHealthPlanFeedback,
  healthPlanFeedbackSelector
} from 'services/database/actions/healthPlanFeedback';
import HealthPlanFeedback from './HealthPlanFeedback';
import { healthPlanSelector } from 'services/database/actions/healthPlans';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanFeedback',
    action: fetchHealthPlanFeedbackInit,
  }),
  withAction({
    actionName: 'resetHealthPlanFeedback',
    action: resetHealthPlanFeedback,
  }),
  connect((state) => ({
    healthPlan: healthPlanSelector(state),
    healthPlanFeedback: healthPlanFeedbackSelector(state),
  }))
)(HealthPlanFeedback);

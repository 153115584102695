import normalizeData from 'services/backend/dataNormalizer/normalizeData';
import { isEmpty } from 'lodash';

const adaptResponseData = function (data) {
  const normalizedProgress = !isEmpty(data.goalProgressReviews)
    ? normalizeData(
        data.goalProgressReviews.map((progress) => ({
          ...progress,
          goalProgressBarriers: {},
        })),

        'goalProgressReviews'
      )
    : {};

  return {
    id: data.id,
    healthPlan: data.healthPlan,
    status: data.status,
    createdAt: data.createdAt,
    createdBy: data.createdBy,
    finalisedAt: data.finalisedAt,
    finalisedBy: data.finalisedBy,
    goalProgressReviews: normalizedProgress,
    isAnnualReview: data.isAnnualReview,
    sharedAt: data.sharedAt,
    sharedBy: data.sharedBy,
  };
};

export default adaptResponseData;

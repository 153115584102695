import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import { USER_LOGOUT_INIT } from 'services/database/actions/auth/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_GOALS_SUCCESS,
  CREATE_HEALTH_PLAN_GOAL_SUCCESS,
  DELETE_HEALTH_PLAN_GOAL_SUCCESS,
  RESET_HEALTH_PLAN_GOALS,
} from './actionNames';

const initialState = {};

function handleFetchHealthPlanGoalsSuccess(state, { data }) {
  return data;
}

function handleCreateHealthPlanGoalSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleDeleteHealthPlanGoalSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    delete draftState[data.healthPlanGoalId];
  });
  return nextState;
}

function handleResetHealthPlanGoals() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_GOALS_SUCCESS]: handleFetchHealthPlanGoalsSuccess,
  [CREATE_HEALTH_PLAN_GOAL_SUCCESS]: handleCreateHealthPlanGoalSuccess,
  [DELETE_HEALTH_PLAN_GOAL_SUCCESS]: handleDeleteHealthPlanGoalSuccess,
  [RESET_HEALTH_PLAN_GOALS]: handleResetHealthPlanGoals,
  [USER_LOGOUT_INIT]: handleResetHealthPlanGoals,
};

export default buildReducer(initialState, ACTION_HANDLERS);

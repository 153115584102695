import { patientHealthPlanPath } from 'routing/utils/paths';

export default function ({ patient, createHealthPlan, history }) {
  const handleOnSuccess = () => {
    history.push(patientHealthPlanPath({ patientId: patient.id }));
  };

  return createHealthPlan({
    data: { patient: patient.id },
  }).then(handleOnSuccess);
}

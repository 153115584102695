import { createSelector } from 'reselect';

const selectHealthPlanReviews = (state) => state.healthPlanReviews;

export const healthPlanReviewsSelector = createSelector(
  [selectHealthPlanReviews],
  (healthPlanReviews) => healthPlanReviews
);

export const healthPlanCurrentReviewSelector = createSelector(
  [selectHealthPlanReviews],
  (healthPlanReviews) => healthPlanReviews.healthPlanCurrentReview
);

export const healthPlanPreviousReviewsSelector = createSelector(
  [selectHealthPlanReviews],
  (healthPlanReviews) => healthPlanReviews.healthPlanPreviousReviews
);

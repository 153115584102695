import {
  FETCH_DASHBOARD_INIT,
  FETCH_DASHBOARD_SUCCESS,
  RESET_DASHBOARD,
} from './actionNames';

export function fetchDashboardInit(actionParams) {
  return { type: FETCH_DASHBOARD_INIT, ...actionParams };
}

export function fetchDashboardSuccess({ data }) {
  return { type: FETCH_DASHBOARD_SUCCESS, data };
}

export function resetDashboard(actionParams) {
  return { type: RESET_DASHBOARD, ...actionParams };
}

export default ({
  patientCycleOfCareModule,
  deletePatientCycleOfCareModule,
}) => {
  return deletePatientCycleOfCareModule({
    query: {
      patientId: patientCycleOfCareModule.patient,
      patientCycleOfCareModuleId: patientCycleOfCareModule.id,
    },
    data: { isActive: false },
  });
};

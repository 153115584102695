import { object, string } from 'yup';

export default () =>
  object().shape({
    firstName: string().required('This field is required'),
    lastName: string().required('This field is required'),
    email: string()
      .email('This field must be a valid email address')
      .required('This field is required'),
  });

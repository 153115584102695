import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_REVIEW_FULL_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
} from 'common/constants';
import {
  BarrierPropType,
  GoalPropType,
  SolutionPropType,
  HealthPlanReviewPropType,
  PatientPropType,
} from 'common/propTypes';
import GoalProgressReview from './components/GoalProgressReview';

const GoalProgressReviewsTab = ({
  healthPlanReview,
  barrierDefinitions,
  goalDefinitions,
  solutionDefinitions,
  handleGoToSection,
}) => {
  const sectionId = HEALTH_PLAN_REVIEW_FULL_SECTIONS.PROGRESS;

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      {!isEmpty(healthPlanReview) &&
        Object.values(healthPlanReview.goalProgressReviews).map(
          (goalProgressReview, idx) => (
            <GoalProgressReview
              key={idx}
              index={idx + 1}
              goalProgressReview={{
                ...goalProgressReview,
                goalDefinition:
                  goalDefinitions[goalProgressReview.goalDefinition],
              }}
              barrierDefinitions={barrierDefinitions}
              solutionDefinitions={solutionDefinitions}
            />
          )
        )}
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

GoalProgressReviewsTab.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  currentHealthPlanReview: PropTypes.shape(HealthPlanReviewPropType),
  barrierDefinitions: PropTypes.objectOf(PropTypes.shape(BarrierPropType))
    .isRequired,
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
  solutionDefinitions: PropTypes.objectOf(PropTypes.shape(SolutionPropType))
    .isRequired,
  handleGoToSection: PropTypes.func.isRequired,
};

export default GoalProgressReviewsTab;

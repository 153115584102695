import { createSelector } from 'reselect';

const selectPatientList = (state) => state.patients.patientList;
const selectPatientCount = (state) => state.patients.patientCount;

export const patientListSelector = createSelector(
  [selectPatientList],
  (patientList) => patientList
);
export const patientCountSelector = createSelector(
  [selectPatientCount],
  (patientCount) => patientCount
);

import React from 'react';
import PropTypes from 'prop-types';
import { Image as StyledImage } from './styles';

const Image = ({ src }) => (
  <StyledImage src={src} onDragStart={(e) => e.preventDefault()} />
);

Image.defaultProps = {};
Image.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Image;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { isEmpty, isNull } from 'lodash';
import { Typography } from '@mui/material';
import { Button, ProgressButton } from 'common/components/buttons';
import {
  TitleBox,
  FlexBox,
  Card,
} from 'common/components/layouts';
import { HEALTH_PLAN_STATUSES, PROVIDER_TYPES } from 'common/constants';
import { handleSubmit as handleCreateHealthPlan } from 'common/data/healthPlans/create';
import { handleSubmit as handleCreateHealthPlanReview } from 'common/data/healthPlanReviews/create';
import { handleSubmit as handleDownload } from 'common/data/healthPlans/download';
import {
  HealthPlanPropType,
  PatientPropType,
  UserPropType,
} from 'common/propTypes';
import {
  isUserNurse,
  renderFullName,
  renderHealthPlanStatus,
  renderHealthPlanButton,
  isUserDoctor,
} from 'common/utils';
import { patientHealthPlanPath } from 'routing/utils/paths';
import HealthJourney from './components/HealthJourney';
import HealthJourneyDialog from './components/HealthJourneyDialog';
import MedicareForm from './components/MedicareForm';
import PatientProfile from './components/PatientProfile';

const PatientPage = ({
  user,
  patient,
  healthPlan,
  healthJourney,
  updatePatient,
  createHealthPlan,
  createHealthPlanReview,
  downloadHealthPlan,
  fetchHealthJourney,
  resetHealthJourney,
  history,
  inlineLoading,
  fetchProviders,
  resetProviders,
}) => {
  const [downloading, setDownloading] = useState(false),
    isNurse = isUserNurse(user),
    hasHealthPlan = !isNull(patient.healthPlan),
    canHealthPlanBeReviewed =
      hasHealthPlan &&
      patient.healthPlan.status === HEALTH_PLAN_STATUSES.FINALISED,
    buttonLabel = renderHealthPlanButton(
      healthJourney,
      patient.healthPlan,
      isNurse
    ),
    buttonColor =
      !hasHealthPlan || canHealthPlanBeReviewed ? 'success' : 'primary',
    enableDownload =
      !isNull(healthPlan) &&
      [HEALTH_PLAN_STATUSES.SHARED, HEALTH_PLAN_STATUSES.FINALISED].includes(
        healthPlan.status
      );

  const handleHealthPlanAction = () => {
      if (!hasHealthPlan) {
        handleCreateHealthPlan({ patient, createHealthPlan, history });
      } else if (canHealthPlanBeReviewed) {
        handleCreateHealthPlanReview({
          healthPlan,
          createHealthPlanReview,
          history,
        });
      } else {
        isUserDoctor(user)
          ? history.push({
              pathname: patientHealthPlanPath({ patientId: patient.id }),
              state: {
                jumpToSummary:
                  hasHealthPlan &&
                  patient.healthPlan.status === HEALTH_PLAN_STATUSES.SHARED,
              },
            })
          : history.push(
              patientHealthPlanPath({
                patientId: patient.id,
              })
            );
      }
    },
    [openHealthJourneyDialog, setOpenHealthJourneyDialog] = useState(false),
    handleToggleHealthJourneyDialog = () => {
      setOpenHealthJourneyDialog(!openHealthJourneyDialog);
    },
    handleOnClick = () => {
      if (isEmpty(healthJourney)) {
        handleToggleHealthJourneyDialog();
      } else {
        handleHealthPlanAction();
      }
    },
    handleOnDownload = () => {
      setDownloading(true);
      handleDownload({
        healthPlan,
        downloadHealthPlan,
        disableLoader: () => setDownloading(false),
      });
    };

  const hasHealthJourney = !isEmpty(healthJourney);

  const { patientId } = useParams();

  useEffect(() => {
    fetchHealthJourney({ query: { patientId } }).catch((error) =>
      console.log('journey error:', error)
    );
    return () => resetHealthJourney();
    // eslint-disable-next-line
  }, []);

useEffect(() => {
  fetchProviders({
    query: { providerTypes: [PROVIDER_TYPES.GENERAL_PRACTITIONER] },
  });
  return () => {
    resetProviders();
  };
}, [fetchProviders, resetProviders]);

  return (
    <>
      <TitleBox
        contentLeft={
          <FlexBox flexDirection="column">
            <Typography variant="h1">{renderFullName(patient)}</Typography>
            <Typography variant="h3" color="textSecondary">
              {renderHealthPlanStatus(patient.healthPlan)}
            </Typography>
          </FlexBox>
        }
        contentRight={
          <FlexBox flexDirection="column">
            <Button
              label={buttonLabel}
              color={buttonColor}
              onClick={handleOnClick}
            />
            {enableDownload && (
              <ProgressButton
                label="download"
                variant="outlined"
                color="secondary"
                onClick={handleOnDownload}
                loading={downloading}
                disabled={inlineLoading.includes('update-user')}
              />
            )}
          </FlexBox>
        }
      />
      {!patient.medicareNumber && (
        <MedicareForm
          patient={patient}
          updatePatient={updatePatient}
          inlineLoading={inlineLoading.includes('update-user')}
        />
      )}
      {hasHealthJourney && (
        <HealthJourney
          patient={patient}
          healthJourney={healthJourney}
          inlineLoading={inlineLoading}
        />
      )}
      <Card>
      
      <PatientProfile
          user={user}
          patient={patient}
          updatePatient={updatePatient}
          history={history}
          inlineLoading={inlineLoading.includes('update-user')}
        />

      </Card>
      {openHealthJourneyDialog && (
        <HealthJourneyDialog
          open={openHealthJourneyDialog}
          patient={patient}
          handleContinue={handleHealthPlanAction}
          onClose={handleToggleHealthJourneyDialog}
          inlineLoading={inlineLoading.includes('healthJourney-create')}
        />
      )}
    </>
  );
};

PatientPage.defaultProps = {
  user: { id: 0, email: '', firstName: '', lastName: '' },
  patient: {
    firstName: '',
    lastName: '',
    healthPlan: { lastReview: {} },
    generalPractitioner: { id: 0 },
    sex: 0,
    medicareNumber: true,
  },
  healthPlan: {},
  healthJourney: {},
};
PatientPage.propTypes = {
  user: PropTypes.shape(UserPropType),
  patient: PropTypes.shape(PatientPropType),
  healthPlan: PropTypes.shape(HealthPlanPropType),
  healthJourney: PropTypes.shape({}),
  updatePatient: PropTypes.func.isRequired,
  createHealthPlan: PropTypes.func.isRequired,
  createHealthPlanReview: PropTypes.func.isRequired,
  downloadHealthPlan: PropTypes.func.isRequired,
  fetchHealthJourney: PropTypes.func.isRequired,
  resetHealthJourney: PropTypes.func.isRequired,
  fetchProviders: PropTypes.func.isRequired,
  resetProviders: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  inlineLoading: PropTypes.array.isRequired,
};

export default PatientPage;

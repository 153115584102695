import React from 'react';
import PropTypes from 'prop-types';
import { toNumber } from 'lodash';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Button } from 'common/components/buttons';
import { Form, SelectField } from 'common/components/form';
import { JOURNEY_DURATION, REVIEW_FREQUENCY } from 'common/constants';
import { renderObject } from 'common/utils';

const HealthJourneyDialog = ({
  open,
  values,
  values: { duration, reviewFrequency },
  setValues,
  handleSubmit,
  onClose,
  inlineLoading,
}) => {
  const handleOnSubmit = () => {
    handleSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Set Health Journey Parameters</DialogTitle>
      <DialogContent>
        <Form>
          <SelectField
            label="Duration"
            name="duration"
            value={duration}
            options={JOURNEY_DURATION}
            renderSelectLabel={renderObject}
            sortByIndex
            setValues={setValues}
            values={values}
            margin="dense"
            disabled={inlineLoading}
          />
          <SelectField
            label="Review Frequency"
            name="reviewFrequency"
            value={reviewFrequency}
            options={Object.entries(REVIEW_FREQUENCY).reduce(
              (acc, [key, value]) => {
                if (toNumber(key) <= toNumber(duration)) {
                  acc[key] = value;
                }
                return acc;
              },
              Object.create(null)
            )}
            renderSelectLabel={renderObject}
            sortByIndex
            setValues={setValues}
            values={values}
            margin="dense"
            disabled={!duration || inlineLoading}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="close"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={inlineLoading}
        />
        <Button
          label="continue"
          onClick={handleOnSubmit}
          disabled={inlineLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

HealthJourneyDialog.defaultProps = {};
HealthJourneyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  // values: PropTypes.shape({
  //   providerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //   tasks: PropTypes.arrayOf(PropTypes.number),
  //   numberOfVisits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // }).isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  inlineLoading: PropTypes.bool.isRequired,
};

export default HealthJourneyDialog;

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  withAction,
  withIsNotAuthenticated,
  withFullPageLayout,
  withFormErrorHandler,
  withRouterProps,
} from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/auth/login';
import { loginInit, authErrorsSelector } from 'services/database/actions/auth';
import LoginPage from './LoginPage';

export default compose(
  withAction({ actionName: 'login', action: loginInit }),
  connect((state) => ({
    formErrorData: authErrorsSelector(state),
  })),
  withIsNotAuthenticated,
  withFullPageLayout,
  withRouterProps,
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  }),
  withFormErrorHandler
)(LoginPage);

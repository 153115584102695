import { call, put } from 'redux-saga/effects';
import { deletePatientConditionSuccess } from 'services/database/actions/patientConditions';
import request from './request';

export default function* deleteSaga({ query, resolve, reject }) {
  try {
    yield call(request, { query });
    yield put(deletePatientConditionSuccess({ data: query }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import getComponentDisplayName from './utils/getComponentDisplayName';

function withFormErrorHandler(OriginalComponent) {
  const Component = ({ formErrorData, setErrors, ...props }) => {
    useEffect(() => {
      setErrors(formErrorData);
    }, [formErrorData, setErrors]);

    return <OriginalComponent {...props} />;
  };

  Component.defaultProps = {};
  Component.propTypes = {
    formErrorData: PropTypes.object,
    setErrors: PropTypes.func,
  };
  Component.displayName = `withFormErrorHandler(${getComponentDisplayName(
    OriginalComponent
  )})`;

  return Component;

  // DISCUSS: Do we want to include refreshErrors functionality?
  // const Container = compose(
  //   withAction({
  //     actionName: 'refreshErrors',
  //     action: refreshErrors,
  //   })
  // )(Component);

  // return Container;
}

export default withFormErrorHandler;

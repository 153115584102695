import { isUserDoctor } from 'common/utils';
import { patientsPath } from 'routing/utils/paths';
import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { user, patient, history, updatePatient }, resetForm }
) {
  const handleOnSuccess = () => {
      isUserDoctor(user) &&
        user.id !== Number(values.generalPractitioner) &&
        history.push(patientsPath());
    },
    handleOnError = () => {
      resetForm();
    };

  return updatePatient({
    query: { patientId: patient.id },
    data: mapValuesToProps(values),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

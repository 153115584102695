import { call, put } from 'redux-saga/effects';
import {
  updateHealthPlanSuccess,
  updateHealthPlanError,
} from 'services/database/actions/healthPlans';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  try {
    const responseData = yield call(request, { query, data });
    yield put(updateHealthPlanSuccess({ data: responseData }));
    yield call(resolve, { healthPlanId: query.healthPlanId });
  } catch (error) {
    yield put(updateHealthPlanError({ data: error.data }));
    yield call(reject, { error: error.data });
  }
}

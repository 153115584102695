import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/patientCycleOfCareModules/fetch';
import createSaga from 'services/backend/actions/patientCycleOfCareModules/create';
import deleteSaga from 'services/backend/actions/patientCycleOfCareModules/delete';
import {
  FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT,
  CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT,
  DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT,
} from './actionNames';

function* patientCycleOfCareModulesFlowSaga() {
  yield all([
    takeLatest(FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT, fetchSaga),
    takeLatest(CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT, createSaga),
    takeLatest(DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT, deleteSaga),
  ]);
}

export default patientCycleOfCareModulesFlowSaga;

import {
  FETCH_PATIENT_CONDITIONS_INIT,
  FETCH_PATIENT_CONDITIONS_SUCCESS,
  CREATE_PATIENT_CONDITION_INIT,
  CREATE_PATIENT_CONDITION_SUCCESS,
  DELETE_PATIENT_CONDITION_INIT,
  DELETE_PATIENT_CONDITION_SUCCESS,
  RESET_PATIENT_CONDITIONS,
} from './actionNames';

export function fetchPatientConditionsInit(actionParams) {
  return { type: FETCH_PATIENT_CONDITIONS_INIT, ...actionParams };
}

export function fetchPatientConditionsSuccess({ data }) {
  return { type: FETCH_PATIENT_CONDITIONS_SUCCESS, data };
}

export function createPatientConditionInit(actionParams) {
  return { type: CREATE_PATIENT_CONDITION_INIT, ...actionParams };
}

export function createPatientConditionSuccess({ data }) {
  return { type: CREATE_PATIENT_CONDITION_SUCCESS, data };
}

export function deletePatientConditionInit(actionParams) {
  return { type: DELETE_PATIENT_CONDITION_INIT, ...actionParams };
}

export function deletePatientConditionSuccess({ data }) {
  return { type: DELETE_PATIENT_CONDITION_SUCCESS, data };
}

export function resetPatientConditions(actionParams) {
  return { type: RESET_PATIENT_CONDITIONS, ...actionParams };
}

import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  patient: PropTypes.number.isRequired,
  patientCycleOfCareModule: PropTypes.number.isRequired,
  definition: PropTypes.number.isRequired,
  status: PropTypes.number,
  frequency: PropTypes.number,
  completedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  nextCheckDue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  lastMeasurement: PropTypes.object, // TODO: Update to use PatientMeasurementPropType
};

import React from 'react';
import PropTypes from 'prop-types';
import { TableCell as StyledTableCell } from './styles';

const TableCell = ({ children, color, ...props }) => (
  <StyledTableCell color={color} {...props}>
    {children}
  </StyledTableCell>
);

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

export default TableCell;

export default function ({ goalProgressBarrier, deleteGoalProgressBarrier }) {
  return deleteGoalProgressBarrier({
    query: {
      healthPlanId: goalProgressBarrier.healthPlan,
      healthPlanReviewId: goalProgressBarrier.healthPlanReview,
      goalProgressReviewId: goalProgressBarrier.goalProgressReview,
      goalProgressBarrierId: goalProgressBarrier.id,
      definitionId: goalProgressBarrier.definition,
    },
  });
}

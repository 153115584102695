import { isUndefined } from 'lodash';
import { TimelineDot as MuiTimelineDot } from '@mui/lab';
import { styled } from '@mui/material/styles';

export const TimelineDot = styled(MuiTimelineDot)(({ theme, color }) => ({
  color: !isUndefined(theme.palette[color])
    ? theme.palette[color].contrastText
    : color,
  backgroundColor: !isUndefined(theme.palette[color])
    ? theme.palette[color].main
    : color,
  borderColor: 'transparent',
}));

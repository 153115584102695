import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { healthPlan, createHealthPlanAction } }
) {
  const handleOnSuccess = () => {},
    handleOnError = () => {};

  return createHealthPlanAction({
    query: { healthPlanId: healthPlan.id },
    data: mapValuesToProps({ ...values, healthPlan }),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction } from 'common/HOCs';
import { deleteHealthPlanMeasurementInit } from 'services/database/actions/healthPlanMeasurements';
import { inlineLoadingSelector } from 'services/database/actions/loading';
import HealthPlanMeasurement from './HealthPlanMeasurement';

export default compose(
  withAction({
    actionName: 'deleteHealthPlanMeasurement',
    action: deleteHealthPlanMeasurementInit,
  }),
  connect((state) => ({
    inlineLoading: inlineLoadingSelector(state),
  }))
)(HealthPlanMeasurement);

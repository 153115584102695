import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toNumber } from 'lodash';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Button, ToggleButton } from 'common/components/buttons';
import { Form, Input, MockLabel, SelectField } from 'common/components/form';
import { FlexBox, GoalsCard } from 'common/components/layouts';
import { PROVIDER_GROUPS, PROVIDER_TYPE_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  TaskPropType,
  HealthPlanServicePropType,
  ProviderPropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';

const UpdateHealthPlanService = ({
  open,
  healthPlanService,
  providers,
  goalDefinitions,
  taskDefinitions,
  values,
  values: { providerId, tasks, numberOfVisits },
  setValues,
  fetchProviders,
  resetProviders,
  handleSubmit,
  onClose,
  readOnly,
}) => {
  useEffect(() => {
    // DISCUSS: isNil check?
    fetchProviders({
      query: { providerTypes: healthPlanService.definition.providerTypes },
    });
    return () => {
      resetProviders();
    };
    // eslint-disable-next-line
  }, []);

  const handleChange = (taskId) => (e) => {
    if (tasks.includes(taskId)) {
      setValues({
        ...values,
        tasks: tasks.filter((task) => task !== taskId),
      });
    } else {
      setValues({
        ...values,
        tasks: [...tasks, taskId],
      });
    }
  };

  const handleOnSubmit = () => {
    handleSubmit();
    onClose();
  };

  // BUG: Error thrown due to withFormikFieldProps: Warning: React does not recognize the `setValue` prop on a DOM element.
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {healthPlanService.definition.providerTypes.map(
          (providerType) => PROVIDER_TYPE_NAMES[providerType]
        )}
      </DialogTitle>
      <DialogContent>
        <Form>
          <SelectField
            label="Provider"
            name="providerId"
            value={providerId}
            options={providers}
            renderSelectLabel={renderFullName}
            setValues={setValues}
            values={values}
            margin="dense"
            disabled={readOnly}
          />
          {healthPlanService.definition.providerGroup ===
          PROVIDER_GROUPS.PHARMACIST ? (
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <GoalsCard
                    title="Service 1"
                    content={healthPlanService.definition.name}
                    goals={healthPlanService.healthPlanGoals.map(
                      (healthPlanGoal) =>
                        goalDefinitions[healthPlanGoal.definition]
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <>
              <Box mt={2}>
                <MockLabel label="Tasks" disabled={readOnly} shrink />
                <FlexBox mt={0.5}>
                  {taskDefinitions.map((task, idx) => (
                    <FlexBox key={idx} m="0 0.5rem 0.5rem 0">
                      <ToggleButton
                        label={task.name}
                        selected={tasks.includes(task.id)}
                        onClick={handleChange(task.id)}
                        readOnly={readOnly}
                        dense
                      />
                    </FlexBox>
                  ))}
                </FlexBox>
              </Box>
              <Input
                label="Number of Visits"
                name="numberOfVisits"
                value={numberOfVisits}
                type="number"
                disabled={readOnly}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={(e) => {
                  var value = toNumber(e.values.numberOfVisits);
                  if (value < 0) value = 0;
                  setValues({ ...values, numberOfVisits: value });
                }}
              />
            </>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="close"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        />
        {!readOnly && <Button label="update" onClick={handleOnSubmit} />}
      </DialogActions>
    </Dialog>
  );
};

UpdateHealthPlanService.defaultProps = {
  providers: {},
  taskDefinitions: [],
};
UpdateHealthPlanService.propTypes = {
  open: PropTypes.bool.isRequired,
  healthPlanService: PropTypes.shape({
    ...HealthPlanServicePropType,
    healthPlan: PropTypes.number.isRequired,
    definition: PropTypes.shape(ServicePropType),
  }).isRequired,
  providers: PropTypes.objectOf(PropTypes.shape(ProviderPropType)),
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
  taskDefinitions: PropTypes.arrayOf(PropTypes.shape(TaskPropType)),
  values: PropTypes.shape({
    providerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tasks: PropTypes.arrayOf(PropTypes.number),
    numberOfVisits: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  fetchProviders: PropTypes.func.isRequired,
  resetProviders: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default UpdateHealthPlanService;

import { isArray } from 'lodash';
import ErrorWithData from './ErrorWithData';
// define errors. data field used by FormGeneralErrorMessage to display unauthorizedError. Error set/displayed using formik

export class UnauthorizedError extends Error {
  constructor(data, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorWithData);
    }
    this.name = 'UnauthorizedError';
    this.number = 401;
    this.data = data;
  }
}

export class InvalidDataError extends ErrorWithData {
  constructor(data, ...params) {
    super(...params);
    const newData = Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = isArray(value) ? value[0] : value;
      return acc;
    }, Object.create(null));
    this.name = 'InvalidDataError';
    this.data = newData;
  }
}

export class ForbiddenError extends Error {
  constructor(data, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorWithData);
    }
    this.name = 'ForbiddenError';
    this.number = 403;
    this.data = data;
  }
}

export class NotFoundError extends Error {
  constructor(data, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorWithData);
    }
    this.name = 'NotFoundError';
    this.number = 404;
    this.data = data;
  }
}

export class OtherError extends Error {
  constructor(error, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorWithData);
    }
    this.name = 'OtherError';
    this.message = error.message;
  }
}

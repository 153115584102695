import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patientConditions/create';
import { createPatientConditionInit } from 'services/database/actions/patientConditions';
import PatientConditionAdd from './PatientConditionAdd';

export default compose(
  withAction({
    actionName: 'createPatientCondition',
    action: createPatientConditionInit,
  }),
  withFormik({ mapPropsToValues, validationSchema, handleSubmit })
)(PatientConditionAdd);

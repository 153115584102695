import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { isEmpty, sortBy } from 'lodash';
import { Button } from 'common/components/buttons';
import { FlexBox } from 'common/components/layouts';
import {
  CycleOfCareItemPropType,
  CycleOfCareModulePropType,
  PatientCycleOfCareItemPropType,
  PatientCycleOfCareModulePropType,
  PatientPropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';
import PatientCycleOfCareModuleAdd from './components/PatientCycleOfCareModuleAdd';
import PatientCycleOfCareModule from './components/PatientCycleOfCareModule';

const PatientCyclesOfCare = ({
  patient,
  activePatientCycleOfCareModules,
  inactivePatientCycleOfCareModules,
  availableCycleOfCareModules,
  fetchPatientCycleOfCareModules,
  resetPatientCycleOfCareModules,
  readOnly,
}) => {
  useEffect(() => {
    // DISCUSS: isNil check?
    fetchPatientCycleOfCareModules({ query: { patientId: patient.id } });
    return () => resetPatientCycleOfCareModules();
    // eslint-disable-next-line
  }, []);

  const [showPatientCycleOfCareModuleAdd, setShowPatientCycleOfCareModuleAdd] =
      useState(false),
    handleToggleCreate = () => {
      setShowPatientCycleOfCareModuleAdd(!showPatientCycleOfCareModuleAdd);
    };

  return (
    <>
      <Typography color="textSecondary" paragraph>
        Select the Cycles of Care to be included in {renderFullName(patient)}'s
        Health Plan.
      </Typography>

      {isEmpty(activePatientCycleOfCareModules) ? (
        <Typography variant="h4" align="center">
          No cycles of care have been added
        </Typography>
      ) : (
        sortBy(activePatientCycleOfCareModules, ['id']).map(
          (patientCycleOfCareModule) => (
            <PatientCycleOfCareModule
              key={patientCycleOfCareModule.id}
              patientCycleOfCareModule={patientCycleOfCareModule}
              readOnly={readOnly}
            />
          )
        )
      )}

      {!readOnly && (
        <FlexBox flexDirection="column" mt="1rem">
          {showPatientCycleOfCareModuleAdd && (
            <PatientCycleOfCareModuleAdd
              open={showPatientCycleOfCareModuleAdd}
              patient={patient}
              availableCycleOfCareModules={availableCycleOfCareModules}
              inactivePatientCycleOfCareModules={
                inactivePatientCycleOfCareModules
              }
              onClose={handleToggleCreate}
            />
          )}
          <Button
            label="Add Cycle of Care"
            onClick={handleToggleCreate}
            disabled={isEmpty(availableCycleOfCareModules)}
          />
        </FlexBox>
      )}
    </>
  );
};

PatientCyclesOfCare.defaultProps = {
  activePatientCycleOfCareModules: [],
  inactivePatientCycleOfCareModules: [],
  availableCycleOfCareModules: [],
};
PatientCyclesOfCare.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  activePatientCycleOfCareModules: PropTypes.arrayOf(
    PropTypes.shape({
      ...PatientCycleOfCareModulePropType,
      patientCycleOfCareItems: PropTypes.arrayOf(
        PropTypes.shape({
          ...PatientCycleOfCareItemPropType,
          definition: PropTypes.shape(CycleOfCareItemPropType),
        })
      ),
      definition: PropTypes.shape(CycleOfCareModulePropType),
    })
  ),
  inactivePatientCycleOfCareModules: PropTypes.arrayOf(
    PropTypes.shape({
      ...PatientCycleOfCareModulePropType,
      patientCycleOfCareItems: PropTypes.arrayOf(
        PropTypes.shape({
          ...PatientCycleOfCareItemPropType,
          definition: PropTypes.shape(CycleOfCareItemPropType),
        })
      ),
      definition: PropTypes.shape(CycleOfCareModulePropType),
    })
  ),
  availableCycleOfCareModules: PropTypes.arrayOf(
    PropTypes.shape(CycleOfCareModulePropType)
  ),
  fetchPatientCycleOfCareModules: PropTypes.func.isRequired,
  resetPatientCycleOfCareModules: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default PatientCyclesOfCare;

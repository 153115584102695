import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { DeletableCard, Chip } from 'common/components/layouts';
import { handleSubmit as handleDelete } from 'common/data/healthPlanGoals/delete';
import { GoalPropType, HealthPlanGoalPropType } from 'common/propTypes';

const HealthPlanGoal = ({
  index,
  healthPlanGoal,
  deleteHealthPlanGoal,
  readOnly,
}) => {
  const handleOnDelete = () => {
    handleDelete({ healthPlanGoal, deleteHealthPlanGoal });
  };

  return (
    <DeletableCard
      onClick={handleOnDelete}
      readOnly={readOnly || healthPlanGoal.isDefault}
    >
      <Chip
        title={`Goal #${index}${
          healthPlanGoal.isDefault ? ' - Default (Cannot be removed)' : ''
        }`}
        variant="h5"
        color={healthPlanGoal.color}
      />
      <Typography variant="h2">{healthPlanGoal.name}</Typography>
    </DeletableCard>
  );
};

HealthPlanGoal.propTypes = {
  index: PropTypes.number.isRequired,
  healthPlanGoal: PropTypes.shape({
    ...GoalPropType,
    ...HealthPlanGoalPropType,
  }).isRequired,
  deleteHealthPlanGoal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanGoal;

import { PROVIDER_TYPES } from 'common/constants';

export default {
  [PROVIDER_TYPES.GENERAL_PRACTITIONER]: 'General Practitioner',
  [PROVIDER_TYPES.PRACTICE_NURSE]: 'Practice Nurse',
  [PROVIDER_TYPES.PHARMACIST]: 'Pharmacist',
  [PROVIDER_TYPES.PHYSIOTHERAPIST]: 'Physiotherapist',
  [PROVIDER_TYPES.PODIATRIST]: 'Podiatrist',
  [PROVIDER_TYPES.EXERCISE_PHYSIOLOGIST]: 'Exercise Physiologist',
  [PROVIDER_TYPES.DIABETES_EDUCATOR]: 'Diabetes Educator',
  [PROVIDER_TYPES.DIETICIAN]: 'Dietician',
  [PROVIDER_TYPES.OCCUPATIONAL_THERAPIST]: 'Occupational Therapist',
  [PROVIDER_TYPES.ABORIGINAL_AND_TORRES_STRAIT_ISLANDER_HEALTH_PRACTITIONER]:
    'Aboriginal And Torres Strait Islander Health Practitioner',
  [PROVIDER_TYPES.ABORIGINAL_HEALTH_WORKER]: 'Aboriginal Health Worker',
  [PROVIDER_TYPES.AUDIOLOGIST]: 'Audiologist',
  [PROVIDER_TYPES.CHIROPRACTOR]: 'Chiropractor',
  [PROVIDER_TYPES.SPEECH_PATHOLOGIST]: 'Speech Pathologist',
  [PROVIDER_TYPES.OSTEOPATH]: 'Osteopath',
  [PROVIDER_TYPES.MENTAL_HEALTH_WORKER]: 'Mental Health Worker',
  [PROVIDER_TYPES.PSYCHOLOGIST]: 'Psychologist',
  [PROVIDER_TYPES.SPECIALIST_IN_ADDICTION_MEDICINE]:
    'Specialist In Addiction Medicine',
  [PROVIDER_TYPES.ANAESTHETIST]: 'Anaesthetist',
  [PROVIDER_TYPES.DERMATOLOGIST]: 'Dermatologist',
  [PROVIDER_TYPES.EMERGENCY_PHYSICIAN]: 'Emergency Physician',
  [PROVIDER_TYPES.PAEDIATRIC_EMERGENCY_PHYSICIAN]:
    'Paediatric Emergency Physician',
  [PROVIDER_TYPES.INTENSIVE_CARE_PHYSICIAN]: 'Intensive Care Physician',
  [PROVIDER_TYPES.PAEDIATRIC_INTENSIVE_CARE_PHYSICIAN]:
    'Paediatric Intensive Care Physician',
  [PROVIDER_TYPES.MEDICAL_ADMINISTRATOR]: 'Medical Administrator',
  [PROVIDER_TYPES.OBSTETRICIAN_AND_GYNAECOLOGIST]:
    'Obstetrician And Gynaecologist',
  [PROVIDER_TYPES.GYNAECOLOGICAL_ONCOLOGIST]: 'Gynaecological Oncologist',
  [PROVIDER_TYPES.SPECIALIST_IN_MATERNAL_FETAL_MEDICINE]:
    'Specialist In Maternal Fetal Medicine',
  [PROVIDER_TYPES.SPECIALIST_IN_OBSTETRICS_AND_GYNAECOLOGICAL_ULTRASOUND]:
    'Specialist In Obstetrics And Gynaecological Ultrasound',
  [PROVIDER_TYPES.SPECIALIST_IN_REPRODUCTIVE_ENDOCRINOLOGY_AND_INFERTILITY]:
    'Specialist In Reproductive Endocrinology And Infertility',
  [PROVIDER_TYPES.UROGYNAECOLOGIST]: 'Urogynaecologist',
  [PROVIDER_TYPES.OCCUPATIONAL_AND_ENVIRONMENTAL_PHYSICIAN]:
    'Occupational And Environmental Physician',
  [PROVIDER_TYPES.OPHTHALMOLOGIST]: 'Ophthalmologist',
  [PROVIDER_TYPES.PAEDIATRICIAN]: 'Paediatrician',
  [PROVIDER_TYPES.PAEDIATRIC_CLINICAL_GENETICIST]:
    'Paediatric Clinical Geneticist',
  [PROVIDER_TYPES.SPECIALIST_IN_COMMUNITY_CHILD_HEALTH]:
    'Specialist In Community Child Health',
  [PROVIDER_TYPES.GENERAL_PAEDIATRICIAN]: 'General Paediatrician',
  [PROVIDER_TYPES.NEONATOLOGIST]: 'Neonatologist',
  [PROVIDER_TYPES.PAEDIATRIC_CARDIOLOGIST]: 'Paediatric Cardiologist',
  [PROVIDER_TYPES.PAEDIATRIC_CLINICAL_PHARMACOLOGIST]:
    'Paediatric Clinical Pharmacologist',
  [PROVIDER_TYPES.PAEDIATRIC_ENDOCRINOLOGIST]: 'Paediatric Endocrinologist',
  [PROVIDER_TYPES.PAEDIATRIC_GASTROENTEROLOGIST_AND_HEPATOLOGIST]:
    'Paediatric Gastroenterologist And Hepatologist',
  [PROVIDER_TYPES.PAEDIATRIC_HAEMATOLOGIST]: 'Paediatric Haematologist',
  [PROVIDER_TYPES.PAEDIATRIC_IMMUNOLOGIST_AND_ALLERGIST]:
    'Paediatric Immunologist And Allergist',
  [PROVIDER_TYPES.PAEDIATRIC_INFECTIOUS_DISEASES_PHYSICIAN]:
    'Paediatric Infectious Diseases Physician',
  [PROVIDER_TYPES.PAEDIATRIC_MEDICAL_ONCOLOGIST]:
    'Paediatric Medical Oncologist',
  [PROVIDER_TYPES.PAEDIATRIC_NEPHROLOGIST]: 'Paediatric Nephrologist',
  [PROVIDER_TYPES.PAEDIATRIC_NEUROLOGIST]: 'Paediatric Neurologist',
  [PROVIDER_TYPES.PAEDIATRIC_NUCLEAR_MEDICINE_PHYSICIAN]:
    'Paediatric Nuclear Medicine Physician',
  [PROVIDER_TYPES.PAEDIATRIC_PALLIATIVE_MEDICINE_PHYSICIAN]:
    'Paediatric Palliative Medicine Physician',
  [PROVIDER_TYPES.PAEDIATRIC_REHABILITATION_PHYSICIAN]:
    'Paediatric Rehabilitation Physician',
  [PROVIDER_TYPES.PAEDIATRIC_RESPIRATORY_AND_SLEEP_MEDICINE_PHYSICIAN]:
    'Paediatric Respiratory And Sleep Medicine Physician',
  [PROVIDER_TYPES.PAEDIATRIC_RHEUMATOLOGIST]: 'Paediatric Rheumatologist',
  [PROVIDER_TYPES.PAIN_MEDICINE_PHYSICIAN]: 'Pain Medicine Physician',
  [PROVIDER_TYPES.PALLIATIVE_MEDICINE_PHYSICIAN]:
    'Palliative Medicine Physician',
  [PROVIDER_TYPES.PATHOLOGIST]: 'Pathologist',
  [PROVIDER_TYPES.GENERAL_PATHOLOGIST]: 'General Pathologist',
  [PROVIDER_TYPES.ANATOMICAL_PATHOLOGIST]: 'Anatomical Pathologist',
  [PROVIDER_TYPES.CHEMICAL_PATHOLOGIST]: 'Chemical Pathologist',
  [PROVIDER_TYPES.HAEMATOLOGIST]: 'Haematologist',
  [PROVIDER_TYPES.IMMUNOLOGIST]: 'Immunologist',
  [PROVIDER_TYPES.MICROBIOLOGIST]: 'Microbiologist',
  [PROVIDER_TYPES.FORENSIC_PATHOLOGIST]: 'Forensic Pathologist',
  [PROVIDER_TYPES.SPECIALIST_PHYSICIAN]: 'Specialist Physician',
  [PROVIDER_TYPES.CARDIOLOGIST]: 'Cardiologist',
  [PROVIDER_TYPES.CLINICAL_GENETICIST]: 'Clinical Geneticist',
  [PROVIDER_TYPES.CLINICAL_PHARMACOLOGIST]: 'Clinical Pharmacologist',
  [PROVIDER_TYPES.ENDOCRINOLOGIST]: 'Endocrinologist',
  [PROVIDER_TYPES.GASTROENTEROLOGIST_AND_HEPATOLOGIST]:
    'Gastroenterologist And Hepatologist',
  [PROVIDER_TYPES.SPECIALIST_GENERAL_PHYSICIAN]: 'Specialist General Physician',
  [PROVIDER_TYPES.GERIATRICIAN]: 'Geriatrician',
  [PROVIDER_TYPES.IMMUNOLOGIST_AND_ALLERGIST]: 'Immunologist And Allergist',
  [PROVIDER_TYPES.INFECTIOUS_DISEASES_PHYSICIAN]:
    'Infectious Diseases Physician',
  [PROVIDER_TYPES.MEDICAL_ONCOLOGIST]: 'Medical Oncologist',
  [PROVIDER_TYPES.NEPHROLOGIST]: 'Nephrologist',
  [PROVIDER_TYPES.NEUROLOGIST]: 'Neurologist',
  [PROVIDER_TYPES.NUCLEAR_MEDICINE_PHYSICIAN]: 'Nuclear Medicine Physician',
  [PROVIDER_TYPES.RESPIRATORY_AND_SLEEP_MEDICINE_PHYSICIAN]:
    'Respiratory And Sleep Medicine Physician',
  [PROVIDER_TYPES.RHEUMATOLOGIST]: 'Rheumatologist',
  [PROVIDER_TYPES.PSYCHIATRIST]: 'Psychiatrist',
  [PROVIDER_TYPES.PUBLIC_HEALTH_PHYSICIAN]: 'Public Health Physician',
  [PROVIDER_TYPES.RADIATION_ONCOLOGIST]: 'Radiation Oncologist',
  [PROVIDER_TYPES.RADIOLOGIST]: 'Radiologist',
  [PROVIDER_TYPES.SPECIALIST_IN_NUCLEAR_MEDICINE]:
    'Specialist In Nuclear Medicine',
  [PROVIDER_TYPES.REHABILITATION_PHYSICIAN]: 'Rehabilitation Physician',
  [PROVIDER_TYPES.SEXUAL_HEALTH_PHYSICIAN]: 'Sexual Health Physician',
  [PROVIDER_TYPES.SPORT_AND_EXERCISE_PHYSICIAN]: 'Sport And Exercise Physician',
  [PROVIDER_TYPES.SPECIALIST_SURGEON]: 'Specialist Surgeon',
  [PROVIDER_TYPES.CARDIO_THORACIC_SURGEON]: 'Cardio Thoracic Surgeon',
  [PROVIDER_TYPES.GENERAL_SURGEON]: 'General Surgeon',
  [PROVIDER_TYPES.NEUROSURGEON]: 'Neurosurgeon',
  [PROVIDER_TYPES.ORTHOPAEDIC_SURGEON]: 'Orthopaedic Surgeon',
  [PROVIDER_TYPES.OTOLARYNGOLOGIST]: 'Otolaryngologist',
  [PROVIDER_TYPES.ORAL_AND_MAXILLOFACIAL_SURGEON]:
    'Oral And Maxillofacial Surgeon',
  [PROVIDER_TYPES.PAEDIATRIC_SURGEON]: 'Paediatric Surgeon',
  [PROVIDER_TYPES.PLASTIC_SURGEON]: 'Plastic Surgeon',
  [PROVIDER_TYPES.UROLOGIST]: 'Urologist',
  [PROVIDER_TYPES.VASCULAR_SURGEON]: 'Vascular Surgeon',
  [PROVIDER_TYPES.OPTOMETRIST]: 'Optometrist',
};

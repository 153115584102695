import React from 'react';
import PropTypes from 'prop-types';
import { Box as StyledBox } from './styles';

const ContentBox = ({ children, ...props }) => {
  return <StyledBox {...props}>{children}</StyledBox>;
};

ContentBox.defaultProps = {};
ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentBox;

import getJSONLocalStorage from './getJSONLocalStorage';

// AuthService.js
const AuthService = (function () {
  const jsonLocalStorage = getJSONLocalStorage();
  let authLocalStorage = jsonLocalStorage.getItem('auth');

  function _getAuth() {
    let _auth;
    if (!_auth) {
      _auth = this;
      return _auth;
    }
    return _auth;
  }
  function _setAuth(authObj) {
    jsonLocalStorage.setItem('auth', authObj);
    authLocalStorage = jsonLocalStorage.getItem('auth');
  }
  function _getAccessToken() {
    return authLocalStorage ? authLocalStorage.accessToken : null;
  }
  function _getRefreshToken() {
    return authLocalStorage ? authLocalStorage.refreshToken : null;
  }
  function _getIsAuthenticated() {
    return authLocalStorage ? authLocalStorage.isAuthenticated : false;
  }
  function _clearAuth() {
    jsonLocalStorage.removeItem('auth');
    authLocalStorage = jsonLocalStorage.getItem('auth');
  }
  return {
    getAuth: _getAuth,
    setAuth: _setAuth,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getIsAuthenticated: _getIsAuthenticated,
    clearAuth: _clearAuth,
  };
})();

export default AuthService;

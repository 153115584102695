import React from 'react';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import paths from 'routing/paths';
import { withIsAuthenticatedProp } from 'common/HOCs';
import getComponentDisplayName from './utils/getComponentDisplayName';

function withIsAuthenticated(OriginalComponent) {
  const Component = ({ ...props }) => {
    return props.isAuthenticated ? (
      <OriginalComponent {...props} />
    ) : (
      <Redirect to={paths.LOGIN} />
    );
  };

  Component.displayName = `withIsAuthenticated(${getComponentDisplayName(
    OriginalComponent
  )})`;

  const Container = compose(withIsAuthenticatedProp)(Component);

  return Container;
}

export default withIsAuthenticated;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { FlexBox } from 'common/components/layouts';
import { Input } from 'common/components/form';
import { Button } from 'common/components/buttons';
import { Card as StyledCard } from './styles';
import { PatientPropType } from 'common/propTypes';

const MedicareForm = ({
  patient,
  values: { email, medicareNumber },
  handleSubmit,
  inlineLoading,
}) => {
  return (
    <StyledCard>
      <FlexBox flexDirection="column">
        <Typography variant="h4" gutterBottom>
          Please confirm {patient.firstName}'s email and provide medicare number
        </Typography>
        <Input
          label="Email"
          name="email"
          value={email}
          margin="none"
          color="secondary"
          style={{ width: '100%' }}
          disabled={inlineLoading}
        />
        <Input
          label="Medicare Number"
          name="medicareNumber"
          value={medicareNumber}
          margin="none"
          style={{ width: '100%' }}
          disabled={inlineLoading}
        />
        <Typography variant="subtitle1">
          The medicare number is required to allow {patient.firstName} to access
          the patient app.
        </Typography>
        <Button
          label="update"
          type="submit"
          onClick={handleSubmit}
          style={{ alignSelf: 'flex-end' }}
          disabled={inlineLoading}
        />
      </FlexBox>
    </StyledCard>
  );
};

MedicareForm.defaultProps = {};
MedicareForm.propTypes = {
  patient: PropTypes.shape(PatientPropType),
  values: PropTypes.shape({
    email: PropTypes.string,
    medicareNumber: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inlineLoading: PropTypes.bool.isRequired,
};

export default MedicareForm;

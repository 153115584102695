import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, sortBy } from 'lodash';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import { ExpandableCard, FlexBox, GoalChips } from 'common/components/layouts';
import {
  GoalPropType,
  MeasurementPropType,
  HealthPlanPropType,
  HealthPlanGoalPropType,
  PatientPropType,
  HealthPlanMeasurementPropType,
  PatientMeasurementPropType,
} from 'common/propTypes';
import HealthPlanMeasurement from './components/HealthPlanMeasurement';
import HealthPlanMeasurementAdd from './components/HealthPlanMeasurementAdd';

const HealthPlanMeasurements = ({
  patient,
  healthPlan,
  healthPlanGoals,
  healthPlanMeasurements,
  availableHealthPlanMeasurements,
  fetchHealthPlanMeasurements,
  resetHealthPlanMeasurements,
  fetchPatientMeasurements,
  resetPatientMeasurements,
  readOnly,
}) => {
  useEffect(() => {
    if (!isNil(healthPlan.id)) {
      fetchHealthPlanMeasurements({ query: { patientId: healthPlan.id } });
    }
    return () => resetHealthPlanMeasurements();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isNil(patient.id)) {
      fetchPatientMeasurements({ query: { patientId: patient.id } });
    }
    return () => resetPatientMeasurements();
    // eslint-disable-next-line
  }, []);

  const [showPatientMeasurementAdd, setShowPatientMeasurementAdd] =
      useState(false),
    handleToggleCreate = () => {
      setShowPatientMeasurementAdd(!showPatientMeasurementAdd);
    };

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Typography variant="h2">Baseline Data</Typography>
          <Typography variant="h6" color="textSecondary">
            Measurements: {healthPlanMeasurements.length}
          </Typography>
        </Box>
      }
    >
      <GoalChips goals={healthPlanGoals} />
      {isEmpty(healthPlanMeasurements) ? (
        <Typography variant="h4" align="center">
          No health plan measurements have been added
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {sortBy(healthPlanMeasurements, ['createdAt']).map(
            (healthPlanMeasurement, idx) => (
              <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
                <HealthPlanMeasurement
                  patient={patient}
                  healthPlan={healthPlan}
                  healthPlanMeasurement={healthPlanMeasurement}
                  healthPlanGoals={healthPlanGoals}
                  readOnly={readOnly}
                />
              </Grid>
            )
          )}
        </Grid>
      )}
      {!readOnly && (
        <FlexBox flexDirection="column" mt="1rem">
          {showPatientMeasurementAdd && (
            <HealthPlanMeasurementAdd
              open={showPatientMeasurementAdd}
              healthPlan={healthPlan}
              availableHealthPlanMeasurements={availableHealthPlanMeasurements}
              onClose={handleToggleCreate}
            />
          )}
          <Button
            label="Add Measurement"
            onClick={handleToggleCreate}
            disabled={isEmpty(availableHealthPlanMeasurements)}
          />
        </FlexBox>
      )}
    </ExpandableCard>
  );
};

HealthPlanMeasurements.defaultProps = {
  availableHealthPlanMeasurements: [],
  healthPlanGoals: [],
};
HealthPlanMeasurements.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ),
  healthPlanMeasurements: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanMeasurementPropType,
      healthPlan: PropTypes.number,
      lastMeasurement: PropTypes.shape(PatientMeasurementPropType),
      definition: PropTypes.shape(MeasurementPropType),
    })
  ),
  availableHealthPlanMeasurements: PropTypes.arrayOf(
    PropTypes.shape(MeasurementPropType)
  ),
  fetchPatientMeasurements: PropTypes.func.isRequired,
  resetPatientMeasurements: PropTypes.func.isRequired,
  fetchHealthPlanMeasurements: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanMeasurements;

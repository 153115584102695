import { isUndefined } from 'lodash';
import { IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(({ theme, color }) => ({
  '&:hover': {
    color: !isUndefined(theme.palette[color])
      ? theme.palette[color].main
      : color,
  },
}));

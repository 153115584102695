import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { TableRow, TableCell } from 'common/components/layouts';
import {
  CYCLE_OF_CARE_FREQUENCIES,
  CYCLE_OF_CARE_ITEM_STATUSES,
  CYCLE_OF_CARE_ITEM_STATUS_NAMES,
} from 'common/constants';
import {
  CycleOfCareItemPropType,
  PatientCycleOfCareItemPropType,
} from 'common/propTypes';
import { renderDate } from 'common/utils';

const PatientCycleOfCareItem = ({ patientCycleOfCareItem }) => {
  const isOverdue =
    patientCycleOfCareItem.status === CYCLE_OF_CARE_ITEM_STATUSES.OVERDUE;

  return (
    <TableRow dense>
      <TableCell align="left">
        <Typography>{patientCycleOfCareItem.definition.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography color={isOverdue ? 'error' : 'textPrimary'}>
          {CYCLE_OF_CARE_ITEM_STATUS_NAMES[patientCycleOfCareItem.status]}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {CYCLE_OF_CARE_FREQUENCIES[patientCycleOfCareItem.frequency]}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {renderDate(patientCycleOfCareItem.completedAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography color={isOverdue ? 'error' : 'textPrimary'}>
          {renderDate(patientCycleOfCareItem.nextCheckDue)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

PatientCycleOfCareItem.propTypes = {
  patientCycleOfCareItem: PropTypes.shape({
    ...PatientCycleOfCareItemPropType,
    definition: PropTypes.shape(CycleOfCareItemPropType),
  }).isRequired,
};

export default PatientCycleOfCareItem;

import React, { useState } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Stack, Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import { Input } from 'common/components/form';
import { Card, TitleBox } from 'common/components/layouts';
import GlobalTheme from 'common/styles/global';
import ChangePasswordModal from './components/ChangePasswordModal';

const SettingsPage = ({
  values: { firstName, lastName, email },
  values,
  initialValues,
  handleSubmit,
}) => {
  const [showChangePassword, setShowChangePassword] = useState(false),
    handleToggleChangePasswordDialog = () => {
      setShowChangePassword(!showChangePassword);
    };

  const handleOnClick = () => {
    handleSubmit();
  };

  return (
    <>
      <TitleBox contentLeft={<Typography variant="h1">Settings</Typography>} />
      <Card>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Input
              label="First Name"
              name="firstName"
              value={firstName}
              margin="none"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Input
              label="Last Name"
              name="lastName"
              value={lastName}
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Input label="Email" name="email" value={email} margin="none" />
          </Grid>
        </Grid>

        {showChangePassword && (
          <ChangePasswordModal
            open={showChangePassword}
            onClose={handleToggleChangePasswordDialog}
          />
        )}

        <Stack
          direction="row"
          sx={{ marginTop: GlobalTheme.spacing(3), justifyContent: 'flex-end' }}
        >
          <Button
            label="change password"
            color="secondary"
            variant="text"
            onClick={handleToggleChangePasswordDialog}
          />
          <Button
            label="update"
            type="submit"
            onClick={handleOnClick}
            disabled={isEqual(values, initialValues)}
          />
        </Stack>
      </Card>
    </>
  );
};

SettingsPage.defaultProps = {};
SettingsPage.propTypes = {
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  initialValues: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SettingsPage;

export default function ({
  healthPlanMeasurement,
  deleteHealthPlanMeasurement,
}) {
  return deleteHealthPlanMeasurement({
    query: {
      healthPlanId: healthPlanMeasurement.healthPlan,
      healthPlanMeasurementId: healthPlanMeasurement.id,
    },
  });
}

import { call, put } from 'redux-saga/effects';
import { updateHealthPlanServiceSuccess } from 'services/database/actions/healthPlanServices';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  try {
    const responseData = yield call(request, {
      query,
      data,
    });

    yield put(
      updateHealthPlanServiceSuccess({
        data: responseData,
        query,
      })
    );
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

import { renderDateForSubmit } from 'common/utils';

export default function (values) {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    patientProfile: {
      sex: values.sex,
      dateOfBirth: renderDateForSubmit(values.dateOfBirth),
      generalPractitioner: values.generalPractitioner,
      phoneNumber: values.phoneNumber.replace(/\s/g, ''),
      street: values.street,
      city: values.city,
      state: values.state,
      postcode: values.postcode,
    },
  };
}

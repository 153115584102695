import mapValuesToProps from './mapValuesToProps';

export default function ({
  goalProgressReview,
  barrier,
  createGoalProgressBarrier,
}) {
  return createGoalProgressBarrier({
    query: {
      healthPlanId: goalProgressReview.healthPlan,
      healthPlanReviewId: goalProgressReview.healthPlanReview,
      goalProgressReviewId: goalProgressReview.id,
    },
    data: mapValuesToProps({ barrier, goalProgressReview }),
  });
}

import normalizeData from 'services/backend/dataNormalizer/normalizeData';

const adaptResponseData = function (data) {
  // DISCUSS: data also contains previous & next urls. Probably not worth using
  return {
    patientCount: data.count,
    patientList: normalizeData(data.results, 'patients'),
  };
};

export default adaptResponseData;

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withAction, withFormErrorHandler } from 'common/HOCs';
import {
  mapPropsToValues,
  handleSubmit,
} from 'common/data/healthPlanAdminItems/update';
import {
  updateHealthPlanAdminItemInit,
  healthPlanAdminItemsErrorsSelector,
} from 'services/database/actions/healthPlanAdminItems';
import { inlineLoadingSelector } from 'services/database/actions/loading';
import HealthPlanAdminItem from './HealthPlanAdminItem';

export default compose(
  withAction({
    actionName: 'updateHealthPlanAdminItem',
    action: updateHealthPlanAdminItemInit,
  }),
  connect((state) => ({
    inlineLoading: inlineLoadingSelector(state),
    formErrorData: healthPlanAdminItemsErrorsSelector(state),
  })),
  withFormik({ mapPropsToValues, handleSubmit }),
  withFormErrorHandler
)(HealthPlanAdminItem);

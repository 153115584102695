import { compose } from 'redux';
import { withAction } from 'common/HOCs';
import {
  createGoalProgressSolutionInit,
  deleteGoalProgressSolutionInit,
} from 'services/database/actions/goalProgressSolutions';
import GoalProgressSolution from './GoalProgressSolution';

export default compose(
  withAction({
    actionName: 'createGoalProgressSolution',
    action: createGoalProgressSolutionInit,
  }),
  withAction({
    actionName: 'deleteGoalProgressSolution',
    action: deleteGoalProgressSolutionInit,
  })
)(GoalProgressSolution);

import { put, call } from 'redux-saga/effects';
import { updatePatientPasswordError } from 'services/database/actions/patient';
import request from './request';

export default function* updateUserPasswordSaga({ data, resolve, reject }) {
  try {
    yield call(request, { data });
    yield call(resolve);
  } catch (error) {
    yield put(updatePatientPasswordError({ error }));
    yield call(reject);
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  Button as StyledButton,
  Container as StyledContainer,
  CircularProgress as StyledCircularProgress,
} from './styles';

// full width button which opens send modal
const ToggleButton = ({
  label,
  selected,
  color,
  onClick,
  disabled,
  loading,
  readOnly,
  dense,
  ...props
}) => (
  <StyledContainer>
    <StyledButton
      color={color}
      selected={selected}
      variant={selected || disabled ? 'contained' : 'outlined'}
      onClick={onClick}
      disabled={disabled || readOnly || loading}
      loading={loading}
      readOnly={readOnly}
      dense={dense}
      {...props}
    >
      {label}
    </StyledButton>
    {loading && <StyledCircularProgress size={24} color={color} />}
  </StyledContainer>
);

ToggleButton.defaultProps = {
  selected: false,
  color: 'primary',
  disabled: false,
  loading: false,
  readOnly: false,
  dense: false,
};
ToggleButton.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  dense: PropTypes.bool,
};

export default ToggleButton;

import { MEASUREMENT_KINDS } from 'common/constants';

export default function ({ patientMeasurement }) {
  const isSplitValue =
    patientMeasurement.definition.kind === MEASUREMENT_KINDS.SPLIT_VALUE;

  return {
    isSplitValue: isSplitValue,
    valueType: patientMeasurement.definition.valueType,
    value: !isSplitValue
      ? patientMeasurement.value
      : `${patientMeasurement.value} / ${patientMeasurement.secondValue}`,
  };
}

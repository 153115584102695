import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import { updateGoalProgressReviewInit } from 'services/database/actions/goalProgressReviews';
import { inlineLoadingSelector } from 'services/database/actions/loading';
import {
  mapPropsToValues,
  handleSubmit,
} from 'common/data/goalProgressReviews/update';
import GoalProgressReview from './GoalProgressReview';

export default compose(
  withAction({
    actionName: 'updateGoalProgressReview',
    action: updateGoalProgressReviewInit,
  }),
  connect((state) => ({
    inlineLoading: inlineLoadingSelector(state),
  })),
  withFormik({ mapPropsToValues, handleSubmit, enableReinitialize: true })
)(GoalProgressReview);

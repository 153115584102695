import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlanReviews/fetch';
import createSaga from 'services/backend/actions/healthPlanReviews/create';
import updateSaga from 'services/backend/actions/healthPlanReviews/update';
import {
  FETCH_HEALTH_PLAN_REVIEWS_INIT,
  CREATE_HEALTH_PLAN_REVIEW_INIT,
  UPDATE_HEALTH_PLAN_REVIEW_INIT,
} from './actionNames';

function* healthPlanReviewsFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_PLAN_REVIEWS_INIT, fetchSaga),
    takeLatest(CREATE_HEALTH_PLAN_REVIEW_INIT, createSaga),
    takeLatest(UPDATE_HEALTH_PLAN_REVIEW_INIT, updateSaga),
  ]);
}

export default healthPlanReviewsFlowSaga;

import React, { useEffect, useState, useRef } from 'react';
import { isNil, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { MEASUREMENT_KINDS } from 'common/constants';
import { Box, Tooltip, Typography } from '@mui/material';
import { Card, FlexBox, GoalChips } from 'common/components/layouts';
import {
  GoalPropType,
  MeasurementPropType,
  PatientMeasurementPropType,
  HealthPlanPropType,
  HealthPlanGoalPropType,
  HealthPlanMeasurementPropType,
} from 'common/propTypes';
import { parseMeasurementData } from 'common/utils';

const HealthPlanMeasurement = ({
  healthPlan,
  healthPlanMeasurement,
  healthPlanGoals,
}) => {
  const titleRef = useRef(),
    targetValueRef = useRef();

  const [titleOverflow, setTitleOverflow] = useState(false),
    [targetValueOverflow, setTargetValueOverflow] = useState(false);

  useEffect(() => {
    titleRef.current &&
    titleRef.current.scrollWidth > titleRef.current.clientWidth
      ? setTitleOverflow(true)
      : setTitleOverflow(false);
    targetValueRef.current &&
    targetValueRef.current.scrollWidth > targetValueRef.current.clientWidth
      ? setTargetValueOverflow(true)
      : setTargetValueOverflow(false);
  }, []);

  const hasPatientMeasurement = !isNil(healthPlanMeasurement.lastMeasurement),
    showLastMeasurement =
      hasPatientMeasurement &&
      (isNull(healthPlan.currentReview) ||
        healthPlanMeasurement.lastMeasurement.healthPlanReview ===
          healthPlan.currentReview),
    isSplitValue =
      healthPlanMeasurement.definition.kind === MEASUREMENT_KINDS.SPLIT_VALUE,
    firstValue = parseMeasurementData(
      healthPlanMeasurement.lastMeasurement.value,
      healthPlanMeasurement.lastMeasurement.valueType
    ),
    value = !isSplitValue
      ? firstValue
      : `${firstValue} / ${parseMeasurementData(
          healthPlanMeasurement.lastMeasurement.secondValue,
          healthPlanMeasurement.lastMeasurement.valueType
        )}`;

  return (
    <Card>
      <FlexBox alignItems="baseline" flexWrap="nowrap">
        <Box overflow="hidden" mr={1}>
          <Tooltip
            title={healthPlanMeasurement.definition.name || ''}
            placement="right"
            disableHoverListener={!titleOverflow}
          >
            <Typography
              ref={titleRef}
              variant="h5"
              color="textSecondary"
              noWrap
            >
              {healthPlanMeasurement.definition.name}
            </Typography>
          </Tooltip>
        </Box>
        <GoalChips goals={healthPlanGoals} dense />
      </FlexBox>
      <FlexBox justifyContent="space-between" flexWrap="nowrap">
        <Box mr={3} style={{ minHeight: '1.5rem' }}>
          <Typography variant="h2" noWrap>
            {showLastMeasurement ? value : ''}
          </Typography>
        </Box>
        <Box overflow="hidden">
          <Tooltip
            title={healthPlanMeasurement.valueTarget || ''}
            placement="right"
            disableHoverListener={!targetValueOverflow}
          >
            <Typography ref={targetValueRef} variant="h2" align="right" noWrap>
              {!isNull(healthPlanMeasurement.valueTarget)
                ? healthPlanMeasurement.valueTarget
                : ''}
            </Typography>
          </Tooltip>
        </Box>
      </FlexBox>
      <FlexBox justifyContent="space-between">
        <Typography variant="h6" color="textSecondary">
          {healthPlanMeasurement.definition.unit}
        </Typography>
        <Typography variant="h6" color="textSecondary" align="right">
          Target
        </Typography>
      </FlexBox>
    </Card>
  );
};

HealthPlanMeasurement.propTypes = {
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanMeasurement: PropTypes.shape({
    ...HealthPlanMeasurementPropType,
    healthPlan: PropTypes.number,
    lastMeasurement: PropTypes.shape(PatientMeasurementPropType),
    definition: PropTypes.shape(MeasurementPropType),
  }).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
};

export default HealthPlanMeasurement;

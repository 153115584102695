import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
} from '@mui/material';
import { MockLabel } from 'common/components/form';

const RadioGroup = ({
  name,
  label,
  value,
  options,
  renderLabel,
  onChange,
  variant,
  row,
  disabled,
}) => {
  const handleOnChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <>
      <MockLabel label={label} shrink />
      <MuiRadioGroup
        name={name}
        value={value}
        onChange={handleOnChange}
        row={row}
      >
        {Object.entries(options).map(([optionId, optionValue]) => (
          <FormControlLabel
            key={optionId}
            value={optionId}
            label={
              <Typography variant={variant}>
                {renderLabel(optionValue)}
              </Typography>
            }
            control={<Radio />}
            disabled={disabled}
          />
        ))}
      </MuiRadioGroup>
    </>
  );
};

RadioGroup.defaultProps = {
  variant: 'h3',
  row: false,
  disabled: false,
};
RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.object.isRequired,
  renderLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RadioGroup;

import PropTypes from 'prop-types';
import AdminItemPropType from './AdminItemPropType';

export default {
  id: PropTypes.number.isRequired,
  healthPlan: PropTypes.number.isRequired,
  definition: PropTypes.shape(AdminItemPropType),
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  checked: PropTypes.bool,
};

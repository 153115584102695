import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/healthPlanGoals/create';
import { createHealthPlanGoalInit } from 'services/database/actions/healthPlanGoals';
import HealthPlanGoalAdd from './HealthPlanGoalAdd';

export default compose(
  withAction({
    actionName: 'createHealthPlanGoal',
    action: createHealthPlanGoalInit,
  }),
  withFormik({ mapPropsToValues, validationSchema, handleSubmit })
)(HealthPlanGoalAdd);

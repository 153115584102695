import { compose } from 'redux';
import { connect } from 'react-redux';
import { taskDefinitionsSelector } from 'services/database/actions/definitions';
import HealthPlanService from './HealthPlanService';

export default compose(
  connect((state) => ({
    taskDefinitions: taskDefinitionsSelector(state),
  }))
)(HealthPlanService);

import { put, call } from 'redux-saga/effects';
import { loginSuccess, loginError } from 'services/database/actions/auth';
import { fetchDefinitionsInit } from 'services/database/actions/definitions';
import { fetchUserInit } from 'services/database/actions/user';
import request from './request';

export default function* loginSaga({ data, resolve, reject }) {
  try {
    const responseData = yield call(request, data);
    yield put(loginSuccess({ data: responseData }));
    yield put(fetchUserInit());
    yield put(fetchDefinitionsInit());
    yield call(resolve);
  } catch (error) {
    yield put(loginError({ data: error.data }));
    yield call(reject, { error: error.data });
  }
}

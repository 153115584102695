import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
} from 'common/constants';
import {
  GoalPropType,
  HealthPlanGoalPropType,
  HealthPlanPropType,
  PatientPropType,
} from 'common/propTypes';
import PatientConditions from './components/PatientConditions';
import HealthPlanMeasurements from './components/HealthPlanMeasurements';

const ClinicalDataTab = ({
  patient,
  healthPlan,
  healthPlanGoals,
  handleGoToSection,
}) => {
  const sectionId = HEALTH_PLAN_SECTIONS.CLINICAL_DATA;

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      <PatientConditions patient={patient} healthPlan={healthPlan} />
      <HealthPlanMeasurements
        patient={patient}
        healthPlan={healthPlan}
        healthPlanGoals={healthPlanGoals}
      />
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

ClinicalDataTab.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({ ...GoalPropType, ...HealthPlanGoalPropType })
  ).isRequired,
  handleGoToSection: PropTypes.func.isRequired,
};

export default ClinicalDataTab;

import normalizeData from 'services/backend/dataNormalizer/normalizeData';

const adaptResponseData = function (data) {
  return {
    ...data,
    patientCycleOfCareItems: normalizeData(
      data.patientCycleOfCareItems,
      'patientCycleOfCareItems'
    ),
  };
};
export default adaptResponseData;

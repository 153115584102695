import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patients/updateMedicare';
import { withFormErrorHandler } from 'common/HOCs';
import { patientErrorSelector } from 'services/database/actions/patient';
import MedicareForm from './MedicareForm';

export default compose(
  connect((state) => ({
    formErrorData: patientErrorSelector(state),
  })),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  }),
  withFormErrorHandler
)(MedicareForm);

import { buildReducer } from 'common/utils/redux';
import { FETCH_DEFINITIONS_SUCCESS, RESET_DEFINITIONS } from './actionNames';

const initialState = {
  actions: {},
  adminItems: {},
  barriers: {},
  billableItems: {},
  conditions: {},
  cycleOfCareItems: {},
  cycleOfCareModules: {},
  goalBarriers: {},
  goals: {},
  measurements: {},
  services: {},
  solutions: {},
  tasks: {},
};

function handleFetchDefinitions(state, { data }) {
  return { ...state, ...data };
}

export function handleResetDefinitions(state) {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_DEFINITIONS_SUCCESS]: handleFetchDefinitions,
  [RESET_DEFINITIONS]: handleResetDefinitions,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ActionPropType,
  BarrierPropType,
  CycleOfCareItemPropType,
  CycleOfCareModulePropType,
  GoalPropType,
  ServicePropType,
  HealthPlanActionPropType,
  HealthPlanGoalPropType,
  HealthPlanServicePropType,
  HealthPlanPropType,
  PatientCycleOfCareItemPropType,
  PatientCycleOfCareModulePropType,
  PatientPropType,
  UserPropType,
  SolutionPropType,
  HealthPlanReviewPropType,
} from 'common/propTypes';
import { isNil } from 'lodash';
import { isUserDoctor, isUserNurse } from 'common/utils';
import ClinicalDataTab from './components/ClinicalDataTab';
import PatientCyclesOfCareTab from './components/PatientCyclesOfCareTab';
import GoalProgressReviewsTab from './components/GoalProgressReviewsTab';
import HealthPlanActionsTab from './components/HealthPlanActionsTab';
import HealthPlanAdminItemsTab from './components/HealthPlanAdminItemsTab';
import HealthPlanGoalsTab from './components/HealthPlanGoalsTab';
import HealthPlanManagementTab from './components/HealthPlanManagementTab';
import HealthPlanServicesTab from './components/HealthPlanServicesTab';
import HealthPlanTeamTab from './components/HealthPlanTeamTab';
import HealthPlanFeedbackTab from './components/HealthPlanFeedbackTab';

const Summary = ({
  user,
  patient,
  healthPlan,
  healthPlanReview,
  healthPlanActions,
  healthPlanGoals,
  healthPlanServices,
  healthPlanFeedback,
  patientCycleOfCareModules,
  barrierDefinitions,
  goalDefinitions,
  serviceDefinitions,
  solutionDefinitions,
  isHealthPlanReview,
  isHealthPlanReviewFull,
  fetchHealthPlanActions,
  fetchHealthPlanServices,
  resetHealthPlanServices,
  fetchHealthPlanFeedback,
  resetHealthPlanFeedback,
  fetchHealthPlanReviews,
  resetHealthPlanReviews,
  fetchHealthJourney,
  resetHealthJourney,
  history,
  handleTabChange,
  readOnly,
}) => {
  useEffect(() => {
    if (!isNil(patient.id)) {
      fetchHealthPlanActions({ query: { patientId: patient.id } });
      fetchHealthPlanServices({ query: { healthPlanId: patient.id } });
      fetchHealthPlanFeedback({ query: { healthPlanId: patient.id } });
      fetchHealthPlanReviews({ query: { healthPlanId: patient.id } });
      fetchHealthJourney({ query: { patientId: patient.id } });
    }
    return () => {
      resetHealthPlanServices();
      resetHealthPlanFeedback();
      resetHealthPlanReviews();
      resetHealthJourney();
    };
    // eslint-disable-next-line
  }, []);

  const showFullHealthPlan =
    !isHealthPlanReview || (isHealthPlanReview && isHealthPlanReviewFull);

  const handleGoToSection = (value) => (e) => {
    handleTabChange(e, value);
  };

  return (
    <>
      <HealthPlanGoalsTab
        healthPlanGoals={healthPlanGoals}
        handleGoToSection={handleGoToSection}
      />
      <ClinicalDataTab
        patient={patient}
        healthPlan={healthPlan}
        healthPlanGoals={healthPlanGoals}
        handleGoToSection={handleGoToSection}
      />
      {showFullHealthPlan && (
        <>
          <HealthPlanServicesTab
            healthPlan={healthPlan}
            healthPlanGoals={healthPlanGoals}
            healthPlanServices={healthPlanServices}
            serviceDefinitions={serviceDefinitions}
            handleGoToSection={handleGoToSection}
          />
          <HealthPlanTeamTab
            user={user}
            patient={patient}
            healthPlan={healthPlan}
            healthPlanServices={healthPlanServices}
            serviceDefinitions={serviceDefinitions}
            goalDefinitions={goalDefinitions}
            handleGoToSection={handleGoToSection}
            readOnly={true}
          />
        </>
      )}
      <PatientCyclesOfCareTab
        patient={patient}
        patientCycleOfCareModules={patientCycleOfCareModules}
        handleGoToSection={handleGoToSection}
      />
      {showFullHealthPlan && (
        <HealthPlanActionsTab
          healthPlanActions={healthPlanActions}
          healthPlanGoals={healthPlanGoals}
          handleGoToSection={handleGoToSection}
        />
      )}
      {isHealthPlanReview && (
        <GoalProgressReviewsTab
          patient={patient}
          healthPlanReview={healthPlanReview}
          barrierDefinitions={barrierDefinitions}
          goalDefinitions={goalDefinitions}
          solutionDefinitions={solutionDefinitions}
          handleGoToSection={handleGoToSection}
        />
      )}
       <HealthPlanFeedbackTab
          healthPlanFeedback={healthPlanFeedback}
          handleGoToSection={handleGoToSection}
        />
      <HealthPlanAdminItemsTab
        patient={patient}
        healthPlan={healthPlan}
        readOnly={readOnly}
      />
      {isUserDoctor(user) | isUserNurse(user) && (
        <HealthPlanManagementTab
          user={user}
          patient={patient}
          healthPlan={healthPlan}
          healthPlanReview={healthPlanReview}
          isHealthPlanReview={isHealthPlanReview}
          history={history}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

Summary.propTypes = {
  user: PropTypes.shape(UserPropType),
  patient: PropTypes.shape(PatientPropType),
  healthPlan: PropTypes.shape(HealthPlanPropType),
  healthPlanReview: PropTypes.shape(HealthPlanReviewPropType),
  healthPlanActions: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanActionPropType,
      healthPlan: PropTypes.number,
      definition: PropTypes.shape(ActionPropType),
    })
  ),
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ),
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      definition: PropTypes.shape(ServicePropType),
    })
  ),
  patientCycleOfCareModules: PropTypes.arrayOf(
    PropTypes.shape({
      ...PatientCycleOfCareModulePropType,
      patientCycleOfCareItems: PropTypes.arrayOf(
        PropTypes.shape({
          ...PatientCycleOfCareItemPropType,
          definition: PropTypes.shape(CycleOfCareItemPropType),
        })
      ),
      definition: PropTypes.shape(CycleOfCareModulePropType),
    })
  ),
  barrierDefinitions: PropTypes.objectOf(PropTypes.shape(BarrierPropType)),
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)),
  serviceDefinitions: PropTypes.objectOf(PropTypes.shape(ServicePropType)),
  solutionDefinitions: PropTypes.objectOf(PropTypes.shape(SolutionPropType)),
  isHealthPlanReview: PropTypes.bool.isRequired,
  isHealthPlanReviewFull: PropTypes.bool.isRequired,
  fetchHealthPlanActions: PropTypes.func.isRequired,
  fetchHealthPlanServices: PropTypes.func.isRequired,
  resetHealthPlanServices: PropTypes.func.isRequired,
  fetchHealthPlanReviews: PropTypes.func.isRequired,
  resetHealthPlanReviews: PropTypes.func.isRequired,
  history: PropTypes.object,
  handleTabChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default Summary;

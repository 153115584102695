import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  withIsAuthenticated,
  withAction,
  withAuthenticatedUser,
  withFullPageLayout,
} from 'common/HOCs';
import ProvidersPage from './ProvidersPage';
import {
  fetchProvidersInit,
  providerCountSelector,
  providersSelector,
  resetProviders,
} from 'services/database/actions/providers';
import { inlineLoadingSelector } from 'services/database/actions/loading';

export default compose(
  withIsAuthenticated,
  withAuthenticatedUser,
  withAction({
    actionName: 'fetchProviders',
    action: fetchProvidersInit,
  }),
  withAction({
    actionName: 'resetProviders',
    action: resetProviders,
  }),
  connect((state) => ({
    providers: providersSelector(state),
    providerCount: providerCountSelector(state),
    inlineLoading: inlineLoadingSelector(state),
  })),
  withFullPageLayout
)(ProvidersPage);

import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel as StyledFormLabel } from './styles';

const MockLabel = ({ label, shrink, ...props }) => (
  <StyledFormLabel shrink={shrink} {...props}>
    {label}
  </StyledFormLabel>
);

MockLabel.defaultProps = {
  shrink: false,
};
MockLabel.propTypes = {
  label: PropTypes.string.isRequired,
  shrink: PropTypes.bool,
};

export default MockLabel;

import { put, call } from 'redux-saga/effects';
import {
  updateHealthPlanReviewSuccess,
  updateHealthPlanReviewError,
} from 'services/database/actions/healthPlanReviews';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  try {
    const responseData = yield call(request, { query, data });
    yield put(updateHealthPlanReviewSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield put(updateHealthPlanReviewError({ data: error.data }));
    yield call(reject, { error: error.data });
  }
}

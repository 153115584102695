export const FETCH_HEALTH_JOURNEY_INIT = 'FETCH_HEALTH_JOURNEY_INIT';
export const FETCH_HEALTH_JOURNEY_SUCCESS = 'FETCH_HEALTH_JOURNEY_SUCCESS';
export const CREATE_HEALTH_JOURNEY_INIT = 'CREATE_HEALTH_JOURNEY_INIT';
export const CREATE_HEALTH_JOURNEY_SUCCESS = 'CREATE_HEALTH_JOURNEY_SUCCESS';
export const UPDATE_HEALTH_JOURNEY_ITEM_INIT =
  'UPDATE_HEALTH_JOURNEY_ITEM_INIT';
export const UPDATE_HEALTH_JOURNEY_ITEM_SUCCESS =
  'UPDATE_HEALTH_JOURNEY_ITEM_SUCCESS';

export const RESET_HEALTH_JOURNEY = 'RESET_HEALTH_JOURNEY';

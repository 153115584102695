import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'common/components/form';

const PatientMeasurementInput = ({
  values: { value },
  initialValues: { value: initialValue },
  handleSubmit,
  readOnly,
}) => {
  const handleOnBlur = () => {
    value !== initialValue && handleSubmit();
  };
  return (
    <Form>
      <Input
        name="value"
        value={value}
        margin="dense"
        onBlur={handleOnBlur}
        disabled={readOnly}
        preventDefault
      />
    </Form>
  );
};

PatientMeasurementInput.propTypes = {
  values: PropTypes.shape({ value: PropTypes.string }).isRequired,
  initialValues: PropTypes.shape({ value: PropTypes.string }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default PatientMeasurementInput;

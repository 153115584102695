export default {
  1: 'Once',
  2: 'Annual',
  3: 'Every 2 years',
  4: 'Every 5 years',
  5: 'Every 10 years',
  6: 'Every 3 months',
  7: 'Every 6 months',
  8: 'At age of 50 and 60',
};

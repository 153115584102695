export const FETCH_HEALTH_PLAN_SERVICES_INIT =
  'FETCH_HEALTH_PLAN_SERVICES_INIT';
export const FETCH_HEALTH_PLAN_SERVICES_SUCCESS =
  'FETCH_HEALTH_PLAN_SERVICES_SUCCESS';

export const CREATE_HEALTH_PLAN_SERVICE_INIT =
  'CREATE_HEALTH_PLAN_SERVICE_INIT';
export const CREATE_HEALTH_PLAN_SERVICE_SUCCESS =
  'CREATE_HEALTH_PLAN_SERVICE_SUCCESS';

export const UPDATE_HEALTH_PLAN_SERVICE_INIT =
  'UPDATE_HEALTH_PLAN_SERVICE_INIT';
export const UPDATE_HEALTH_PLAN_SERVICE_SUCCESS =
  'UPDATE_HEALTH_PLAN_SERVICE_SUCCESS';

export const DELETE_HEALTH_PLAN_SERVICE_INIT =
  'DELETE_HEALTH_PLAN_SERVICE_INIT';
export const DELETE_HEALTH_PLAN_SERVICE_SUCCESS =
  'DELETE_HEALTH_PLAN_SERVICE_SUCCESS';

export const RESET_HEALTH_PLAN_SERVICES = 'RESET_HEALTH_PLAN_SERVICES';

import React from 'react';
import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import { Checkbox } from 'common/components/form';

const CheckboxField = ({
  name,
  label,
  checked,
  onChange,
  disabled,
  variant,
  margin,
  padding,
  color,
  loading,
  ...props
}) => (
  <Field name={name}>
    {({ field, form }) => {
      const errorMessage = getIn(form.errors, field.name);

      return (
        <Checkbox
          name={name}
          label={label}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          variant={variant}
          margin={margin}
          padding={padding}
          color={color}
          errorMessage={errorMessage}
          loading={loading}
          {...props}
        />
      );
    }}
  </Field>
);

CheckboxField.defaultProps = {
  checked: false,
  disabled: false,
  variant: 'body2',
  margin: 'none',
  padding: 'normal',
  color: 'primary',
};
CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  padding: PropTypes.oneOf(['dense', 'none', 'normal']),
};

export default CheckboxField;

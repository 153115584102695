import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import { RESET_PATIENT } from 'services/database/actions/patient/redux/actionNames';
import { USER_LOGOUT_INIT } from 'services/database/actions/auth/redux/actionNames';
import {
  CREATE_HEALTH_JOURNEY_SUCCESS,
  FETCH_HEALTH_JOURNEY_SUCCESS,
  RESET_HEALTH_JOURNEY,
  UPDATE_HEALTH_JOURNEY_ITEM_SUCCESS,
} from './actionNames';

const initialState = {};

function handleFetchHealthJourneySuccess(state, { data }) {
  return data;
}

function handleUpdateHealthJourneyItemSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.healthJourneyItems[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleResetHealthJourney() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_JOURNEY_SUCCESS]: handleFetchHealthJourneySuccess,
  [CREATE_HEALTH_JOURNEY_SUCCESS]: handleFetchHealthJourneySuccess,
  [UPDATE_HEALTH_JOURNEY_ITEM_SUCCESS]: handleUpdateHealthJourneyItemSuccess,
  [RESET_HEALTH_JOURNEY]: handleResetHealthJourney,
  [RESET_PATIENT]: handleResetHealthJourney,
  [USER_LOGOUT_INIT]: handleResetHealthJourney,
};

export default buildReducer(initialState, ACTION_HANDLERS);

export const FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT =
  'FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT';
export const FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS =
  'FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS';

export const CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT =
  'CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT';
export const CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS =
  'CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS';

export const DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT =
  'DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT';
export const DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS =
  'DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS';

export const RESET_PATIENT_CYCLE_OF_CARE_MODULES =
  'RESET_PATIENT_CYCLE_OF_CARE_MODULES';

import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query: { patientId, patientMeasurementId }, data }) {
  return http
    .patch(`/patients/${patientId}/measurements/${patientMeasurementId}/`, {
      data,
    })
    .catch(handleHTTPError);
}

export const FETCH_PATIENT_MEASUREMENT_INIT = 'FETCH_PATIENT_MEASUREMENT_INIT';
export const FETCH_PATIENT_MEASUREMENT_SUCCESS =
  'FETCH_PATIENT_MEASUREMENT_SUCCESS';

export const CREATE_PATIENT_MEASUREMENT_INIT =
  'CREATE_PATIENT_MEASUREMENT_INIT';
export const CREATE_PATIENT_MEASUREMENT_SUCCESS =
  'CREATE_PATIENT_MEASUREMENT_SUCCESS';

export const UPDATE_PATIENT_MEASUREMENT_INIT =
  'UPDATE_PATIENT_MEASUREMENT_INIT';
export const UPDATE_PATIENT_MEASUREMENT_SUCCESS =
  'UPDATE_PATIENT_MEASUREMENT_SUCCESS';

export const DELETE_PATIENT_MEASUREMENT_INIT =
  'DELETE_PATIENT_MEASUREMENT_INIT';
export const DELETE_PATIENT_MEASUREMENT_SUCCESS =
  'DELETE_PATIENT_MEASUREMENT_SUCCESS';

export const RESET_PATIENT_MEASUREMENTS = 'RESET_PATIENT_MEASUREMENTS';

export const FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT =
  'FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT';
export const FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS =
  'FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS';

export const UPDATE_HEALTH_PLAN_ADMIN_ITEM_INIT =
  'UPDATE_HEALTH_PLAN_ADMIN_ITEM_INIT';
export const UPDATE_HEALTH_PLAN_ADMIN_ITEM_SUCCESS =
  'UPDATE_HEALTH_PLAN_ADMIN_ITEM_SUCCESS';

export const RESET_HEALTH_PLAN_ADMIN_ITEMS = 'RESET_HEALTH_PLAN_ADMIN_ITEMS';

import PropTypes from 'prop-types';
import GoalProgressSolutionPropType from './GoalProgressSolutionPropType';

export default {
  id: PropTypes.number.isRequired,
  definition: PropTypes.number.isRequired,
  goalProgressReview: PropTypes.number.isRequired,
  healthPlanReview: PropTypes.number.isRequired,
  healthPlan: PropTypes.number.isRequired,
  solutionDefinitions: PropTypes.arrayOf(PropTypes.number),
  goalProgressSolutions: PropTypes.objectOf(
    PropTypes.shape(GoalProgressSolutionPropType)
  ),
};

import { put, call } from 'redux-saga/effects';
import { fetchProvidersSuccess } from 'services/database/actions/providers';
import request from './request';

export default function* fetchSaga({ query, resolve, reject }) {
  try {
    const responseData = yield call(request, { query });
    yield put(fetchProvidersSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

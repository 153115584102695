import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { patientMeasurement, updatePatientMeasurement } }
) {
  return updatePatientMeasurement({
    query: {
      patientId: patientMeasurement.patient,
      patientMeasurementId: patientMeasurement.id,
    },
    data: mapValuesToProps(values, patientMeasurement.definition),
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { Search as SearchIcon } from '@mui/icons-material';
import { Input } from 'common/components/form';

const Search = ({
  name,
  placeholder,
  submitForm,
  onEnter,
  values: { search },
}) => (
  <Input
    name={name}
    placeholder={placeholder}
    type="text"
    margin="dense"
    InputProps={{
      style: { fontSize: '0.8rem' },
      startAdornment: <SearchIcon color="inherit" />,
    }}
    onChange={() => setTimeout(submitForm)}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onEnter(search);
        e.target.blur();
      }
    }}
    fullWidth
  />
);

Search.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  submitForm: PropTypes.func,
  onEnter: PropTypes.func,
  values: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default Search;

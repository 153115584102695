import { createSelector } from 'reselect';

const selectLoading = (state) => state.loading.loading;
const selectLoadingInLine = (state) => state.loading.inlineLoading;

export const loadingSelector = createSelector(
  [selectLoading],
  (loading) => loading
);

export const inlineLoadingSelector = createSelector(
  [selectLoadingInLine],
  (inlineLoading) => inlineLoading
);

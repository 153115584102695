import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patientMeasurements/create';
import { createPatientMeasurementInit } from 'services/database/actions/patientMeasurements';
import PatientMeasurementInput from './PatientMeasurementInput';

export default compose(
  withAction({
    actionName: 'createPatientMeasurement',
    action: createPatientMeasurementInit,
  }),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  })
)(PatientMeasurementInput);

import {
  FETCH_PATIENT_INIT,
  FETCH_PATIENT_SUCCESS,
  ADD_PATIENT_INIT,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_ERROR,
  RESET_PATIENT,
  UPDATE_PATIENT_INIT,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_ERROR,
  UPDATE_PATIENT_PASSWORD_INIT,
  UPDATE_PATIENT_PASSWORD_SUCCESS,
  UPDATE_PATIENT_PASSWORD_ERROR,
} from './actionNames';

export function fetchPatientInit(actionParams) {
  return { type: FETCH_PATIENT_INIT, ...actionParams };
}

export function fetchPatientSuccess({ data }) {
  return { type: FETCH_PATIENT_SUCCESS, data };
}

export function addPatientInit(actionParams) {
  return { type: ADD_PATIENT_INIT, ...actionParams };
}

export function addPatientSuccess({ data }) {
  return { type: ADD_PATIENT_SUCCESS, data };
}

export function addPatientError({ error }) {
  return { type: ADD_PATIENT_ERROR, error };
}

export function updatePatientInit(actionParams) {
  return { type: UPDATE_PATIENT_INIT, ...actionParams };
}

export function updatePatientSuccess({ data }) {
  return { type: UPDATE_PATIENT_SUCCESS, data };
}

export function updatePatientError({ error }) {
  return { type: UPDATE_PATIENT_ERROR, error };
}

export function updatePatientPasswordInit(actionParams) {
  return { type: UPDATE_PATIENT_PASSWORD_INIT, ...actionParams };
}

export function updatePatientPasswordSuccess(actionParams) {
  return { type: UPDATE_PATIENT_PASSWORD_SUCCESS, ...actionParams };
}

export function updatePatientPasswordError({ error }) {
  return { type: UPDATE_PATIENT_PASSWORD_ERROR, error };
}

export function resetPatient(actionParams) {
  return { type: RESET_PATIENT, ...actionParams };
}

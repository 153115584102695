import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import {
  FlexBox,
  ContentBox,
  Card,
  GoalChips,
} from 'common/components/layouts';
import { PROVIDER_GROUP_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanGoalPropType,
  HealthPlanServicePropType,
} from 'common/propTypes';
const HealthPlanService = ({
  index,
  healthPlanService,
  healthPlanServiceGoals,
}) => (
  <Card>
    <FlexBox flexDirection="column">
      <FlexBox alignItems="baseline">
        <ContentBox width="max-content" mr={1}>
          <Typography variant="h5" color="textSecondary">
            Service #{index}
          </Typography>
        </ContentBox>
        <GoalChips goals={healthPlanServiceGoals} dense />
      </FlexBox>
      <Typography variant="h2">{healthPlanService.definition.name}</Typography>
      <Typography variant="h6" color="textSecondary">
        {PROVIDER_GROUP_NAMES[healthPlanService.definition.providerGroup]}
      </Typography>
    </FlexBox>
  </Card>
);

HealthPlanService.propTypes = {
  index: PropTypes.number.isRequired,
  healthPlanService: PropTypes.shape({
    ...HealthPlanServicePropType,
    healthPlan: PropTypes.number.isRequired,
    definition: PropTypes.shape(ServicePropType),
  }).isRequired,
  healthPlanServiceGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
};

export default HealthPlanService;

import { compose } from 'redux';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import {
  providersSelector,
} from 'services/database/actions/providers';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patients/update';
import PatientProfile from './PatientProfile';

export default compose(
  connect((state) => ({
    providers: providersSelector(state),
  })),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  })
)(PatientProfile);

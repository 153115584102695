import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  withAuthenticatedUser,
  withFullPageLayout,
  withIsAuthenticated,
  withPatient,
  withPropsMapping,
} from 'common/HOCs';
import {
  actionDefinitionsSelector,
  barrierDefinitionsSelector,
  cyclesOfCareItemDefinitionsSelector,
  cyclesOfCareModuleDefinitionsSelector,
  serviceDefinitionsSelector,
  solutionDefinitionsSelector,
} from 'services/database/actions/definitions';
import { patientCycleOfCareModulesSelector } from 'services/database/actions/patientCycleOfCareModules';
import HealthPlanPage from './HealthPlanPage';
import { healthPlanActionsSelector } from 'services/database/actions/healthPlanActions';

export default compose(
  withIsAuthenticated,
  withAuthenticatedUser,
  withPatient,
  connect((state) => ({
    healthPlanActions: healthPlanActionsSelector(state),
    actionDefinitions: actionDefinitionsSelector(state),
    barrierDefinitions: barrierDefinitionsSelector(state),
    serviceDefinitions: serviceDefinitionsSelector(state),
    solutionDefinitions: solutionDefinitionsSelector(state),
    cycleOfCareItemDefinitions: cyclesOfCareItemDefinitionsSelector(state),
    cycleOfCareModuleDefinitions: cyclesOfCareModuleDefinitionsSelector(state),
    patientCycleOfCareModules: patientCycleOfCareModulesSelector(state),
  })),
  // TODO: Standardise how definitions are spread into healthPlanObjects
  withPropsMapping((props) => ({
    ...props,
    // Override these props
    healthPlanActions: Object.values(props.healthPlanActions).map(
      (healthPlanAction) => ({
        ...healthPlanAction,
        definition: props.actionDefinitions[healthPlanAction.definition],
      })
    ),
    patientCycleOfCareModules: Object.values(
      props.patientCycleOfCareModules
    ).map((patientCycleOfCareModule) => ({
      ...patientCycleOfCareModule,
      patientCycleOfCareItems: Object.values(
        patientCycleOfCareModule.patientCycleOfCareItems
      ).map((patientCycleOfCareItem) => ({
        ...patientCycleOfCareItem,
        definition:
          props.cycleOfCareItemDefinitions[patientCycleOfCareItem.definition],
      })),
      definition:
        props.cycleOfCareModuleDefinitions[patientCycleOfCareModule.definition],
    })),
  })),
  withFullPageLayout
)(HealthPlanPage);

import React from 'react';
import { isEmpty, orderBy } from 'lodash';
import { Divider, Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import { renderDate } from 'common/utils';
import GlobalTheme from 'common/styles/global';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
} from 'common/constants';

const HealthPlanFeedbackTab = ({
  healthPlanFeedback,
  handleGoToSection,
}) => {
  const sectionId = HEALTH_PLAN_SECTIONS.FEEDBACK;

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      {isEmpty(healthPlanFeedback) ? (
    <Typography variant="h4" align="center">
      Patient has not provided any feedback
    </Typography>
  ) : 
      orderBy(healthPlanFeedback, ['createdAt'], ['desc']).map((feedback, idx) => (
      <FlexBox flexDirection="column" key={idx}>
        {feedback.title && <Typography variant="h2" color={GlobalTheme.palette.primary.main}>{feedback.title}</Typography>}
        <Typography variant="h4" color="#a9abad" p="0 0 0.25rem">
          {renderDate(feedback.createdAt)}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          {feedback.content}
        </Typography>
        {idx !== healthPlanFeedback.length-1 && <Divider flexItem sx={{margin: "0.5rem 0"}}/>}
       </FlexBox>
      ))}
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

export default HealthPlanFeedbackTab;

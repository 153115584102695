import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Button = styled(MuiButton)(
  ({ theme, color, width, minWidth, maxWidth }) => ({
    width: width,
    minWidth: minWidth,
    maxWidth: maxWidth,
    '&.MuiButton-contained': {
      color: theme.palette[color].contrastText,
      '&:not(.Mui-disabled)': {
        backgroundColor: theme.palette[color].main,
        ':hover': {
          backgroundColor: theme.palette[color].light,
        },
      },
    },
    '&.MuiButton-outlined:not(.Mui-disabled)': {
      color: theme.palette[color].main,
      ':hover': {
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
        borderColor: `${theme.palette[color].main} !important`,
      },
    },
  })
);

import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  USER_LOGIN_INIT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
} from 'services/database/actions/auth/redux/actionNames';
import {
  FETCH_DASHBOARD_INIT,
  FETCH_DASHBOARD_SUCCESS,
} from 'services/database/actions/dashboard/redux/actionNames';
import {
  FETCH_PATIENTS_INIT,
  FETCH_PATIENTS_SUCCESS,
} from 'services/database/actions/patients/redux/actionNames';
import {
  FETCH_PATIENT_INIT,
  FETCH_PATIENT_SUCCESS,
} from 'services/database/actions/patient/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_INIT,
  FETCH_HEALTH_PLAN_SUCCESS,
  UPDATE_HEALTH_PLAN_ERROR,
  UPDATE_HEALTH_PLAN_INIT,
  UPDATE_HEALTH_PLAN_SUCCESS,
} from 'services/database/actions/healthPlans/redux/actionNames';
import {
  CREATE_PATIENT_CONDITION_INIT,
  CREATE_PATIENT_CONDITION_SUCCESS,
  DELETE_PATIENT_CONDITION_INIT,
  DELETE_PATIENT_CONDITION_SUCCESS,
  FETCH_PATIENT_CONDITIONS_INIT,
  FETCH_PATIENT_CONDITIONS_SUCCESS,
} from 'services/database/actions/patientConditions/redux/actionNames';
import {
  FETCH_PATIENT_MEASUREMENT_INIT,
  FETCH_PATIENT_MEASUREMENT_SUCCESS,
} from 'services/database/actions/patientMeasurements/redux/actionNames';
import {
  CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT,
  CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS,
  DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT,
  DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS,
  FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT,
  FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS,
} from 'services/database/actions/patientCycleOfCareModules/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_SERVICES_INIT,
  FETCH_HEALTH_PLAN_SERVICES_SUCCESS,
  UPDATE_HEALTH_PLAN_SERVICE_INIT,
  UPDATE_HEALTH_PLAN_SERVICE_SUCCESS,
} from 'services/database/actions/healthPlanServices/redux/actionNames';
import {
  CREATE_HEALTH_PLAN_ACTION_INIT,
  CREATE_HEALTH_PLAN_ACTION_SUCCESS,
  DELETE_HEALTH_PLAN_ACTION_INIT,
  DELETE_HEALTH_PLAN_ACTION_SUCCESS,
  FETCH_HEALTH_PLAN_ACTIONS_INIT,
  FETCH_HEALTH_PLAN_ACTIONS_SUCCESS,
} from 'services/database/actions/healthPlanActions/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_REVIEWS_INIT,
  FETCH_HEALTH_PLAN_REVIEWS_SUCCESS,
  UPDATE_HEALTH_PLAN_REVIEW_ERROR,
  UPDATE_HEALTH_PLAN_REVIEW_INIT,
  UPDATE_HEALTH_PLAN_REVIEW_SUCCESS,
} from 'services/database/actions/healthPlanReviews/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT,
  FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS,
} from 'services/database/actions/healthPlanAdminItems/redux/actionNames';
import {
  CREATE_HEALTH_PLAN_GOAL_INIT,
  CREATE_HEALTH_PLAN_GOAL_SUCCESS,
  DELETE_HEALTH_PLAN_GOAL_INIT,
  DELETE_HEALTH_PLAN_GOAL_SUCCESS,
  FETCH_HEALTH_PLAN_GOALS_INIT,
  FETCH_HEALTH_PLAN_GOALS_SUCCESS,
} from 'services/database/actions/healthPlanGoals/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_MEASUREMENTS_INIT,
  FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS,
} from 'services/database/actions/healthPlanMeasurements/redux/actionNames';
import {
  FETCH_PROVIDERS_INIT,
  FETCH_PROVIDERS_SUCCESS,
} from 'services/database/actions/providers/redux/actionNames';
import {
  FETCH_HEALTH_JOURNEY_INIT,
  FETCH_HEALTH_JOURNEY_SUCCESS,
} from 'services/database/actions/healthJourney/redux/actionNames';
import {
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_LOADING_INLINE_ON,
  SET_LOADING_INLINE_OFF,
  RESET_LOADERS,
} from './actionNames';
import { FETCH_HEALTH_PLAN_FEEDBACK_INIT, FETCH_HEALTH_PLAN_FEEDBACK_SUCCESS } from '../../healthPlanFeedback/redux/actionNames';

const initialState = {
  loading: [],
  inlineLoading: [],
};

// inlineLoading called from saga
function handleSetInlineLoadingOn(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.inlineLoading.push(data);
  });
  return { ...state, ...nextState };
}

function handleSetInlineLoadingOff(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.inlineLoading = draftState.inlineLoading.filter(
      (loadingId) => loadingId !== data
    );
  });
  return { ...state, ...nextState };
}

function handleSetLoadingOn(state, actionParams) {
  const id = actionParams.type.slice(0, actionParams.type.lastIndexOf('_')),
    nextState = produce(state, (draftState) => {
      draftState.loading.push(id);
    });
  return { ...state, ...nextState };
}

function handleSetLoadingOff(state, actionParams) {
  const id = actionParams.type.slice(0, actionParams.type.lastIndexOf('_')),
    nextState = produce(state, (draftState) => {
      draftState.loading = draftState.loading.filter(
        (loadingId) => loadingId !== id
      );
    });

  return { ...state, ...nextState };
}

function handleResetLoading() {
  return initialState;
}

const ACTION_HANDLERS = {
  [SET_LOADING_INLINE_ON]: handleSetInlineLoadingOn,
  [SET_LOADING_INLINE_OFF]: handleSetInlineLoadingOff,

  [SET_LOADING_ON]: handleSetLoadingOn,
  [SET_LOADING_OFF]: handleSetLoadingOff,

  [USER_LOGIN_INIT]: handleSetLoadingOn,
  [USER_LOGIN_SUCCESS]: handleSetLoadingOff,
  [USER_LOGIN_ERROR]: handleSetLoadingOff,

  [FETCH_DASHBOARD_INIT]: handleSetLoadingOn,
  [FETCH_DASHBOARD_SUCCESS]: handleSetLoadingOff,

  [FETCH_PATIENTS_INIT]: handleSetLoadingOn,
  [FETCH_PATIENTS_SUCCESS]: handleSetLoadingOff,

  [FETCH_PATIENT_INIT]: handleSetLoadingOn,
  [FETCH_PATIENT_SUCCESS]: handleSetLoadingOff,

  [FETCH_HEALTH_JOURNEY_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_JOURNEY_SUCCESS]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_SUCCESS]: handleSetLoadingOff,

  [UPDATE_HEALTH_PLAN_INIT]: handleSetLoadingOn,
  [UPDATE_HEALTH_PLAN_SUCCESS]: handleSetLoadingOff,
  [UPDATE_HEALTH_PLAN_ERROR]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_ACTIONS_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_ACTIONS_SUCCESS]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_GOALS_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_GOALS_SUCCESS]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_FEEDBACK_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_FEEDBACK_SUCCESS]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_MEASUREMENTS_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_REVIEWS_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_REVIEWS_SUCCESS]: handleSetLoadingOff,

  [UPDATE_HEALTH_PLAN_REVIEW_INIT]: handleSetLoadingOn,
  [UPDATE_HEALTH_PLAN_REVIEW_SUCCESS]: handleSetLoadingOff,
  [UPDATE_HEALTH_PLAN_REVIEW_ERROR]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_SERVICES_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_SERVICES_SUCCESS]: handleSetLoadingOff,

  [FETCH_PATIENT_CONDITIONS_INIT]: handleSetLoadingOn,
  [FETCH_PATIENT_CONDITIONS_SUCCESS]: handleSetLoadingOff,

  [FETCH_PATIENT_MEASUREMENT_INIT]: handleSetLoadingOn,
  [FETCH_PATIENT_MEASUREMENT_SUCCESS]: handleSetLoadingOff,

  [FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT]: handleSetLoadingOn,
  [FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS]: handleSetLoadingOff,

  [FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT]: handleSetLoadingOn,
  [FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS]: handleSetLoadingOff,

  [CREATE_HEALTH_PLAN_GOAL_INIT]: handleSetLoadingOn,
  [CREATE_HEALTH_PLAN_GOAL_SUCCESS]: handleSetLoadingOff,

  [DELETE_HEALTH_PLAN_GOAL_INIT]: handleSetLoadingOn,
  [DELETE_HEALTH_PLAN_GOAL_SUCCESS]: handleSetLoadingOff,

  [CREATE_PATIENT_CONDITION_INIT]: handleSetLoadingOn,
  [CREATE_PATIENT_CONDITION_SUCCESS]: handleSetLoadingOff,

  [DELETE_PATIENT_CONDITION_INIT]: handleSetLoadingOn,
  [DELETE_PATIENT_CONDITION_SUCCESS]: handleSetLoadingOff,

  [FETCH_PROVIDERS_INIT]: handleSetLoadingOn,
  [FETCH_PROVIDERS_SUCCESS]: handleSetLoadingOff,

  // loading updates called in saga as want loading || inlineLoading
  // [CREATE_HEALTH_PLAN_SERVICE_INIT]: handleSetLoadingOn,
  // [CREATE_HEALTH_PLAN_SERVICE_SUCCESS]: handleSetLoadingOff,

  [UPDATE_HEALTH_PLAN_SERVICE_INIT]: handleSetLoadingOn,
  [UPDATE_HEALTH_PLAN_SERVICE_SUCCESS]: handleSetLoadingOff,

  [CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT]: handleSetLoadingOn,
  [CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS]: handleSetLoadingOff,

  [DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT]: handleSetLoadingOn,
  [DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS]: handleSetLoadingOff,

  [CREATE_HEALTH_PLAN_ACTION_INIT]: handleSetLoadingOn,
  [CREATE_HEALTH_PLAN_ACTION_SUCCESS]: handleSetLoadingOff,

  [DELETE_HEALTH_PLAN_ACTION_INIT]: handleSetLoadingOn,
  [DELETE_HEALTH_PLAN_ACTION_SUCCESS]: handleSetLoadingOff,

  [RESET_LOADERS]: handleResetLoading,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import { createSelector } from 'reselect';

const selectHealthPlanAdminItems = (state) =>
  state.healthPlanAdminItems.adminItems;
const selectHealthPlanAdminItemsErrors = (state) =>
  state.healthPlanAdminItems.errors;

export const healthPlanAdminItemsSelector = createSelector(
  [selectHealthPlanAdminItems],
  (healthPlanAdminItems) => healthPlanAdminItems
);

export const healthPlanAdminItemsErrorsSelector = createSelector(
  [selectHealthPlanAdminItemsErrors],
  (healthPlanAdminItemsErrors) => healthPlanAdminItemsErrors
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import { DatePicker, Form, Input, SelectField } from 'common/components/form';
import { PROVIDER_TYPES, SEX, STATE } from 'common/constants';
import { renderObject, renderFullName } from 'common/utils';
import { Button } from 'common/components/buttons';
import { ProviderPropType } from 'common/propTypes';
import { isEmpty } from 'lodash';

const AddPatient = ({
  open,
  fetchProviders,
  resetProviders,
  providers,
  values: {
    firstName,
    lastName,
    email,
    medicareNumber,
    phoneNumber,
    dateOfBirth,
    sex,
    street,
    city,
    state,
    postcode,
    generalPractitioner,
  },
  values,
  setValues,
  handleSubmit,
  onClose,
  inlineLoading,
}) => {
  const setDateOfBirth = (value) => {
    setValues({
      ...values,
      dateOfBirth: value,
    });
  };

  useEffect(() => {
    fetchProviders({
      query: { providerTypes: [PROVIDER_TYPES.GENERAL_PRACTITIONER] },
    });
    return () => {
      resetProviders();
    };
  }, [fetchProviders, resetProviders]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>Add Patient</DialogTitle>
      <DialogContent>
        <Form>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <Input
                label="First Name"
                name="firstName"
                value={firstName}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Input
                label="Last Name"
                name="lastName"
                value={lastName}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Input
                label="Email"
                name="email"
                value={email}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Input
                label="Medicare Number"
                name="medicareNumber"
                value={medicareNumber}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Input
                label="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={4} xs={6}>
              <DatePicker
                id="date-of-birth-date-picker"
                name="dateOfBirth"
                label="Date of Birth"
                value={dateOfBirth}
                onChange={setDateOfBirth}
                maxDate={new Date()}
                margin="none"
                dateOfBirth
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={4} xs={6}>
              <SelectField
                name="sex"
                label="Sex"
                value={sex}
                options={SEX}
                renderSelectLabel={renderObject}
                sortByIndex
                setValues={setValues}
                values={values}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                name="generalPractitioner"
                label="Doctor"
                value={generalPractitioner}
                options={providers}
                renderSelectLabel={renderFullName}
                setValues={setValues}
                values={values}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Street"
                name="street"
                value={street}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Input
                label="Suburb"
                name="city"
                value={city}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <SelectField
                name="state"
                label="State"
                value={state}
                options={STATE}
                renderSelectLabel={renderObject}
                setValues={setValues}
                values={values}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <Input
                label="Postcode"
                name="postcode"
                value={postcode}
                margin="none"
                disabled={!isEmpty(inlineLoading)}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="cancel"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={!isEmpty(inlineLoading)}
        />
        <Button
          label="Add Patient"
          color="primary"
          onClick={handleSubmit}
          disabled={!isEmpty(inlineLoading)}
        />
      </DialogActions>
    </Dialog>
  );
};

AddPatient.defaultProps = {};
AddPatient.propTypes = {
  open: PropTypes.bool,
  fetchProviders: PropTypes.func.isRequired,
  resetProviders: PropTypes.func.isRequired,
  providers: PropTypes.objectOf(PropTypes.shape(ProviderPropType)),
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    medicareNumber: PropTypes.string,
    phoneNumber: PropTypes.string,
    dateOfBirth: PropTypes.instanceOf(Date),
    sex: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
    generalPractitioner: PropTypes.string,
  }),
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array.isRequired,
};

export default AddPatient;

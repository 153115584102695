import mapValuesToProps from './mapValuesToProps';

export default function (values, { props: { addProvider, onSuccess } }) {
  const handleOnSuccess = () => {
      onSuccess();
    },
    handleOnError = () => {};
  return addProvider({
    data: mapValuesToProps(values),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

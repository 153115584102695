import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patientCycleOfCareItems/update';
import { updatePatientCycleOfCareItemInit } from 'services/database/actions/patientCycleOfCareItems';
import PatientCycleOfCareItemUpdate from './PatientCycleOfCareItemUpdate';

export default compose(
  withAction({
    actionName: 'updatePatientCycleOfCareItem',
    action: updatePatientCycleOfCareItemInit,
  }),
  withFormik({ mapPropsToValues, validationSchema, handleSubmit })
)(PatientCycleOfCareItemUpdate);

import { isEmpty } from 'lodash';
import { Step, StepLabel, Typography } from '@mui/material';
import { FlexBox } from 'common/components/layouts';
import GlobalTheme from 'common/styles/global';
import { Tooltip as StyledTooltip } from './styles';

export default function ({
  key,
  item,
  labelProps,
  cycleOfCareModuleDefinitions,
}) {
  return (
    <StyledTooltip
      key={key}
      title={
        <FlexBox
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p="0.5rem"
        >
          <Typography
            variant="body2"
            color={item.overdue ? 'error' : 'primary'}
          >
            {isEmpty(item.cycleOfCareModules)
              ? 'No Cycles of Care'
              : 'Cycles of Care'}
          </Typography>

          {item.cycleOfCareModules.map((module, idx) => (
            <Typography
              key={idx}
              variant="body2"
              color={item.overdue && 'error'}
            >
              {cycleOfCareModuleDefinitions[module].name}
            </Typography>
          ))}
        </FlexBox>
      }
    >
      <Step sx={{ minWidth: GlobalTheme.spacing(20) }}>
        <StepLabel {...labelProps}>
          <Typography color={item.overdue && 'error'}>{item.name}</Typography>
          <Typography variant="body2" color={item.overdue && 'error'}>
            {item.dateLabel}
          </Typography>
        </StepLabel>
      </Step>
    </StyledTooltip>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { TextField as StyledTextField } from './styles';

const MockInput = ({ label, value, variant }) => {
  return <StyledTextField label={label} value={value} variant={variant} disabled />;
};

MockInput.defaultProps = {
  variant: 'outlined',
};
MockInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default MockInput;

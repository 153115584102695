import { isUndefined } from 'lodash';
import { Fab as MuiFab } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Fab = styled(MuiFab)(({ theme, color, position }) => ({
  alignSelf: 'flex-end',
  boxShadow:
    '0px 2px 5px -1px rgba(0, 0, 0, 0.2), 0px 4px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 12px 0px rgba(0, 0, 0, 0.12)',
  backgroundColor: !isUndefined(theme.palette[color])
    ? theme.palette[color].main
    : color,
  color: !isUndefined(theme.palette[color])
    ? theme.palette[color].contrastText
    : color,
  ':hover': {
    backgroundColor: !isUndefined(theme.palette[color])
      ? theme.palette[color].main
      : color,
  },
  ':active': {
    backgroundColor: !isUndefined(theme.palette[color])
      ? theme.palette[color].main
      : color,
  },
}));

import React from 'react';
import { isUndefined, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { ExpandableCard, GoalsCard } from 'common/components/layouts';
import { PROVIDER_TYPES, PROVIDER_TYPE_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanServicePropType,
  ProviderPropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';

const PracticeStaff = ({
  healthProfessional,
  healthPlanServices,
  goalDefinitions,
}) => {
  const numberOfServices = healthPlanServices.length;

  return (
    <>
      <ExpandableCard
        cardContent={
          <Box>
            <Typography variant="h6" color="primary">
              {healthProfessional.providerType === PROVIDER_TYPES.PRACTICE_NURSE
                ? 'Health Coach'
                : PROVIDER_TYPE_NAMES[healthProfessional.providerType]}
            </Typography>
            <Typography
              variant="h2"
              color={
                !isUndefined(healthProfessional.id)
                  ? 'textPrimary'
                  : 'textSecondary'
              }
            >
              {!isUndefined(healthProfessional.id)
                ? renderFullName(healthProfessional)
                : 'No Provider Selected'}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Health Services: {numberOfServices}
            </Typography>
          </Box>
        }
      >
        {numberOfServices === 0 ? (
          <Typography variant="h4" align="center">
            No Health Services assigned to Practice Staff.
          </Typography>
        ) : (
          <Box mb={-2.5}>
            <Grid container spacing={2}>
              {sortBy(healthPlanServices, ['createdAt']).map(
                (healthPlanService, idx) => (
                  <Grid key={idx} item sm={6} xs={12}>
                    <GoalsCard
                      title={`Service #${healthPlanService.index}`}
                      content={healthPlanService.definition.name}
                      goals={healthPlanService.healthPlanGoals.map(
                        (healthPlanGoal) =>
                          goalDefinitions[healthPlanGoal.definition]
                      )}
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>
        )}
      </ExpandableCard>
    </>
  );
};

PracticeStaff.defaultProps = {};
PracticeStaff.propTypes = {
  healthProfessional: PropTypes.shape(ProviderPropType).isRequired,
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      definition: PropTypes.shape(ServicePropType),
    })
  ).isRequired,
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
};

export default PracticeStaff;

import axios from 'axios';
import makeUrlFromPath from './makeUrlFromPath';
import serializeParams from './serializeParams';
import { authService } from 'services/database/storage';
import { isNull } from 'lodash';

// define request. set auth header
export function request(method, path, { data, responseType, params } = {}) {
  const url = makeUrlFromPath({ path });
  const auth = authService.getAuth();
  const unauthenticatedPaths = ['/token/'];
  if (!unauthenticatedPaths.includes(path)) {
    // Add a request interceptor
    axios.interceptors.request.use(
      (config) => {
        const accessToken = auth.getAccessToken();
        if (accessToken) {
          config.headers['Authorization'] = 'Bearer ' + accessToken;
          config.headers['Content-Encoding'] = 'gzip';
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }
  //Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      if (response.config.url === makeUrlFromPath({ path: '/token/' })) {
        auth.setAuth({
          accessToken: response.data.access,
          refreshToken: response.data.refresh,
          isAuthenticated: true,
        });
      }
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      const refreshToken = auth.getRefreshToken();
      if (
        error.response &&
        error.response.status === 401 &&
        (isNull(refreshToken) ||
          originalRequest.url === makeUrlFromPath({ path: '/token/refresh/' }))
      ) {
        auth.setAuth({
          accessToken: null,
          refreshToken: null,
          isAuthenticated: false,
        });
        return Promise.reject(error);
      } else if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return axios
          .post(makeUrlFromPath({ path: '/token/refresh/' }), {
            refresh: refreshToken,
          })
          .catch((error) => {
            auth.setAuth({
              accessToken: null,
              refreshToken: null,
              isAuthenticated: false,
            });
            throw error;
          })
          .then((res) => {
            if (res.status === 200) {
              const newData = {
                accessToken: res.data.access,
                refreshToken: res.data.refresh,
                isAuthenticated: true,
              };
              auth.setAuth(newData);
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + newData.accessToken;
              return axios(originalRequest);
            }
          });
      }
      return Promise.reject(error);
    }
  );

  return axios({
    method,
    url,
    data,
    responseType,
    params,
    paramsSerializer: serializeParams,
  }).then((response) => {
    return response.data;
  });
}

export default {
  get: request.bind(this, 'get'),
  post: request.bind(this, 'post'),
  put: request.bind(this, 'put'),
  patch: request.bind(this, 'patch'),
  delete: request.bind(this, 'delete'),
};

export default {
  HEALTH_GOALS: 0,
  CLINICAL_DATA: 1,
  HEALTH_SERVICES: 2,
  HEALTH_TEAM: 3,
  CYCLE_OF_CARE: 4,
  HEALTH_ACTIONS: 5,
  FEEDBACK: 7,
  SUMMARY: 8,
};

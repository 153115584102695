import React, { Component } from 'react';
import PropTypes from 'prop-types';
import history from 'routing/history';
import ErrorPage from 'pages/error';

const initial_state = {
  error: null,
  info: null,
};

// BUG: how to import history here?
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // establish the initial state
    this.state = initial_state;

    // Bind any event handlers
    this.reset = this.reset.bind(this);
  }

  componentDidCatch(error, info) {
    this.setState({ error: error, info: info });
    // You can also log the error to an error reporting service
    console.log('ErrorBoundary -> componentDidCatch -> error', error);
    console.log('ErrorBoundary -> componentDidCatch -> info', info);
  }

  reset() {
    // Reset state
    this.setState(initial_state);
    // Navigate back
    history.go(-1);
  }

  render() {
    // read the error information from the state
    let { error, info } = this.state;

    if (error) {
      return <ErrorPage onClick={this.reset} error={error} info={info} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

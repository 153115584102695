export default function ({ healthPlanService, deleteHealthPlanService }) {
  return deleteHealthPlanService({
    query: {
      healthPlanId: healthPlanService.healthPlan,
      healthPlanServiceId: healthPlanService.id,
      definition: healthPlanService.definition.id,
      includesServices: healthPlanService.includesServices,
    },
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxField } from 'common/components/form';
import { Card } from 'common/components/layouts';
import { HealthPlanAdminItemPropType } from 'common/propTypes';

const HealthPlanAdminItem = ({
  healthPlanAdminItem,
  values,
  values: { checked },
  setValues,
  handleSubmit,
  inlineLoading,
  readOnly,
}) => {
  const handleOnClick = () => {
    setValues({
      ...values,
      checked: !checked,
    });
    handleSubmit();
  };

  return (
    <Card>
      <CheckboxField
        name={healthPlanAdminItem.id.toString()}
        label={healthPlanAdminItem.definition.name}
        variant="h4"
        checked={checked}
        onChange={handleOnClick}
        disabled={readOnly}
        loading={inlineLoading.includes(healthPlanAdminItem.id)}
      />
    </Card>
  );
};

HealthPlanAdminItem.defaultProps = {
  inlineLoading: [],
};
HealthPlanAdminItem.propTypes = {
  healthPlanAdminItem: PropTypes.shape(HealthPlanAdminItemPropType).isRequired,
  values: PropTypes.shape({ checked: PropTypes.bool }).isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanAdminItem;

import React from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
} from 'common/constants';
import { GoalPropType, HealthPlanGoalPropType } from 'common/propTypes';
import HealthPlanGoal from './components/HealthPlanGoal';

const HealthPlanGoalsTab = ({ healthPlanGoals, handleGoToSection }) => {
  const sectionId = HEALTH_PLAN_SECTIONS.HEALTH_GOALS;

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      {sortBy(healthPlanGoals, ['createdAt']).map((healthPlanGoal, idx) => (
        <HealthPlanGoal
          key={idx}
          index={idx + 1}
          healthPlanGoal={healthPlanGoal}
        />
      ))}
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

HealthPlanGoalsTab.propTypes = {
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({ ...GoalPropType, ...HealthPlanGoalPropType })
  ).isRequired,
  handleGoToSection: PropTypes.func.isRequired,
};

export default HealthPlanGoalsTab;

import React from 'react';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
  PROVIDER_GROUPS,
  PROVIDER_TYPES,
} from 'common/constants';
import {
  UserPropType,
  PatientPropType,
  HealthPlanPropType,
  HealthPlanServicePropType,
  GoalPropType,
  ServicePropType,
} from 'common/propTypes';
import PracticeStaff from './components/PracticeStaff';
import HealthPlanService from './components/HealthPlanService';

const HealthPlanTeamTab = ({
  healthPlan,
  healthPlanServices,
  goalDefinitions,
  handleGoToSection,
}) => {
  const sectionId = HEALTH_PLAN_SECTIONS.HEALTH_TEAM;

  const practiceStaff = [
      !isEmpty(healthPlan.generalPractitioner)
        ? healthPlan.generalPractitioner
        : { providerType: PROVIDER_TYPES.GENERAL_PRACTITIONER },
      !isEmpty(healthPlan.coach)
        ? healthPlan.coach
        : { providerType: PROVIDER_TYPES.PRACTICE_NURSE },
    ],
    sortedHealthPlanServices = sortBy(healthPlanServices, [
      'definition.providerGroup',
      'createdAt',
    ]).map((healthPlanService, idx) => ({
      ...healthPlanService,
      index: idx + 1,
    })),
    practiceStaffHealthServices = sortedHealthPlanServices.filter(
      (healthPlanService) =>
        healthPlanService.definition.providerGroup ===
        PROVIDER_GROUPS.PRACTICE_STAFF
    ),
    otherHealthServices = sortedHealthPlanServices
      .filter(
        (healthPlanService) =>
          healthPlanService.definition.providerGroup !==
          PROVIDER_GROUPS.PRACTICE_STAFF
      )
      .map((healthPlanService) => ({
        ...healthPlanService,
        provider: healthPlan.healthTeam[healthPlanService.provider],
      }));

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      {practiceStaff.map((healthProfessional, idx) => (
        <PracticeStaff
          key={idx}
          healthProfessional={healthProfessional}
          goalDefinitions={goalDefinitions}
          healthPlanServices={practiceStaffHealthServices}
        />
      ))}
      {otherHealthServices.map((healthPlanService, idx) => (
        <HealthPlanService
          key={idx}
          healthPlanService={healthPlanService}
          goalDefinitions={goalDefinitions}
        />
      ))}
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

HealthPlanTeamTab.propTypes = {
  user: PropTypes.shape(UserPropType),
  patient: PropTypes.shape(PatientPropType),
  healthPlan: PropTypes.shape(HealthPlanPropType),
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      definition: PropTypes.shape(ServicePropType),
    })
  ),
  serviceDefinitions: PropTypes.objectOf(PropTypes.shape(ServicePropType)),
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)),
  handleGoToSection: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default HealthPlanTeamTab;

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withHealthPlanGoals, withPropsMapping } from 'common/HOCs';
import { measurementDefinitionsSelector } from 'services/database/actions/definitions';
import {
  fetchPatientMeasurementsInit,
  patientMeasurementsSelector,
  resetPatientMeasurements,
} from 'services/database/actions/patientMeasurements';
import {
  fetchHealthPlanMeasurementsInit,
  healthPlanMeasurementsSelector,
  resetHealthPlanMeasurements,
} from 'services/database/actions/healthPlanMeasurements';
import HealthPlanMeasurements from './HealthPlanMeasurements';

export default compose(
  withAction({
    actionName: 'fetchPatientMeasurements',
    action: fetchPatientMeasurementsInit,
  }),
  withAction({
    actionName: 'resetPatientMeasurements',
    action: resetPatientMeasurements,
  }),
  withAction({
    actionName: 'fetchHealthPlanMeasurements',
    action: fetchHealthPlanMeasurementsInit,
  }),

  withAction({
    actionName: 'resetHealthPlanMeasurements',
    action: resetHealthPlanMeasurements,
  }),
  connect((state) => ({
    healthPlanMeasurements: healthPlanMeasurementsSelector(state),
    patientMeasurements: patientMeasurementsSelector(state),
    measurementsDefinitions: measurementDefinitionsSelector(state),
  })),
  withPropsMapping((props) => ({
    ...props,
    healthPlanMeasurements: Object.values(props.healthPlanMeasurements).map(
      (healthPlanMeasurement) => ({
        ...healthPlanMeasurement,
        lastMeasurement:
          props.patientMeasurements[healthPlanMeasurement.lastMeasurement],
        definition:
          props.measurementsDefinitions[healthPlanMeasurement.definition],
      })
    ),
    availableHealthPlanMeasurements: Object.values(
      props.measurementsDefinitions
    ).filter(
      (measurement) =>
        !Object.values(props.healthPlanMeasurements).some(
          (healthPlanMeasurement) =>
            healthPlanMeasurement.definition === measurement.id
        )
    ),
  })),
  withHealthPlanGoals
)(HealthPlanMeasurements);

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import NoResultsImage from 'common/assets/graphics/no-results.svg';
import { FlexBox } from 'common/components/layouts';
import { ImageContainer as StyledImageContainer } from './styles';

const NoResults = ({ title, data }) => (
  <FlexBox flexDirection="column" alignItems="center">
    <StyledImageContainer src={NoResultsImage} />
    <Typography variant="h2">{title}</Typography>
    <Typography color="textSecondary">{data}</Typography>
  </FlexBox>
);

NoResults.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
};

export default NoResults;

import React from 'react';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { Card, ExpandableCard, GoalsCard } from 'common/components/layouts';
import { PROVIDER_GROUPS, PROVIDER_TYPE_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanServicePropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';

const HealthPlanService = ({
  healthPlanService,
  goalDefinitions,
  taskDefinitions,
}) => {
  const numberOfTasks = healthPlanService.tasks.length,
    numberOfVisits = healthPlanService.numberOfVisits;

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Typography variant="h6" color="primary">
            {healthPlanService.definition.providerTypes.map(
              (providerType) => PROVIDER_TYPE_NAMES[providerType]
            )}
          </Typography>
          <Typography
            variant="h2"
            color={
              !isUndefined(healthPlanService.provider)
                ? 'textPrimary'
                : 'textSecondary'
            }
          >
            {!isUndefined(healthPlanService.provider)
              ? renderFullName(healthPlanService.provider)
              : 'No Provider Selected'}
          </Typography>
          {healthPlanService.definition.providerGroup ===
          PROVIDER_GROUPS.PHARMACIST ? (
            <Typography variant="h6" color="textSecondary">
              Health Services: 1
            </Typography>
          ) : (
            <Box>
              <Typography
                variant="h6"
                color={numberOfTasks > 0 ? 'textSecondary' : 'error'}
              >
                Health Tasks: {numberOfTasks}
              </Typography>
              <Typography
                variant="h6"
                color={numberOfVisits > 0 ? 'textSecondary' : 'error'}
              >
                Number Of Visits: {numberOfVisits}
              </Typography>
            </Box>
          )}
        </Box>
      }
    >
      {healthPlanService.definition.providerGroup ===
      PROVIDER_GROUPS.PHARMACIST ? (
        <Box mb={-2.5}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <GoalsCard
                title={`Service #${healthPlanService.index}`}
                content={healthPlanService.definition.name}
                goals={healthPlanService.healthPlanGoals.map(
                  (healthPlanGoal) => goalDefinitions[healthPlanGoal.definition]
                )}
              />
            </Grid>
          </Grid>
        </Box>
      ) : numberOfTasks === 0 ? (
        <Typography variant="h4" align="center">
          No tasks assigned to this Health Team member.
        </Typography>
      ) : (
        <Box mb={-2.5}>
          <Grid container spacing={2}>
            {healthPlanService.tasks.map((task, idx) => (
              <Grid key={idx} item sm={6} xs={12}>
                <Card>
                  <Typography variant="h5" color="textSecondary">
                    Task #{idx + 1}
                  </Typography>
                  <Typography variant="h2">
                    {taskDefinitions[task].name}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </ExpandableCard>
  );
};

HealthPlanService.defaultProps = {};
HealthPlanService.propTypes = {
  healthPlanService: PropTypes.shape({
    ...HealthPlanServicePropType,
    healthPlan: PropTypes.number.isRequired,
    definition: PropTypes.shape(ServicePropType),
  }).isRequired,
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
};

export default HealthPlanService;

import { styled } from '@mui/material/styles';

export const ColorIndicator = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dense',
})(({ theme, color, dense }) => ({
  height: theme.spacing(1.5),
  width: theme.spacing(1.5),
  marginRight: dense ? 0 : theme.spacing(0.5),
  borderRadius: '50%',
  backgroundColor: color,
}));

import { put, call } from 'redux-saga/effects';
import {
  updateUserSuccess,
  updateUserError,
} from 'services/database/actions/user';
import request from './request';

export default function* updateUserSaga({ query, data, resolve, reject }) {
  try {
    const responseData = yield call(request, {
      query,
      data,
    });
    yield put(updateUserSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield put(updateUserError({ error }));
    yield call(reject);
  }
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withHealthPlanGoals, withPropsMapping } from 'common/HOCs';
import {
  fetchHealthPlanServicesInit,
  resetHealthPlanServices,
  healthPlanServicesSelector,
} from 'services/database/actions/healthPlanServices';
import HealthPlanServices from './HealthPlanServices';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanServices',
    action: fetchHealthPlanServicesInit,
  }),
  withAction({
    actionName: 'resetHealthPlanServices',
    action: resetHealthPlanServices,
  }),
  connect((state) => ({
    healthPlanServices: healthPlanServicesSelector(state),
  })),
  withHealthPlanGoals,
  withPropsMapping((props) => ({
    availableServices: Object.values(props.serviceDefinitions).filter(
      (service) => {
        return (
          service.goals.some((goal) => {
            return props.healthPlanGoals.some((healthPlanGoal) => {
              return healthPlanGoal.definition === goal;
            });
          }) &&
          (service.isShortlisted ||
            // Include all assigned health services
            Object.values(props.healthPlanServices).some(
              (healthPlanService) => {
                return healthPlanService.definition === service.id;
              }
            ) ||
            // Include all services that are included by an assigned health service
            Object.values(props.healthPlanServices).some(
              (healthPlanService) => {
                return props.serviceDefinitions[
                  healthPlanService.definition
                ].includesServices.includes(service.id);
              }
            ))
          // &&
          // TODO: Include all services that are included by patient condition
          // Exclude all services that are excluded by an assigned health service
          // !props.healthPlanServices.some((healthPlanService) => {
          //   return healthPlanService.definition.excludesServices.includes(service.id);
          // })
          // TODO: Exclude all services that are excluded by patient condition
        );
      }
    ),
    excludedServices: [
      ...new Set(
        Object.values(props.healthPlanServices).reduce(
          (acc, cur) =>
            acc.concat(
              props.serviceDefinitions[cur.definition].excludingServices
            ),
          []
        )
      ),
    ],
    ...props,
    // Override these props
    healthPlanServices: Object.values(props.healthPlanServices).map(
      (healthPlanService) => {
        const service = props.serviceDefinitions[healthPlanService.definition];

        return {
          ...healthPlanService,
          includesServices: Object.values(props.healthPlanServices)
            .filter((healthPlanService) =>
              service.includesServices.includes(healthPlanService.definition)
            )
            .map((healthPlanService) => healthPlanService.id),
          definition: service,
        };
      }
    ),
  }))
)(HealthPlanServices);

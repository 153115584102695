import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  providerGroup: PropTypes.number,
  providerTypes: PropTypes.arrayOf(PropTypes.number),
  isDefault: PropTypes.bool,
  isShortlisted: PropTypes.bool,
  goals: PropTypes.arrayOf(PropTypes.number),
  excludesServices: PropTypes.arrayOf(PropTypes.number),
  excludingConditions: PropTypes.arrayOf(PropTypes.number),
  excludingServices: PropTypes.arrayOf(PropTypes.number),
  includesServices: PropTypes.arrayOf(PropTypes.number),
  includingConditions: PropTypes.arrayOf(PropTypes.number),
  includingServices: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.number),
};

import PropTypes from 'prop-types';
import PatientCycleOfCareItemPropType from './PatientCycleOfCareItemPropType';

export default {
  id: PropTypes.number.isRequired,
  patient: PropTypes.number.isRequired,
  definition: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  patientCycleOfCareItems: PropTypes.objectOf(
    PropTypes.shape(PatientCycleOfCareItemPropType)
  ),
};

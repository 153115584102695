import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { onSuccess, user, healthPlanReview, updateHealthPlanReview } }
) {
  const handleOnSuccess = () => {
      onSuccess();
    },
    handleOnError = () => {};

  return updateHealthPlanReview({
    query: {
      healthPlanId: healthPlanReview.healthPlan,
      healthPlanReviewId: healthPlanReview.id,
    },
    data: mapValuesToProps(user, values),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

import { Tooltip as MuiTooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip
    {...props}
    componentsProps={{ tooltip: { className: className } }}
  />
))(({ theme }) => ({
  color: `${theme.palette.text.disabled}`,
  backgroundColor: 'transparent',
  marginTop: '0 !important',
}));

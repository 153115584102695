import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducer';
import rootSaga from './saga';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'definitions'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const initialState = {};

const persistedStore = () => {
  let store = createStore(
    persistedReducer,
    initialState,
    bindMiddleware([sagaMiddleware])
  );
  let persistor = persistStore(store);
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return { store, persistor };
};

export default persistedStore;

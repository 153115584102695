import React, { useState, useEffect, useRef } from 'react';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  Pagination,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { Button } from 'common/components/buttons';
import { FlexBox, TitleBox, NoResults } from 'common/components/layouts';
import { useDebounce } from 'common/hooks';
import { PatientPropType, UserPropType } from 'common/propTypes';
import { insertIf, isUserDoctor, isUserNurse } from 'common/utils';
import Search from './components/Search';
import Patient from './components/Patient';
import AddPatient from './components/AddPatient';

const PatientsPage = ({
  user,
  patients,
  fetchPatients,
  resetPatients,
  patientCount,
  loading,
  history,
}) => {
  const PATIENTS_PER_PAGE = 10;

  // TODO: Improve how search delay is handled?
  const [currentPageNumber, setCurrentPageNumber] = useState(1),
    [pageNumbers, setPageNumbers] = useState(1),
    [searchQuery, setSearchQuery] = useState(''),
    tempDebouncedSearchQuery = useDebounce(searchQuery, 1000),
    [debouncedSearchQuery, setDebouncedSearchQuery] = useState(''),
    [finalSearchQuery, setFinalSearchQuery] = useState('');

  const displayDict = {
      all: {
        label: 'All',
        color: 'primary',
        status: undefined,
        noResults: {
          title: 'No Results Found',
          data: 'Try adjusting your search or filters to find what you’re looking for.',
        },
      },
      upcoming: {
        label: 'Upcoming',
        color: 'success',
        status: 'upcoming',
        noResults: {
          title: 'No Upcoming Reviews',
          data: 'There are no Health Plan reviews within the next month.',
        },
      },
      ...(isUserDoctor(user)
        ? {
            shared: {
              label: 'To Be Finalised',
              color: 'warning',
              status: 'shared',
              noResults: {
                title: 'Up To Date',
                data: 'There are no Health Plans to finalise.',
              },
            },
          }
        : {}),
      ...(isUserNurse(user)
        ? {
            in_progress: {
              label: 'In Progress',
              color: 'warning',
              status: 'in_progress',
              noResults: {
                title: 'Up To Date',
                data: 'There are no Health Plans in progress.',
              },
            },
          }
        : {}),
      overdue: {
        label: 'Overdue',
        color: 'error',
        status: 'overdue',
        noResults: {
          title: 'No Overdue Reviews',
          data: 'There are no Health Plans with overdue reviews.',
        },
      },
    },
    // location used so provider can click on dashboard status & open list with correct filtering
    location = useLocation(),
    [status, setStatus] = useState(
      location.state
        ? displayDict[location.state.status].status
        : displayDict['all'].status
    ),
    statusObject = Object.values(displayDict).find(
      (displayObject) => displayObject.status === status
    );

  // BUG: The patients response data contains previous & next urls to use
  // Surely not the best way to deal with page number reset on search
  // Issue is that if on page 2 and run a search with 1 reset, fetch will go off with page=2?search="Angus", so have no results
  // Data flow:
  // Type in search and don't press enter:
  // - updates searchQuery, which runs useDebounce for the temp value.
  // - then updates debouncedSearchQuery then finally the finalSearch query
  // Type in search and press enter:
  // - jumps straight to setDebouncedSearchQuery and then sets finalSearchQuery
  // Method is required so can search from any page and either let the key release or onEnter run the search
  // sets the debounced search query
  // No change in searchQuery so nav through pages doesn't call these useEffects
  useEffect(() => {
    setDebouncedSearchQuery(tempDebouncedSearchQuery);
  }, [tempDebouncedSearchQuery]);

  // resets filter to all, the page number to 1 and sets the final search value
  // the useRef is used to stop this useEffect from running on the first render
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setStatus(undefined);
    setCurrentPageNumber(1);
    setFinalSearchQuery(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  // Search filtering done on backend
  useEffect(() => {
    fetchPatients({
      query: {
        page: currentPageNumber,
        search: finalSearchQuery,
        status,
      },
    });
    return () => resetPatients();
  }, [
    currentPageNumber,
    finalSearchQuery,
    fetchPatients,
    resetPatients,
    status,
  ]);

  useEffect(() => {
    setPageNumbers(Math.ceil(patientCount / PATIENTS_PER_PAGE));
  }, [patientCount, PATIENTS_PER_PAGE]);

  const isNurse = isUserNurse(user),
    listHeadings = [
      'First Name',
      'Last Name',
      ...insertIf(isNurse, 'Assigned GP'),
      'Status',
      'Review Due',
    ];

  const handleOnClick = (displayStatus) => () => {
    if (status !== displayStatus) {
      setStatus(displayStatus);
      setCurrentPageNumber(1);
    }
  };

  const [showAddPatient, setShowAddPatient] = useState(false),
    handleToggleAddPatient = () => {
      setShowAddPatient(!showAddPatient);
    };

  return (
    <>
      <TitleBox
        contentLeft={
          <>
            <FlexBox alignItems="baseline">
              <Typography variant="h1" style={{ marginRight: '2.5rem' }}>
                Patients
              </Typography>
              <Typography variant="h3" color="textSecondary">
                {patientCount} Total
              </Typography>
            </FlexBox>
            <Button
              label="add patient"
              variant="outlined"
              size="small"
              onClick={handleToggleAddPatient}
            />
          </>
        }
        contentRight={
          <FlexBox flexDirection="column" alignItems="end">
            <Search
              name="search"
              placeholder="Search patient list"
              search={searchQuery}
              onChange={setSearchQuery}
              onEnter={setDebouncedSearchQuery}
              onSubmit={setSearchQuery}
            />
            <FlexBox>
              {Object.values(displayDict).map(
                ({
                  label: displayLabel,
                  color: displayColor,
                  status: displayStatus,
                }) => (
                  <Button
                    key={displayLabel}
                    label={displayLabel}
                    color={displayColor}
                    variant={
                      status === displayStatus ? 'contained' : 'outlined'
                    }
                    size="small"
                    onClick={handleOnClick(displayStatus)}
                    // disabled={status === displayStatus} // DISCUSS: Commented out to avoid disabled styling.
                  />
                )
              )}
            </FlexBox>
          </FlexBox>
        }
      />

      {isEmpty(loading) && Object.keys(patients).length === 0 ? (
        <NoResults
          title={statusObject.noResults.title}
          data={statusObject.noResults.data}
        />
      ) : (
        <>
          <Table sx={{ marginBottom: '3rem' }}>
            <TableHead>
              <TableRow>
                {listHeadings.map((heading, idx) => (
                  <TableCell key={idx}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(sortBy(patients, ['lastName', 'firstName'])).map(
                (patient, idx) => (
                  <Patient
                    key={idx}
                    isNurse={isNurse}
                    patient={patient}
                    history={history}
                  />
                )
              )}
            </TableBody>
          </Table>

          {patientCount > Object.keys(patients).length && (
            <Pagination
              color="primary"
              count={pageNumbers}
              page={currentPageNumber}
              onChange={(event, value) => setCurrentPageNumber(value)}
              siblingCount={1}
              boundaryCount={1}
            />
          )}
        </>
      )}
      {showAddPatient && (
        <AddPatient
          open={showAddPatient}
          onClose={handleToggleAddPatient}
          user={user}
          history={history}
        />
      )}
    </>
  );
};

PatientsPage.defaultProps = {
  // DISCUSS: full user needs to be defined as otherwise throws propType error within UserPropType
  user: {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    userType: null,
    providerType: null,
    practices: [],
  },
  patients: {},
  patientCount: 0,
  loading: [],
  history: {},
};
PatientsPage.propTypes = {
  user: PropTypes.shape(UserPropType),
  patients: PropTypes.objectOf(PropTypes.shape(PatientPropType)),
  fetchPatients: PropTypes.func.isRequired,
  resetPatients: PropTypes.func.isRequired,
  patientCount: PropTypes.number,
  loading: PropTypes.array, // TODO: Update to arrayOf
  history: PropTypes.object,
};

export default PatientsPage;

import { HEALTH_PLAN_STATUSES } from 'common/constants';
import { isUserDoctor, isUserNurse } from 'common/utils';

export default function (user, values) {
  return {
    ...(isUserDoctor(user) ||
    (isUserNurse(user) && values.status <= HEALTH_PLAN_STATUSES.SHARED)
      ? { status: values.status }
      : {}),
  };
}

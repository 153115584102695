import React from 'react';
import { Tab as MuiTab } from '@mui/material';

const Tab = ({ ...props }) => (
  <MuiTab {...props} disableFocusRipple disableRipple />
);

Tab.defaultProps = {};
Tab.propTypes = {};

export default Tab;

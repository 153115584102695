export const FETCH_HEALTH_PLAN_ACTIONS_INIT = 'FETCH_HEALTH_PLAN_ACTIONS_INIT';
export const FETCH_HEALTH_PLAN_ACTIONS_SUCCESS =
  'FETCH_HEALTH_PLAN_ACTIONS_SUCCESS';

export const CREATE_HEALTH_PLAN_ACTION_INIT = 'CREATE_HEALTH_PLAN_ACTION_INIT';
export const CREATE_HEALTH_PLAN_ACTION_SUCCESS =
  'CREATE_HEALTH_PLAN_ACTION_SUCCESS';

export const DELETE_HEALTH_PLAN_ACTION_INIT = 'DELETE_HEALTH_PLAN_ACTION_INIT';
export const DELETE_HEALTH_PLAN_ACTION_SUCCESS =
  'DELETE_HEALTH_PLAN_ACTION_SUCCESS';

export const RESET_HEALTH_PLAN_ACTIONS = 'RESET_HEALTH_PLAN_ACTIONS';

import React from 'react';
import PropTypes from 'prop-types';
import { Fab as StyledFab } from './styles';

const FloatingButton = ({
  icon,
  size,
  color,
  // position,
  onClick,
  to,
  disabled,
}) => {
  return (
    <StyledFab
      size={size}
      color={color}
      // position={position}
      onClick={onClick}
      to={to}
      disabled={disabled}
    >
      {icon}
    </StyledFab>
  );
};

FloatingButton.defaultProps = {
  size: 'large',
  bottom: '4rem',
};
FloatingButton.propTypes = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  bottom: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  to: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default FloatingButton;

import { isNull } from 'lodash';
import { renderDateForSubmit } from 'common/utils';

export default function (values) {
  return {
    completedAt: !isNull(values.completedAt)
      ? renderDateForSubmit(values.completedAt)
      : null,
  };
}

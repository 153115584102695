import { flattenObject } from 'common/utils';
import { buildReducer } from 'common/utils/redux';
import { RESET_HEALTH_PLAN } from 'services/database/actions/healthPlans/redux/actionNames';
import {
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_ERROR,
  FETCH_PATIENT_SUCCESS,
  RESET_PATIENT,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_ERROR,
  UPDATE_PATIENT_PASSWORD_ERROR,
} from './actionNames';

const initialState = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  medicareNumber: true,
  sex: null,
  dateOfBirth: null,
  city: '',
  postcode: '',
  street: '',
  phoneNumber: '',
  generalPractitioner: {
    id: null,
  },
  healthPlan: null,
  errors: {},
};

function handleFetchPatientSuccess(state, { data }) {
  return data;
}

function handleUpdatePatientSuccess(state, { data }) {
  return { ...state, ...data };
}

function handleError(state, { error: { data } }) {
  return { ...state, errors: flattenObject(data) };
}

function handleResetPatient() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_PATIENT_SUCCESS]: handleFetchPatientSuccess,
  [ADD_PATIENT_SUCCESS]: handleFetchPatientSuccess,
  [ADD_PATIENT_ERROR]: handleError,
  [UPDATE_PATIENT_SUCCESS]: handleUpdatePatientSuccess,
  [UPDATE_PATIENT_ERROR]: handleError,
  [UPDATE_PATIENT_PASSWORD_ERROR]: handleError,
  [RESET_PATIENT]: handleResetPatient,
  [RESET_HEALTH_PLAN]: handleResetPatient,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import React, { useEffect, useState } from 'react';
import { isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import { FlexBox, GoalChips } from 'common/components/layouts';
import {
  ActionPropType,
  GoalPropType,
  HealthPlanPropType,
  HealthPlanActionPropType,
  HealthPlanGoalPropType,
  PatientPropType,
} from 'common/propTypes';
import { renderFullName, renderFirstName } from 'common/utils';
import HealthPlanActionAdd from './components/HealthPlanActionAdd';
import HealthPlanAction from './components/HealthPlanAction';

const HealthPlanActions = ({
  patient,
  healthPlan,
  healthPlanActions,
  healthPlanGoals,
  availableHealthPlanActions,
  fetchHealthPlanActions,
  resetHealthPlanActions,
  readOnly,
}) => {
  useEffect(() => {
    if (!isNil(patient.id)) {
      fetchHealthPlanActions({ query: { patientId: patient.id } });
    }
    return () => resetHealthPlanActions();
  }, [patient, fetchHealthPlanActions, resetHealthPlanActions]);

  const [showHealthActionAdd, setShowHealthActionAdd] = useState(false),
    handleToggleCreate = () => {
      setShowHealthActionAdd(!showHealthActionAdd);
    };

  return (
    <>
      <Typography color="textSecondary" paragraph>
        Here are {renderFullName(patient)}'s suggested Health Actions. As a
        reference for {renderFirstName(patient)}, we have included a brief
        explanation of each Health Action and links to more information.
      </Typography>

      <GoalChips goals={healthPlanGoals} />
      {sortBy(healthPlanActions, ['createdAt']).map((healthPlanAction, idx) => {
        const healthPlanActionGoals = healthPlanGoals.filter((healthPlanGoal) =>
          healthPlanAction.definition.goals.includes(healthPlanGoal.definition)
        );

        return (
          <HealthPlanAction
            key={healthPlanAction.id}
            index={idx + 1}
            patient={patient}
            healthPlanAction={healthPlanAction}
            healthPlanActionGoals={healthPlanActionGoals}
            readOnly={readOnly}
          />
        );
      })}
      {!readOnly && (
        <FlexBox flexDirection="column" mt="1rem">
          {showHealthActionAdd && (
            <HealthPlanActionAdd
              open={showHealthActionAdd}
              healthPlan={healthPlan}
              availableHealthPlanActions={availableHealthPlanActions}
              onClose={handleToggleCreate}
            />
          )}
          <Button
            label="Add Health Action"
            onClick={handleToggleCreate}
            disabled={availableHealthPlanActions.length === 0}
          />
        </FlexBox>
      )}
    </>
  );
};

HealthPlanActions.defaultProps = {
  availableHealthPlanActions: [],
  healthPlanGoals: [],
};
HealthPlanActions.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanActions: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanActionPropType,
      healthPlan: PropTypes.number,
      definition: PropTypes.shape(ActionPropType),
    })
  ).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ),
  availableHealthPlanActions: PropTypes.arrayOf(
    PropTypes.shape(ActionPropType)
  ),
  fetchHealthPlanActions: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanActions;

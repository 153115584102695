import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'common/components/form';
import { HEALTH_JOURNEY_STATUSES } from 'common/constants';
import { PatientPropType } from 'common/propTypes';
import { FlexBox } from 'common/components/layouts';

const Item = ({ item, patient, onSubmit, loading }) => {
  const [checked, setChecked] = useState(
      item.status === HEALTH_JOURNEY_STATUSES.COMPLETE
    ),
    handleOnSubmit = (item) => () => {
      setChecked(!checked);
      onSubmit({
        query: {
          patientId: patient.id,
          journeyId: item.healthJourney,
          itemId: item.id,
        },
        data: {
          completedAt: checked ? null : new Date(),
        },
      });
    };
  return (
    <FlexBox flexDirection="column">
      <Checkbox
        name={item.name}
        label={item.name}
        checked={checked}
        onChange={handleOnSubmit(item)}
        padding="dense"
        disabled={loading}
      />
    </FlexBox>
  );
};

Item.defaultProps = {};
Item.propTypes = {
  item: PropTypes.object,
  patient: PropTypes.shape(PatientPropType).isRequired,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default Item;

import { fork } from 'redux-saga/effects';
import authFlowSaga from './services/database/actions/auth/redux/saga';
import userFlowSaga from './services/database/actions/user/redux/saga';
import definitionsFlowSaga from './services/database/actions/definitions/redux/saga';
import dashboardFlowSaga from './services/database/actions/dashboard/redux/saga';
import providersFlowSaga from './services/database/actions/providers/redux/saga';
import patientsFlowSaga from './services/database/actions/patients/redux/saga';
import patientFlowSaga from './services/database/actions/patient/redux/saga';
import patientConditionsFlowSaga from './services/database/actions/patientConditions/redux/saga';
import patientMeasurementsFlowSaga from './services/database/actions/patientMeasurements/redux/saga';
import patientCycleOfCareModulesFlowSaga from './services/database/actions/patientCycleOfCareModules/redux/saga';
import patientCycleOfCareItemsFlowSaga from './services/database/actions/patientCycleOfCareItems/redux/saga';
import healthJourneyFlowSaga from 'services/database/actions/healthJourney/redux/saga';
import healthPlanFlowSaga from './services/database/actions/healthPlans/redux/saga';
import healthPlanReviewsFlowSaga from './services/database/actions/healthPlanReviews/redux/saga';
import goalProgressBarriersFlowSaga from './services/database/actions/goalProgressBarriers/redux/saga';
import goalProgressReviewsFlowSaga from './services/database/actions/goalProgressReviews/redux/saga';
import goalProgressSolutionsFlowSaga from './services/database/actions/goalProgressSolutions/redux/saga';
import healthPlanActionsFlowSaga from './services/database/actions/healthPlanActions/redux/saga';
import healthPlanAdminItemsFlowSaga from './services/database/actions/healthPlanAdminItems/redux/saga';
import healthPlanGoalsFlowSaga from './services/database/actions/healthPlanGoals/redux/saga';
import healthPlanMeasurementsFlowSaga from './services/database/actions/healthPlanMeasurements/redux/saga';
import healthPlanServicesFlowSaga from './services/database/actions/healthPlanServices/redux/saga';
import notificationsFlowSaga from './services/database/actions/notifications/redux/saga';
import healthPlanFeedbackFlowSaga from './services/database/actions/healthPlanFeedback/redux/saga';

export default function* rootSaga() {
  yield fork(authFlowSaga);
  yield fork(userFlowSaga);
  yield fork(definitionsFlowSaga);
  yield fork(dashboardFlowSaga);
  yield fork(providersFlowSaga);
  yield fork(patientsFlowSaga);
  yield fork(patientFlowSaga);
  yield fork(patientConditionsFlowSaga);
  yield fork(patientMeasurementsFlowSaga);
  yield fork(patientCycleOfCareModulesFlowSaga);
  yield fork(patientCycleOfCareItemsFlowSaga);
  yield fork(healthJourneyFlowSaga);
  yield fork(healthPlanFlowSaga);
  yield fork(healthPlanReviewsFlowSaga);
  yield fork(goalProgressBarriersFlowSaga);
  yield fork(goalProgressReviewsFlowSaga);
  yield fork(goalProgressSolutionsFlowSaga);
  yield fork(healthPlanActionsFlowSaga);
  yield fork(healthPlanAdminItemsFlowSaga);
  yield fork(healthPlanGoalsFlowSaga);
  yield fork(healthPlanMeasurementsFlowSaga);
  yield fork(healthPlanServicesFlowSaga);
  yield fork(notificationsFlowSaga);
  yield fork(healthPlanFeedbackFlowSaga);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import { HEALTH_PLAN_STATUSES } from 'common/constants';
import { UserPropType } from 'common/propTypes';
import { isUserDoctor, isUserNurse } from 'common/utils';

const UpdateHealthPlanStatusButton = ({
  user,
  label,
  values,
  setValues,
  handleSubmit,
  disabled,
}) => {
  const getStatus = () => {
      if (isUserNurse(user)) return HEALTH_PLAN_STATUSES.SHARED;
      if (isUserDoctor(user)) return HEALTH_PLAN_STATUSES.FINALISED;
      throw new Error('Expected logged in user to be a nurse or a doctor');
    },
    handleOnClick = () => {
      setValues({ ...values, status: getStatus() });
      handleSubmit();
    };

  return <Button label={label} onClick={handleOnClick} disabled={disabled} />;
};

UpdateHealthPlanStatusButton.propTypes = {
  user: PropTypes.shape(UserPropType).isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default UpdateHealthPlanStatusButton;

import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query, data }) {
  return http
    .patch(
      `/patients/${query.patientId}/health_journeys/${query.journeyId}/health_journey_items/${query.itemId}/`,
      { data }
    )
    .catch(handleHTTPError);
}

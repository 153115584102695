import React from 'react';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { IconButton } from 'common/components/buttons';
import { Card, FlexBox } from 'common/components/layouts';

const DeletableCard = ({ children, onClick, readOnly }) => {
  return (
    <Card>
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <FlexBox flexDirection="column">{children}</FlexBox>
        {!readOnly && (
          <IconButton
            icon={<Close />}
            color="error"
            size="small"
            onClick={onClick}
          />
        )}
      </FlexBox>
    </Card>
  );
};

DeletableCard.defaultProps = {
  readOnly: false,
};
DeletableCard.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default DeletableCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Card, Chip } from 'common/components/layouts';
import { GoalPropType, HealthPlanGoalPropType } from 'common/propTypes';

const HealthPlanGoal = ({ index, healthPlanGoal }) => {
  return (
    <Card>
      <Chip
        title={`Goal #${index}${
          healthPlanGoal.isDefault ? ' - Default (Cannot be removed)' : ''
        }`}
        variant="h5"
        color={healthPlanGoal.color}
      />
      <Typography variant="h2">{healthPlanGoal.name}</Typography>
    </Card>
  );
};

HealthPlanGoal.propTypes = {
  index: PropTypes.number.isRequired,
  healthPlanGoal: PropTypes.shape({
    ...GoalPropType,
    ...HealthPlanGoalPropType,
  }).isRequired,
};

export default HealthPlanGoal;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy, sortBy } from 'lodash';
import { startOfToday } from 'date-fns';
import { Box, Stepper } from '@mui/material';
import animate from '@mui/material/internal/animate';
import {
  getNormalizedScrollLeft,
  detectScrollType,
} from '@mui/material/utils/scrollLeft';
import { debounce, ownerWindow, useEventCallback } from '@mui/material/utils';
import {
  CheckRounded as Complete,
  HourglassBottomRounded as InProgress,
  PriorityHighRounded as Overdue,
  Today,
  CircleOutlined as Upcoming,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { IconButton } from 'common/components/buttons';
import { HEALTH_JOURNEY_STATUSES } from 'common/constants';
import { PatientPropType, CycleOfCareModulePropType } from 'common/propTypes';
import { isOverdue as isOverdueFunc, renderDate } from 'common/utils';
import GlobalTheme from 'common/styles/global';
import { basicStep, buttonStep, tooltipStep } from './utils';
import MultipleItemsDialog from './components/MultipleItemsDialog';

const HealthJourney = ({
  patient,
  healthJourney,
  updateHealthJourneyItem,
  cycleOfCareModuleDefinitions,
  inlineLoading,
}) => {
  const CustomStepIcon = ({ item }) => {
    return <div>{item.icon}</div>;
  };

  const todaysDate = startOfToday(),
    todayArray = [];

  const [showMultipleDialog, setShowMultipleDialog] = useState(false),
    [selectedItem, setSelectedItem] = useState(undefined),
    handleToggleDialog = () => {
      setShowMultipleDialog(!showMultipleDialog);
    },
    handleOnItemClick =
      ({ adminDate, adminItem }) =>
      (e) => {
        if (adminItem.isMultiple) {
          handleToggleDialog();
          setSelectedItem({ ...adminItem, date: adminDate });
        } else {
          updateHealthJourneyItem({
            query: {
              patientId: patient.id,
              journeyId: adminItem.healthJourney,
              itemId: adminItem.id,
            },
            data: {
              completedAt:
                adminItem.status === HEALTH_JOURNEY_STATUSES.COMPLETE
                  ? null
                  : new Date(),
            },
          });
        }
      };

  const reviewItems = Object.values(healthJourney.healthJourneyItems)
      .filter((item) => !item.editable)
      .map((reviewItem) => {
        const isComplete =
            reviewItem.status === HEALTH_JOURNEY_STATUSES.COMPLETE,
          isOverdue = !isComplete && isOverdueFunc(reviewItem.dueAt),
          isInProgress =
            reviewItem.status === HEALTH_JOURNEY_STATUSES.IN_PROGRESS,
          itemDate = new Date(reviewItem.dueAt),
          itemActive = renderDate(todaysDate) === renderDate(itemDate);

        const journeyItem = {
          name: reviewItem.name,
          date: itemDate,
          dateLabel: itemActive ? 'TODAY' : renderDate(itemDate),
          active: itemActive && !isComplete && !isOverdue,
          icon: isComplete ? (
            <Complete fontSize="small" color="success" />
          ) : isOverdue ? (
            <Overdue fontSize="small" color="error" />
          ) : isInProgress ? (
            <InProgress fontSize="small" color="primary" />
          ) : (
            <Upcoming
              fontSize="small"
              color={reviewItem.editable ? 'secondary' : 'disabled'}
            />
          ),
          overdue: isOverdue,
          // editable: reviewItem.editable,
          cycleOfCareModules: reviewItem.cycleOfCareModules,
        };
        return {
          ...journeyItem,
          onClick: handleOnItemClick({ journeyItem }),
        };
      }),
    groupedAdminItems = groupBy(
      Object.values(healthJourney.healthJourneyItems).filter(
        (item) => item.editable
      ),
      'dueAt'
    );

  const adminItems = Object.entries(groupedAdminItems).map(
    ([adminDate, adminItems]) => {
      const adminItem =
          adminItems.length > 1
            ? {
                name: 'Multiple Items',
                journeyItems: adminItems,
                isMultiple: true,
              }
            : adminItems[0],
        isComplete = !adminItems.find(
          (item) => item.status !== HEALTH_JOURNEY_STATUSES.COMPLETE
        ),
        isInProgress = adminItems.find(
          (item) => item.status === HEALTH_JOURNEY_STATUSES.IN_PROGRESS
        ),
        isOverdue = !isComplete && isOverdueFunc(adminDate),
        itemActive = renderDate(todaysDate) === renderDate(adminDate);

      return {
        date: new Date(adminDate),
        active: itemActive && !isComplete && !isOverdue,
        dateLabel: itemActive ? 'TODAY' : renderDate(adminDate),
        icon: isComplete ? (
          <Complete fontSize="small" color="success" />
        ) : isOverdue ? (
          <Overdue fontSize="small" color="error" />
        ) : isInProgress ? (
          <InProgress fontSize="small" color="primary" />
        ) : (
          <Upcoming fontSize="small" color="secondary" />
        ),
        overdue: isOverdue,
        editable: true,
        ...adminItem,
        onClick: handleOnItemClick({ adminDate, adminItem }),
      };
    }
  );

  !Object.values(healthJourney.healthJourneyItems).find((item) => {
    return renderDate(todaysDate) === renderDate(new Date(item.dueAt));
  }) &&
    todayArray.push({
      name: 'Today',
      date: new Date(todaysDate),
      icon: <Today fontSize="small" color="primary" />,
      active: true,
      isToday: true,
    });

  const healthJourneyItems = sortBy(
    [...todayArray, ...reviewItems, ...adminItems],
    ['date']
  );
  const isRtl = GlobalTheme.direction === 'rtl';

  const containerRef = useRef(null),
    stepperRef = useRef(null);

  const [displayScroll, setDisplayScroll] = useState({
    start: false,
    end: false,
  });

  const showScrollButtons = displayScroll.start || displayScroll.end;

  const updateScrollButtonState = useEventCallback(() => {
    const { scrollWidth, clientWidth } = stepperRef.current;
    let showStartScroll;
    let showEndScroll;

    const scrollLeft = getNormalizedScrollLeft(
      stepperRef.current,
      GlobalTheme.direction
    );
    // use 1 for the potential rounding error with browser zooms.
    showStartScroll = isRtl
      ? scrollLeft < scrollWidth - clientWidth - 1
      : scrollLeft > 1;
    showEndScroll = !isRtl
      ? scrollLeft < scrollWidth - clientWidth - 1
      : scrollLeft > 1;

    if (
      showStartScroll !== displayScroll.start ||
      showEndScroll !== displayScroll.end
    ) {
      setDisplayScroll({ start: showStartScroll, end: showEndScroll });
    }
  });

  React.useEffect(() => {
    const handleResize = debounce(() => {
      updateScrollButtonState();
    });
    const win = ownerWindow(stepperRef.current);
    win.addEventListener('resize', handleResize);

    let resizeObserver;

    if (typeof ResizeObserver !== 'undefined') {
      resizeObserver = new ResizeObserver(handleResize);
      Array.from(containerRef.current.children).forEach((child) => {
        resizeObserver.observe(child);
      });
    }

    return () => {
      handleResize.clear();
      win.removeEventListener('resize', handleResize);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [updateScrollButtonState]);

  const handleStepperScroll = useMemo(
    () =>
      debounce(() => {
        updateScrollButtonState();
      }),
    [updateScrollButtonState]
  );

  useEffect(() => {
    return () => {
      handleStepperScroll.clear();
    };
  }, [handleStepperScroll]);

  const scroll = (scrollValue, { animation = true } = {}) => {
    if (animation) {
      animate('scrollLeft', stepperRef.current, scrollValue, {
        duration: GlobalTheme.transitions.duration.standard,
      });
    } else {
      stepperRef.current.scrollLeft = scrollValue;
    }
  };

  const moveTabsScroll = (delta) => {
    let scrollValue = stepperRef.current.scrollLeft;

    scrollValue += delta * (isRtl ? -1 : 1);
    // Fix for Edge
    scrollValue *= isRtl && detectScrollType() === 'reverse' ? -1 : 1;

    scroll(scrollValue);
  };

  const getScrollSize = () => {
    const containerSize = stepperRef.current.clientWidth;
    let totalSize = 0;
    const children = Array.from(stepperRef.current.children);

    for (let i = 0; i < children.length; i += 1) {
      const step = children[i];
      if (totalSize + step.clientWidth > containerSize) {
        break;
      }
      totalSize += step.clientWidth;
    }
    return totalSize;
  };

  const handleStartScrollClick = () => {
    moveTabsScroll(-1 * getScrollSize());
  };

  const handleEndScrollClick = () => {
    moveTabsScroll(getScrollSize());
  };

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          margin: '2rem 0 4rem 0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showScrollButtons && (
          <IconButton
            icon={<KeyboardArrowLeft fontSize="small" />}
            size="small"
            onClick={handleStartScrollClick}
            disabled={!displayScroll.start}
          />
        )}
        <Stepper
          ref={stepperRef}
          activeStep={healthJourneyItems.findIndex((item) => item.active)}
          onScroll={handleStepperScroll}
          sx={{
            width: '100%',
            overflow: 'hidden',
            scrollbarWidth: 'none',
          }}
          nonLinear
          alternativeLabel
        >
          {healthJourneyItems.map((item, idx) => {
            const labelProps = {};
            labelProps.StepIconComponent = CustomStepIcon;
            labelProps.StepIconProps = {
              item,
            };

            // steps need to be functions as otherwise the first step will show the stepConnector line before it
            return item.isToday
              ? basicStep({ key: idx, item, labelProps })
              : item.editable
              ? buttonStep({
                  key: idx,
                  item,
                  labelProps,
                  disabled: inlineLoading.includes(`${item.id}-journeyItem`),
                })
              : tooltipStep({
                  key: idx,
                  item,
                  labelProps,
                  cycleOfCareModuleDefinitions,
                });
          })}
        </Stepper>

        {showScrollButtons && (
          <IconButton
            icon={<KeyboardArrowRight fontSize="small" />}
            size="small"
            onClick={handleEndScrollClick}
            disabled={!displayScroll.end}
          />
        )}
      </Box>
      {showMultipleDialog && (
        <MultipleItemsDialog
          open={showMultipleDialog}
          multipleItems={selectedItem.journeyItems}
          date={selectedItem.date}
          patient={patient}
          onSubmit={updateHealthJourneyItem}
          onClose={handleToggleDialog}
          inlineLoading={inlineLoading}
        />
      )}
    </>
  );
};

HealthJourney.defaultProps = {
  patient: {
    firstName: '',
    lastName: '',
    healthPlan: { lastReview: {} },
    generalPractitioner: { id: 0 },
    sex: 0,
    medicareNumber: true,
  },
};
HealthJourney.propTypes = {
  patient: PropTypes.shape(PatientPropType),
  healthJourney: PropTypes.shape({}), // TODO: Update props
  updateHealthJourneyItem: PropTypes.func.isRequired,
  cycleOfCareModuleDefinitions: PropTypes.objectOf(
    PropTypes.shape(CycleOfCareModulePropType)
  ),
  inlineLoading: PropTypes.array,
};

export default HealthJourney;

import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { addProviderError } from 'services/database/actions/providers';
import request from './request';

export default function* addSaga({ data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: 'add-provider' }));
    yield call(request, {
      data,
    });
    yield call(resolve);
  } catch (error) {
    yield put(addProviderError({ error }));
    yield call(reject);
  } finally {
    yield put(setInlineLoadingOff({ data: 'add-provider' }));
  }
}

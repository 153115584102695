import { isUndefined } from 'lodash';
import { Card as MuiCard } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Card = styled(MuiCard)(
  ({
    theme,
    color,
    width,
    height,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    margin,
  }) => ({
    flexGrow: 1,
    display: 'block',
    width: width,
    height: height,
    minWidth: minWidth,
    maxWidth: maxWidth,
    minHeight: minHeight,
    maxHeight: maxHeight,
    margin: margin,
    color: !isUndefined(theme.palette[color])
      ? theme.palette[color].main
      : color,
    ':hover': {
      color: !isUndefined(theme.palette[color])
        ? theme.palette[color].contrastText
        : color,
      'h1, h2, h3, h4, h5, h6, p': {
        color: !isUndefined(theme.palette[color])
          ? theme.palette[color].contrastText
          : color,
      },
      backgroundColor: !isUndefined(theme.palette[color])
        ? theme.palette[color].main
        : color,
      borderColor: `${
        !isUndefined(theme.palette[color]) ? theme.palette[color].main : color
      } !important`,
    },
  })
);

import {
  FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT,
  FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS,
  CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT,
  CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS,
  DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT,
  DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS,
  RESET_PATIENT_CYCLE_OF_CARE_MODULES,
} from './actionNames';

export function fetchPatientCycleOfCareModulesInit(actionParams) {
  return { type: FETCH_PATIENT_CYCLE_OF_CARE_MODULES_INIT, ...actionParams };
}

export function fetchPatientCycleOfCareModulesSuccess({ data }) {
  return { type: FETCH_PATIENT_CYCLE_OF_CARE_MODULES_SUCCESS, data };
}

export function createPatientCycleOfCareModuleInit(actionParams) {
  return { type: CREATE_PATIENT_CYCLE_OF_CARE_MODULE_INIT, ...actionParams };
}

export function createPatientCycleOfCareModuleSuccess({ data }) {
  return { type: CREATE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS, data };
}

export function deletePatientCycleOfCareModuleInit(actionParams) {
  return { type: DELETE_PATIENT_CYCLE_OF_CARE_MODULE_INIT, ...actionParams };
}

export function deletePatientCycleOfCareModuleSuccess({ data }) {
  return { type: DELETE_PATIENT_CYCLE_OF_CARE_MODULE_SUCCESS, data };
}

export function resetPatientCycleOfCareModules() {
  return { type: RESET_PATIENT_CYCLE_OF_CARE_MODULES };
}

import { createSelector } from 'reselect';

const selectProvidersList = (state) => state.providers.providers;
const selectProviderCount = (state) => state.providers.providerCount;
const selectProviderError = (state) => state.providers.errors;

export const providersSelector = createSelector(
  [selectProvidersList],
  (providersList) => providersList
);

export const providerCountSelector = createSelector(
  [selectProviderCount],
  (providerCount) => providerCount
);

export const providerErrorSelector = createSelector(
  [selectProviderError],
  (errors) => errors
);

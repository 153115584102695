import React from 'react';
import { isNull, isUndefined, sortBy, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import {
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';

// select disables selected options
const Select = ({
  label,
  value,
  options,
  variant,
  className,
  margin,
  startAdornment,
  renderValue,
  renderSelectLabel,
  renderSelectSubLabel,
  sortByIndex,
  onSelect, // TODO: rename onChange
  onFocus,
  onBlur,
  onOpen,
  onClose,
  disabled,
  touched,
  errorMessage,
  nullOption,
  autoFocus,
}) => {
  const handleOnChange = ({ target: { value } }) => {
    onSelect(nullOption && value === 'None' ? null : value);
    setTimeout(() => document.activeElement.blur(), 0);
  }; // DISCUSS: why use onSelect for onChange event?

  let availableOptions =
    Object.keys(options).length === 0
      ? [
          <MenuItem key="none" disabled>
            <Typography variant="body1">No options available</Typography>
          </MenuItem>,
        ]
      : sortBy(Object.entries(options), ([optionId, optionValue]) => {
          return !isUndefined(sortByIndex)
            ? toNumber(optionId)
            : renderSelectLabel(optionValue);
        }).map(([optionId, optionValue]) => (
          <MenuItem key={optionId} value={optionId} dense>
            <div>
              <Typography variant="h3" noWrap>
                {renderSelectLabel(optionValue)}
              </Typography>
              {renderSelectSubLabel && (
                <Typography variant="h3" noWrap>
                  {renderSelectSubLabel(optionValue)}
                </Typography>
              )}
            </div>
          </MenuItem>
        ));

  nullOption &&
    Object.keys(options).length > 0 &&
    (availableOptions = [
      <MenuItem key="none" value="None">
        <Typography variant="body1">
          <em>None</em>
        </Typography>
      </MenuItem>,
    ].concat(availableOptions));

  return (
    <FormControl
      variant={variant}
      margin={margin}
      className={className}
      error={touched && errorMessage && true}
    >
      {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
      <MuiSelect
        value={isNull(value) ? (nullOption ? 'None' : '') : value}
        renderValue={renderValue}
        onChange={handleOnChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onOpen={onOpen}
        onClose={onClose}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '12.5rem',
            },
          },
        }}
        autoFocus={autoFocus}
      >
        {availableOptions}
      </MuiSelect>
      {touched && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

Select.defaultProps = {
  variant: 'standard',
  margin: 'normal',
  disabled: false,
};
Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  className: PropTypes.string,
  startAdornment: PropTypes.element,
  renderValue: PropTypes.func,
  renderSelectLabel: PropTypes.func.isRequired,
  sortByIndex: PropTypes.bool,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  errorMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  autoFocus: PropTypes.bool,
};

export default Select;

import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Button } from 'common/components/buttons';
import { DatePicker, Form, RadioGroup } from 'common/components/form';
import {
  CycleOfCareItemPropType,
  PatientCycleOfCareItemPropType,
} from 'common/propTypes';
import { renderObject } from 'common/utils';

const PatientCycleOfCareItemUpdate = ({
  open,
  patientCycleOfCareItems,
  values,
  values: { completedAt },
  errors,
  setValues,
  handleSubmit,
  onClose,
}) => {
  const setCompletedAt = (date) => {
    setValues({ ...values, completedAt: date });
  };

  const handleOnSubmit = () => {
    handleSubmit();
    onClose();
  };

  const options = {
    complete: 'Complete',
    incomplete: 'Never completed',
  };

  const [completionStatus, setCompletionStatus] = useState('complete'),
    handleOnChange = (value) => {
      setCompletionStatus(value);
      value === 'incomplete'
        ? setValues({ ...values, completedAt: null })
        : setValues({ ...values, completedAt: new Date() });
    };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {patientCycleOfCareItems.length === 1
          ? `Update ${patientCycleOfCareItems[0].definition.name} Check`
          : `Update ${patientCycleOfCareItems.length} Checks`}
      </DialogTitle>
      <DialogContent>
        <Form>
          <RadioGroup
            name="completion-status"
            label="Status"
            value={completionStatus}
            onChange={handleOnChange}
            options={options}
            renderLabel={renderObject}
            row
          />
          <Collapse in={completionStatus === 'complete'}>
            <DatePicker
              id="completed-at-date-picker"
              name="completedAt"
              label="Completed At"
              margin={!isEmpty(errors) ? 'normal' : 'dense'}
              value={completedAt}
              onChange={setCompletedAt}
              maxDate={new Date()}
              disabled={false}
            />
          </Collapse>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="close"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        />
        <Button label="update" onClick={handleOnSubmit} />
      </DialogActions>
    </Dialog>
  );
};

PatientCycleOfCareItemUpdate.propTypes = {
  open: PropTypes.bool.isRequired,
  patientCycleOfCareItems: PropTypes.arrayOf(
    PropTypes.shape({
      ...PatientCycleOfCareItemPropType,
      definition: PropTypes.shape(CycleOfCareItemPropType),
    })
  ).isRequired,
  values: PropTypes.shape({
    completedAt: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
  }).isRequired,
  errors: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PatientCycleOfCareItemUpdate;

import React from 'react';
import { useHistory } from 'react-router-dom';

function withRouterProps(OriginalComponent) {
  const Component = ({ ...props }) => {
    let history = useHistory();
    const routerProps = {
      history,
      ...props,
    };
    return <OriginalComponent {...routerProps} />;
  };

  return Component;
}

export default withRouterProps;

import { compose } from 'redux';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchPatientCycleOfCareModulesInit,
  resetPatientCycleOfCareModules,
} from 'services/database/actions/patientCycleOfCareModules';
import PatientCyclesOfCareTab from './PatientCyclesOfCareTab';

export default compose(
  withAction({
    actionName: 'fetchPatientCycleOfCareModules',
    action: fetchPatientCycleOfCareModulesInit,
  }),
  withAction({
    actionName: 'resetPatientCycleOfCareModules',
    action: resetPatientCycleOfCareModules,
  }),
  withPropsMapping((props) => ({
    activePatientCycleOfCareModules: Object.values(
      props.patientCycleOfCareModules
    ).filter((module) => module.isActive),
    ...props,
  }))
)(PatientCyclesOfCareTab);

import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import {
  updatePatientSuccess,
  updatePatientError,
} from 'services/database/actions/patient';
import request from './request';

export default function* updatePatientDataSaga({
  query,
  data,
  resolve,
  reject,
}) {
  try {
    yield put(setInlineLoadingOn({ data: 'update-user' }));
    const responseData = yield call(request, {
      query,
      data,
    });
    yield put(updatePatientSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield put(updatePatientError({ error }));
    yield call(reject, { error: error.data });
  } finally {
    yield put(setInlineLoadingOff({ data: 'update-user' }));
  }
}

import React, { useEffect } from 'react';
import { isEmpty, isNil, isUndefined, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { ExpandableCard } from 'common/components/layouts';
import {
  GoalPropType,
  MeasurementPropType,
  PatientPropType,
  PatientMeasurementPropType,
  HealthPlanPropType,
  HealthPlanGoalPropType,
  HealthPlanMeasurementPropType,
} from 'common/propTypes';
import HealthPlanMeasurement from './components/HealthPlanMeasurement';

const HealthPlanMeasurements = ({
  patient,
  healthPlan,
  healthPlanGoals,
  healthPlanMeasurements,
  fetchHealthPlanMeasurements,
  resetHealthPlanMeasurements,
  fetchPatientMeasurements,
  resetPatientMeasurements,
}) => {
  useEffect(() => {
    // DISCUSS: have had to add this check for the doctor redircting directly to summary page before patient fetched
    !isNil(patient.id) &&
      fetchHealthPlanMeasurements({ query: { patientId: patient.id } });
    return () => resetHealthPlanMeasurements();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    !isNil(patient.id) &&
      fetchPatientMeasurements({ query: { patientId: patient.id } });
    return () => resetPatientMeasurements();
    // eslint-disable-next-line
  }, []);

  const filteredMeasurements = Object.values(healthPlanMeasurements).filter(
    (measurement) =>
      !isUndefined(measurement.lastMeasurement) &&
      measurement.lastMeasurement.healthPlanReview === healthPlan.currentReview
  );

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Typography variant="h2">Baseline Data</Typography>
          <Typography variant="h6" color="textSecondary">
            Measurements: {filteredMeasurements.length}
          </Typography>
        </Box>
      }
    >
      {isEmpty(filteredMeasurements) ? (
        <Typography variant="h4" align="center">
          No health plan measurements have been added
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {sortBy(filteredMeasurements, ['createdAt']).map(
            (healthPlanMeasurement, idx) => {
              const healthPlanMeasurementGoals = healthPlanGoals.filter(
                (healthPlanGoal) =>
                  healthPlanGoal.measurements.includes(
                    healthPlanMeasurement.definition.id
                  )
              );

              return (
                <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
                  <HealthPlanMeasurement
                    healthPlan={healthPlan}
                    healthPlanMeasurement={healthPlanMeasurement}
                    healthPlanGoals={healthPlanMeasurementGoals}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
      )}
    </ExpandableCard>
  );
};

HealthPlanMeasurements.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
  healthPlanMeasurements: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanMeasurementPropType,
      healthPlan: PropTypes.number,
      lastMeasurement: PropTypes.shape(PatientMeasurementPropType),
      definition: PropTypes.shape(MeasurementPropType),
    })
  ),
  fetchHealthPlanMeasurements: PropTypes.func.isRequired,
  resetHealthPlanMeasurements: PropTypes.func.isRequired,
  fetchPatientMeasurements: PropTypes.func.isRequired,
  resetPatientMeasurements: PropTypes.func.isRequired,
};

export default HealthPlanMeasurements;

import React, { useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ClickableCard, FlexBox } from 'common/components/layouts';
import { PROVIDER_GROUPS, PROVIDER_TYPE_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanServicePropType,
  HealthPlanPropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';
import UpdateHealthPlanService from './components/UpdateHealthPlanService';

const HealthPlanService = ({
  healthPlan,
  healthPlanService,
  goalDefinitions,
  readOnly,
  loading,
}) => {
  const [showUpdate, setShowUpdate] = useState(false),
    handleToggleUpdate = () => {
      setShowUpdate(!showUpdate);
    };

  const numberOfTasks = healthPlanService.tasks.length,
    numberOfVisits = healthPlanService.numberOfVisits;

  return (
    <>
      {showUpdate && (
        <UpdateHealthPlanService
          open={showUpdate && isEmpty(loading)}
          healthPlan={healthPlan}
          healthPlanService={healthPlanService}
          goalDefinitions={goalDefinitions}
          onClose={handleToggleUpdate}
          readOnly={readOnly}
        />
      )}
      <ClickableCard color="primary" onClick={handleToggleUpdate}>
        <FlexBox
          height="100%"
          minHeight="5rem"
          flexDirection="column"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Box>
            <Typography variant="h6" color="primary">
              {healthPlanService.definition.providerTypes.map(
                (providerType) => PROVIDER_TYPE_NAMES[providerType]
              )}
            </Typography>
            <Typography
              variant="h2"
              color={
                !isUndefined(healthPlanService.provider)
                  ? 'textPrimary'
                  : 'textSecondary'
              }
            >
              {!isUndefined(healthPlanService.provider)
                ? renderFullName(healthPlanService.provider)
                : 'No Provider Selected'}
            </Typography>
          </Box>
          {healthPlanService.definition.providerGroup ===
          PROVIDER_GROUPS.PHARMACIST ? (
            <Typography variant="h6" color="textSecondary">
              Health Services: 1
            </Typography>
          ) : (
            <Box>
              <Typography
                variant="h6"
                color={numberOfTasks > 0 ? 'textSecondary' : 'error'}
              >
                Health Tasks: {numberOfTasks}
              </Typography>
              <Typography
                variant="h6"
                color={numberOfVisits > 0 ? 'textSecondary' : 'error'}
              >
                Number Of Visits: {numberOfVisits}
              </Typography>
            </Box>
          )}
        </FlexBox>
      </ClickableCard>
    </>
  );
};

HealthPlanService.defaultProps = {};
HealthPlanService.propTypes = {
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanService: PropTypes.shape({
    ...HealthPlanServicePropType,
    healthPlan: PropTypes.number.isRequired,
    definition: PropTypes.shape(ServicePropType),
  }).isRequired,
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanService;

export const FETCH_PROVIDERS_INIT = 'FETCH_PROVIDERS_INIT';
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS';

export const ADD_PROVIDER_INIT = 'ADD_PROVIDER_INIT';
export const ADD_PROVIDER_ERROR = 'ADD_PROVIDER_ERROR';

export const UPDATE_PROVIDER_INIT = 'UPDATE_PROVIDER_INIT';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_ERROR = 'UPDATE_PROVIDER_ERROR';

export const RESET_PROVIDERS = 'RESET_PROVIDERS';

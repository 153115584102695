import {
  FETCH_HEALTH_PLAN_FEEDBACK_INIT,
  FETCH_HEALTH_PLAN_FEEDBACK_SUCCESS,
  RESET_HEALTH_PLAN_FEEDBACK,
} from './actionNames';

export function fetchHealthPlanFeedbackInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_FEEDBACK_INIT, ...actionParams };
}

export function fetchHealthPlanFeedbackSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_FEEDBACK_SUCCESS, data };
}

export function resetHealthPlanFeedback(actionParams) {
  return { type: RESET_HEALTH_PLAN_FEEDBACK, actionParams };
}

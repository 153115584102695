import React from 'react';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Collapse, Typography } from '@mui/material';

// display error message or have div that takes up the space
const FormGeneralErrorMessage = ({ errorMessage }) => {
  return (
    <Collapse in={!isUndefined(errorMessage)} timeout="auto" unmountOnExit>
      <Typography variant="subtitle1" color="error">
        {errorMessage}
      </Typography>
    </Collapse>
  );
};

FormGeneralErrorMessage.defaultProps = {};
FormGeneralErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

export default FormGeneralErrorMessage;

import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/patient/fetch';
import addSaga from 'services/backend/actions/patient/add';
import updateSaga from 'services/backend/actions/patient/update';
import updatePasswordSaga from 'services/backend/actions/patient/updatePassword';
import {
  FETCH_PATIENT_INIT,
  ADD_PATIENT_INIT,
  UPDATE_PATIENT_INIT,
  UPDATE_PATIENT_PASSWORD_INIT,
} from './actionNames';

function* patientFlowSaga() {
  yield all([
    takeLatest(FETCH_PATIENT_INIT, fetchSaga),
    takeLatest(ADD_PATIENT_INIT, addSaga),
    takeLatest(UPDATE_PATIENT_INIT, updateSaga),
    takeLatest(UPDATE_PATIENT_PASSWORD_INIT, updatePasswordSaga),
  ]);
}

export default patientFlowSaga;

export default class ErrorWithData extends Error {
  constructor(data, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorWithData);
    }
    this.name = 'ErrorWithData';
    this.data = data;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { FlexBox } from 'common/components/layouts';
import { Box as StyledBox } from './styles';

const TitleBox = ({ contentLeft, contentRight }) => (
  <FlexBox justifyContent="space-between" alignItems="center" flexWrap="nowrap">
    <StyledBox>{contentLeft}</StyledBox>
    {contentRight}
  </FlexBox>
);

TitleBox.defaultProps = {};
TitleBox.propTypes = {
  contentLeft: PropTypes.node.isRequired,
  contentRight: PropTypes.node,
};

export default TitleBox;

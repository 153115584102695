import { Box as MuiBox, Checkbox as MuiCheckbox } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Box = styled(MuiBox)(({ theme, padding }) => ({
  alignSelf: 'start',
  padding: () => {
    switch (padding) {
      default:
      case 'normal':
        return theme.spacing(1);
      case 'dense':
        return theme.spacing(0.25);
      case 'none':
        return 0;
    }
  },
  paddingLeft: 0,
}));

export const Checkbox = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ theme, padding, error }) => ({
  alignSelf: 'start',
  padding: () => {
    switch (padding) {
      default:
      case 'normal':
        return theme.spacing(1);
      case 'dense':
        return theme.spacing(0.25);
      case 'none':
        return 0;
    }
  },
  paddingLeft: 0,
  color: error && theme.palette.error.main,
}));

import React, { useEffect } from 'react';
import { isEmpty, isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Card, ExpandableCard } from 'common/components/layouts';
import {
  ConditionsPropType,
  PatientConditionsPropType,
  PatientPropType,
} from 'common/propTypes';

const PatientConditions = ({
  patient,
  patientConditions,
  fetchPatientConditions,
  resetPatientConditions,
}) => {
  useEffect(() => {
    !isNil(patient.id) &&
      fetchPatientConditions({ query: { patientId: patient.id } });
    return () => resetPatientConditions();
    // eslint-disable-next-line
  }, []);

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Typography variant="h2">Existing Conditions</Typography>
          <Typography variant="h6" color="textSecondary">
            Conditions: {patientConditions.length}
          </Typography>
        </Box>
      }
    >
      {isEmpty(patientConditions) ? (
        <Typography variant="h4" align="center">
          No patient conditions have been added
        </Typography>
      ) : (
        sortBy(patientConditions, ['createdAt']).map(
          (patientCondition, idx) => (
            <Card key={idx}>
              <Typography variant="h5" color="textSecondary">
                Condition #{idx + 1}
              </Typography>
              <Typography variant="h2">{patientCondition.name}</Typography>
            </Card>
          )
        )
      )}
    </ExpandableCard>
  );
};

PatientConditions.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  patientConditions: PropTypes.arrayOf(
    PropTypes.shape({ ...ConditionsPropType, ...PatientConditionsPropType })
  ),
  fetchPatientConditions: PropTypes.func.isRequired,
  resetPatientConditions: PropTypes.func.isRequired,
};

export default PatientConditions;

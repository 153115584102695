import React from 'react';
import PropTypes from 'prop-types';
import { FlexBox, GoalChips } from 'common/components/layouts';
import { ToggleButton } from 'common/components/buttons';
import { handleSubmit as handleCreate } from 'common/data/healthPlanServices/createWithoutFormik';
import { handleSubmit as handleDelete } from 'common/data/healthPlanServices/delete';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanPropType,
  HealthPlanGoalPropType,
  HealthPlanServicePropType,
} from 'common/propTypes';

const HealthPlanService = ({
  healthPlan,
  selectedService,
  service,
  selected,
  excluded,
  healthPlanServiceGoals,
  createHealthPlanService,
  deleteHealthPlanService,
  inlineLoading,
  readOnly,
}) => {
  const handleOnClick = () => {
    selected
      ? handleDelete({
          healthPlanService: selectedService,
          deleteHealthPlanService,
        })
      : handleCreate({ healthPlan, service, createHealthPlanService });
  };

  return (
    <FlexBox flexDirection="column" mb="1rem" mx="0.5rem">
      {/* {inlineLoading.includes(service.id) && <ComponentLoader />} */}
      <FlexBox pb={0.5}>
        <GoalChips goals={healthPlanServiceGoals} dense />
        {/* DISCUSS: What was this section doing?
        {assignedService &&
          shouldDisplayAssignedHealthServiceStatusForm({
            assignedService,
          }) && (
            <StyledStatusForm>
              <UpdateHealthServiceStatusForm
                readOnly={withStatusUpdate ? false : readOnly}
                patientId={patientId}
                assignedService={assignedService}
              />
            </StyledStatusForm>
          )}
        */}
      </FlexBox>

      <ToggleButton
        label={service.name}
        selected={selected}
        onClick={handleOnClick}
        disabled={excluded}
        loading={inlineLoading.includes(service.id)}
        readOnly={readOnly}
        dense
      />
    </FlexBox>
  );
};

HealthPlanService.defaultProps = {
  inlineLoading: [],
};
HealthPlanService.propTypes = {
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  service: PropTypes.shape(ServicePropType).isRequired,
  selectedService: PropTypes.shape({
    ...HealthPlanServicePropType,
    healthPlan: PropTypes.number.isRequired,
    definition: PropTypes.shape(ServicePropType),
  }),
  selected: PropTypes.bool.isRequired,
  excluded: PropTypes.bool.isRequired,
  healthPlanServiceGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
  createHealthPlanService: PropTypes.func.isRequired,
  deleteHealthPlanService: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanService;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isUndefined } from 'lodash';
import { Typography } from '@mui/material';
import { ToggleButton } from 'common/components/buttons';
import { FlexBox } from 'common/components/layouts';
import { handleSubmit as handleCreate } from 'common/data/goalProgressBarriers/create';
import { handleSubmit as handleDelete } from 'common/data/goalProgressBarriers/delete';
import {
  BarrierPropType,
  GoalPropType,
  SolutionPropType,
  GoalProgressBarrierPropType,
  GoalProgressReviewPropType,
} from 'common/propTypes';
import GoalProgressSolution from './components/GoalProgressSolution';
import { GoalProgressSolutionsDiv as StyledGoalProgressSolutionsDiv } from './styles';

const GoalProgressBarrier = ({
  goalProgressReview,
  goalProgressBarrier,
  barrier,
  solutions,
  createGoalProgressBarrier,
  deleteGoalProgressBarrier,
  inlineLoading,
  disabled,
  readOnly,
}) => {
  const selected = !isUndefined(goalProgressBarrier);

  const handleOnClick = () => {
    selected
      ? handleDelete({
          goalProgressBarrier,
          deleteGoalProgressBarrier,
        })
      : handleCreate({
          goalProgressReview,
          barrier,
          createGoalProgressBarrier,
        });
  };

  return (
    <FlexBox flexDirection="column">
      <ToggleButton
        label={barrier.name}
        selected={selected}
        onClick={handleOnClick}
        loading={inlineLoading.includes(barrier.id)}
        disabled={disabled}
        readOnly={readOnly}
      />
      {selected && !isEmpty(solutions) && !inlineLoading.includes(barrier.id) && (
        <StyledGoalProgressSolutionsDiv>
          <Typography variant="h6">Solutions</Typography>
          {solutions.map((solution, idx) => {
            const goalProgressSolution = Object.values(
              goalProgressBarrier.goalProgressSolutions
            ).find(
              (goalProgressSolution) =>
                goalProgressSolution.definition === solution.id
            );

            return (
              <GoalProgressSolution
                key={idx}
                goalProgressBarrier={goalProgressBarrier}
                goalProgressSolution={goalProgressSolution}
                solution={solution}
                inlineLoading={inlineLoading}
                disabled={disabled}
                readOnly={readOnly}
              />
            );
          })}
        </StyledGoalProgressSolutionsDiv>
      )}
    </FlexBox>
  );
};

GoalProgressBarrier.defaultProps = {
  solutions: [],
};
GoalProgressBarrier.propTypes = {
  goalProgressReview: PropTypes.shape({
    ...GoalProgressReviewPropType,
    goalDefinition: PropTypes.shape(GoalPropType),
  }).isRequired,
  goalProgressBarrier: PropTypes.shape(GoalProgressBarrierPropType),
  barrier: PropTypes.shape(BarrierPropType).isRequired,
  solutions: PropTypes.arrayOf(PropTypes.shape(SolutionPropType)),
  createGoalProgressBarrier: PropTypes.func.isRequired,
  deleteGoalProgressBarrier: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default GoalProgressBarrier;

import React, { useState, useEffect } from 'react';
import { isEmpty, sortBy, size } from 'lodash';
import PropTypes from 'prop-types';
import {
  Pagination,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { PROVIDER_GROUPS } from 'common/constants';
import { Button } from 'common/components/buttons';
import { FlexBox, TitleBox, NoResults } from 'common/components/layouts';
import { useDebounce } from 'common/hooks';
import { ProviderPropType } from 'common/propTypes';
import Search from './components/Search';
import Provider from './components/Provider';
import AddProvider from './components/AddProvider';
import UpdateProvider from './components/UpdateProvider';

const ProvidersPage = ({
  providers,
  fetchProviders,
  resetProviders,
  providerCount,
  loading,
  inlineLoading,
  history,
}) => {
  const PATIENTS_PER_PAGE = 10;

  // TODO: Improve how search delay is handled?
  const [currentPageNumber, setCurrentPageNumber] = useState(1),
    [pageNumbers, setPageNumbers] = useState(1),
    [searchQuery, setSearchQuery] = useState(''),
    tempDebouncedSearchQuery = useDebounce(searchQuery, 1000),
    [debouncedSearchQuery, setDebouncedSearchQuery] = useState(''),
    [finalSearchQuery, setFinalSearchQuery] = useState('');

  const displayDict = {
      all: {
        label: 'All',
        color: 'success',
        providerGroup: undefined,
        noResults: {
          title: 'No Results Found',
          data: 'Try adjusting your search or filters to find what you’re looking for.',
        },
      },
      practice: {
        label: 'Practice Staff',
        color: 'primary',
        providerGroup: PROVIDER_GROUPS.PRACTICE_STAFF,
        noResults: {
          title: 'No Practice Staff',
          data: 'Try adjusting your search or filters to find what you’re looking for.',
        },
      },
      pharmacist: {
        label: 'Pharmacists',
        color: 'primary',
        providerGroup: PROVIDER_GROUPS.PHARMACIST,
        noResults: {
          title: 'No Pharmacist Providers',
          data: 'Try adjusting your search or filters to find what you’re looking for.',
        },
      },
      alliedHealth: {
        label: 'Allied Health',
        color: 'primary',
        providerGroup: PROVIDER_GROUPS.ALLIED_HEALTH_PROFESSIONAL,
        noResults: {
          title: 'No Allied Health Providers',
          data: 'Try adjusting your search or filters to find what you’re looking for.',
        },
      },
      specialist: {
        label: 'Specialists',
        color: 'primary',
        providerGroup: PROVIDER_GROUPS.SPECIALIST,
        noResults: {
          title: 'No Specialist Providers',
          data: 'Try adjusting your search or filters to find what you’re looking for.',
        },
      },
    },
    [providerGroup, setProviderGroup] = useState(
      displayDict['all'].providerGroup
    ),
    providerGroupObject = Object.values(displayDict).find(
      (displayObject) => displayObject.providerGroup === providerGroup
    );

  // BUG: The patients response data contains previous & next urls to use
  // Surely not the best way to deal with page number reset on search
  // Issue is that if on page 2 and run a search with 1 reset, fetch will go off with page=2?search="Angus", so have no results
  // Data flow:
  // Type in search and don't press enter:
  // - updates searchQuery, which runs useDebounce for the temp value.
  // - then updates debouncedSearchQuery then finally the finalSearch query
  // Type in search and press enter:
  // - jumps straight to setDebouncedSearchQuery and then sets finalSearchQuery
  // Method is required so can search from any page and either let the key release or onEnter run the search
  // sets the debounced search query
  // No change in searchQuery so nav through pages doesn't call these useEffects
  useEffect(() => {
    setDebouncedSearchQuery(tempDebouncedSearchQuery);
  }, [tempDebouncedSearchQuery]);

  // resets the filter to all, page number to 1 and sets the final search value
  useEffect(() => {
    setProviderGroup(undefined);
    setCurrentPageNumber(1);
    setFinalSearchQuery(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setPageNumbers(Math.ceil(providerCount / PATIENTS_PER_PAGE));
  }, [providerCount, PATIENTS_PER_PAGE]);

  const listHeadings = [
    'First Name',
    'Last Name',
    'Provider Group',
    'Provider Type',
  ];

  const handleOnClick = (displayProviderGroup) => () => {
    if (providerGroup !== displayProviderGroup) {
      setProviderGroup(displayProviderGroup);
      setCurrentPageNumber(1);
    }
  };

  const [showAddProvider, setShowAddProvider] = useState(false),
    [refetchToggle, setRefetchToggle] = useState(false),
    handleToggleAddProvider = () => {
      setShowAddProvider(!showAddProvider);
    },
    handleAddProviderSuccess = () => {
      // refetchToggle required after provider added to force useEffect to be called again
      setRefetchToggle(!refetchToggle);
      handleToggleAddProvider();
    };

  const [showUpdateProvider, setShowUpdateProvider] = useState(false),
    [selectedProvider, setSelectedProvider] = useState(null),
    handleToggleUpdateProvider = () => {
      setShowUpdateProvider(!showUpdateProvider);
    },
    handleOpenUpdateProvider = (provider) => (e) => {
      setShowUpdateProvider(!showUpdateProvider);
      setSelectedProvider(provider);
    };

  // Search filtering done on backend
  useEffect(() => {
    fetchProviders({
      query: {
        page: currentPageNumber,
        search: finalSearchQuery,
        providerGroup,
      },
    });
    return () => resetProviders();
  }, [
    refetchToggle, //forces refetch when toggled true/false
    currentPageNumber,
    finalSearchQuery,
    fetchProviders,
    resetProviders,
    providerGroup,
  ]);

  return (
    <>
      <TitleBox
        contentLeft={
          <>
            <FlexBox alignItems="baseline">
              <Typography variant="h1" style={{ marginRight: '2.5rem' }}>
                Providers
              </Typography>
              <Typography variant="h3" color="textSecondary">
                {providerCount} Total
              </Typography>
            </FlexBox>
            <Button
              label="add provider"
              variant="outlined"
              size="small"
              onClick={handleToggleAddProvider}
            />
          </>
        }
        contentRight={
          <FlexBox flexDirection="column" alignItems="end">
            <Search
              name="search"
              placeholder="Search provider list"
              search={searchQuery}
              onChange={setSearchQuery}
              onEnter={setDebouncedSearchQuery}
              onSubmit={setSearchQuery}
            />
            <FlexBox>
              {Object.values(displayDict).map(
                ({
                  label: displayLabel,
                  color: displayColor,
                  providerGroup: displayProviderGroup,
                }) => (
                  <Button
                    key={displayLabel}
                    label={displayLabel}
                    color={displayColor}
                    variant={
                      providerGroup === displayProviderGroup
                        ? 'contained'
                        : 'outlined'
                    }
                    size="small"
                    onClick={handleOnClick(displayProviderGroup)}
                  />
                )
              )}
            </FlexBox>
          </FlexBox>
        }
      />

      {isEmpty(loading) && providerCount === 0 ? (
        <NoResults
          title={providerGroupObject.noResults.title}
          data={providerGroupObject.noResults.data}
        />
      ) : (
        <>
          <Table sx={{ marginBottom: '3rem' }}>
            <TableHead>
              <TableRow>
                {listHeadings.map((heading, idx) => (
                  <TableCell key={idx}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(sortBy(providers, ['lastName', 'firstName'])).map(
                (provider, idx) => (
                  <Provider
                    key={idx}
                    provider={provider}
                    history={history}
                    onClick={handleOpenUpdateProvider(provider)}
                  />
                )
              )}
            </TableBody>
          </Table>

          {providerCount > size(providers) && (
            <Pagination
              color="primary"
              count={pageNumbers}
              page={currentPageNumber}
              onChange={(event, value) => setCurrentPageNumber(value)}
              siblingCount={1}
              boundaryCount={1}
            />
          )}
        </>
      )}
      {showAddProvider && (
        <AddProvider
          open={showAddProvider}
          onSuccess={handleAddProviderSuccess}
          onClose={handleToggleAddProvider}
          inlineLoading={inlineLoading}
        />
      )}
      {showUpdateProvider && (
        <UpdateProvider
          provider={selectedProvider}
          open={showUpdateProvider}
          onClose={handleToggleUpdateProvider}
          inlineLoading={inlineLoading}
        />
      )}
    </>
  );
};

ProvidersPage.defaultProps = {
  providers: {},
  providerCount: 0,
  loading: [],
  history: {},
};
ProvidersPage.propTypes = {
  providers: PropTypes.objectOf(PropTypes.shape(ProviderPropType)),
  fetchProviders: PropTypes.func.isRequired,
  resetProviders: PropTypes.func.isRequired,
  providerCount: PropTypes.number,
  loading: PropTypes.array,
  history: PropTypes.object,
};

export default ProvidersPage;

import { put, call } from 'redux-saga/effects';
import { fetchNotificationsSuccess } from 'services/database/actions/notifications';
import request from './request';

export default function* fetchSaga({ resolve, reject }) {
  try {
    const responseData = yield call(request);
    yield put(fetchNotificationsSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

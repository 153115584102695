import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import {
  addPatientError,
  addPatientSuccess,
} from 'services/database/actions/patient';
import request from './request';

export default function* addSaga({ data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: 'add-patient' }));
    const responseData = yield call(request, {
      data,
    });
    yield put(addPatientSuccess({ data: responseData }));
    yield call(resolve, { responseData });
  } catch (error) {
    yield put(addPatientError({ error }));
    yield call(reject);
  } finally {
    yield put(setInlineLoadingOff({ data: 'add-patient' }));
  }
}

import { put, call } from 'redux-saga/effects';

import { downloadHealthPlanSuccess } from 'services/database/actions/healthPlans';
import request from './request';

export default function* downloadSaga({ query, resolve, reject }) {
  try {
    const responseData = yield call(request, { query });
    yield put(downloadHealthPlanSuccess());
    yield call(resolve, { download: responseData });
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import { TableRow as StyledTableRow } from './styles';

const TableRow = ({ children, color, onClick, dense, disabled, ...props }) => (
  <StyledTableRow
    onClick={onClick}
    color={color}
    dense={dense}
    clickable={!disabled && !isUndefined(onClick)}
    {...props}
  >
    {children}
  </StyledTableRow>
);

TableRow.defaultProps = {
  dense: false,
  disabled: false,
};
TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TableRow;

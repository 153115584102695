import {
  FETCH_HEALTH_PLAN_SERVICES_INIT,
  FETCH_HEALTH_PLAN_SERVICES_SUCCESS,
  CREATE_HEALTH_PLAN_SERVICE_INIT,
  CREATE_HEALTH_PLAN_SERVICE_SUCCESS,
  UPDATE_HEALTH_PLAN_SERVICE_INIT,
  UPDATE_HEALTH_PLAN_SERVICE_SUCCESS,
  DELETE_HEALTH_PLAN_SERVICE_INIT,
  DELETE_HEALTH_PLAN_SERVICE_SUCCESS,
  RESET_HEALTH_PLAN_SERVICES,
} from './actionNames';

export function fetchHealthPlanServicesInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_SERVICES_INIT, ...actionParams };
}

export function fetchHealthPlanServicesSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_SERVICES_SUCCESS, data };
}

export function createHealthPlanServiceInit(actionParams) {
  return { type: CREATE_HEALTH_PLAN_SERVICE_INIT, ...actionParams };
}

export function createHealthPlanServiceSuccess({ data }) {
  return { type: CREATE_HEALTH_PLAN_SERVICE_SUCCESS, data };
}

export function updateHealthPlanServiceInit(actionParams) {
  return { type: UPDATE_HEALTH_PLAN_SERVICE_INIT, ...actionParams };
}

export function updateHealthPlanServiceSuccess(actionParams) {
  return { type: UPDATE_HEALTH_PLAN_SERVICE_SUCCESS, ...actionParams };
}

export function deleteHealthPlanServiceInit(actionParams) {
  return { type: DELETE_HEALTH_PLAN_SERVICE_INIT, ...actionParams };
}

export function deleteHealthPlanServiceSuccess({ data }) {
  return { type: DELETE_HEALTH_PLAN_SERVICE_SUCCESS, data };
}

export function resetHealthPlanServices(actionParams) {
  return { type: RESET_HEALTH_PLAN_SERVICES, ...actionParams };
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import { mapPropsToValues, handleSubmit } from 'common/data/healthPlans/update';
import { updateHealthPlanInit } from 'services/database/actions/healthPlans';
import UpdateStatusButton from './UpdateStatusButton';

export default compose(
  withAction({
    actionName: 'updateHealthPlan',
    action: updateHealthPlanInit,
  }),
  connect(() => ({
    formikFields: ['status'],
  })),
  withFormik({ mapPropsToValues, handleSubmit, enableReinitialize: true })
)(UpdateStatusButton);

export const FETCH_HEALTH_PLAN_REVIEWS_INIT = 'FETCH_HEALTH_PLAN_REVIEWS_INIT';
export const FETCH_HEALTH_PLAN_REVIEWS_SUCCESS =
  'FETCH_HEALTH_PLAN_REVIEWS_SUCCESS';

export const CREATE_HEALTH_PLAN_REVIEW_INIT = 'CREATE_HEALTH_PLAN_REVIEW_INIT';
export const CREATE_HEALTH_PLAN_REVIEW_SUCCESS =
  'CREATE_HEALTH_PLAN_REVIEW_SUCCESS';

export const UPDATE_HEALTH_PLAN_REVIEW_INIT = 'UPDATE_HEALTH_PLAN_REVIEW_INIT';
export const UPDATE_HEALTH_PLAN_REVIEW_SUCCESS =
  'UPDATE_HEALTH_PLAN_REVIEW_SUCCESS';
export const UPDATE_HEALTH_PLAN_REVIEW_ERROR =
  'UPDATE_HEALTH_PLAN_REVIEW_ERROR';

export const RESET_HEALTH_PLAN_REVIEWS = 'RESET_HEALTH_PLAN_REVIEWS';

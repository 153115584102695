export const FETCH_HEALTH_PLAN_MEASUREMENTS_INIT =
  'FETCH_HEALTH_PLAN_MEASUREMENTS_INIT';
export const FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS =
  'FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS';

export const CREATE_HEALTH_PLAN_MEASUREMENT_INIT =
  'CREATE_HEALTH_PLAN_MEASUREMENT_INIT';
export const CREATE_HEALTH_PLAN_MEASUREMENT_SUCCESS =
  'CREATE_HEALTH_PLAN_MEASUREMENT_SUCCESS';

export const UPDATE_HEALTH_PLAN_MEASUREMENT_INIT =
  'UPDATE_HEALTH_PLAN_MEASUREMENT_INIT';
export const UPDATE_HEALTH_PLAN_MEASUREMENT_SUCCESS =
  'UPDATE_HEALTH_PLAN_MEASUREMENT_SUCCESS';

export const DELETE_HEALTH_PLAN_MEASUREMENT_INIT =
  'DELETE_HEALTH_PLAN_MEASUREMENT_INIT';
export const DELETE_HEALTH_PLAN_MEASUREMENT_SUCCESS =
  'DELETE_HEALTH_PLAN_MEASUREMENT_SUCCESS';

export const RESET_HEALTH_PLAN_MEASUREMENTS = 'RESET_HEALTH_PLAN_MEASUREMENTS';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  withIsAuthenticated,
  withAction,
  withAuthenticatedUser,
  withFullPageLayout,
} from 'common/HOCs';
import {
  fetchPatientsInit,
  resetPatients,
  patientListSelector,
  patientCountSelector,
} from 'services/database/actions/patients';
import PatientsPage from './PatientsPage';

export default compose(
  withIsAuthenticated,
  withAuthenticatedUser,
  withAction({
    actionName: 'fetchPatients',
    action: fetchPatientsInit,
  }),
  withAction({
    actionName: 'resetPatients',
    action: resetPatients,
  }),
  connect((state) => ({
    patients: patientListSelector(state),
    patientCount: patientCountSelector(state),
  })),
  withFullPageLayout
)(PatientsPage);

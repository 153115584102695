import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlans/fetch';
import createSaga from 'services/backend/actions/healthPlans/create';
import updateSaga from 'services/backend/actions/healthPlans/update';
import downloadSaga from 'services/backend/actions/healthPlans/download';
import {
  CREATE_HEALTH_PLAN_INIT,
  FETCH_HEALTH_PLAN_INIT,
  UPDATE_HEALTH_PLAN_INIT,
  DOWNLOAD_HEALTH_PLAN_INIT,
} from './actionNames';

function* healthPlanFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_PLAN_INIT, fetchSaga),
    takeLatest(CREATE_HEALTH_PLAN_INIT, createSaga),
    takeLatest(UPDATE_HEALTH_PLAN_INIT, updateSaga),
    takeLatest(DOWNLOAD_HEALTH_PLAN_INIT, downloadSaga),
  ]);
}

export default healthPlanFlowSaga;

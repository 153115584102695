import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/material/styles';

const CenteredBox = styled(MuiBox)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export default CenteredBox;

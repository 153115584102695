export default function (
  values,
  { props: { patient, createHealthJourney, handleContinue } }
) {
  const handleOnSuccess = () => {
    handleContinue();
  };
  return createHealthJourney({
    query: { patientId: patient.id },
    data: { patient: patient.id, ...values },
  }).then(handleOnSuccess);
}

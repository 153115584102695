import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'common/utils/ErrorBoundary';
import paths from 'routing/paths';
import LoginPage from 'pages/login';
import DashboardPage from 'pages/dashboard';
// import NotificationsPage from 'pages/notifications';
import SettingsPage from 'pages/settings';
import ProvidersPage from 'pages/providers';
import PatientsPage from 'pages/patients';
import PatientPage from 'pages/patient';
import PatientHealthPlanPage from 'pages/healthPlan';
import Http404Page from 'pages/http404';

const Router = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <Switch>
        <Route exact path={paths.LOGIN} component={LoginPage} />
        <Route exact path={paths.DASHBOARD} component={DashboardPage} />
        {/* <Route exact path={paths.NOTIFICATIONS} component={NotificationsPage} /> */}
        <Route exact path={paths.SETTINGS} component={SettingsPage} />
        <Route exact path={paths.PROVIDERS} component={ProvidersPage} />
        <Route exact path={paths.PATIENTS} component={PatientsPage} />
        <Route exact path={paths.PATIENT_DASHBOARD} component={PatientPage} />
        <Route
          exact
          path={paths.PATIENT_HEALTH_PLAN}
          component={PatientHealthPlanPage}
        />
        <Route component={Http404Page} />
      </Switch>
    </ErrorBoundary>
  </BrowserRouter>
);

export default Router;

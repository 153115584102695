import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withAction } from 'common/HOCs';
import {
  fetchHealthPlanReviewsInit,
  healthPlanReviewsSelector,
  resetHealthPlanReviews,
} from 'services/database/actions/healthPlanReviews';
import getComponentDisplayName from './utils/getComponentDisplayName';

function withHealthPlanReviews(OriginalComponent) {
  const Component = ({
    healthPlanReviews,
    fetchHealthPlanReviewsInit,
    resetHealthPlanReviews,
    ...props
  }) => {
    const { patientId } = useParams();

    useEffect(() => {
      fetchHealthPlanReviewsInit({ query: { healthPlanId: patientId } });
      return () => resetHealthPlanReviews();
    }, [patientId, fetchHealthPlanReviewsInit, resetHealthPlanReviews]);

    return (
      <OriginalComponent healthPlanReviews={healthPlanReviews} {...props} />
    );
  };

  Component.defaultProps = {};
  Component.propTypes = {
    healthPlanReviews: PropTypes.object,
    fetchHealthPlanReviewsInit: PropTypes.func,
    resetHealthPlanReviews: PropTypes.func,
  };
  Component.displayName = `withHealthPlanReviews(${getComponentDisplayName(
    OriginalComponent
  )})`;

  const Container = compose(
    withAction({
      actionName: 'fetchHealthPlanReviewsInit',
      action: fetchHealthPlanReviewsInit,
    }),
    withAction({
      actionName: 'resetHealthPlanReviews',
      action: resetHealthPlanReviews,
    }),
    connect((state) => ({
      healthPlanReviews: healthPlanReviewsSelector(state),
    }))
  )(Component);

  return Container;
}

export default withHealthPlanReviews;

import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlanGoals/fetch';
import createSaga from 'services/backend/actions/healthPlanGoals/create';
import deleteSaga from 'services/backend/actions/healthPlanGoals/delete';
import {
  FETCH_HEALTH_PLAN_GOALS_INIT,
  CREATE_HEALTH_PLAN_GOAL_INIT,
  DELETE_HEALTH_PLAN_GOAL_INIT,
} from './actionNames';

function* healthGoalsFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_PLAN_GOALS_INIT, fetchSaga),
    takeLatest(CREATE_HEALTH_PLAN_GOAL_INIT, createSaga),
    takeLatest(DELETE_HEALTH_PLAN_GOAL_INIT, deleteSaga),
  ]);
}

export default healthGoalsFlowSaga;

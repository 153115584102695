import PropTypes from 'prop-types';
import UserPracticePropType from './UserPracticePropType';

export default {
  id: PropTypes.number,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userType: PropTypes.number,
  providerType: PropTypes.number,
  practices: PropTypes.arrayOf(PropTypes.shape(UserPracticePropType)),
};

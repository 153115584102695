import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction } from 'common/HOCs';
import {
  fetchHealthPlanReviewsInit,
  resetHealthPlanReviews,
  healthPlanPreviousReviewsSelector,
  healthPlanCurrentReviewSelector,
} from 'services/database/actions/healthPlanReviews';
import { goalDefinitionsSelector } from 'services/database/actions/definitions';
import GoalProgressReviews from './GoalProgressReviews';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanReviews',
    action: fetchHealthPlanReviewsInit,
  }),
  withAction({
    actionName: 'resetHealthPlanReviews',
    action: resetHealthPlanReviews,
  }),
  connect((state) => ({
    currentHealthPlanReview: healthPlanCurrentReviewSelector(state),
    previousHealthPlanReviews: healthPlanPreviousReviewsSelector(state),
    goalDefinitions: goalDefinitionsSelector(state),
  }))
)(GoalProgressReviews);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { Button } from 'common/components/buttons';
import { Form, RadioGroup, SelectField } from 'common/components/form';
import { PROVIDER_GROUPS, PROVIDER_GROUP_NAMES } from 'common/constants';
import { ServicePropType } from 'common/propTypes';
import { renderObject } from 'common/utils';

const HealthPlanServiceAdd = ({
  open,
  availableHealthPlanServices,
  values,
  values: { definitionId },
  setValues,
  handleSubmit,
  onClose,
}) => {
  const [provider, setProvider] = useState(
    PROVIDER_GROUPS.ALLIED_HEALTH_PROFESSIONAL
  );

  const handleChange = (value) => {
    setProvider(Number(value));
    setValues({
      ...values,
      definitionId: null,
    });
  };

  const radioOptions = {
      [PROVIDER_GROUPS.ALLIED_HEALTH_PROFESSIONAL]:
        PROVIDER_GROUP_NAMES[PROVIDER_GROUPS.ALLIED_HEALTH_PROFESSIONAL],
      [PROVIDER_GROUPS.SPECIALIST]:
        PROVIDER_GROUP_NAMES[PROVIDER_GROUPS.SPECIALIST],
    },
    selectOptions = availableHealthPlanServices
      .filter((service) => service.providerGroup === provider)
      .reduce((acc, cur) => {
        acc[cur.id] = cur.name;
        return acc;
      }, {});

  const handleOnSubmit = () => {
    handleSubmit();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Form>
          <RadioGroup
            name="provider"
            label="Provider Type"
            value={provider.toString()}
            options={radioOptions}
            renderLabel={renderObject}
            onChange={handleChange}
            row
          />
          <SelectField
            label="Service"
            name="definitionId"
            value={definitionId}
            options={selectOptions}
            renderSelectLabel={renderObject}
            setValues={setValues}
            values={values}
            margin="dense"
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="close"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        />
        <Button label="add" onClick={handleOnSubmit} />
      </DialogActions>
    </Dialog>
  );
};

HealthPlanServiceAdd.defaultProps = {
  availableHealthPlanServices: [],
};
HealthPlanServiceAdd.propTypes = {
  open: PropTypes.bool.isRequired,
  availableHealthPlanServices: PropTypes.arrayOf(
    PropTypes.shape(ServicePropType)
  ),
  values: PropTypes.shape({
    definitionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HealthPlanServiceAdd;

import { createSelector } from 'reselect';

const selectNotifications = (state) => state.notifications.notificationList;
const selectUnreadNotificationsCount = (state) =>
  state.notifications.countUnread;

export const notificationsSelector = createSelector(
  [selectNotifications],
  (notificationList) => notificationList
);
export const unreadNotificationsCountSelector = createSelector(
  [selectUnreadNotificationsCount],
  (countUnread) => countUnread
);

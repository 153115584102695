import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import {
  Container as StyledContainer,
  CircularProgress as StyledCircularProgress,
} from './styles';

// full width button which opens send modal
const ProgressButton = ({ loading, color, disabled, ...props }) => (
  <StyledContainer>
    <Button disabled={disabled || loading} color={color} {...props} />
    {loading && <StyledCircularProgress size={24} color={color} />}
  </StyledContainer>
);

ProgressButton.defaultProps = {
  loading: false,
  disabled: false,
};
ProgressButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ProgressButton;

import { http } from 'services/backend/http';
import adaptResponseData from './adaptResponseData';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query: { patientId, inactiveModuleId }, data }) {
  return http
    .patch(
      `/patients/${patientId}/cycles_of_care_module/${inactiveModuleId}/`,
      { data }
    )
    .then(adaptResponseData)
    .catch(handleHTTPError);
}

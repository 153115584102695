import { http } from 'services/backend/http';
import adaptResponseData from './adaptResponseData';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query: { healthPlanId } }) {
  return http
    .get(`/health_plans/${healthPlanId}/feedback/`)
    // .then(adaptResponseData)
    .catch(handleHTTPError);
}

import React, { useEffect, useState } from 'react';
import { isNil, groupBy, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import { ExpandableCard, FlexBox, GoalChips } from 'common/components/layouts';
import { PROVIDER_GROUP_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanPropType,
  HealthPlanGoalPropType,
  HealthPlanServicePropType,
  PatientPropType,
} from 'common/propTypes';
import { renderFullName, renderFirstName } from 'common/utils';
import HealthPlanServiceAdd from './components/HealthPlanServiceAdd';
import HealthPlanService from './components/HealthPlanService';

const HealthPlanServices = ({
  patient,
  healthPlan,
  healthPlanGoals,
  healthPlanServices,
  serviceDefinitions,
  availableServices,
  excludedServices,
  fetchHealthPlanServices,
  resetHealthPlanServices,
  readOnly,
}) => {
  // DISCUSS: forced to only call on initial render and reroute, otherwise flashed after each edit
  useEffect(() => {
    if (!isNil(healthPlan.id)) {
      fetchHealthPlanServices({ query: { healthPlanId: healthPlan.id } });
    }
    return () => {
      resetHealthPlanServices();
    };
    // eslint-disable-next-line
  }, []);

  const [showHealthServiceAdd, setShowHealthServiceAdd] = useState(false),
    handleToggleCreate = () => {
      setShowHealthServiceAdd(!showHealthServiceAdd);
    };

  return (
    <>
      <Typography color="textSecondary" paragraph>
        Here are {renderFullName(patient)}'s suggested Health Services, default
        Health Services have already been selected. Select all the Health
        Services that {renderFirstName(patient)} requires to prepare their
        Health Team.
      </Typography>

      <GoalChips goals={healthPlanGoals} />
      {Object.entries(groupBy(availableServices, 'providerGroup')).map(
        ([providerGroup, providerGroupServices], idx) => {
          const providerGroupAssignedServices = healthPlanServices.filter(
            (healthPlanService) =>
              healthPlanService.definition.providerGroup ===
              Number(providerGroup)
          );

          return (
            <ExpandableCard
              key={idx}
              cardContent={
                <FlexBox flexDirection="column">
                  <Typography variant="h2">
                    {PROVIDER_GROUP_NAMES[providerGroup]}
                  </Typography>
                  <Typography variant="h6" color={'textSecondary'}>
                    Health Services: {providerGroupAssignedServices.length}
                  </Typography>
                </FlexBox>
              }
            >
              <FlexBox>
                {sortBy(providerGroupServices, [
                  'includingServices',
                  'name',
                ]).map((service, idy) => {
                  const selectedService = providerGroupAssignedServices.find(
                      (healthPlanService) =>
                        healthPlanService.definition.id === service.id
                    ),
                    healthPlanServiceGoals = healthPlanGoals.filter(
                      (healthPlanGoal) =>
                        service.goals.includes(healthPlanGoal.definition)
                    );

                  // TODO: Improve this: pass in only healthPlanService which includes definition information
                  return (
                    <HealthPlanService
                      key={idy}
                      healthPlan={healthPlan}
                      service={service}
                      selectedService={selectedService}
                      excludedServices={excludedServices}
                      healthPlanServiceGoals={healthPlanServiceGoals}
                      readOnly={readOnly}
                    />
                  );
                })}
              </FlexBox>
            </ExpandableCard>
          );
        }
      )}

      {!readOnly && (
        <FlexBox flexDirection="column" mt="1rem">
          {showHealthServiceAdd && (
            <HealthPlanServiceAdd
              open={showHealthServiceAdd}
              healthPlan={healthPlan}
              healthPlanServices={healthPlanServices}
              serviceDefinitions={serviceDefinitions}
              onClose={handleToggleCreate}
            />
          )}
          <Button
            label="Add Service"
            onClick={handleToggleCreate}
            // TODO: implement disabled when no more services are available
          />
        </FlexBox>
      )}
    </>
  );
};

HealthPlanServices.defaultProps = {
  availableServices: [],
  excludedServices: [],
  healthPlanGoals: [],
};
HealthPlanServices.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ),
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      includesServices: PropTypes.arrayOf(PropTypes.number),
      definition: PropTypes.shape(ServicePropType),
    })
  ).isRequired,
  serviceDefinitions: PropTypes.objectOf(PropTypes.shape(ServicePropType))
    .isRequired,
  availableServices: PropTypes.arrayOf(PropTypes.shape(ServicePropType)),
  excludedServices: PropTypes.arrayOf(PropTypes.number),
  fetchHealthPlanServices: PropTypes.func.isRequired,
  resetHealthPlanServices: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanServices;

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction } from 'common/HOCs';
import { deletePatientCycleOfCareModuleInit } from 'services/database/actions/patientCycleOfCareModules';
import { inlineLoadingSelector } from 'services/database/actions/loading';
import PatientCycleOfCareModule from './PatientCycleOfCareModule';

export default compose(
  withAction({
    actionName: 'deletePatientCycleOfCareModule',
    action: deletePatientCycleOfCareModuleInit,
  }),
  connect((state) => ({
    inlineLoading: inlineLoadingSelector(state),
  }))
)(PatientCycleOfCareModule);

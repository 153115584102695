import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import { RESET_PATIENT } from 'services/database/actions/patient/redux/actionNames';
import { UPDATE_HEALTH_PLAN_SERVICE_SUCCESS } from 'services/database/actions/healthPlanServices/redux/actionNames';
import {
  FETCH_HEALTH_PLAN_SUCCESS,
  UPDATE_HEALTH_PLAN_SUCCESS,
  RESET_HEALTH_PLAN,
} from './actionNames';

const initialState = {
  coach: null,
  createdAt: null,
  createdBy: null,
  finalisedAt: null,
  finalisedBy: null,
  generalPractitioner: null,
  healthTeam: {},
  nextReview: null,
  patient: null,
  sharedAt: null,
  sharedBy: null,
  status: null,
};

function handleFetchHealthPlanSuccess(state, { data }) {
  return data;
}

function handleUpdateHealthPlanTeam(state, { query }) {
  const nextState = produce(state, (draftState) => {
    draftState.healthTeam[query.provider.id] = query.provider;
  });
  return { ...state, ...nextState };
}

function handleResetHealthPlan() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_SUCCESS]: handleFetchHealthPlanSuccess,
  [UPDATE_HEALTH_PLAN_SUCCESS]: handleFetchHealthPlanSuccess,
  [UPDATE_HEALTH_PLAN_SERVICE_SUCCESS]: handleUpdateHealthPlanTeam,
  [RESET_PATIENT]: handleResetHealthPlan,
  [RESET_HEALTH_PLAN]: handleResetHealthPlan,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import axios from 'axios';
import makeUrlFromPath from './makeUrlFromPath';
import serializeParams from './serializeParams';

// define request. set auth header
export function request(
  method,
  path,
  { accessToken, data, responseType, params } = {}
) {
  const url = makeUrlFromPath({ path });
  const headers = {
    ...(accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {}),
  };

  return axios({
    crossDomain: true,
    method,
    headers,
    url,
    data,
    responseType,
    params,
    paramsSerializer: serializeParams,
  }).then((response) => response);
}

export default {
  get: request.bind(this, 'get'),
  post: request.bind(this, 'post'),
  put: request.bind(this, 'put'),
  patch: request.bind(this, 'patch'),
  delete: request.bind(this, 'delete'),
};

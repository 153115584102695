import { isUndefined } from 'lodash';

export default function ({
  healthPlanService = {
    provider: { id: null },
    tasks: [],
    numberOfVisits: 0,
  },
}) {
  return {
    providerId: !isUndefined(healthPlanService.provider)
      ? healthPlanService.provider.id
      : null,
    tasks: healthPlanService.tasks,
    numberOfVisits: healthPlanService.numberOfVisits,
  };
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar as MuiAppBar, Typography, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import {
  dashboardPath,
  loginPath,
  patientsPath,
  providersPath,
  settingsPath,
} from 'routing/utils/paths';
import { FlexBox } from 'common/components/layouts';
import Logo from 'common/assets/logos/myhealthplan-logo.svg';
import { renderFullName, renderPracticeName } from 'common/utils';
import {
  ImageContainer as StyledImageContainer,
  NavLink as StyledNavLink,
  MenuDiv as StyledMenuDiv,
} from './styles';

const AppBar = ({ user, logoutAction, history }) => {
  const menuId = 'primary-account-menu';
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClearState = () => {
    logoutAction().then(() => history.replace(loginPath()));
  };

  return (
    <MuiAppBar position="fixed">
      <FlexBox
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        height="100%"
        width="100%"
        justifyContent="space-between"
      >
        <StyledImageContainer src={Logo} to={dashboardPath} />
        <FlexBox flexDirection="row" flexWrap="nowrap" alignItems="center">
          <StyledNavLink to={providersPath}>
            <Typography variant="h5">Providers</Typography>
          </StyledNavLink>
          <StyledNavLink to={patientsPath}>
            <Typography variant="h5">Patients</Typography>
          </StyledNavLink>
          <StyledMenuDiv
            aria-label="user account menu"
            aria-controls={menuId}
            open={menuOpen}
            onClick={handleOpenMenu}
          >
            <Typography variant="h4">{renderFullName(user)}</Typography>
            <Typography variant="subtitle2">
              {renderPracticeName(user)}
            </Typography>
          </StyledMenuDiv>
        </FlexBox>
      </FlexBox>
      <Menu
        id={menuId}
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -12, horizontal: 'right' }}
        MenuListProps={{
          style: {
            width: '15rem',
          },
        }}
        open={menuOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem component={Link} to={settingsPath}>
          <Typography>Settings</Typography>
        </MenuItem>
        <MenuItem onClick={handleClearState}>
          <Typography>Log Out</Typography>
        </MenuItem>
      </Menu>
    </MuiAppBar>
  );
};

AppBar.defaultProps = {
  user: { email: '', firstName: '', lastName: '', practices: [] },
};
AppBar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  logoutAction: PropTypes.func,
  history: PropTypes.object,
};

export default AppBar;

import produce from 'immer';
import { flattenObject } from 'common/utils';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_PROVIDERS_SUCCESS,
  ADD_PROVIDER_ERROR,
  UPDATE_PROVIDER_SUCCESS,
  UPDATE_PROVIDER_ERROR,
  RESET_PROVIDERS,
} from './actionNames';

const initialState = { providerCount: 0, providers: {}, errors: {} };

function handleFetchProvidersSuccess(state, { data }) {
  return data;
}

function handleUpdateProviderSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.providers[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleError(state, { error: { data } }) {
  return { ...state, errors: flattenObject(data) };
}

function handleResetProviders(state) {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_PROVIDERS_SUCCESS]: handleFetchProvidersSuccess,
  [ADD_PROVIDER_ERROR]: handleError,
  [UPDATE_PROVIDER_SUCCESS]: handleUpdateProviderSuccess,
  [UPDATE_PROVIDER_ERROR]: handleError,
  [RESET_PROVIDERS]: handleResetProviders,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlanActions/fetch';
import createSaga from 'services/backend/actions/healthPlanActions/create';
import deleteSaga from 'services/backend/actions/healthPlanActions/delete';
import {
  FETCH_HEALTH_PLAN_ACTIONS_INIT,
  CREATE_HEALTH_PLAN_ACTION_INIT,
  DELETE_HEALTH_PLAN_ACTION_INIT,
} from './actionNames';

function* healthActionsFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_PLAN_ACTIONS_INIT, fetchSaga),
    takeLatest(CREATE_HEALTH_PLAN_ACTION_INIT, createSaga),
    takeLatest(DELETE_HEALTH_PLAN_ACTION_INIT, deleteSaga),
  ]);
}

export default healthActionsFlowSaga;

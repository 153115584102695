export default function ({
  provider = {
    firstName: '',
    lastName: '',
    email: '',
    providerGroup: '',
    providerProfile: { ahpraNumber: '', providerType: '' },
  },
}) {
  return {
    firstName: provider.firstName,
    lastName: provider.lastName,
    email: provider.email,
    ahpraNumber: provider.providerProfile.ahpraNumber,
    providerGroup: provider.userType,
    providerType: provider.providerProfile.providerType,
  };
}

import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlanMeasurements/fetch';
import createSaga from 'services/backend/actions/healthPlanMeasurements/create';
import updateSaga from 'services/backend/actions/healthPlanMeasurements/update';
import deleteSaga from 'services/backend/actions/healthPlanMeasurements/delete';
import {
  FETCH_HEALTH_PLAN_MEASUREMENTS_INIT,
  CREATE_HEALTH_PLAN_MEASUREMENT_INIT,
  UPDATE_HEALTH_PLAN_MEASUREMENT_INIT,
  DELETE_HEALTH_PLAN_MEASUREMENT_INIT,
} from './actionNames';

function* healthPlanMeasurementsFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_PLAN_MEASUREMENTS_INIT, fetchSaga),
    takeEvery(CREATE_HEALTH_PLAN_MEASUREMENT_INIT, createSaga),
    takeEvery(UPDATE_HEALTH_PLAN_MEASUREMENT_INIT, updateSaga),
    takeEvery(DELETE_HEALTH_PLAN_MEASUREMENT_INIT, deleteSaga),
  ]);
}

export default healthPlanMeasurementsFlowSaga;

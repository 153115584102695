import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlanServices/fetch';
import createSaga from 'services/backend/actions/healthPlanServices/create';
import updateSaga from 'services/backend/actions/healthPlanServices/update';
import deleteSaga from 'services/backend/actions/healthPlanServices/delete';
import {
  FETCH_HEALTH_PLAN_SERVICES_INIT,
  UPDATE_HEALTH_PLAN_SERVICE_INIT,
  CREATE_HEALTH_PLAN_SERVICE_INIT,
  DELETE_HEALTH_PLAN_SERVICE_INIT,
} from './actionNames';

function* healthPlanServicesFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_PLAN_SERVICES_INIT, fetchSaga),
    takeEvery(UPDATE_HEALTH_PLAN_SERVICE_INIT, updateSaga),
    takeEvery(CREATE_HEALTH_PLAN_SERVICE_INIT, createSaga),
    takeEvery(DELETE_HEALTH_PLAN_SERVICE_INIT, deleteSaga),
  ]);
}

export default healthPlanServicesFlowSaga;

import { isUndefined } from 'lodash';
import { http } from 'services/backend/http';
import adaptResponseData from './adaptResponseData';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({
  query: { page, search, providerGroup, providerTypes },
}) {
  // DISCUSS: move logic to handleSubmit?
  const provider_type = !isUndefined(providerTypes)
    ? providerTypes.map((type) => `${type}`).join('&')
    : undefined;
  return http
    .get(`/providers/`, {
      params: {
        page,
        search,
        user_type: providerGroup,
        provider_type,
      },
    })
    .then(adaptResponseData)
    .catch(handleHTTPError);
}

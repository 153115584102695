import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({
  query: { healthPlanId, healthPlanAdminItemId },
  data,
}) {
  return http
    .patch(
      `/health_plans/${healthPlanId}/admin_items/${healthPlanAdminItemId}/`,
      {
        data,
      }
    )
    .catch(handleHTTPError);
}

export const FETCH_PATIENT_CONDITIONS_INIT = 'FETCH_PATIENT_CONDITIONS_INIT';
export const FETCH_PATIENT_CONDITIONS_SUCCESS =
  'FETCH_PATIENT_CONDITIONS_SUCCESS';

export const CREATE_PATIENT_CONDITION_INIT = 'CREATE_PATIENT_CONDITION_INIT';
export const CREATE_PATIENT_CONDITION_SUCCESS =
  'CREATE_PATIENT_CONDITION_SUCCESS';

export const DELETE_PATIENT_CONDITION_INIT = 'DELETE_PATIENT_CONDITION_INIT';
export const DELETE_PATIENT_CONDITION_SUCCESS =
  'DELETE_PATIENT_CONDITION_SUCCESS';

export const RESET_PATIENT_CONDITIONS = 'RESET_PATIENT_CONDITIONS';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withHealthPlanGoals, withPropsMapping } from 'common/HOCs';
import { fetchHealthPlanActionsInit } from 'services/database/actions/healthPlanActions';
import {
  fetchHealthPlanServicesInit,
  resetHealthPlanServices,
  healthPlanServicesSelector,
} from 'services/database/actions/healthPlanServices';
import {
  fetchHealthPlanReviewsInit,
  resetHealthPlanReviews,
  healthPlanCurrentReviewSelector,
} from 'services/database/actions/healthPlanReviews';
import {
  fetchHealthJourneyInit,
  resetHealthJourney,
} from 'services/database/actions/healthJourney';
import Summary from './Summary';
import { fetchHealthPlanFeedbackInit, healthPlanFeedbackSelector, resetHealthPlanFeedback } from 'services/database/actions/healthPlanFeedback';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanActions',
    action: fetchHealthPlanActionsInit,
  }),
  withAction({
    actionName: 'fetchHealthPlanServices',
    action: fetchHealthPlanServicesInit,
  }),
  withAction({
    actionName: 'resetHealthPlanServices',
    action: resetHealthPlanServices,
  }),
  withAction({
    actionName: 'fetchHealthPlanFeedback',
    action: fetchHealthPlanFeedbackInit,
  }),
  withAction({
    actionName: 'resetHealthPlanFeedback',
    action: resetHealthPlanFeedback,
  }),
  withAction({
    actionName: 'fetchHealthPlanReviews',
    action: fetchHealthPlanReviewsInit,
  }),
  withAction({
    actionName: 'resetHealthPlanReviews',
    action: resetHealthPlanReviews,
  }),
  withAction({
    actionName: 'fetchHealthJourney',
    action: fetchHealthJourneyInit,
  }),
  withAction({
    actionName: 'resetHealthJourney',
    action: resetHealthJourney,
  }),
  connect((state) => ({
    healthPlanServices: healthPlanServicesSelector(state),
    healthPlanFeedback: healthPlanFeedbackSelector(state),
    healthPlanReview: healthPlanCurrentReviewSelector(state),
  })),
  withPropsMapping((props) => ({
    ...props,
    // Override these props
    healthPlanServices: Object.values(props.healthPlanServices).map(
      (healthPlanService) => ({
        ...healthPlanService,
        definition: props.serviceDefinitions[healthPlanService.definition],
      })
    ),
  })),
  withHealthPlanGoals
)(Summary);

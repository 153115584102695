import React from 'react';
import { CssBaseline } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import GlobalTheme from './global';
import MyCssBaseline from './MyCssBaseline';

const ThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={GlobalTheme}>
      <CssBaseline />
      <MyCssBaseline />
      {children}
    </MuiThemeProvider>
  </StylesProvider>
);

export default ThemeProvider;

import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlanAdminItems/fetch';
import updateSaga from 'services/backend/actions/healthPlanAdminItems/update';
import {
  FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT,
  UPDATE_HEALTH_PLAN_ADMIN_ITEM_INIT,
} from './actionNames';

function* adminFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_PLAN_ADMIN_ITEMS_INIT, fetchSaga),
    takeEvery(UPDATE_HEALTH_PLAN_ADMIN_ITEM_INIT, updateSaga),
  ]);
}

export default adminFlowSaga;

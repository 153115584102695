import React from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
} from 'common/constants';
import {
  ActionPropType,
  GoalPropType,
  HealthPlanActionPropType,
  HealthPlanGoalPropType,
} from 'common/propTypes';
import HealthPlanAction from './components/HealthPlanAction';

const HealthPlanActionsTab = ({
  healthPlanActions,
  healthPlanGoals,
  handleGoToSection,
}) => {
  const sectionId = HEALTH_PLAN_SECTIONS.HEALTH_ACTIONS;

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      {sortBy(healthPlanActions, ['createdAt']).map((healthPlanAction, idx) => {
        const healthPlanActionGoals = healthPlanGoals.filter((healthPlanGoal) =>
          healthPlanAction.definition.goals.includes(healthPlanGoal.definition)
        );

        return (
          <HealthPlanAction
            key={idx}
            index={idx + 1}
            healthPlanAction={healthPlanAction}
            healthPlanActionGoals={healthPlanActionGoals}
          />
        );
      })}
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

HealthPlanActionsTab.propTypes = {
  healthPlanActions: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanActionPropType,
      healthPlan: PropTypes.number,
      definition: PropTypes.shape(ActionPropType),
    })
  ).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
  handleGoToSection: PropTypes.func.isRequired,
};

export default HealthPlanActionsTab;

import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { deleteHealthPlanMeasurementSuccess } from 'services/database/actions/healthPlanMeasurements';
import request from './request';

export default function* deleteSaga({ query, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: query.healthPlanMeasurementId }));
    yield call(request, { query });
    yield put(deleteHealthPlanMeasurementSuccess({ data: query }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  } finally {
    yield put(setInlineLoadingOff({ data: query.healthPlanMeasurementId }));
  }
}

import { isEmpty } from 'lodash';
import { call, put } from 'redux-saga/effects';
import { fetchHealthJourneySuccess } from 'services/database/actions/healthJourney';
import request from './request';

export default function* fetchSaga({ query, resolve, reject }) {
  try {
    const responseData = yield call(request, { query });
    // responseData is an array only containing the current health journey
    yield put(
      fetchHealthJourneySuccess({
        data: !isEmpty(responseData) ? responseData[0] : {},
      })
    );
  } catch (error) {
    yield call(reject, { error });
    return error;
  } finally {
  }
}

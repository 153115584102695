import React from 'react';
import PropTypes from 'prop-types';
import { ColorIndicator as StyledColorIndicator } from './styles';

const ColorIndicator = ({ color, dense }) => (
  <StyledColorIndicator color={color} dense={dense} />
);

ColorIndicator.defaultProps = {
  dense: false,
};
ColorIndicator.propTypes = {
  color: PropTypes.string.isRequired,
  dense: PropTypes.bool,
};

export default ColorIndicator;

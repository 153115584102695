import { compose } from 'redux';
import { withFormik } from 'formik';
import { updateUserInit } from 'services/database/actions/user';
import {
  withAction,
  withAuthenticatedUser,
  withFullPageLayout,
  withIsAuthenticated,
} from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/user/update';
import SettingsPage from './SettingsPage';

export default compose(
  withAction({
    actionName: 'updateUser',
    action: updateUserInit,
  }),
  withIsAuthenticated,
  withAuthenticatedUser,
  withFullPageLayout,
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  })
)(SettingsPage);

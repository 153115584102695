import PropTypes from 'prop-types';
import GoalProgressBarrierPropType from './GoalProgressBarrierPropType';

export default {
  id: PropTypes.number.isRequired,
  healthPlanGoal: PropTypes.number.isRequired,
  healthPlanReview: PropTypes.number.isRequired,
  healthPlan: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  notes: PropTypes.string,
  goalDefinition: PropTypes.number,
  barrierDefinitions: PropTypes.arrayOf(PropTypes.number),
  goalProgressBarriers: PropTypes.objectOf(
    PropTypes.shape(GoalProgressBarrierPropType)
  ),
};

import React from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from 'common/components/buttons';
import { FlexBox, TabCard } from 'common/components/layouts';
import {
  HEALTH_PLAN_SECTIONS,
  HEALTH_PLAN_SECTION_NAMES,
} from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanGoalPropType,
  HealthPlanServicePropType,
} from 'common/propTypes';
import HealthPlanService from './components/HealthPlanService';

const HealthPlanServicesTab = ({
  healthPlanGoals,
  healthPlanServices,
  handleGoToSection,
}) => {
  const sectionId = HEALTH_PLAN_SECTIONS.HEALTH_SERVICES;

  return (
    <TabCard title={HEALTH_PLAN_SECTION_NAMES[sectionId]}>
      {sortBy(healthPlanServices, [
        'definition.providerGroup',
        'createdAt',
      ]).map((healthPlanService, idx) => {
        const healthPlanServiceGoals = healthPlanGoals.filter(
          (healthPlanGoal) =>
            healthPlanService.definition.goals.includes(
              healthPlanGoal.definition
            )
        );

        return (
          <HealthPlanService
            key={idx}
            index={idx + 1}
            healthPlanService={healthPlanService}
            healthPlanServiceGoals={healthPlanServiceGoals}
          />
        );
      })}
      <FlexBox justifyContent="flex-end">
        <Button
          label="Go To Section"
          variant="outlined"
          color="primary"
          onClick={handleGoToSection(sectionId)}
        />
      </FlexBox>
    </TabCard>
  );
};

HealthPlanServicesTab.propTypes = {
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      definition: PropTypes.shape(ServicePropType),
    })
  ).isRequired,
  handleGoToSection: PropTypes.func.isRequired,
};

export default HealthPlanServicesTab;

export default function (
  values,
  { props: { updatePatientPassword, onClose } }
) {
  const handleOnSuccess = () => {
      onClose();
    },
    handleOnError = () => {};

  return updatePatientPassword({
    data: values,
  })
    .then(() => handleOnSuccess())
    .catch(() => handleOnError());
}

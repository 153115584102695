export default function ({
  patient = {
    email: '',
  },
}) {
  return {
    email: patient.email,
    medicareNumber: '',
  };
}

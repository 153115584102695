import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { Button } from 'common/components/buttons';
import { Form, SelectField } from 'common/components/form';
import { CycleOfCareModulePropType } from 'common/propTypes';
import { renderObject } from 'common/utils';

const PatientCycleOfCareModuleAdd = ({
  open,
  availableCycleOfCareModules,
  values,
  values: { definitionId },
  setValues,
  handleSubmit,
  onClose,
}) => {
  const options = availableCycleOfCareModules.reduce((acc, cur) => {
    acc[cur.id] = cur.name;
    return acc;
  }, {});

  const handleOnSubmit = () => {
    handleSubmit();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Form>
          <SelectField
            name="definitionId"
            label="Cycle Of Care"
            value={definitionId}
            options={options}
            renderSelectLabel={renderObject}
            setValues={setValues}
            values={values}
            margin="dense"
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="close"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        />
        <Button label="add" onClick={handleOnSubmit} />
      </DialogActions>
    </Dialog>
  );
};

PatientCycleOfCareModuleAdd.propTypes = {
  open: PropTypes.bool.isRequired,
  availableCycleOfCareModules: PropTypes.arrayOf(
    PropTypes.shape(CycleOfCareModulePropType)
  ).isRequired,
  values: PropTypes.shape({
    definitionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PatientCycleOfCareModuleAdd;

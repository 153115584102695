import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import { mapPropsToValues, handleSubmit } from 'common/data/healthPlans/update';
import { updateHealthPlanInit } from 'services/database/actions/healthPlans';
import {
  fetchProvidersInit,
  providersSelector,
  resetProviders,
} from 'services/database/actions/providers';
import UpdateHealthPlanPracticeStaff from './UpdateHealthPlanPracticeStaff';

export default compose(
  withAction({
    actionName: 'fetchProviders',
    action: fetchProvidersInit,
  }),
  withAction({
    actionName: 'resetProviders',
    action: resetProviders,
  }),
  withAction({
    actionName: 'updateHealthPlan',
    action: updateHealthPlanInit,
  }),
  connect((state) => ({
    providers: providersSelector(state),
    formikFields: ['coach'],
  })),
  withFormik({ mapPropsToValues, handleSubmit, enableReinitialize: true })
)(UpdateHealthPlanPracticeStaff);

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withAction, withFormErrorHandler } from 'common/HOCs';
import {
  updatePatientPasswordInit,
  patientErrorSelector,
} from 'services/database/actions/patient';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patients/changePassword';
import ChangePasswordModal from './ChangePasswordModal';

export default compose(
  withAction({
    actionName: 'updatePatientPassword',
    action: updatePatientPasswordInit,
  }),
  connect((state) => ({
    formErrorData: patientErrorSelector(state),
  })),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  }),
  withFormErrorHandler
)(ChangePasswordModal);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button } from 'common/components/buttons';
import { Form, Input } from 'common/components/form';
import { isEmpty } from 'lodash';

// form to update password. Uses formik to handleSubmit & data validation. Form is reset when modal closed
const ChangePasswordModal = ({
  open,
  values: { newPassword },
  onClose,
  handleSubmit,
  resetForm,
  loading,
}) => {
  const handleOnClose = () => {
    onClose();
    resetForm();
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  // DISCUSS: hiding buttons look weird when autoFocus is on
  // const [showButtons, setShowButtons] = useState(true);
  // const handleInputFocus = () => {
  //   setShowButtons(false);
  // };
  // const handleOnBlur = () => {
  //   setShowButtons(true);
  // };

  return (
    <Dialog open={open}>
      <DialogTitle>Change Password </DialogTitle>
      <DialogContent>
        <Form>
          <Input
            label="New Password"
            name="newPassword"
            value={newPassword}
            type={showNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleShowNewPassword}
                  style={{ cursor: 'pointer' }}
                >
                  {showNewPassword ? (
                    <Visibility fontSize="small" color="primary" />
                  ) : (
                    <VisibilityOff fontSize="small" color="primary" />
                  )}
                </InputAdornment>
              ),
            }}
            onKeyPress={handleSubmit}
            margin="dense"
            preventDefault
            disabled={!isEmpty(loading)}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button
          label="cancel"
          onClick={handleOnClose}
          color="warning"
          disabled={!isEmpty(loading)}
        />
        <Button
          label="confirm"
          onClick={handleSubmit}
          color="primary"
          disabled={!isEmpty(loading)}
        />
      </DialogActions>
    </Dialog>
  );
};

ChangePasswordModal.defaultProps = {};
ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default ChangePasswordModal;

import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  definition: PropTypes.number.isRequired,
  valueTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  secondValueTarget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lastMeasurement: PropTypes.number,
  createdAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
};

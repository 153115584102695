import React, { useEffect } from 'react';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { withAction } from 'common/HOCs';
import { HealthPlanPropType, PatientPropType } from 'common/propTypes';
import {
  fetchHealthPlanInit,
  healthPlanSelector,
} from 'services/database/actions/healthPlans';
import {
  fetchPatientInit,
  patientSelector,
  resetPatient,
} from 'services/database/actions/patient';
import getComponentDisplayName from './utils/getComponentDisplayName';

function withPatient(OriginalComponent) {
  const Component = ({
    patient,
    healthPlan,
    fetchPatient,
    fetchHealthPlan,
    resetAll,
    ...props
  }) => {
    const { patientId } = useParams();
    useEffect(() => {
      fetchPatient({ query: { patientId } })
        .then(({ responseData }) => {
          !isNull(responseData.healthPlan) &&
            fetchHealthPlan({ query: { healthPlanId: patientId } }).catch(
              (error) => console.log('hp error:', error)
            );
        })
        .catch((error) => console.log('patient error', error));
      return () => resetAll();
    }, [patientId, fetchPatient, fetchHealthPlan, resetAll]);

    return (
      <OriginalComponent patient={patient} healthPlan={healthPlan} {...props} />
    );
  };

  Component.defaultProps = {
    patient: {
      id: null,
      firstName: '',
      lastName: '',
      healthPlan: null,
      medicareNumber: true,
    },
    healthPlan: {
      id: null,
    },
  };
  Component.propTypes = {
    patient: PropTypes.shape(PatientPropType),
    healthPlan: PropTypes.shape(HealthPlanPropType),
    fetchPatient: PropTypes.func.isRequired,
    fetchHealthPlan: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
  };
  Component.displayName = `withPatient(${getComponentDisplayName(
    OriginalComponent
  )})`;

  const Container = compose(
    withAction({
      actionName: 'fetchPatient',
      action: fetchPatientInit,
    }),
    withAction({
      actionName: 'fetchHealthPlan',
      action: fetchHealthPlanInit,
    }),
    withAction({
      actionName: 'resetAll',
      action: resetPatient,
    }),
    connect((state) => ({
      patient: patientSelector(state),
      healthPlan: healthPlanSelector(state),
    }))
  )(Component);

  return Container;
}

export default withPatient;

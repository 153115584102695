import PropTypes from 'prop-types';
import HealthPlanPropType from './HealthPlanPropType';

export default {
  id: PropTypes.number,
  email: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  generalPractitioner: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  profile: PropTypes.shape({
    dateOfBirth: PropTypes.instanceOf(Date),
    medicareNumber: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
    street: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  healthPlan: PropTypes.shape(HealthPlanPropType),
};

import {
  CREATE_GOAL_PROGRESS_BARRIER_INIT,
  CREATE_GOAL_PROGRESS_BARRIER_SUCCESS,
  DELETE_GOAL_PROGRESS_BARRIER_INIT,
  DELETE_GOAL_PROGRESS_BARRIER_SUCCESS,
} from './actionNames';

export function createGoalProgressBarrierInit(actionParams) {
  return { type: CREATE_GOAL_PROGRESS_BARRIER_INIT, ...actionParams };
}

export function createGoalProgressBarrierSuccess({ data }) {
  return { type: CREATE_GOAL_PROGRESS_BARRIER_SUCCESS, data };
}

export function deleteGoalProgressBarrierInit(actionParams) {
  return { type: DELETE_GOAL_PROGRESS_BARRIER_INIT, ...actionParams };
}

export function deleteGoalProgressBarrierSuccess({ data }) {
  return { type: DELETE_GOAL_PROGRESS_BARRIER_SUCCESS, data };
}

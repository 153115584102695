import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as StyledIconButton } from './styles';

// full width button which opens send modal
const IconButton = ({
  icon,
  label,
  color,
  onClick,
  to,
  component,
  disabled,
  ...props
}) => {
  return (
    <StyledIconButton
      aria-label={label}
      onClick={onClick}
      to={to}
      component={component}
      color={color}
      disabled={disabled}
      {...props}
    >
      {icon}
    </StyledIconButton>
  );
};

IconButton.defaultProps = {
  disabled: false,
};
IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  component: PropTypes.object,
  disabled: PropTypes.bool,
};

export default IconButton;

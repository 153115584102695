import { MEASUREMENT_VALUE_TARGET_TYPES } from 'common/constants';

export default {
  [MEASUREMENT_VALUE_TARGET_TYPES.EXACTLY]: null,
  [MEASUREMENT_VALUE_TARGET_TYPES.LESS_THAN]: '<',
  [MEASUREMENT_VALUE_TARGET_TYPES.LESS_THAN_EQUAL]: '<=',
  [MEASUREMENT_VALUE_TARGET_TYPES.GREATER_THAN]: '>',
  [MEASUREMENT_VALUE_TARGET_TYPES.GREATER_THAN_EQUAL]: '>=',
  [MEASUREMENT_VALUE_TARGET_TYPES.RANGE]: '-',
};

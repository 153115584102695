import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOff,
  setInlineLoadingOn,
} from 'services/database/actions/loading';
import { createPatientMeasurementSuccess } from 'services/database/actions/patientMeasurements/redux/actions';
import request from './request';

export default function* createSaga({ query, data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: query.healthPlanMeasurementId }));
    const responseData = yield call(request, { query, data });
    yield put(createPatientMeasurementSuccess({ data: responseData, query }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  } finally {
    yield put(setInlineLoadingOff({ data: query.healthPlanMeasurementId }));
  }
}

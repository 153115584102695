import React from 'react';
import { authService } from 'services/database/storage';

function withIsAuthenticatedProp(OriginalComponent) {
  const Component = ({ ...props }) => {
    const auth = authService.getAuth();

    const authProps = {
      isAuthenticated: auth.getIsAuthenticated(),
      ...props,
    };
    return <OriginalComponent {...authProps} />;
  };

  return Component;
}

export default withIsAuthenticatedProp;

import {
  FETCH_USER_INIT,
  FETCH_USER_SUCCESS,
  RESET_USER,
  UPDATE_USER_INIT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_PASSWORD_INIT,
} from './actionNames';

export function fetchUserInit(actionParams) {
  return { type: FETCH_USER_INIT, ...actionParams };
}

export function fetchUserSuccess({ data }) {
  return { type: FETCH_USER_SUCCESS, data };
}

export function updateUserInit(actionParams) {
  return { type: UPDATE_USER_INIT, ...actionParams };
}

export function updateUserSuccess({ data }) {
  return { type: UPDATE_USER_SUCCESS, data };
}

export function updateUserError({ error }) {
  return { type: UPDATE_USER_ERROR, error };
}

export function updateUserPasswordInit(actionParams) {
  return { type: UPDATE_USER_PASSWORD_INIT, ...actionParams };
}

export function resetUser(actionParams) {
  return { type: RESET_USER, ...actionParams };
}

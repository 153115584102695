import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS,
  RESET_HEALTH_PLAN_ADMIN_ITEMS,
} from 'services/database/actions/healthPlanAdminItems/redux/actionNames';
import { UPDATE_HEALTH_PLAN_ERROR } from 'services/database/actions/healthPlans/redux/actionNames';
import { UPDATE_HEALTH_PLAN_REVIEW_ERROR } from 'services/database/actions/healthPlanReviews/redux/actionNames';
import { isUndefined } from 'lodash';

const initialState = { adminItems: {}, errors: {} };

function handleFetchHealthPlanAdminItemsSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState.adminItems = data;
  });
  return { ...state, ...nextState };
}

function handleUpdateHealthPlanError(state, { data }) {
  const nextState = produce(state, (draftState) => {
    let errors = {};
    if (!isUndefined(data.adminItems)) {
      data.adminItems.healthPlanAdminItems.map(
        (itemId) => (errors[itemId.toString()] = data.adminItems.error)
      );
    }
    draftState.errors = errors;
  });
  return { ...state, ...nextState };
}

function handleResetHealthPlanAdminItems() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_ADMIN_ITEMS_SUCCESS]:
    handleFetchHealthPlanAdminItemsSuccess,
  [UPDATE_HEALTH_PLAN_ERROR]: handleUpdateHealthPlanError,
  [UPDATE_HEALTH_PLAN_REVIEW_ERROR]: handleUpdateHealthPlanError,
  [RESET_HEALTH_PLAN_ADMIN_ITEMS]: handleResetHealthPlanAdminItems,
};

export default buildReducer(initialState, ACTION_HANDLERS);

import React from 'react';
import PropTypes from 'prop-types';
import { TabCard as StyledTabCard } from './styles';

const TabCard = ({ children, title, ...props }) => (
  <StyledTabCard title={title} {...props}>
    {children}
  </StyledTabCard>
);

TabCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default TabCard;

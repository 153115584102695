import React, { useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ClickableCard, FlexBox } from 'common/components/layouts';
import { PROVIDER_TYPE_NAMES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanServicePropType,
  HealthPlanPropType,
  ProviderPropType,
  UserPropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';
import UpdateHealthPlanPracticeStaff from './components/UpdateHealthPlanPracticeStaff';

const PracticeStaff = ({
  user,
  isDoctor,
  healthProfessional,
  healthPlan,
  healthPlanServices,
  goalDefinitions,
  loading,
  readOnly,
}) => {
  const [showUpdate, setShowUpdate] = useState(false),
    handleToggleUpdate = () => {
      setShowUpdate(!showUpdate);
    };

  const numberOfServices = healthPlanServices.length;

  return (
    <>
      {showUpdate && (
        <UpdateHealthPlanPracticeStaff
          open={showUpdate && isEmpty(loading)}
          user={user}
          isDoctor={isDoctor}
          healthProfessional={healthProfessional}
          healthPlan={healthPlan}
          healthPlanServices={healthPlanServices}
          goalDefinitions={goalDefinitions}
          onClose={handleToggleUpdate}
          onSuccess={handleToggleUpdate}
          readOnly={readOnly}
        />
      )}
      <ClickableCard color="primary" onClick={handleToggleUpdate}>
        <FlexBox
          height="100%"
          minHeight="5rem"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h6" color="primary">
              {!isDoctor
                ? 'Health Coach'
                : PROVIDER_TYPE_NAMES[healthProfessional.providerType]}
            </Typography>
            <Typography
              variant="h2"
              color={
                !isUndefined(healthProfessional.id)
                  ? 'textPrimary'
                  : 'textSecondary'
              }
            >
              {!isUndefined(healthProfessional.id)
                ? renderFullName(healthProfessional)
                : 'No Provider Selected'}
            </Typography>
          </Box>
          <Typography variant="h6" color="textSecondary">
            Health Services: {numberOfServices}
          </Typography>
        </FlexBox>
      </ClickableCard>
    </>
  );
};

PracticeStaff.defaultProps = {};
PracticeStaff.propTypes = {
  user: PropTypes.shape(UserPropType).isRequired,
  isDoctor: PropTypes.bool.isRequired,
  healthProfessional: PropTypes.shape(ProviderPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      definition: PropTypes.shape(ServicePropType),
    })
  ).isRequired,
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)).isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default PracticeStaff;

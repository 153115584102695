import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { Typography } from '@mui/material';
import { Button } from 'common/components/buttons';
import { FlexBox } from 'common/components/layouts';
import {
  GoalPropType,
  HealthPlanGoalPropType,
  HealthPlanPropType,
  PatientPropType,
} from 'common/propTypes';
import { renderFullName } from 'common/utils';
import HealthPlanGoalAdd from './components/HealthPlanGoalAdd';
import HealthPlanGoal from './components/HealthPlanGoal';

const HealthPlanGoals = ({
  patient,
  healthPlan,
  healthPlanGoals,
  availableHealthPlanGoals,
  readOnly,
}) => {
  const [showHealthGoalAdd, setShowHealthGoalAdd] = useState(false),
    handleToggleCreate = () => {
      setShowHealthGoalAdd(!showHealthGoalAdd);
    };

  return (
    <>
      <Typography color="textSecondary" paragraph>
        Select the Health Goals that {renderFullName(patient)} wants to work
        towards. We will use these Health Goals to suggest Health Actions,
        Health Services, and what clinical data should be recorded.
      </Typography>

      {sortBy(healthPlanGoals, ['createdAt']).map((healthPlanGoal, idx) => (
        <HealthPlanGoal
          key={idx}
          index={idx + 1}
          healthPlanGoal={healthPlanGoal}
          readOnly={readOnly}
        />
      ))}
      {!readOnly && (
        <FlexBox flexDirection="column" mt="1rem">
          {showHealthGoalAdd && (
            <HealthPlanGoalAdd
              open={showHealthGoalAdd}
              healthPlan={healthPlan}
              availableHealthPlanGoals={availableHealthPlanGoals}
              onClose={handleToggleCreate}
            />
          )}
          <Button
            label="Add Health Goal"
            onClick={handleToggleCreate}
            disabled={availableHealthPlanGoals.length === 0}
          />
        </FlexBox>
      )}
    </>
  );
};

HealthPlanGoals.defaultProps = {
  availableHealthPlanGoals: [],
  healthPlanGoals: [],
};
HealthPlanGoals.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({ ...GoalPropType, ...HealthPlanGoalPropType })
  ),
  availableHealthPlanGoals: PropTypes.arrayOf(PropTypes.shape(GoalPropType)),
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanGoals;

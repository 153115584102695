import React, { useEffect, useState, useRef } from 'react';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IconButton } from 'common/components/buttons';
import { Card, FlexBox, GoalChips } from 'common/components/layouts';
import { ComponentLoader } from 'common/components/loaders';
import { handleSubmit as handleDelete } from 'common/data/healthPlanMeasurements/delete';
import {
  GoalPropType,
  MeasurementPropType,
  PatientPropType,
  PatientMeasurementPropType,
  HealthPlanPropType,
  HealthPlanGoalPropType,
  HealthPlanMeasurementPropType,
} from 'common/propTypes';
import HealthPlanMeasurementInput from './components/HealthPlanMeasurementInput';
import PatientMeasurementInputCreate from './components/PatientMeasurementInput/PatientMeasurementInputCreateContainer';
import PatientMeasurementInputUpdate from './components/PatientMeasurementInput/PatientMeasurementInputUpdateContainer';

const HealthPlanMeasurement = ({
  patient,
  healthPlan,
  healthPlanMeasurement,
  healthPlanGoals,
  deleteHealthPlanMeasurement,
  inlineLoading,
  readOnly,
}) => {
  const titleRef = useRef();

  const [titleOverflow, setTitleOverflow] = useState(false);

  useEffect(() => {
    titleRef.current &&
    titleRef.current.scrollWidth > titleRef.current.clientWidth
      ? setTitleOverflow(true)
      : setTitleOverflow(false);
    // eslint-disable-next-line
  }, []);

  const handleOnDelete = () => {
    handleDelete({
      healthPlanMeasurement,
      deleteHealthPlanMeasurement,
    });
  };

  const healthPlanMeasurementGoals = healthPlanGoals.filter((healthPlanGoal) =>
      healthPlanGoal.measurements.includes(healthPlanMeasurement.definition.id)
    ),
    hasPatientMeasurement = !isUndefined(healthPlanMeasurement.lastMeasurement),
    createNewMeasurement =
      !hasPatientMeasurement ||
      healthPlanMeasurement.lastMeasurement.healthPlanReview !==
        healthPlan.currentReview,
    // DISCUSS: don't love this for loading but needs to work for patient & HP measurements
    loading =
      inlineLoading.includes(healthPlanMeasurement.id) ||
      (hasPatientMeasurement &&
        inlineLoading.includes(healthPlanMeasurement.lastMeasurement.id)),
    enableEdit = !readOnly && !loading;
  return (
    <Card>
      <FlexBox
        alignItems="center"
        justifyContent="space-between"
        flexWrap="nowrap"
        overflow="hidden"
      >
        {/* TODO: What is this supposed to do? */}
        {/* {healthPlanMeasurement.newTestRequired && (
        <NewTestRequiredForm
          readOnly={readOnly}
          healthPlanMeasurementId={
            healthPlanMeasurement.healthPlanMeasurementId
          }
        />
      )} */}
        <FlexBox alignItems="baseline" flexWrap="nowrap" mr={1}>
          <Box overflow="hidden" mr={1}>
            <Tooltip
              title={healthPlanMeasurement.definition.name || ''}
              placement="right"
              disableHoverListener={!titleOverflow}
            >
              <Typography
                ref={titleRef}
                variant="h5"
                color="textSecondary"
                noWrap
              >
                {healthPlanMeasurement.definition.name}
              </Typography>
            </Tooltip>
          </Box>
          <GoalChips goals={healthPlanMeasurementGoals} dense />
        </FlexBox>
        {!readOnly && (
          <IconButton
            icon={<Close fontSize="small" />}
            color="error"
            size="small"
            onClick={handleOnDelete}
            disabled={!enableEdit}
          />
        )}
      </FlexBox>
      {loading ? (
        <ComponentLoader />
      ) : (
        <>
          <FlexBox justifyContent="space-between" flexWrap="nowrap">
            <Box>
              {hasPatientMeasurement && !createNewMeasurement ? (
                <PatientMeasurementInputUpdate
                  // TODO: Improve how definition data is passed in
                  patientMeasurement={{
                    ...healthPlanMeasurement.lastMeasurement,
                    definition: healthPlanMeasurement.definition,
                  }}
                  readOnly={readOnly}
                />
              ) : (
                <PatientMeasurementInputCreate
                  patient={patient}
                  healthPlan={healthPlan}
                  healthPlanMeasurement={healthPlanMeasurement}
                  readOnly={readOnly}
                />
              )}
            </Box>
            <Box ml={3} overflow="hidden">
              <HealthPlanMeasurementInput
                healthPlanMeasurement={healthPlanMeasurement}
                readOnly={readOnly}
              />
            </Box>
          </FlexBox>
          <FlexBox justifyContent="space-between">
            <Typography variant="h6" color="textSecondary">
              {healthPlanMeasurement.definition.unit}
            </Typography>
            <Typography variant="h6" color="textSecondary" align="right">
              Target
            </Typography>
          </FlexBox>
        </>
      )}
    </Card>
  );
};

HealthPlanMeasurement.defaultProps = {
  inlineLoading: [],
};
HealthPlanMeasurement.propTypes = {
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanMeasurement: PropTypes.shape({
    ...HealthPlanMeasurementPropType,
    healthPlan: PropTypes.number,
    lastMeasurement: PropTypes.shape(PatientMeasurementPropType),
    definition: PropTypes.shape(MeasurementPropType),
  }).isRequired,
  healthPlanGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
  deleteHealthPlanMeasurement: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanMeasurement;

export default class JSONStorageWrapper {
  constructor(storage) {
    this.storage = storage;
  }

  setItem(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    const value = this.storage.getItem(key);
    return value && JSON.parse(value);
  }

  removeItem(key) {
    delete this.storage.removeItem(key);
  }
}

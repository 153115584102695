import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Logo from 'common/assets/logos/myhealthplan-logo.svg';
import { Button } from 'common/components/buttons';
import { Form, Input, FormGeneralErrorMessage } from 'common/components/form';
import { CenteredCard, FlexBox } from 'common/components/layouts';
import {
  ImageContainer as StyledImageContainer,
  VersionDiv as StyledVersionDiv,
} from './styles';

const LoginPage = ({ handleSubmit }) => {
  return (
    <CenteredCard>
      <FlexBox
        height="20rem"
        minWidth="12rem"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledImageContainer src={Logo} />
        <Form>
          <Input
            name="email"
            label="Email"
            type="text"
            inputProps={{ autoCapitalize: 'none' }}
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              style: {
                position: 'absolute',
                top: 1.5,
                right: 0,
                lineHeight: 1,
              },
            }}
            autoFocus
            isAutocomplete
          />
          <Input
            name="password"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              style: {
                position: 'absolute',
                top: 1.5,
                right: 0,
                lineHeight: 1,
              },
            }}
            onKeyPress={handleSubmit}
            preventDefault
          />
          <FormGeneralErrorMessage name="detail" />
        </Form>
        <Button label="log in" onClick={handleSubmit} />
      </FlexBox>
      <StyledVersionDiv>
        <Typography variant="subtitle2" color="textSecondary">
          v{process.env.REACT_APP_VERSION}
        </Typography>
      </StyledVersionDiv>
    </CenteredCard>
  );
};

LoginPage.defaultProps = {};
LoginPage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default LoginPage;

import React from 'react';
import PropTypes from 'prop-types';
import { FullWidthForm as StyledFullWidthForm } from './styles';

const Form = ({ children }) => {
  return <StyledFullWidthForm>{children}</StyledFullWidthForm>;
};

Form.defaultProps = {};
Form.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Form;

import { call, put } from 'redux-saga/effects';
import {
  setLoadingOn,
  setLoadingOff,
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { createHealthPlanServiceSuccess } from 'services/database/actions/healthPlanServices';
import request from './request';

export default function* createSaga({ query, data, resolve, reject }) {
  try {
    query.withPageLoader
      ? yield put(
          setLoadingOn({
            actionParams: { type: 'CREATE_HEALTH_PLAN_SERVICE_INIT' },
          })
        )
      : yield put(setInlineLoadingOn({ data: data.definition }));
    const responseData = yield call(request, {
      query,
      data,
    });
    yield put(createHealthPlanServiceSuccess({ query, data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  } finally {
    query.withPageLoader
      ? yield put(
          setLoadingOff({
            actionParams: { type: 'CREATE_HEALTH_PLAN_SERVICE_SUCCESS' },
          })
        )
      : yield put(setInlineLoadingOff({ data: data.definition }));
  }
}

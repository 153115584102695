import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Warning } from '@mui/icons-material';
import { Button } from 'common/components/buttons';
import { Card, FlexBox } from 'common/components/layouts';
import GlobalTheme from 'common/styles/global';
import { renderFullName } from 'common/utils';
import { isEmpty } from 'lodash';

const ConfirmationDialog = ({
  open,
  generalPractitioner,
  onSubmit,
  onClose,
  loading,
}) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Transfer Patient</DialogTitle>
      <DialogContent>
        <Card
          style={{
            color: GlobalTheme.palette.error.contrastText,
            backgroundColor: GlobalTheme.palette.error.light,
          }}
        >
          <FlexBox width="100%" flexWrap="nowrap">
            <Warning color="error" />
            <Box ml={2}>
              <DialogContentText color="inherit">
                You are about to transfer this patient to another General
                Practitioner.
              </DialogContentText>
              <DialogContentText color="inherit" style={{ marginBottom: 0 }}>
                This action will revoke your access.
              </DialogContentText>
            </Box>
          </FlexBox>
        </Card>
        <DialogContentText color="textPrimary">
          Are you sure you want to transfer this patient to{' '}
          {renderFullName(generalPractitioner)}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          label="cancel"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={!isEmpty(loading)}
        />
        <Button
          label="transfer"
          color="error"
          onClick={onSubmit}
          disabled={!isEmpty(loading)}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  open: false,
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'common/components/form';

const SelectField = ({
  name,
  label,
  value,
  options,
  values,
  setValues,
  className,
  margin,
  startAdornment,
  renderValue,
  renderSelectLabel,
  renderSelectSubLabel,
  sortByIndex,
  onFocus,
  onBlur,
  disabled,
  nullOption,
  autoFocus,
}) => {
  // handle on select of type
  const [selected, setSelected] = useState(value);

  // required to update the selected value if the submit fails & value gets revered back to initialValue
  useEffect(() => {
    setSelected(value);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    setValues({
      ...values,
      [name]: selected,
    });
    // eslint-disable-next-line
  }, [selected, setValues]);

  return (
    <Select
      name={name}
      label={label}
      value={value}
      options={options}
      className={className}
      margin={margin}
      startAdornment={startAdornment}
      renderValue={renderValue}
      renderSelectLabel={renderSelectLabel}
      renderSelectSubLabel={renderSelectSubLabel}
      sortByIndex={sortByIndex}
      onSelect={setSelected}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      nullOption={nullOption}
      autoFocus={autoFocus}
    />
  );
};

SelectField.defaultProps = {
  margin: 'normal',
  disabled: false,
  nullOption: false,
  autoFocus: false,
};
SelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.object.isRequired,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired, // DISCUSS: PropType object?
  setValues: PropTypes.func.isRequired,
  className: PropTypes.string,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  startAdornment: PropTypes.element,
  renderValue: PropTypes.func,
  renderSelectLabel: PropTypes.func.isRequired,
  renderSelectSubLabel: PropTypes.func,
  sortByIndex: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  nullOption: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default SelectField;

import React from 'react';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const Input = ({
  name,
  label,
  placeholder,
  type,
  value,
  variant,
  color,
  autoFocus,
  style,
  margin,
  inputProps,
  InputProps,
  InputLabelProps,
  FormHelperTextProps,
  onChange,
  onClick,
  onFocus,
  onBlur,
  onKeyPress,
  disabled,
  preventDefault,
  touched,
  errorMessage,
  multiline,
  minRows,
  fullWidth,
}) => (
  <TextField
    id={name}
    name={name}
    label={label}
    placeholder={placeholder}
    type={type}
    value={!isUndefined(value) ? value : ''}
    variant={variant}
    color={color}
    error={touched && errorMessage && true}
    helperText={touched && errorMessage}
    inputProps={inputProps}
    InputProps={InputProps}
    InputLabelProps={InputLabelProps}
    FormHelperTextProps={FormHelperTextProps}
    disabled={disabled}
    onClick={onClick}
    onFocus={onFocus}
    onBlur={onBlur}
    onChange={onChange}
    onKeyPress={
      preventDefault
        ? (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.target.blur();
              onKeyPress();
            }
          }
        : onKeyPress
    }
    autoComplete="off"
    autoFocus={autoFocus}
    margin={margin}
    style={style}
    multiline={multiline}
    minRows={minRows}
    fullWidth={fullWidth}
  />
);

Input.defaultProps = {
  margin: 'normal',
  color: 'primary',
  onKeyPress: () => {},
  disabled: false,
  preventDefault: false,
  multiline: false,
  variant: 'standard',
};

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  color: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  style: PropTypes.any, // TODO: review prop type
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  preventDefault: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  errorMessage: PropTypes.string,
  multiline: PropTypes.bool,
};

export default Input;

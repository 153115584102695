import React, { useEffect } from 'react';
import { isNil, orderBy, isEmpty } from 'lodash';
import { Divider, Typography } from '@mui/material';
import { renderDate, renderFullName } from 'common/utils';
import GlobalTheme from 'common/styles/global';
import { FlexBox } from 'common/components/layouts';

const HealthPlanFeedback = ({
  patient,
  healthPlan,
  healthPlanFeedback,
  fetchHealthPlanFeedback,
  resetHealthPlanFeedback,
}) => {
  console.log(patient)
  useEffect(() => {
    if (!isNil(patient.id)) {
      fetchHealthPlanFeedback({ query: { healthPlanId: healthPlan.id } });
    }
    return () => resetHealthPlanFeedback();
  }, [patient, fetchHealthPlanFeedback, resetHealthPlanFeedback]);

  return isEmpty(healthPlanFeedback) ? (
    <Typography variant="h4" align="center">
      Patient has not provided any feedback
    </Typography>
  ) : (
    <>
      <Typography color="textSecondary" paragraph>
        Here is {renderFullName(patient)}'s feedback.
      </Typography>

      {orderBy(healthPlanFeedback, ['createdAt'], ['desc']).map((feedback, idx) => (
      <FlexBox flexDirection="column" key={idx}>
        {feedback.title && <Typography variant="h2" color={GlobalTheme.palette.primary.main}>{feedback.title}</Typography>}
        <Typography variant="h4" color="#a9abad" p="0 0 0.25rem">
          {renderDate(feedback.createdAt)}
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          {feedback.content}
        </Typography>
        {idx !== healthPlanFeedback.length-1 && <Divider flexItem sx={{margin: "0.5rem 0"}}/>}
       </FlexBox>
      ))}
    </>
  );
};

export default HealthPlanFeedback;

import { compose } from 'redux';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { withAction, withFormErrorHandler } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patients/add';
import {
  fetchProvidersInit,
  providersSelector,
  resetProviders,
} from 'services/database/actions/providers';
import {
  addPatientInit,
  patientErrorSelector,
} from 'services/database/actions/patient';
import { inlineLoadingSelector } from 'services/database/actions/loading';
import AddPatient from './AddPatient';

export default compose(
  withAction({
    actionName: 'addPatient',
    action: addPatientInit,
  }),
  withAction({
    actionName: 'fetchProviders',
    action: fetchProvidersInit,
  }),
  withAction({
    actionName: 'resetProviders',
    action: resetProviders,
  }),
  connect((state) => ({
    providers: providersSelector(state),
    formErrorData: patientErrorSelector(state),
    inlineLoading: inlineLoadingSelector(state),
  })),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  }),
  withFormErrorHandler
)(AddPatient);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { GoalPropType, HealthPlanGoalPropType } from 'common/propTypes';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchHealthPlanGoalsInit,
  healthPlanGoalsSelector,
  resetHealthPlanGoals,
} from 'services/database/actions/healthPlanGoals';
import { goalDefinitionsSelector } from 'services/database/actions/definitions';

function withHealthPlanGoals(OriginalComponent) {
  const Component = ({
    fetchHealthPlanGoals,
    resetHealthPlanGoals,
    ...props
  }) => {
    const { patientId } = useParams();
    useEffect(() => {
      fetchHealthPlanGoals({ query: { patientId } });
      return () => resetHealthPlanGoals();
    }, [patientId, fetchHealthPlanGoals, resetHealthPlanGoals]);

    return <OriginalComponent {...props} />;
  };

  Component.defaultProps = {
    healthPlanGoals: [],
  };
  Component.propTypes = {
    healthPlanGoals: PropTypes.arrayOf(
      PropTypes.shape({
        ...GoalPropType,
        ...HealthPlanGoalPropType,
        healthPlan: PropTypes.number,
      })
    ),
    goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)),
    fetchHealthPlanGoals: PropTypes.func.isRequired,
    resetHealthPlanGoals: PropTypes.func.isRequired,
  };

  const Container = compose(
    withAction({
      actionName: 'fetchHealthPlanGoals',
      action: fetchHealthPlanGoalsInit,
    }),
    withAction({
      actionName: 'resetHealthPlanGoals',
      action: resetHealthPlanGoals,
    }),
    connect((state) => ({
      healthPlanGoals: healthPlanGoalsSelector(state),
      goalDefinitions: goalDefinitionsSelector(state),
    })),
    withPropsMapping((props) => ({
      ...props,
      healthPlanGoals: Object.values(props.healthPlanGoals).map(
        (healthPlanGoal) => ({
          ...props.goalDefinitions[healthPlanGoal.definition],
          ...healthPlanGoal,
        })
      ),
    }))
  )(Component);

  return Container;
}

export default withHealthPlanGoals;

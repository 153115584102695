import React from 'react';
import { Card as MuiCard, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(MuiCard)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.spacing(1),
  margin: theme.spacing(0.25),
  padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
  boxShadow: '0 0 1.25rem 0 rgba(0, 0, 0, 0.05)',
}));

export const Card = ({ children, pb }) => (
  <StyledCard pb={pb}>
    <CardContent>{children}</CardContent>
  </StyledCard>
);

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  withAction,
  withIsAuthenticated,
  withAuthenticatedUser,
  withFullPageLayout,
} from 'common/HOCs';
import {
  dashboardPatientsSelector,
  dashboardHealthPlansSelector,
  fetchDashboardInit,
  resetDashboard,
} from 'services/database/actions/dashboard';
import DashboardPage from './DashboardPage';

export default compose(
  withAction({
    actionName: 'fetchDashboard',
    action: fetchDashboardInit,
  }),
  withAction({
    actionName: 'resetDashboard',
    action: resetDashboard,
  }),
  connect((state) => ({
    maxWidth: 'md',
    patients: dashboardPatientsSelector(state),
    healthPlans: dashboardHealthPlansSelector(state),
  })),
  withIsAuthenticated,
  withAuthenticatedUser,
  withFullPageLayout
)(DashboardPage);

import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { healthPlanAdminItem, updateHealthPlanAdminItem } }
) {
  return updateHealthPlanAdminItem({
    query: {
      healthPlanId: healthPlanAdminItem.healthPlan,
      healthPlanAdminItemId: healthPlanAdminItem.id,
    },
    data: mapValuesToProps(values),
  });
}

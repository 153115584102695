import { call, put } from 'redux-saga/effects';
import {
  setInlineLoadingOff,
  setInlineLoadingOn,
} from 'services/database/actions/loading';
import { updatePatientCycleOfCareItemSuccess } from 'services/database/actions/patientCycleOfCareItems';
import request from './request';

export default function* updateSaga({ query, data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: query.patientCycleOfCareItemId }));
    const responseData = yield call(request, { query, data });
    yield put(updatePatientCycleOfCareItemSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  } finally {
    yield put(setInlineLoadingOff({ data: query.patientCycleOfCareItemId }));
  }
}

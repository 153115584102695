export const FETCH_NOTIFICATIONS_INIT = 'FETCH_NOTIFICATIONS_INIT';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';

export const UPDATE_NOTIFICATION_INIT = 'UPDATE_NOTIFICATION_INIT';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';

export const FETCH_NOTIFICATION_COUNT_UNREAD_INIT =
  'FETCH_NOTIFICATIONS_COUNT_UNREAD_INIT';
export const FETCH_NOTIFICATION_COUNT_UNREAD_SUCCESS =
  'FETCH_NOTIFICATIONS_COUNT_UNREAD_SUCCESS';

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

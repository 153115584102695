import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@mui/material';
import { Card as StyledCard } from './styles';

const Card = ({ children, ...props }) => (
  <StyledCard {...props}>
    <CardContent>{children}</CardContent>
  </StyledCard>
);

Card.defaultProps = {};
Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;

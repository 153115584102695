import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Button } from 'common/components/buttons';
import { HEALTH_JOURNEY_STATUSES } from 'common/constants';
import { PatientPropType } from 'common/propTypes';
import Item from './components/Item';

const MultipleItemsDialog = ({
  open,
  multipleItems,
  date,
  patient,
  onSubmit,
  onClose,
  inlineLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{patient.firstName}'s Journey Items</DialogTitle>
      <DialogContent>
        <Typography variant="h5" color="secondary">
          {date}
        </Typography>
        {multipleItems.map((item, idx) => (
          <Item
            key={idx}
            item={item}
            patient={patient}
            checked={item.status === HEALTH_JOURNEY_STATUSES.COMPLETE}
            onSubmit={onSubmit}
            loading={inlineLoading.includes(`${item.id}-journeyItem`)}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          label="close"
          variant="outlined"
          color="secondary"
          onClick={onClose}
          disabled={!isEmpty(inlineLoading)}
        />
      </DialogActions>
    </Dialog>
  );
};

MultipleItemsDialog.defaultProps = {};
MultipleItemsDialog.propTypes = {
  open: PropTypes.bool,
  multipleItems: PropTypes.array,
  date: PropTypes.string,
  patient: PropTypes.shape(PatientPropType).isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  inlineLoading: PropTypes.array,
};

export default MultipleItemsDialog;

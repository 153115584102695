import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextField = styled(MuiTextField)(({ theme }) => ({
  label: {
    color: `${theme.palette.primary.main} !important`,
  },
  input: {
    color: `${theme.palette.text.primary} !important`,
  },
  'div:before': {
    borderBottom: 'unset !important',
  },
}));

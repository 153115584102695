import { put, call } from 'redux-saga/effects';
import { createHealthPlanGoalSuccess } from 'services/database/actions/healthPlanGoals';
import request from './request';

export default function* createSaga({ query, data, resolve, reject }) {
  try {
    const responseData = yield call(request, { query, data });
    yield put(createHealthPlanGoalSuccess({ data: responseData }));
    yield call(resolve);
  } catch (error) {
    yield call(reject, { error: error.data });
  }
}

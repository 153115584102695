import {
  FETCH_HEALTH_PLAN_MEASUREMENTS_INIT,
  FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS,
  CREATE_HEALTH_PLAN_MEASUREMENT_INIT,
  CREATE_HEALTH_PLAN_MEASUREMENT_SUCCESS,
  UPDATE_HEALTH_PLAN_MEASUREMENT_INIT,
  UPDATE_HEALTH_PLAN_MEASUREMENT_SUCCESS,
  DELETE_HEALTH_PLAN_MEASUREMENT_INIT,
  DELETE_HEALTH_PLAN_MEASUREMENT_SUCCESS,
  RESET_HEALTH_PLAN_MEASUREMENTS,
} from './actionNames';

export function fetchHealthPlanMeasurementsInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_MEASUREMENTS_INIT, ...actionParams };
}

export function fetchHealthPlanMeasurementsSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_MEASUREMENTS_SUCCESS, data };
}

export function createHealthPlanMeasurementInit(actionParams) {
  return { type: CREATE_HEALTH_PLAN_MEASUREMENT_INIT, ...actionParams };
}

export function createHealthPlanMeasurementSuccess({ data }) {
  return { type: CREATE_HEALTH_PLAN_MEASUREMENT_SUCCESS, data };
}

export function updateHealthPlanMeasurementInit(actionParams) {
  return { type: UPDATE_HEALTH_PLAN_MEASUREMENT_INIT, ...actionParams };
}

export function updateHealthPlanMeasurementSuccess({ data }) {
  return { type: UPDATE_HEALTH_PLAN_MEASUREMENT_SUCCESS, data };
}

export function deleteHealthPlanMeasurementInit(actionParams) {
  return { type: DELETE_HEALTH_PLAN_MEASUREMENT_INIT, ...actionParams };
}

export function deleteHealthPlanMeasurementSuccess({ data }) {
  return { type: DELETE_HEALTH_PLAN_MEASUREMENT_SUCCESS, data };
}

export function resetHealthPlanMeasurements(actionParams) {
  return { type: RESET_HEALTH_PLAN_MEASUREMENTS, ...actionParams };
}

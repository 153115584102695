import { isNull } from 'lodash';
import { renderPhoneNumber } from 'common/utils';

export default function ({
  patient = {
    firstName: '',
    lastName: '',
    email: '',
    generalPractitioner: { id: null },
    phoneNumber: '',
    dateOfBirth: null,
    sex: null,
    street: '',
    city: '',
    state: '',
    postcode: '',
  },
}) {
  return {
    firstName: patient.firstName,
    lastName: patient.lastName,
    email: patient.email,
    generalPractitioner: !isNull(patient.generalPractitioner.id)
      ? patient.generalPractitioner.id.toString()
      : '',
    phoneNumber: renderPhoneNumber(patient.phoneNumber),
    dateOfBirth: !isNull(patient.dateOfBirth)
      ? new Date(patient.dateOfBirth)
      : null,
    sex: !isNull(patient.sex) ? patient.sex.toString() : '',
    street: patient.street || '',
    city: patient.city || '',
    state: patient.state || '',
    postcode: patient.postcode || '',
  };
}

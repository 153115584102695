import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthPlanFeedback/fetch';
import {
  FETCH_HEALTH_PLAN_FEEDBACK_INIT,
} from './actionNames';

function* healthFeedbackFlowSaga() {
  yield all([    takeLatest(FETCH_HEALTH_PLAN_FEEDBACK_INIT, fetchSaga),
  ]);
}

export default healthFeedbackFlowSaga;

import { combineReducers } from 'redux';
import authReducer from './services/database/actions/auth/redux/reducer';
import userReducer from './services/database/actions/user/redux/reducer';
import definitionsReducer from './services/database/actions/definitions/redux/reducer';
import dashboardReducer from './services/database/actions/dashboard/redux/reducer';
import providersReducer from './services/database/actions/providers/redux/reducer';
import patientsReducer from './services/database/actions/patients/redux/reducer';
import patientReducer from './services/database/actions/patient/redux/reducer';
import patientConditionsReducer from './services/database/actions/patientConditions/redux/reducer';
import patientMeasurementsReducer from './services/database/actions/patientMeasurements/redux/reducer';
import patientCycleOfCareModulesReducer from './services/database/actions/patientCycleOfCareModules/redux/reducer';
import healthJourneyReducer from 'services/database/actions/healthJourney/redux/reducer';
import healthPlanReducer from './services/database/actions/healthPlans/redux/reducer';
import healthPlanReviewReducer from './services/database/actions/healthPlanReviews/redux/reducer';
import healthPlanActionsReducer from './services/database/actions/healthPlanActions/redux/reducer';
import healthPlanAdminItemsReducer from './services/database/actions/healthPlanAdminItems/redux/reducer';
import healthPlanGoalsReducer from './services/database/actions/healthPlanGoals/redux/reducer';
import healthPlanMeasurementsReducer from './services/database/actions/healthPlanMeasurements/redux/reducer';
import healthPlanServicesReducer from './services/database/actions/healthPlanServices/redux/reducer';
import notificationsReducer from './services/database/actions/notifications/redux/reducer';
import loadingReducer from './services/database/actions/loading/redux/reducer';
import healthPlanFeedbackReducer from './services/database/actions/healthPlanFeedback/redux/reducer';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  definitions: definitionsReducer,
  dashboard: dashboardReducer,
  providers: providersReducer,
  patients: patientsReducer,
  patient: patientReducer,
  patientConditions: patientConditionsReducer,
  patientMeasurements: patientMeasurementsReducer,
  patientCycleOfCareModules: patientCycleOfCareModulesReducer,
  healthJourney: healthJourneyReducer,
  healthPlan: healthPlanReducer,
  healthPlanReviews: healthPlanReviewReducer,
  healthPlanActions: healthPlanActionsReducer,
  healthPlanAdminItems: healthPlanAdminItemsReducer,
  healthPlanGoals: healthPlanGoalsReducer,
  healthPlanMeasurements: healthPlanMeasurementsReducer,
  healthPlanServices: healthPlanServicesReducer,
  healthPlanFeedback: healthPlanFeedbackReducer,
  notifications: notificationsReducer,
  loading: loadingReducer,
});

export const FETCH_HEALTH_PLAN_GOALS_INIT = 'FETCH_HEALTH_PLAN_GOALS_INIT';
export const FETCH_HEALTH_PLAN_GOALS_SUCCESS =
  'FETCH_HEALTH_PLAN_GOALS_SUCCESS';

export const CREATE_HEALTH_PLAN_GOAL_INIT = 'CREATE_HEALTH_PLAN_GOAL_INIT';
export const CREATE_HEALTH_PLAN_GOAL_SUCCESS =
  'CREATE_HEALTH_PLAN_GOAL_SUCCESS';

export const DELETE_HEALTH_PLAN_GOAL_INIT = 'DELETE_HEALTH_PLAN_GOAL_INIT';
export const DELETE_HEALTH_PLAN_GOAL_SUCCESS =
  'DELETE_HEALTH_PLAN_GOAL_SUCCESS';

export const RESET_HEALTH_PLAN_GOALS = 'RESET_HEALTH_PLAN_GOALS';

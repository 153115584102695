import { Step, StepLabel, StepButton, Typography } from '@mui/material';
import GlobalTheme from 'common/styles/global';

export default function ({ key, item, labelProps, disabled }) {
  return (
    <Step key={key} sx={{ minWidth: GlobalTheme.spacing(20) }}>
      <StepButton onClick={item.onClick} disabled={disabled}>
        <StepLabel {...labelProps}>
          <Typography color={item.overdue && 'error'}>{item.name}</Typography>
          <Typography variant="body2" color={item.overdue && 'error'}>
            {item.dateLabel}
          </Typography>
        </StepLabel>
      </StepButton>
    </Step>
  );
}

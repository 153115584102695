import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import {
  FlexBox,
  ContentBox,
  Card,
  GoalChips,
} from 'common/components/layouts';
import {
  ActionPropType,
  GoalPropType,
  HealthPlanActionPropType,
  HealthPlanGoalPropType,
} from 'common/propTypes';

const HealthPlanAction = ({
  index,
  healthPlanAction,
  healthPlanActionGoals,
}) => {
  return (
    <Card>
      <FlexBox flexDirection="column">
        <FlexBox alignItems="baseline">
          <ContentBox width="max-content" mr={1}>
            <Typography variant="h5" color="textSecondary">
              Action #{index}
            </Typography>
          </ContentBox>
          <GoalChips goals={healthPlanActionGoals} dense />
        </FlexBox>
        <Typography variant="h2">{healthPlanAction.definition.name}</Typography>
      </FlexBox>
    </Card>
  );
};

HealthPlanAction.propTypes = {
  index: PropTypes.number.isRequired,
  healthPlanAction: PropTypes.shape({
    ...HealthPlanActionPropType,
    healthPlan: PropTypes.number,
    definition: PropTypes.shape(ActionPropType),
  }).isRequired,
  healthPlanActionGoals: PropTypes.arrayOf(
    PropTypes.shape({
      ...GoalPropType,
      ...HealthPlanGoalPropType,
      healthPlan: PropTypes.number,
    })
  ).isRequired,
};

export default HealthPlanAction;

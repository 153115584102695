import { compose } from 'redux';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import { withAction, withPropsMapping } from 'common/HOCs';
import { inlineLoadingSelector } from 'services/database/actions/loading';
import {
  createHealthPlanServiceInit,
  deleteHealthPlanServiceInit,
} from 'services/database/actions/healthPlanServices';
import HealthPlanService from './HealthPlanService';

export default compose(
  // DISCUSS: Move to higher level so not initialised ?
  withAction({
    actionName: 'createHealthPlanService',
    action: createHealthPlanServiceInit,
  }),
  withAction({
    actionName: 'deleteHealthPlanService',
    action: deleteHealthPlanServiceInit,
  }),
  connect((state) => ({
    inlineLoading: inlineLoadingSelector(state),
  })),
  withPropsMapping((props) => ({
    selected: !isUndefined(props.selectedService),
    excluded: !isUndefined(
      props.excludedServices.find(
        (excludedService) => excludedService === props.service.id
      )
    ),
    ...props,
  }))
)(HealthPlanService);

import React, { useEffect } from 'react';
import { isEmpty, isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { PROVIDER_GROUPS, PROVIDER_TYPES } from 'common/constants';
import {
  GoalPropType,
  ServicePropType,
  HealthPlanPropType,
  HealthPlanServicePropType,
  UserPropType,
  PatientPropType,
} from 'common/propTypes';
import { renderFirstName, renderFullName } from 'common/utils';
import PracticeStaff from './components/PracticeStaff';
import HealthPlanService from './components/HealthPlanService';

const HealthPlanTeam = ({
  user,
  patient,
  healthPlan,
  healthPlanServices,
  goalDefinitions,
  fetchHealthPlanServices,
  resetHealthPlanServices,
  loading,
  readOnly,
}) => {
  useEffect(() => {
    if (!isNil(healthPlan.id)) {
      fetchHealthPlanServices({ query: { healthPlanId: healthPlan.id } });
    }
    return () => {
      resetHealthPlanServices();
    };
    // eslint-disable-next-line
  }, []);

  const practiceStaff = [
      !isEmpty(healthPlan.generalPractitioner)
        ? { isDoctor: true, healthProfessional: healthPlan.generalPractitioner }
        : { isDoctor: true, providerType: PROVIDER_TYPES.GENERAL_PRACTITIONER },
      !isEmpty(healthPlan.coach)
        ? { isDoctor: false, healthProfessional: healthPlan.coach }
        : { isDoctor: false, providerType: PROVIDER_TYPES.PRACTICE_NURSE },
    ],
    practiceStaffHealthServices = healthPlanServices.filter(
      (healthPlanService) =>
        healthPlanService.definition.providerGroup ===
        PROVIDER_GROUPS.PRACTICE_STAFF
    ),
    otherHealthServices = healthPlanServices
      .filter(
        (healthPlanService) =>
          healthPlanService.definition.providerGroup !==
          PROVIDER_GROUPS.PRACTICE_STAFF
      )
      .map((healthPlanService) => ({
        ...healthPlanService,
        provider: healthPlan.healthTeam[healthPlanService.provider],
      }));

  return (
    <>
      <Typography color="textSecondary" paragraph>
        Here is {renderFullName(patient)}'s suggested Health Team. Update any
        professional to finalise {renderFirstName(patient)}'s Health Team.
      </Typography>

      <Grid container spacing={3}>
        {practiceStaff.map(({ isDoctor, healthProfessional }, idx) => (
          <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
            <PracticeStaff
              user={user}
              isDoctor={isDoctor}
              healthProfessional={healthProfessional}
              healthPlan={healthPlan}
              goalDefinitions={goalDefinitions}
              healthPlanServices={practiceStaffHealthServices}
              loading={loading}
              readOnly={readOnly}
            />
          </Grid>
        ))}
        {sortBy(otherHealthServices, [
          'definition.providerGroup',
          'definition.name',
        ]).map((healthPlanService, idx) => (
          <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
            <HealthPlanService
              healthPlan={healthPlan}
              healthPlanService={healthPlanService}
              goalDefinitions={goalDefinitions}
              loading={loading}
              readOnly={readOnly}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

HealthPlanTeam.defaultProps = {
  healthPlanServices: [],
  goalDefinitions: {},
};
HealthPlanTeam.propTypes = {
  user: PropTypes.shape(UserPropType).isRequired,
  patient: PropTypes.shape(PatientPropType).isRequired,
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanServices: PropTypes.arrayOf(
    PropTypes.shape({
      ...HealthPlanServicePropType,
      healthPlan: PropTypes.number.isRequired,
      definition: PropTypes.shape(ServicePropType),
    })
  ),
  goalDefinitions: PropTypes.objectOf(PropTypes.shape(GoalPropType)),
  fetchHealthPlanServices: PropTypes.func.isRequired,
  resetHealthPlanServices: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanTeam;

import { http } from 'services/backend/http';
import adaptResponseData from './adaptResponseData';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query: { patientId } }) {
  return http
    .get(`/patients/${patientId}/conditions/`)
    .then(adaptResponseData)
    .catch(handleHTTPError);
}

import { all, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/providers/fetch';
import addSaga from 'services/backend/actions/providers/add';
import updateSaga from 'services/backend/actions/providers/update';
import {
  FETCH_PROVIDERS_INIT,
  ADD_PROVIDER_INIT,
  UPDATE_PROVIDER_INIT,
} from './actionNames';

function* healthTeamFlowSaga() {
  yield all([
    takeLatest(FETCH_PROVIDERS_INIT, fetchSaga),
    takeLatest(ADD_PROVIDER_INIT, addSaga),
    takeLatest(UPDATE_PROVIDER_INIT, updateSaga),
  ]);
}

export default healthTeamFlowSaga;

import React from 'react';
import { Image } from 'common/components/layouts';
import { styled } from '@mui/material/styles';

const Div = styled('div')({
  width: '100%',
  padding: '1.25rem 2.5rem 0 2.5rem',
});

export const ImageContainer = ({ src }) => (
  <Div>
    <Image src={src} />
  </Div>
);

export const VersionDiv = styled('div')({
  position: 'absolute',
  bottom: '0.5rem',
  right: '0.5rem',
});

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { PageLoader } from 'common/components/loaders';
import getComponentDisplayName from './utils/getComponentDisplayName';
import { loadingSelector } from 'services/database/actions/loading';
import { isEmpty } from 'lodash';

function withPageLoader(OriginalComponent) {
  const Component = ({ ...props }) => {
    return (
      <>
        {!isEmpty(props.loading) && <PageLoader />}
        <OriginalComponent {...props} />
      </>
    );
  };

  Component.defaultProps = {};
  Component.propTypes = {
    loading: PropTypes.array,
  };
  Component.displayName = `withPageLoader(${getComponentDisplayName(
    OriginalComponent
  )})`;

  const Container = compose(
    connect((state) => ({
      loading: loadingSelector(state),
    }))
  )(Component);

  return Container;
}

export default withPageLoader;

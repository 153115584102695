import { GOAL_PROGRESS_REVIEW_STATUSES } from 'common/constants';

export default {
  [GOAL_PROGRESS_REVIEW_STATUSES.FULLY]: {
    id: GOAL_PROGRESS_REVIEW_STATUSES.FULLY,
    label: 'Fully',
    color: 'success',
  },
  [GOAL_PROGRESS_REVIEW_STATUSES.MOSTLY]: {
    id: GOAL_PROGRESS_REVIEW_STATUSES.MOSTLY,
    label: 'Mostly',
    color: 'primary',
  },
  [GOAL_PROGRESS_REVIEW_STATUSES.PARTIALLY]: {
    id: GOAL_PROGRESS_REVIEW_STATUSES.PARTIALLY,
    label: 'Partially',
    color: 'warning',
  },
  [GOAL_PROGRESS_REVIEW_STATUSES.NOT_AT_ALL]: {
    id: GOAL_PROGRESS_REVIEW_STATUSES.NOT_AT_ALL,
    label: 'Not At All',
    color: 'error',
  },
};

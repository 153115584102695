import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchPatientConditionsInit,
  deletePatientConditionInit,
  resetPatientConditions,
  patientConditionsSelector,
} from 'services/database/actions/patientConditions';
import { conditionDefinitionsSelector } from 'services/database/actions/definitions';
import PatientConditions from './PatientConditions';

export default compose(
  withAction({
    actionName: 'fetchPatientConditions',
    action: fetchPatientConditionsInit,
  }),
  withAction({
    actionName: 'deletePatientCondition',
    action: deletePatientConditionInit,
  }),
  withAction({
    actionName: 'resetPatientConditions',
    action: resetPatientConditions,
  }),
  connect((state) => ({
    patientConditions: patientConditionsSelector(state),
    conditionsDefinitions: conditionDefinitionsSelector(state),
  })),
  withPropsMapping((props) => ({
    ...props,
    patientConditions: Object.values(props.patientConditions).map(
      (patientCondition) => ({
        ...props.conditionsDefinitions[patientCondition.definition],
        ...patientCondition,
      })
    ),
    availablePatientConditions: Object.values(
      props.conditionsDefinitions
    ).filter(
      (condition) =>
        !Object.values(props.patientConditions).some(
          (patientCondition) => patientCondition.definition === condition.id
        )
    ),
  }))
)(PatientConditions);

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import Http404 from 'common/assets/graphics/http-404.svg';
import { Button } from 'common/components/buttons';
import { FlexBox } from 'common/components/layouts';
import GlobalTheme from 'common/styles/global';
import { ImageContainer as StyledImageContainer } from './styles';

const Http404Page = () => {
  let history = useHistory();
  const onClick = () => history.go(-1);

  return (
    <FlexBox
      height={`calc(100vh - ${GlobalTheme.constants.MuiAppBar.height})`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <StyledImageContainer src={Http404} />
      <Typography variant="h2" paragraph>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button label="Go Back" onClick={onClick} />
    </FlexBox>
  );
};

Http404Page.defaultProps = {};
Http404Page.propTypes = {};

export default Http404Page;

import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from 'common/components/layouts';
import { PROVIDER_GROUP_NAMES, PROVIDER_TYPE_NAMES } from 'common/constants';
import { ProviderPropType } from 'common/propTypes';

const Provider = ({ provider, onClick }) => {
  return (
    <TableRow color="success" onClick={onClick}>
      <TableCell>{provider.firstName}</TableCell>
      <TableCell>{provider.lastName}</TableCell>
      <TableCell>{PROVIDER_GROUP_NAMES[provider.userType]}</TableCell>
      <TableCell>
        {PROVIDER_TYPE_NAMES[provider.providerProfile.providerType]}
      </TableCell>
    </TableRow>
  );
};

Provider.defaultProps = {};
Provider.propTypes = {
  provider: PropTypes.shape(ProviderPropType),
};

export default Provider;

import { call, put } from 'redux-saga/effects';
import { fetchHealthPlanServicesSuccess } from 'services/database/actions/healthPlanServices';
import request from './request';

export default function* fetchSaga({ query }) {
  try {
    const responseData = yield call(request, { query });
    yield put(fetchHealthPlanServicesSuccess({ data: responseData }));
  } catch (error) {}
}

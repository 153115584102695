import {
  FETCH_DEFINITIONS_INIT,
  FETCH_DEFINITIONS_SUCCESS,
} from './actionNames';

export function fetchDefinitionsInit(actionParams) {
  return { type: FETCH_DEFINITIONS_INIT, ...actionParams };
}

export function fetchDefinitionsSuccess(actionParams) {
  return { type: FETCH_DEFINITIONS_SUCCESS, ...actionParams };
}

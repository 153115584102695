import PropTypes from 'prop-types';

export default {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  isDefault: PropTypes.bool,
  barriers: PropTypes.arrayOf(PropTypes.number),
  measurements: PropTypes.arrayOf(PropTypes.number),
};

import { Card as MuiCard } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Card = styled(MuiCard)(
  ({ width, height, minWidth, maxWidth, minHeight, maxHeight }) => ({
    display: 'block',
    width: width,
    minWidth: minWidth,
    maxWidth: maxWidth,
    height: height,
    minHeight: minHeight,
    maxHeight: maxHeight,
  })
);

import mapValuesToProps from './mapValuesToProps';

export default function ({
  goalProgressBarrier,
  solution,
  createGoalProgressSolution,
}) {
  return createGoalProgressSolution({
    query: {
      healthPlanId: goalProgressBarrier.healthPlan,
      healthPlanReviewId: goalProgressBarrier.healthPlanReview,
      goalProgressReviewId: goalProgressBarrier.goalProgressReview,
      goalProgressBarrierId: goalProgressBarrier.id,
    },
    data: mapValuesToProps({ solution, goalProgressBarrier }),
  });
}

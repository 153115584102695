import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_PATIENT_CONDITIONS_SUCCESS,
  RESET_PATIENT_CONDITIONS,
  CREATE_PATIENT_CONDITION_SUCCESS,
  DELETE_PATIENT_CONDITION_SUCCESS,
} from './actionNames';

const initialState = {};

function handleFetchPatientConditionsSuccess(state, { data }) {
  return { ...state, ...data };
}

function handleCreatePatientConditionSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleDeletePatientConditionSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    delete draftState[data.patientConditionId];
  });
  return { ...nextState };
}

function handleResetPatientConditions() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_PATIENT_CONDITIONS_SUCCESS]: handleFetchPatientConditionsSuccess,
  [CREATE_PATIENT_CONDITION_SUCCESS]: handleCreatePatientConditionSuccess,
  [DELETE_PATIENT_CONDITION_SUCCESS]: handleDeletePatientConditionSuccess,
  [RESET_PATIENT_CONDITIONS]: handleResetPatientConditions,
};

export default buildReducer(initialState, ACTION_HANDLERS);

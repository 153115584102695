import { Box as MuiBox } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Box = styled(MuiBox)(
  ({
    width,
    height,
    margin,
    flexDirection,
    flexWrap,
    flexBasis,
    flexGrow,
    alignItems,
    justifyContent,
    overflow,
  }) => ({
    height: height,
    width: width,
    margin: margin,
    display: 'flex',
    flexDirection: flexDirection || 'row',
    flexWrap: flexWrap || 'wrap',
    flexBasis: flexBasis,
    flexGrow: flexGrow,
    alignItems: alignItems || 'flex-start',
    justifyContent: justifyContent || 'flex-start',
    overflow: overflow,
  })
);

import { isUndefined } from 'lodash';

export default function (
  values,
  { props: { onSuccess, healthPlan, updateHealthPlan } }
) {
  const handleOnSuccess = () => {
      !isUndefined(onSuccess) && onSuccess();
    },
    handleOnError = () => {};

  return updateHealthPlan({
    query: { healthPlanId: healthPlan.patient },
    data: values,
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

import mapValuesToProps from './mapValuesToProps';

export default function (values, { props: { user, updateUser }, resetForm }) {
  const handleOnSuccess = () => {},
    handleOnError = () => {
      resetForm();
    };

  return updateUser({
    query: { userId: user.id },
    data: mapValuesToProps(values),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

import { createSelector } from 'reselect';

const selectPatientMeasurements = (state) => state.patientMeasurements;

export const patientMeasurementsSelector = createSelector(
  [selectPatientMeasurements],
  (patientMeasurements) => {
    return patientMeasurements;
  }
);

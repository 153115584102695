import normalizeData from 'services/backend/dataNormalizer/normalizeData';

const adaptResponseData = function (data) {
  const normalizedItems = data.map((module) => {
    return {
      ...module,
      patientCycleOfCareItems: normalizeData(
        module.patientCycleOfCareItems,
        'patientCycleOfCareItems'
      ),
    };
  });
  return normalizeData(normalizedItems, 'patientCycleOfCareModules');
};
export default adaptResponseData;

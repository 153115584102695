import { compose } from 'redux';
import { withHealthPlanGoals, withPropsMapping } from 'common/HOCs';
import HealthPlanGoals from './HealthPlanGoals';

export default compose(
  withHealthPlanGoals,
  withPropsMapping((props) => ({
    availableHealthPlanGoals: Object.values(props.goalDefinitions).filter(
      (definition) =>
        !Object.values(props.healthPlanGoals).some(
          (healthPlanGoal) => healthPlanGoal.definition === definition.id
        )
    ),
    ...props,
  }))
)(HealthPlanGoals);

import { isEmpty, isUndefined } from 'lodash';

export default function ({
  healthPlan = {
    coach: {},
  },
  formikFields,
}) {
  const values = {
    coach: !isEmpty(healthPlan.coach) ? healthPlan.coach.id : null,
    status: healthPlan.status,
  };
  //formikFields always defined when this function is called
  return isUndefined(formikFields)
    ? values
    : Object.keys(values)
        .filter((key) => formikFields.includes(key))
        .reduce((acc, cur) => {
          acc[cur] = values[cur];
          return acc;
        }, {});
}

import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { patient, createPatientCondition } }
) {
  const handleOnSuccess = () => {},
    handleOnError = () => {};

  return createPatientCondition({
    query: { patientId: patient.id },
    data: mapValuesToProps(values),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

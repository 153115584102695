import { http } from 'services/backend/http';
import adaptResponseData from './adaptResponseData';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({
  query: { healthPlanId, healthPlanReviewId, goalProgressReviewId },
  data,
}) {
  return http
    .post(
      `/health_plans/${healthPlanId}/reviews/${healthPlanReviewId}/goal_progress_reviews/${goalProgressReviewId}/goal_progress_barriers/`,
      { data }
    )
    .then(adaptResponseData)
    .catch(handleHTTPError);
}

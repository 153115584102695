export default function ({
  healthPlan,
  downloadHealthPlan,
  disableLoader = () => {},
}) {
  const handleOnSuccess = (download) => {
      const contentType = download.headers['content-type'],
        filename = download.headers['content-disposition']
          .split('filename=')
          .pop(),
        file = new Blob([download.data], { type: contentType }),
        url = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      disableLoader();
    },
    handleOnError = () => {
      disableLoader();
    };

  return downloadHealthPlan({ query: { healthPlanId: healthPlan.patient } })
    .then(({ download }) => {
      handleOnSuccess(download);
    })
    .catch(handleOnError);
}

import { buildReducer } from 'common/utils/redux';
import {
  FETCH_USER_SUCCESS,
  RESET_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from './actionNames';

const initialState = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  practices: [],
  errors: {},
};

function handleFetchUser(state, { data }) {
  return data;
}

function handleUpdateUser(state, { data }) {
  return { ...state, ...data };
}

function handleError(state, { error: { data } }) {
  return { ...state, errors: data };
}

export function handleResetUser(state) {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_USER_SUCCESS]: handleFetchUser,
  [UPDATE_USER_SUCCESS]: handleUpdateUser,
  [UPDATE_USER_ERROR]: handleError,
  [RESET_USER]: handleResetUser,
};

export default buildReducer(initialState, ACTION_HANDLERS);

export const FETCH_HEALTH_PLAN_INIT = 'FETCH_HEALTH_PLAN_INIT';
export const FETCH_HEALTH_PLAN_SUCCESS = 'FETCH_HEALTH_PLAN_SUCCESS';

export const CREATE_HEALTH_PLAN_INIT = 'CREATE_HEALTH_PLAN_INIT';
export const CREATE_HEALTH_PLAN_SUCCESS = 'CREATE_HEALTH_PLAN_SUCCESS';

export const UPDATE_HEALTH_PLAN_INIT = 'UPDATE_HEALTH_PLAN_INIT';
export const UPDATE_HEALTH_PLAN_SUCCESS = 'UPDATE_HEALTH_PLAN_SUCCESS';
export const UPDATE_HEALTH_PLAN_ERROR = 'UPDATE_HEALTH_PLAN_ERROR';

export const DOWNLOAD_HEALTH_PLAN_INIT = 'DOWNLOAD_HEALTH_PLAN_INIT';
export const DOWNLOAD_HEALTH_PLAN_SUCCESS = 'DOWNLOAD_HEALTH_PLAN_SUCCESS';

export const RESET_HEALTH_PLAN = 'RESET_HEALTH_PLAN';

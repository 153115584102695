import { createSelector } from 'reselect';

const selectActions = (state) => {
  return state.definitions.actions;
};
const selectAdminItems = (state) => {
  return state.definitions.adminItems;
};
const selectBarriers = (state) => {
  return state.definitions.barriers;
};
const selectBarrier = (state, barrier) => barrier;
const selectBillableItems = (state) => {
  return state.definitions.billableItems;
};
const selectConditions = (state) => {
  return state.definitions.conditions;
};
const selectCycleOfCareItems = (state) => {
  return state.definitions.cycleOfCareItems;
};
const selectCycleOfCareModules = (state) => {
  return state.definitions.cycleOfCareModules;
};
const selectGoalBarriers = (state) => {
  return state.definitions.goalBarriers;
};
const selectGoals = (state) => {
  return state.definitions.goals;
};
const selectMeasurements = (state) => {
  return state.definitions.measurements;
};
const selectServices = (state) => {
  return state.definitions.services;
};
const selectService = (state, service) => service;
const selectSolutions = (state) => {
  return state.definitions.solutions;
};
const selectTasks = (state) => {
  return state.definitions.tasks;
};

export const actionDefinitionsSelector = createSelector(
  [selectActions],
  (actions) => actions
);

export const adminItemDefinitionsSelector = createSelector(
  [selectAdminItems],
  (adminItems) => adminItems
);

export const barrierDefinitionsSelector = createSelector(
  [selectBarriers],
  (barriers) => barriers
);

export const billableItemDefinitionsSelector = createSelector(
  [selectBillableItems],
  (billableItems) => billableItems
);

export const conditionDefinitionsSelector = createSelector(
  [selectConditions],
  (conditions) => conditions
);

export const cyclesOfCareItemDefinitionsSelector = createSelector(
  [selectCycleOfCareItems],
  (cycleOfCareItems) => cycleOfCareItems
);

export const cyclesOfCareModuleDefinitionsSelector = createSelector(
  [selectCycleOfCareModules],
  (cycleOfCareModules) => cycleOfCareModules
);

export const goalBarrierDefinitionsSelector = createSelector(
  [selectGoalBarriers],
  (goalBarriers) => goalBarriers
);

export const goalDefinitionsSelector = createSelector(
  [selectGoals],
  (goals) => goals
);

export const measurementDefinitionsSelector = createSelector(
  [selectMeasurements],
  (measurements) => measurements
);

export const serviceDefinitionsSelector = createSelector(
  [selectServices],
  (services) => services
);

export const solutionDefinitionsSelector = createSelector(
  [selectSolutions],
  (solutions) => solutions
);

export const taskDefinitionsSelector = createSelector(
  [selectTasks],
  (tasks) => tasks
);

export const serviceTaskDefinitionsSelector = createSelector(
  [selectService, selectTasks],
  (service, tasks) => service.tasks.map((taskId) => tasks[taskId])
);

export const barrierSolutionDefinitionsSelector = createSelector(
  [selectBarrier, selectGoalBarriers, selectSolutions],
  (barrier, goalBarriers, solutions) =>
    [
      ...new Set(
        Object.values(goalBarriers)
          .filter((goalBarrier) => goalBarrier.barrier === barrier.id)
          .reduce((acc, cur) => acc.concat(...cur.solutions), [])
      ),
    ].map((solution) => solutions[solution])
);

export default {
  GENERAL_PRACTITIONER: 1,
  PRACTICE_NURSE: 2,
  PHARMACIST: 3,
  PHYSIOTHERAPIST: 4,
  PODIATRIST: 5,
  EXERCISE_PHYSIOLOGIST: 6,
  DIABETES_EDUCATOR: 7,
  DIETICIAN: 8,
  OCCUPATIONAL_THERAPIST: 9,
  ABORIGINAL_AND_TORRES_STRAIT_ISLANDER_HEALTH_PRACTITIONER: 10,
  ABORIGINAL_HEALTH_WORKER: 11,
  AUDIOLOGIST: 12,
  CHIROPRACTOR: 13,
  SPEECH_PATHOLOGIST: 14,
  OSTEOPATH: 15,
  MENTAL_HEALTH_WORKER: 16,
  PSYCHOLOGIST: 17,
  SPECIALIST_IN_ADDICTION_MEDICINE: 18,
  ANAESTHETIST: 19,
  DERMATOLOGIST: 20,
  EMERGENCY_PHYSICIAN: 21,
  PAEDIATRIC_EMERGENCY_PHYSICIAN: 22,
  INTENSIVE_CARE_PHYSICIAN: 23,
  PAEDIATRIC_INTENSIVE_CARE_PHYSICIAN: 24,
  MEDICAL_ADMINISTRATOR: 25,
  OBSTETRICIAN_AND_GYNAECOLOGIST: 26,
  GYNAECOLOGICAL_ONCOLOGIST: 27,
  SPECIALIST_IN_MATERNAL_FETAL_MEDICINE: 28,
  SPECIALIST_IN_OBSTETRICS_AND_GYNAECOLOGICAL_ULTRASOUND: 29,
  SPECIALIST_IN_REPRODUCTIVE_ENDOCRINOLOGY_AND_INFERTILITY: 30,
  UROGYNAECOLOGIST: 31,
  OCCUPATIONAL_AND_ENVIRONMENTAL_PHYSICIAN: 32,
  OPHTHALMOLOGIST: 33,
  PAEDIATRICIAN: 34,
  PAEDIATRIC_CLINICAL_GENETICIST: 35,
  SPECIALIST_IN_COMMUNITY_CHILD_HEALTH: 36,
  GENERAL_PAEDIATRICIAN: 37,
  NEONATOLOGIST: 38,
  PAEDIATRIC_CARDIOLOGIST: 39,
  PAEDIATRIC_CLINICAL_PHARMACOLOGIST: 40,
  PAEDIATRIC_ENDOCRINOLOGIST: 41,
  PAEDIATRIC_GASTROENTEROLOGIST_AND_HEPATOLOGIST: 42,
  PAEDIATRIC_HAEMATOLOGIST: 43,
  PAEDIATRIC_IMMUNOLOGIST_AND_ALLERGIST: 44,
  PAEDIATRIC_INFECTIOUS_DISEASES_PHYSICIAN: 45,
  PAEDIATRIC_MEDICAL_ONCOLOGIST: 46,
  PAEDIATRIC_NEPHROLOGIST: 47,
  PAEDIATRIC_NEUROLOGIST: 48,
  PAEDIATRIC_NUCLEAR_MEDICINE_PHYSICIAN: 49,
  PAEDIATRIC_PALLIATIVE_MEDICINE_PHYSICIAN: 50,
  PAEDIATRIC_REHABILITATION_PHYSICIAN: 51,
  PAEDIATRIC_RESPIRATORY_AND_SLEEP_MEDICINE_PHYSICIAN: 52,
  PAEDIATRIC_RHEUMATOLOGIST: 53,
  PAIN_MEDICINE_PHYSICIAN: 54,
  PALLIATIVE_MEDICINE_PHYSICIAN: 55,
  PATHOLOGIST: 56,
  GENERAL_PATHOLOGIST: 57,
  ANATOMICAL_PATHOLOGIST: 58,
  CHEMICAL_PATHOLOGIST: 59,
  HAEMATOLOGIST: 60,
  IMMUNOLOGIST: 61,
  MICROBIOLOGIST: 62,
  FORENSIC_PATHOLOGIST: 63,
  SPECIALIST_PHYSICIAN: 64,
  CARDIOLOGIST: 65,
  CLINICAL_GENETICIST: 66,
  CLINICAL_PHARMACOLOGIST: 67,
  ENDOCRINOLOGIST: 68,
  GASTROENTEROLOGIST_AND_HEPATOLOGIST: 69,
  SPECIALIST_GENERAL_PHYSICIAN: 70,
  GERIATRICIAN: 71,
  IMMUNOLOGIST_AND_ALLERGIST: 72,
  INFECTIOUS_DISEASES_PHYSICIAN: 73,
  MEDICAL_ONCOLOGIST: 74,
  NEPHROLOGIST: 75,
  NEUROLOGIST: 76,
  NUCLEAR_MEDICINE_PHYSICIAN: 77,
  RESPIRATORY_AND_SLEEP_MEDICINE_PHYSICIAN: 78,
  RHEUMATOLOGIST: 79,
  PSYCHIATRIST: 80,
  PUBLIC_HEALTH_PHYSICIAN: 81,
  RADIATION_ONCOLOGIST: 82,
  RADIOLOGIST: 83,
  SPECIALIST_IN_NUCLEAR_MEDICINE: 84,
  REHABILITATION_PHYSICIAN: 85,
  SEXUAL_HEALTH_PHYSICIAN: 86,
  SPORT_AND_EXERCISE_PHYSICIAN: 87,
  SPECIALIST_SURGEON: 88,
  CARDIO_THORACIC_SURGEON: 89,
  GENERAL_SURGEON: 90,
  NEUROSURGEON: 91,
  ORTHOPAEDIC_SURGEON: 92,
  OTOLARYNGOLOGIST: 93,
  ORAL_AND_MAXILLOFACIAL_SURGEON: 94,
  PAEDIATRIC_SURGEON: 95,
  PLASTIC_SURGEON: 96,
  UROLOGIST: 97,
  VASCULAR_SURGEON: 98,
  OPTOMETRIST: 99,
};

import { all, takeEvery } from 'redux-saga/effects';
import createSaga from 'services/backend/actions/goalProgressSolutions/create';
import deleteSaga from 'services/backend/actions/goalProgressSolutions/delete';
import {
  CREATE_GOAL_PROGRESS_SOLUTION_INIT,
  DELETE_GOAL_PROGRESS_SOLUTION_INIT,
} from './actionNames';

function* goalProgressSolutionsFlowSaga() {
  yield all([
    takeEvery(CREATE_GOAL_PROGRESS_SOLUTION_INIT, createSaga),
    takeEvery(DELETE_GOAL_PROGRESS_SOLUTION_INIT, deleteSaga),
  ]);
}

export default goalProgressSolutionsFlowSaga;

import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const PageLoader = () => (
  <Backdrop open={true}>
    <CircularProgress color="primary" size={60} thickness={5} />
  </Backdrop>
);

PageLoader.defaultProps = {};
PageLoader.propTypes = {};

export default PageLoader;

import normalizeData from 'services/backend/dataNormalizer/normalizeData';
import { isUndefined, pull } from 'lodash';
import { HEALTH_PLAN_STATUSES } from 'common/constants';

const adaptResponseData = function (data) {
  const healthPlanCurrentReview = data.find(
      (healthPlanReview) =>
        healthPlanReview.status !== HEALTH_PLAN_STATUSES.FINALISED
    ),
    healthPlanPreviousReviews = pull(data, healthPlanCurrentReview);

  const normalizedCurrentProgress = !isUndefined(healthPlanCurrentReview)
    ? normalizeData(
        healthPlanCurrentReview.goalProgressReviews.map((progress) => ({
          ...progress,
          goalProgressBarriers: normalizeData(
            Object.values(progress.goalProgressBarriers).map((barrier) => ({
              ...barrier,
              goalProgressSolutions: normalizeData(
                barrier.goalProgressSolutions,
                'goalProgressSolutions'
              ),
            })),
            'goalProgressBarriers'
          ),
        })),

        'goalProgressReviews'
      )
    : {};
  return {
    healthPlanCurrentReview: healthPlanCurrentReview
      ? {
          id: healthPlanCurrentReview.id,
          healthPlan: healthPlanCurrentReview.healthPlan,
          status: healthPlanCurrentReview.status,
          createdAt: healthPlanCurrentReview.createdAt,
          createdBy: healthPlanCurrentReview.createdBy,
          finalisedAt: healthPlanCurrentReview.finalisedAt,
          finalisedBy: healthPlanCurrentReview.finalisedBy,
          goalProgressReviews: normalizedCurrentProgress,
          isAnnualReview: healthPlanCurrentReview.isAnnualReview,
          sharedAt: healthPlanCurrentReview.sharedAt,
          sharedBy: healthPlanCurrentReview.sharedBy,
        }
      : {},
    healthPlanPreviousReviews: healthPlanPreviousReviews.map(
      (healthPlanPreviousReview) => {
        const normalizedPreviousProgress = normalizeData(
          healthPlanPreviousReview.goalProgressReviews.map((progress) => ({
            ...progress,
            goalProgressBarriers: normalizeData(
              Object.values(progress.goalProgressBarriers).map((barrier) => ({
                ...barrier,
                goalProgressSolutions: normalizeData(
                  barrier.goalProgressSolutions,
                  'goalProgressSolutions'
                ),
              })),
              'goalProgressBarriers'
            ),
          })),

          'goalProgressReviews'
        );
        return {
          id: healthPlanPreviousReview.id,
          healthPlan: healthPlanPreviousReview.healthPlan,
          status: healthPlanPreviousReview.status,
          createdAt: healthPlanPreviousReview.createdAt,
          createdBy: healthPlanPreviousReview.createdBy,
          finalisedAt: healthPlanPreviousReview.finalisedAt,
          finalisedBy: healthPlanPreviousReview.finalisedBy,
          goalProgressReviews: normalizedPreviousProgress,
          isAnnualReview: healthPlanPreviousReview.isAnnualReview,
          sharedAt: healthPlanPreviousReview.sharedAt,
          sharedBy: healthPlanPreviousReview.sharedBy,
        };
      }
    ),
  };
};

export default adaptResponseData;

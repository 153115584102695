import {
  FETCH_HEALTH_PLAN_INIT,
  FETCH_HEALTH_PLAN_SUCCESS,
  CREATE_HEALTH_PLAN_INIT,
  CREATE_HEALTH_PLAN_SUCCESS,
  UPDATE_HEALTH_PLAN_INIT,
  UPDATE_HEALTH_PLAN_SUCCESS,
  UPDATE_HEALTH_PLAN_ERROR,
  DOWNLOAD_HEALTH_PLAN_INIT,
  DOWNLOAD_HEALTH_PLAN_SUCCESS,
  RESET_HEALTH_PLAN,
} from './actionNames';

export function fetchHealthPlanInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_INIT, ...actionParams };
}

export function fetchHealthPlanSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_SUCCESS, data };
}

export function createHealthPlanInit(actionParams) {
  return { type: CREATE_HEALTH_PLAN_INIT, ...actionParams };
}

// DISCUSS: not used
export function createHealthPlanSuccess({ healthPlanId }) {
  return { type: CREATE_HEALTH_PLAN_SUCCESS, healthPlanId };
}

export function updateHealthPlanInit(actionParams) {
  return { type: UPDATE_HEALTH_PLAN_INIT, ...actionParams };
}

export function updateHealthPlanSuccess({ data }) {
  return { type: UPDATE_HEALTH_PLAN_SUCCESS, data };
}

export function updateHealthPlanError({ data }) {
  return { type: UPDATE_HEALTH_PLAN_ERROR, data };
}

export function downloadHealthPlanInit(actionParams) {
  return { type: DOWNLOAD_HEALTH_PLAN_INIT, ...actionParams };
}

export function downloadHealthPlanSuccess() {
  return { type: DOWNLOAD_HEALTH_PLAN_SUCCESS };
}

export function resetHealthPlans(actionParams) {
  return { type: RESET_HEALTH_PLAN, ...actionParams };
}

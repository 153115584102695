import { http } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query: { healthPlanId, healthPlanMeasurementId } }) {
  return http
    .delete(
      `/health_plans/${healthPlanId}/measurements/${healthPlanMeasurementId}/`
    )
    .catch(handleHTTPError);
}

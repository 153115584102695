import { Card as MuiCard } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Card = styled(MuiCard, {
  shouldForwardProp: (prop) => prop !== 'expand',
})(
  ({
    theme,
    expand,
    width,
    height,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
  }) => ({
    flexGrow: 1,
    display: 'block',
    width: width,
    height: height,
    minWidth: minWidth,
    maxWidth: maxWidth,
    minHeight: minHeight,
    maxHeight: maxHeight,
    transition: 'ease',
    border: `2px solid ${expand ? theme.palette.primary.main : 'transparent'}`,
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  })
);

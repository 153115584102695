import { isUndefined } from 'lodash';
import { object, string } from 'yup';

export default () =>
  object().shape({
    firstName: string().required('This field is required'),
    lastName: string().required('This field is required'),
    email: string()
      .email('This field must be a valid email address')
      .required('This field is required'),
    generalPractitioner: string().required('This field is required'),
    dateOfBirth: string().nullable().required('This field is required'),
    sex: string().required('This field is required'),
    phoneNumber: string()
      .test(
        'valid-phone-number-format',
        'Expected numbers only',
        (phoneNumber) =>
          isUndefined(phoneNumber) ||
          /^\+?1?\d{8,15}$/.test(phoneNumber.replace(/\s/g, ''))
      )
      .required('This field is required'),
    street: string().required('This field is required'),
    postcode: string().required('This field is required'),
    city: string().required('This field is required'),
  });

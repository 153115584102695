import {
  FETCH_HEALTH_PLAN_REVIEWS_INIT,
  FETCH_HEALTH_PLAN_REVIEWS_SUCCESS,
  CREATE_HEALTH_PLAN_REVIEW_INIT,
  CREATE_HEALTH_PLAN_REVIEW_SUCCESS,
  UPDATE_HEALTH_PLAN_REVIEW_INIT,
  UPDATE_HEALTH_PLAN_REVIEW_SUCCESS,
  UPDATE_HEALTH_PLAN_REVIEW_ERROR,
  RESET_HEALTH_PLAN_REVIEWS,
} from './actionNames';

export function fetchHealthPlanReviewsInit(actionParams) {
  return { type: FETCH_HEALTH_PLAN_REVIEWS_INIT, ...actionParams };
}

export function fetchHealthPlanReviewsSuccess({ data }) {
  return { type: FETCH_HEALTH_PLAN_REVIEWS_SUCCESS, data };
}

export function createHealthPlanReviewInit(actionParams) {
  return { type: CREATE_HEALTH_PLAN_REVIEW_INIT, ...actionParams };
}

export function createHealthPlanReviewSuccess({ data }) {
  return { type: CREATE_HEALTH_PLAN_REVIEW_SUCCESS, data };
}

export function updateHealthPlanReviewInit(actionParams) {
  return { type: UPDATE_HEALTH_PLAN_REVIEW_INIT, ...actionParams };
}

export function updateHealthPlanReviewSuccess({ data }) {
  return { type: UPDATE_HEALTH_PLAN_REVIEW_SUCCESS, data };
}

export function updateHealthPlanReviewError({ data }) {
  return { type: UPDATE_HEALTH_PLAN_REVIEW_ERROR, data };
}

export function resetHealthPlanReviews(actionParams) {
  return { type: RESET_HEALTH_PLAN_REVIEWS, ...actionParams };
}

import normalizeData from 'services/backend/dataNormalizer/normalizeData';

const adaptResponseData = function (data) {
  return data.map((journey) => {
    return {
      ...journey,
      healthJourneyItems: normalizeData(
        journey.healthJourneyItems,
        'healthJourneyItems'
      ),
    };
  });
};
export default adaptResponseData;

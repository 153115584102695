import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import {
  FETCH_PATIENT_MEASUREMENT_SUCCESS,
  CREATE_PATIENT_MEASUREMENT_SUCCESS,
  UPDATE_PATIENT_MEASUREMENT_SUCCESS,
  RESET_PATIENT_MEASUREMENTS,
} from './actionNames';

const initialState = {};

function handleFetchPatientMeasurementSuccess(state, { data }) {
  return { ...state, ...data };
}

function handleCreatePatientMeasurementSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleUpdatePatientMeasurementSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = data;
  });
  return { ...state, ...nextState };
}

function handleResetMeasurements() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [FETCH_PATIENT_MEASUREMENT_SUCCESS]: handleFetchPatientMeasurementSuccess,
  [CREATE_PATIENT_MEASUREMENT_SUCCESS]: handleCreatePatientMeasurementSuccess,
  [UPDATE_PATIENT_MEASUREMENT_SUCCESS]: handleUpdatePatientMeasurementSuccess,
  [RESET_PATIENT_MEASUREMENTS]: handleResetMeasurements,
};

export default buildReducer(initialState, ACTION_HANDLERS);

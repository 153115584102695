import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { healthPlan, createHealthPlanService } }
) {
  const handleOnSuccess = () => {},
    handleOnError = () => {};

  return createHealthPlanService({
    query: { healthPlanId: healthPlan.id, withPageLoader: true },
    data: mapValuesToProps({ healthPlan, ...values }),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import { Container } from '@mui/material';
import { AppBar, FlexBox, ContentBox } from 'common/components/layouts';
import GlobalTheme from 'common/styles/global';
import 'simplebar/dist/simplebar.min.css';

const FullPageLayout = ({ children, isAuthenticated, maxWidth, history }) => {
  const showHeader = isAuthenticated;

  const [vh, setVh] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
  });

  return (
    <ContentBox height={vh + 'px'} overflow="hidden">
      <FlexBox
        mt={isAuthenticated ? GlobalTheme.constants.MuiAppBar.height : 0}
        flexDirection="row"
        flexWrap="noWrap"
        height={
          isAuthenticated
            ? `calc(100% - ${GlobalTheme.constants.MuiAppBar.height})`
            : '100%'
        }
        width="100%"
      >
        {showHeader && <AppBar history={history} />}
        <SimpleBar style={{ maxHeight: '100%', width: '100%' }}>
          <Container maxWidth={maxWidth}>{children}</Container>
        </SimpleBar>
      </FlexBox>
    </ContentBox>
  );
};

FullPageLayout.defaultProps = {
  isAuthenticated: false,
  maxWidth: 'lg',
};
FullPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isAuthenticated: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
};

export default FullPageLayout;

import React from 'react';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { Card, Chip, ExpandableCard } from 'common/components/layouts';
import {
  GOAL_PROGRESS_REVIEW_STATUSES,
  GOAL_PROGRESS_REVIEW_STATUS_LABELS,
} from 'common/constants';
import {
  BarrierPropType,
  GoalPropType,
  SolutionPropType,
  GoalProgressReviewPropType,
} from 'common/propTypes';
import GlobalTheme from 'common/styles/global';

const GoalProgressReview = ({
  index,
  goalProgressReview,
  barrierDefinitions,
  solutionDefinitions,
}) => {
  const reviewComplete =
    (goalProgressReview.progress !== GOAL_PROGRESS_REVIEW_STATUSES.INITIAL &&
      !isEmpty(goalProgressReview.goalProgressBarriers)) ||
    goalProgressReview.progress === GOAL_PROGRESS_REVIEW_STATUSES.FULLY;

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Chip
            title={`Goal #${index}`}
            variant="h5"
            color={goalProgressReview.goalDefinition.color}
          />
          <Typography variant="h2">
            {goalProgressReview.goalDefinition.name}
          </Typography>
          <Typography
            variant="h6"
            color={reviewComplete ? 'textSecondary' : 'error'}
          >
            {reviewComplete ? 'Review Completed' : 'Review Not Completed'}
          </Typography>
        </Box>
      }
    >
      {!reviewComplete ? (
        <Typography variant="h4" align="center">
          Go to section and finalise review of this Health Goal.
        </Typography>
      ) : (
        <>
          <Card>
            <Typography variant="h5" color="textSecondary">
              Status
            </Typography>
            <Typography
              variant="h2"
              style={{
                color:
                  GlobalTheme.palette[
                    GOAL_PROGRESS_REVIEW_STATUS_LABELS[
                      goalProgressReview.progress
                    ].color
                  ].main,
              }}
            >
              {
                GOAL_PROGRESS_REVIEW_STATUS_LABELS[goalProgressReview.progress]
                  .label
              }{' '}
              Achieved
            </Typography>
          </Card>
          <>
            {sortBy(goalProgressReview.goalProgressBarriers, [
              'definition',
            ]).map((goalProgressBarrier, idx) => {
              const barrier =
                barrierDefinitions[goalProgressBarrier.definition];

              return (
                <Card key={idx}>
                  {/* TODO: Review FlexBox everywhere */}
                  <Box mb={2}>
                    <Typography variant="h5" color="textSecondary">
                      Barrier #{idx + 1}
                    </Typography>
                    <Typography variant="h2">{barrier.name}</Typography>
                  </Box>
                  <Box mb={-2.5}>
                    <Grid container spacing={3}>
                      {Object.values(
                        goalProgressBarrier.goalProgressSolutions
                      ).map((goalProgressSolution, idx) => {
                        const solution =
                          solutionDefinitions[goalProgressSolution.definition];

                        return (
                          <Grid key={idx} item lg={3} md={4} sm={6} xs={12}>
                            <Card>
                              <Typography variant="h5" color="textSecondary">
                                Solution #{idx + 1}
                              </Typography>
                              <Typography variant="h4">
                                {solution.name}
                              </Typography>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Card>
              );
            })}
          </>
          <Card>
            <Typography variant="h5" color="textSecondary">
              Notes
            </Typography>
            <Typography variant="h4">
              {!isEmpty(goalProgressReview.notes)
                ? goalProgressReview.notes
                : 'No notes recorded'}
            </Typography>
          </Card>
        </>
      )}
    </ExpandableCard>
  );
};

GoalProgressReview.propTypes = {
  index: PropTypes.number.isRequired,
  goalProgressReview: PropTypes.shape({
    ...GoalProgressReviewPropType,
    goalDefinition: PropTypes.shape(GoalPropType),
  }).isRequired,
  barrierDefinitions: PropTypes.objectOf(PropTypes.shape(BarrierPropType))
    .isRequired,
  solutionDefinitions: PropTypes.objectOf(PropTypes.shape(SolutionPropType))
    .isRequired,
};

export default GoalProgressReview;

import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import getComponentDisplayName from './utils/getComponentDisplayName';

function withFormikFieldProps(OriginalComponent) {
  const Component = ({ name, onChange, ...props }) => {
    function getOnChangeFunction(form, field, onChangeFromProps) {
      return function (event) {
        const value =
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value;
        const newValues = { ...form.values, [field.name]: value };
        form.setValues(newValues);
        if (onChangeFromProps) {
          onChangeFromProps({ values: newValues });
        }
      };
    }

    function getSetValueFunction(form, field) {
      return function (value) {
        form.setFieldValue(field.name, value);
      };
    }

    function getSetTouchedFunction(form, field) {
      return function (isTouched = true) {
        form.setFieldTouched(field.name, isTouched);
      };
    }

    return (
      <Field name={name}>
        {({ field, form }) => (
          <OriginalComponent
            {...field}
            errorMessage={getIn(form.errors, field.name)}
            touched={getIn(form.touched, field.name)}
            onChange={getOnChangeFunction(form, field, onChange)}
            setValue={getSetValueFunction(form, field)}
            setTouched={getSetTouchedFunction(form, field)}
            {...props}
          />
        )}
      </Field>
    );
  };

  Component.defaultProps = {};
  Component.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
  };
  Component.displayName = `withFormikFieldProps(${getComponentDisplayName(
    OriginalComponent
  )})`;

  return Component;
}

export default withFormikFieldProps;

import { put, call, select } from 'redux-saga/effects';
import {
  updatePatientPasswordSuccess,
  updatePatientPasswordError,
} from 'services/database/actions/patient';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { patientIdSelector } from 'services/database/actions/patient';
import request from './request';

export default function* updatePatientPasswordSaga({ data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: 'update-user-password' }));
    const patientId = yield select(patientIdSelector);
    yield call(request, { patientId, data });
    yield put(updatePatientPasswordSuccess());
    yield call(resolve);
  } catch (error) {
    yield put(updatePatientPasswordError({ error }));
    yield call(reject);
  } finally {
    yield put(setInlineLoadingOff({ data: 'update-user-password' }));
  }
}

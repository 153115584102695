import { http } from 'services/backend/http';
import adaptResponseData from './adaptResponseData';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ data }) {
  return http
    .post(`/patients/`, { data })
    .then(adaptResponseData)
    .catch(handleHTTPError);
}

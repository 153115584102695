import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { patientCycleOfCareItems, updatePatientCycleOfCareItem } }
) {
  patientCycleOfCareItems.map((patientCycleOfCareItem) => {
    return updatePatientCycleOfCareItem({
      query: {
        patientId: patientCycleOfCareItem.patient,
        patientCycleOfCareItemId: patientCycleOfCareItem.id,
      },
      data: mapValuesToProps(values),
    });
  });
}

import { compose } from 'redux';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchPatientCycleOfCareModulesInit,
  resetPatientCycleOfCareModules,
} from 'services/database/actions/patientCycleOfCareModules';
import PatientCyclesOfCare from './PatientCyclesOfCare';

export default compose(
  withAction({
    actionName: 'fetchPatientCycleOfCareModules',
    action: fetchPatientCycleOfCareModulesInit,
  }),
  withAction({
    actionName: 'resetPatientCycleOfCareModules',
    action: resetPatientCycleOfCareModules,
  }),
  withPropsMapping((props) => ({
    activePatientCycleOfCareModules: Object.values(
      props.patientCycleOfCareModules
    ).filter((patientCycleOfCareModule) => patientCycleOfCareModule.isActive),
    inactivePatientCycleOfCareModules: Object.values(
      props.patientCycleOfCareModules
    ).filter((patientCycleOfCareModule) => !patientCycleOfCareModule.isActive),
    ...props,
  })),
  withPropsMapping((props) => ({
    availableCycleOfCareModules: Object.values(
      props.cycleOfCareModuleDefinitions
    ).filter(
      (cycleOfCareModule) =>
        !Object.values(props.activePatientCycleOfCareModules).some(
          (patientCycleOfCareModule) =>
            patientCycleOfCareModule.definition.id === cycleOfCareModule.id
        )
    ),
    ...props,
  }))
)(PatientCyclesOfCare);

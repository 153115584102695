import React from 'react';
import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Event } from '@mui/icons-material';
import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const DatePicker = ({
  id,
  name,
  label,
  margin,
  value,
  variant,
  onChange,
  minDate,
  maxDate,
  disabled,
  dateOfBirth,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Field name={name}>
          {({ field, form }) => {
            const errorMessage =
              form.touched[name] && getIn(form.errors, field.name);
            return (
              <FormControl
                style={{
                  width: '100%',
                }}
              >
                {dateOfBirth ? (
                  <MuiDatePicker
                    id={id}
                    label={label}
                    format="dd/MM/yyyy"
                    margin={margin}
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={null}
                        variant={variant}
                      />
                    )}
                    disableFuture
                    openTo="day"
                    views={['year', 'month', 'day']}
                    error={Boolean(errorMessage)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: !disabled ? 'pointer' : 'unset' }}
                        >
                          <Event color={!disabled ? 'primary' : 'disabled'} />
                        </InputAdornment>
                      ),
                    }}
                    disabled={disabled}
                    style={{ transition: 'all ease 500ms' }}
                    hideTabs
                  />
                ) : (
                  <MuiDatePicker
                    id={id}
                    label={label}
                    format="dd/MM/yyyy"
                    margin={margin}
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={null}
                        variant={variant}
                      />
                    )}
                    minDate={minDate}
                    maxDate={maxDate}
                    error={Boolean(errorMessage)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: !disabled ? 'pointer' : 'unset' }}
                        >
                          <Event color={!disabled ? 'primary' : 'disabled'} />
                        </InputAdornment>
                      ),
                    }}
                    disabled={disabled}
                    style={{ transition: 'all ease 500ms' }}
                    hideTabs
                  />
                )}
                <FormHelperText
                  style={{ bottom: margin === 'dense' ? '-1.2rem' : 0 }}
                  error
                >
                  {errorMessage}
                </FormHelperText>
              </FormControl>
            );
          }}
        </Field>
      </Box>
    </LocalizationProvider>
  );
};

DatePicker.defaultProps = {
  margin: 'normal',
  variant: 'standard',
  disabled: false,
};
DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disabled: PropTypes.bool,
};

export default DatePicker;

import { compose } from 'redux';
import { withFormik } from 'formik';
import { Search } from 'common/components/form';
import mapPropsToValues from './mapPropsToValues';
import handleSubmit from './handleSubmit';

export default compose(
  withFormik({
    mapPropsToValues,
    handleSubmit,
  })
)(Search);

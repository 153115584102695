import {
  // TODO: How to pass data to reducer,
  // should we still have this kind of action so reducer
  // can determine where to put data?
  // Also there seem to be a bug where we are resolving the "promise" but there was a second request which is still in progress
  // And example of that is when we first calling post and then fetching update
  // It seemed that we resolved the promise before "fetching" update has finished, it might be the case for
  // writing custom saga which would handle this kind of process (just an idea)
  FETCH_PATIENT_MEASUREMENT_INIT,
  FETCH_PATIENT_MEASUREMENT_SUCCESS,
  CREATE_PATIENT_MEASUREMENT_INIT,
  CREATE_PATIENT_MEASUREMENT_SUCCESS,
  UPDATE_PATIENT_MEASUREMENT_INIT,
  UPDATE_PATIENT_MEASUREMENT_SUCCESS,
  DELETE_PATIENT_MEASUREMENT_INIT,
  DELETE_PATIENT_MEASUREMENT_SUCCESS,
  RESET_PATIENT_MEASUREMENTS,
} from './actionNames';

export function fetchPatientMeasurementsInit(actionParams) {
  return { type: FETCH_PATIENT_MEASUREMENT_INIT, ...actionParams };
}

export function fetchPatientMeasurementsSuccess({ data }) {
  return { type: FETCH_PATIENT_MEASUREMENT_SUCCESS, data };
}

export function createPatientMeasurementInit(actionParams) {
  return { type: CREATE_PATIENT_MEASUREMENT_INIT, ...actionParams };
}

export function createPatientMeasurementSuccess(actionParams) {
  return { type: CREATE_PATIENT_MEASUREMENT_SUCCESS, ...actionParams };
}

export function updatePatientMeasurementInit(actionParams) {
  return { type: UPDATE_PATIENT_MEASUREMENT_INIT, ...actionParams };
}

export function updatePatientMeasurementSuccess({ data }) {
  return { type: UPDATE_PATIENT_MEASUREMENT_SUCCESS, data };
}

export function deletePatientMeasurementInit(actionParams) {
  return { type: DELETE_PATIENT_MEASUREMENT_INIT, ...actionParams };
}

export function deletePatientMeasurementSuccess({ data }) {
  return { type: DELETE_PATIENT_MEASUREMENT_SUCCESS, data };
}

export function resetPatientMeasurements(actionParams) {
  return { type: RESET_PATIENT_MEASUREMENTS, ...actionParams };
}

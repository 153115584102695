import {
  USER_LOGIN_INIT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT_INIT,
} from './actionNames';

export function loginInit(actionParams) {
  return { type: USER_LOGIN_INIT, ...actionParams };
}

export function loginSuccess({ data }) {
  return { type: USER_LOGIN_SUCCESS, data };
}

export function loginError({ data }) {
  return { type: USER_LOGIN_ERROR, data };
}

export function logoutInit(actionParams) {
  return { type: USER_LOGOUT_INIT, ...actionParams };
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import getComponentDisplayName from './utils/getComponentDisplayName';

// used to dispatch actions to the store. withAction wrapper calls dispatch on action and update store.
function withAction({ actionName, action }) {
  const Component = (OriginalComponent) => {
    // mapDispatchToProps is used for dispatching actions to redux store
    function mapDispatchToProps(dispatch) {
      return {
        [actionName](actionParams) {
          // Promise makes action asynchronous
          return new Promise((resolve, reject) =>
            // dispatch is function of redux store and only way to trigger state change
            dispatch(action({ resolve, reject, ...actionParams }))
          );
        },
      };
    }

    // connect used to connect React component to redux store
    const Container = compose(connect(null, mapDispatchToProps))(
      OriginalComponent
    );

    Container.displayName = `withAction({${actionName}})(${getComponentDisplayName(
      OriginalComponent
    )})`;

    return Container;
  };

  return Component;
}

export default withAction;

import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import { FlexBox } from 'common/components/layouts';
import { Checkbox } from 'common/components/form';
import { handleSubmit as handleCreate } from 'common/data/goalProgressSolutions/create';
import { handleSubmit as handleDelete } from 'common/data/goalProgressSolutions/delete';
import {
  SolutionPropType,
  GoalProgressBarrierPropType,
  GoalProgressSolutionPropType,
} from 'common/propTypes';

const GoalProgressSolution = ({
  goalProgressBarrier,
  goalProgressSolution,
  solution,
  createGoalProgressSolution,
  deleteGoalProgressSolution,
  inlineLoading,
  disabled,
  readOnly,
}) => {
  const checked = !isUndefined(goalProgressSolution);

  const handleOnClick = () => {
    checked
      ? handleDelete({
          goalProgressSolution,
          deleteGoalProgressSolution,
        })
      : handleCreate({
          goalProgressBarrier,
          solution,
          createGoalProgressSolution,
        });
  };

  return (
    <FlexBox flexDirection="column">
      <Checkbox
        name={solution.name}
        label={solution.name}
        checked={checked}
        onChange={handleOnClick}
        padding="dense"
        loading={inlineLoading.includes(solution.id + '-solution')}
        disabled={readOnly || disabled}
      />
    </FlexBox>
  );
};

GoalProgressSolution.defaultProps = {
  selected: false,
};
GoalProgressSolution.propTypes = {
  goalProgressBarrier: PropTypes.shape(GoalProgressBarrierPropType).isRequired,
  goalProgressSolution: PropTypes.shape(GoalProgressSolutionPropType),
  solution: PropTypes.shape(SolutionPropType).isRequired,
  createGoalProgressSolution: PropTypes.func.isRequired,
  deleteGoalProgressSolution: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default GoalProgressSolution;

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'common/components/layouts';

const TabPanel = ({ children, component, value, index }) => {
  const Component = component;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel=${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Component>{children}</Component>}
    </div>
  );
};

TabPanel.defaultProps = {
  component: Card,
};
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;

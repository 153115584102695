import { createSelector } from 'reselect';

const selectDashboard = (state) => state.dashboard;

export const dashboardPatientsSelector = createSelector(
  [selectDashboard],
  (dashboard) => dashboard.patients
);

export const dashboardHealthPlansSelector = createSelector(
  [selectDashboard],
  (dashboard) => dashboard.healthPlans
);

import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/healthJourney/fetch';
import createSaga from 'services/backend/actions/healthJourney/create';
import updateItemSaga from 'services/backend/actions/healthJourney/updateItem';
import {
  FETCH_HEALTH_JOURNEY_INIT,
  CREATE_HEALTH_JOURNEY_INIT,
  UPDATE_HEALTH_JOURNEY_ITEM_INIT,
} from './actionNames';

function* patientCycleOfCareModulesFlowSaga() {
  yield all([
    takeLatest(FETCH_HEALTH_JOURNEY_INIT, fetchSaga),
    takeLatest(CREATE_HEALTH_JOURNEY_INIT, createSaga),
    takeEvery(UPDATE_HEALTH_JOURNEY_ITEM_INIT, updateItemSaga),
  ]);
}

export default patientCycleOfCareModulesFlowSaga;

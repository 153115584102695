import { httpWithHeaders } from 'services/backend/http';
import handleHTTPError from 'services/backend/errors/handleHTTPError';

export default function ({ query: { healthPlanId } }) {
  return httpWithHeaders
    .get(`/health_plans/${healthPlanId}/download/`, {
      responseType: 'blob',
    })
    .catch(handleHTTPError);
}

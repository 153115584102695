import { put, call } from 'redux-saga/effects';
import {
  setInlineLoadingOn,
  setInlineLoadingOff,
} from 'services/database/actions/loading';
import { updateHealthJourneyItemSuccess } from 'services/database/actions/healthJourney';
import request from './request';

export default function* updateItemSaga({ query, data, resolve, reject }) {
  try {
    yield put(setInlineLoadingOn({ data: query.itemId + '-journeyItem' }));
    const responseData = yield call(request, { query, data });
    yield put(updateHealthJourneyItemSuccess({ data: responseData }));
  } catch (error) {
    return error;
  } finally {
    yield put(setInlineLoadingOff({ data: query.itemId + '-journeyItem' }));
  }
}

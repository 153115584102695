import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { ColorIndicator } from 'common/components/layouts';
import { Chip as StyledChip } from './styles';

const Chip = ({ title, variant, color }) => (
  <StyledChip
    avatar={<ColorIndicator color={color} dense />}
    label={
      <Typography variant={variant} color="textSecondary" noWrap>
        {title}
      </Typography>
    }
    size="small"
  />
);

Chip.defaultProps = {
  variant: 'h6',
};
Chip.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string.isRequired,
};

export default Chip;

import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as MuiTabs } from '@mui/material';

const Tabs = ({ className, children, ...props }) => (
  <MuiTabs
    className={className}
    textColor="primary"
    TabIndicatorProps={{ style: { display: 'none' } }}
    {...props}
  >
    {children}
  </MuiTabs>
);

Tabs.defaultProps = {};
Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;

import { patientProfilePath } from 'routing/utils/paths';
import mapValuesToProps from './mapValuesToProps';

export default function (values, { props: { user, history, addPatient, onClose } }) {
  const handleOnSuccess = ({ responseData }) => {
      history.push(
        patientProfilePath({
          patientId: responseData.id,
        })
      );
    },
    handleOnError = () => {};
  return addPatient({
    data: mapValuesToProps(values, user),
  })
    .then((responseData) => handleOnSuccess(responseData))
    .catch(handleOnError);
}

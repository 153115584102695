import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/patientMeasurements/update';
import { updatePatientMeasurementInit } from 'services/database/actions/patientMeasurements';
import PatientMeasurementInput from './PatientMeasurementInput';

export default compose(
  withAction({
    actionName: 'updatePatientMeasurement',
    action: updatePatientMeasurementInit,
  }),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  })
)(PatientMeasurementInput);

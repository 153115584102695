import produce from 'immer';
import { buildReducer } from 'common/utils/redux';
import { USER_LOGOUT_INIT } from 'services/database/actions/auth/redux/actionNames';
import {
  CREATE_HEALTH_PLAN_ACTION_SUCCESS,
  DELETE_HEALTH_PLAN_ACTION_SUCCESS,
  FETCH_HEALTH_PLAN_ACTIONS_SUCCESS,
  RESET_HEALTH_PLAN_ACTIONS,
} from './actionNames';

const initialState = {};

function handleFetchHealthPlanActionsSuccess(state, { data }) {
  return data;
}

function handleCreateHealthPlanActionSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    draftState[data.id] = {
      id: data.id,
      definition: data.definition,
      createdAt: data.createdAt,
    };
  });
  return { ...state, ...nextState };
}

function handleDeleteHealthPlanActionSuccess(state, { data }) {
  const nextState = produce(state, (draftState) => {
    delete draftState[data.healthActionId];
  });
  return nextState;
}

function handleResetHealthPlanActions() {
  return initialState;
}

const ACTION_HANDLERS = {
  [FETCH_HEALTH_PLAN_ACTIONS_SUCCESS]: handleFetchHealthPlanActionsSuccess,
  [CREATE_HEALTH_PLAN_ACTION_SUCCESS]: handleCreateHealthPlanActionSuccess,
  [DELETE_HEALTH_PLAN_ACTION_SUCCESS]: handleDeleteHealthPlanActionSuccess,

  [RESET_HEALTH_PLAN_ACTIONS]: handleResetHealthPlanActions,
  [USER_LOGOUT_INIT]: handleResetHealthPlanActions,
};

export default buildReducer(initialState, ACTION_HANDLERS);

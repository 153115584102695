export const FETCH_PATIENT_INIT = 'FETCH_PATIENT_INIT';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';

export const ADD_PATIENT_INIT = 'ADD_PATIENT_INIT';
export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS';
export const ADD_PATIENT_ERROR = 'ADD_PATIENT_ERROR';

export const UPDATE_PATIENT_INIT = 'UPDATE_PATIENT_INIT';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_ERROR = 'UPDATE_PATIENT_ERROR';

export const UPDATE_PATIENT_PASSWORD_INIT = 'UPDATE_PATIENT_PASSWORD_INIT';
export const UPDATE_PATIENT_PASSWORD_SUCCESS =
  'UPDATE_PATIENT_PASSWORD_SUCCESS';
export const UPDATE_PATIENT_PASSWORD_ERROR = 'UPDATE_PATIENT_PASSWORD_ERROR';

export const RESET_PATIENT = 'RESET_PATIENT';

import React from 'react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import { lighten, styled } from '@mui/material/styles';
import { Image } from 'common/components/layouts';

const Div = styled('div')(({ theme }) => ({
  width: '12.5rem',
  height: theme.constants.MuiAppBar.height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRight: `0.05rem solid ${lighten(theme.palette.text.disabled, 0.6)}`,
  padding: '0 1.5rem',
}));

export const ImageContainer = ({ src, to }) => (
  <RouterLink to={to} onDragStart={(e) => e.preventDefault()}>
    <Div>
      <Image src={src} />
    </Div>
  </RouterLink>
);

export const NavLink = styled(RouterNavLink)(({ theme }) => ({
  width: '10rem',
  height: theme.constants.MuiAppBar.height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRight: `0.05rem solid ${lighten(theme.palette.text.disabled, 0.6)}`,
  color: theme.palette.text.primary,
  fontSize: '0.8rem',
  lineHeight: '0.95rem',
  textAlign: 'center',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&.active': {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
}));

export const MenuDiv = styled('div')(({ theme, open }) => ({
  width: '10rem',
  height: theme.constants.MuiAppBar.height,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  border: 'none',
  backgroundColor: 'transparent',
  color: open ? theme.palette.primary.main : 'inherit',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

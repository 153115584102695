import { http } from 'services/backend/http';
import { HEALTH_PLAN_STATUSES } from 'common/constants';
import handleHTTPError from 'services/backend/errors/handleHTTPError';
import adaptResponseData from './adaptResponseData';

export default function ({ query: { page, search, status } }) {
  // DISCUSS: move logic to handleSubmit?
  const healthPlanStatus = (_status) => {
    switch (_status) {
      case 'in_progress': {
        return HEALTH_PLAN_STATUSES.IN_PROGRESS;
      }
      case 'shared': {
        return HEALTH_PLAN_STATUSES.SHARED;
      }
      default:
        return null;
    }
  };
  return http
    .get('/patients/', {
      params: {
        page,
        search,
        status: healthPlanStatus(status),
        is_upcoming: status === 'upcoming' ? true : null,
        is_overdue: status === 'overdue' ? true : null,
      },
    })
    .then(adaptResponseData)
    .catch(handleHTTPError);
}

import React from 'react';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { FlexBox } from 'common/components/layouts';
import { Button } from 'common/components/buttons';

const NavStepper = ({ nextSectionId, previousSectionId, handleTabChange }) => {
  const handleGoToSection = (value) => (e) => {
    handleTabChange(e, value);
  };

  return (
    <FlexBox
      justifyContent={
        isUndefined(previousSectionId) ? 'flex-end' : 'space-between'
      }
      mt={6}
    >
      {!isUndefined(previousSectionId) && (
        <Button
          label="Previous Step"
          color="secondary"
          variant="outlined"
          onClick={handleGoToSection(previousSectionId)}
        />
      )}
      <Button
        label="Next Step"
        color="secondary"
        variant="outlined"
        onClick={handleGoToSection(nextSectionId)}
      />
    </FlexBox>
  );
};

NavStepper.propTypes = {
  nextSectionId: PropTypes.number.isRequired,
  previousSectionId: PropTypes.number,
  handleTabChange: PropTypes.func.isRequired,
};

export default NavStepper;

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchPatientMeasurementsInit,
  patientMeasurementsSelector,
  resetPatientMeasurements,
} from 'services/database/actions/patientMeasurements';
import { measurementDefinitionsSelector } from 'services/database/actions/definitions';
import {
  fetchHealthPlanMeasurementsInit,
  healthPlanMeasurementsSelector,
  resetHealthPlanMeasurements,
} from 'services/database/actions/healthPlanMeasurements';
import HealthPlanMeasurements from './HealthPlanMeasurements';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanMeasurements',
    action: fetchHealthPlanMeasurementsInit,
  }),
  withAction({
    actionName: 'resetHealthPlanMeasurements',
    action: resetHealthPlanMeasurements,
  }),
  withAction({
    actionName: 'fetchPatientMeasurements',
    action: fetchPatientMeasurementsInit,
  }),
  withAction({
    actionName: 'resetPatientMeasurements',
    action: resetPatientMeasurements,
  }),
  connect((state) => ({
    // TODO: Move up to health plan page and pass down
    healthPlanMeasurements: healthPlanMeasurementsSelector(state),
    patientMeasurements: patientMeasurementsSelector(state),
    measurementsDefinitions: measurementDefinitionsSelector(state),
  })),
  withPropsMapping((props) => ({
    ...props,
    // TODO: Move up to health plan page and pass down
    healthPlanMeasurements: Object.values(props.healthPlanMeasurements).map(
      (healthPlanMeasurement) => ({
        ...healthPlanMeasurement,
        lastMeasurement:
          props.patientMeasurements[healthPlanMeasurement.lastMeasurement],
        definition:
          props.measurementsDefinitions[healthPlanMeasurement.definition],
      })
    ),
  }))
)(HealthPlanMeasurements);

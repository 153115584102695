import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction } from 'common/HOCs';
import { barrierSolutionDefinitionsSelector } from 'services/database/actions/definitions';
import {
  createGoalProgressBarrierInit,
  deleteGoalProgressBarrierInit,
} from 'services/database/actions/goalProgressBarriers';
import GoalProgressBarrier from './GoalProgressBarrier';

export default compose(
  withAction({
    actionName: 'createGoalProgressBarrier',
    action: createGoalProgressBarrierInit,
  }),
  withAction({
    actionName: 'deleteGoalProgressBarrier',
    action: deleteGoalProgressBarrierInit,
  }),
  connect((state, { barrier }) => ({
    solutions: barrierSolutionDefinitionsSelector(state, barrier),
  }))
)(GoalProgressBarrier);

import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import fetchSaga from 'services/backend/actions/patientMeasurements/fetch';
import createSaga from 'services/backend/actions/patientMeasurements/create';
import updateSaga from 'services/backend/actions/patientMeasurements/update';
import {
  FETCH_PATIENT_MEASUREMENT_INIT,
  CREATE_PATIENT_MEASUREMENT_INIT,
  UPDATE_PATIENT_MEASUREMENT_INIT,
} from './actionNames';

function* measurementsFlowSaga() {
  yield all([
    takeLatest(FETCH_PATIENT_MEASUREMENT_INIT, fetchSaga),
    takeEvery(CREATE_PATIENT_MEASUREMENT_INIT, createSaga),
    takeEvery(UPDATE_PATIENT_MEASUREMENT_INIT, updateSaga),
  ]);
}

export default measurementsFlowSaga;

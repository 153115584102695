import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  handleSubmit,
  validationSchema,
} from 'common/data/healthJourney/create';
import HealthJourneyDialog from './HealthJourneyDialog';
import { createHealthJourneyInit } from 'services/database/actions/healthJourney';

export default compose(
  withAction({
    actionName: 'createHealthJourney',
    action: createHealthJourneyInit,
  }),
  withFormik({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
    enableReinitialize: true,
  })
)(HealthJourneyDialog);

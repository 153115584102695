import { createSelector } from 'reselect';

const selectUser = (state) => {
  return state.user;
};
const selectUserErrors = (state) => {
  return state.user.errors;
};

export const userSelector = createSelector([selectUser], (user) => user);

export const userErrorsSelector = createSelector(
  [selectUserErrors],
  (userErrors) => userErrors
);

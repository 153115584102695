import {
  InvalidDataError,
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  OtherError,
} from './errors';

// throw specific errors depending on error status code
export default function (error) {
  // BUG: if no error.response (ie normalizr sent data that doesn't exist), get infinite error loop
  const status = error.response ? error.response.status : 'default';
  switch (status) {
    // 400 error requires special handling as it returns info from backend
    case 400:
      throw new InvalidDataError(error.response.data);
    case 401:
      switch (error.response.data.detail) {
        case 'No active account found with the given credentials':
          error.response.data.detail = 'Incorrect email or password';
          break;
        case 'Given token not valid for any token type':
          error.response.data.detail = 'Reset link expired';
          break;
        default:
      }
      throw new UnauthorizedError(error.response.data);
    case 403:
      throw new ForbiddenError(error.response.data);
    case 404:
      throw new NotFoundError(error.response.data);
    default:
      throw new OtherError(error);
  }
}

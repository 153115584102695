import { styled } from '@mui/material/styles';

export const GoalProgressSolutionsDiv = styled('div')(({ theme }) => ({
  width: '8.5rem',
  margin: '0 0.16rem',
  marginTop: 'calc(-0.16rem - 40.65px / 2)',
  marginBottom: '0.16rem',
  padding: '0.5rem',
  paddingTop: 'calc(0.5rem + 40.65px / 2)',
  borderColor: `${theme.palette.primary.main} !important`,
  border: '2px solid',
  borderRadius: '0 0 1rem 1rem',
}));

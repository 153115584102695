import React, { useEffect } from 'react';
import { isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TabCard } from 'common/components/layouts';
import {
  HealthPlanPropType,
  HealthPlanAdminItemPropType,
} from 'common/propTypes';
import HealthPlanAdminItem from './components/HealthPlanAdminItem';

const HealthPlanAdminItemsTab = ({
  healthPlan,
  healthPlanAdminItems,
  fetchHealthPlanAdminItems,
  resetHealthPlanAdminItems,
  readOnly,
}) => {
  useEffect(() => {
    !isNil(healthPlan.id) &&
      fetchHealthPlanAdminItems({ query: { healthPlanId: healthPlan.id } });
    return () => resetHealthPlanAdminItems();
    // eslint-disable-next-line
  }, []);

  return (
    <TabCard title="Admin Items">
      <Grid container spacing={3}>
        {sortBy(healthPlanAdminItems, ['definition.id']).map(
          (healthPlanAdminItem, idx) => (
            <Grid key={idx} item md={4} sm={6} xs={12}>
              <HealthPlanAdminItem
                key={idx}
                healthPlanAdminItem={healthPlanAdminItem}
                readOnly={readOnly}
              />
            </Grid>
          )
        )}
      </Grid>
    </TabCard>
  );
};

HealthPlanAdminItemsTab.propTypes = {
  healthPlan: PropTypes.shape(HealthPlanPropType).isRequired,
  healthPlanAdminItems: PropTypes.arrayOf(
    PropTypes.shape(HealthPlanAdminItemPropType)
  ),
  fetchHealthPlanAdminItems: PropTypes.func.isRequired,
  resetHealthPlanAdminItems: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HealthPlanAdminItemsTab;

import React from 'react';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled('div')({
  position: 'relative',
});

const Tab = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '10rem',
  height: '2.5rem',
  transform: 'rotate(-90deg) translate(-10rem)',
  transformOrigin: 'top left',
  borderRadius: '0.5rem 0.5rem 0 0',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: 'rgba(0, 0, 0, 0.05) 0 -0.75rem 1.25rem 0',
}));

const Card = styled(MuiCard)({
  borderTopLeftRadius: 0,
  marginLeft: '2.5rem',
  minHeight: '12rem',
});

export const TabCard = ({ title, children, ...props }) => (
  <Container>
    <Tab>
      <MuiTypography variant="h4">{title}</MuiTypography>
    </Tab>
    <Card variant="outlined" {...props}>
      <MuiCardContent>{children}</MuiCardContent>
    </Card>
  </Container>
);

import { put, call } from 'redux-saga/effects';
import { fetchDefinitionsSuccess } from 'services/database/actions/definitions';
import request from './request';

export default function* fetchSaga() {
  try {
    const responseData = yield call(request);
    yield put(fetchDefinitionsSuccess({ data: responseData }));
  } catch (error) {}
}

import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  handleSubmit,
} from 'common/data/healthPlanReviews/update';
import { updateHealthPlanReviewInit } from 'services/database/actions/healthPlanReviews';
import UpdateStatusButton from './UpdateStatusButton';

export default compose(
  withAction({
    actionName: 'updateHealthPlanReview',
    action: updateHealthPlanReviewInit,
  }),
  withFormik({ mapPropsToValues, handleSubmit, enableReinitialize: true })
)(UpdateStatusButton);

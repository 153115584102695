import mapValuesToProps from './mapValuesToProps';

export default function (
  values,
  { props: { provider, updateProvider, onClose } }
) {
  const handleOnSuccess = () => {
      onClose();
    },
    handleOnError = () => {};
  return updateProvider({
    query: { providerId: provider.id },
    data: mapValuesToProps(values),
  })
    .then(handleOnSuccess)
    .catch(handleOnError);
}

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  withAction,
  withIsAuthenticated,
  withAuthenticatedUser,
  withPatient,
  withFullPageLayout,
} from 'common/HOCs';
import {
  fetchHealthJourneyInit,
  healthJourneySelector,
  resetHealthJourney,
} from 'services/database/actions/healthJourney';
import {
  createHealthPlanInit,
  downloadHealthPlanInit,
} from 'services/database/actions/healthPlans';
import { createHealthPlanReviewInit } from 'services/database/actions/healthPlanReviews';
import { updatePatientInit } from 'services/database/actions/patient';
import { inlineLoadingSelector } from 'services/database/actions/loading';
import PatientPage from './PatientPage';
import { fetchProvidersInit, resetProviders } from 'services/database/actions/providers';

export default compose(
  withAction({
    actionName: 'createHealthPlan',
    action: createHealthPlanInit,
  }),
  withAction({
    actionName: 'createHealthPlanReview',
    action: createHealthPlanReviewInit,
  }),
  withAction({
    actionName: 'downloadHealthPlan',
    action: downloadHealthPlanInit,
  }),
  withAction({
    actionName: 'updatePatient',
    action: updatePatientInit,
  }),
  withAction({
    actionName: 'fetchHealthJourney',
    action: fetchHealthJourneyInit,
  }),
  withAction({
    actionName: 'resetHealthJourney',
    action: resetHealthJourney,
  }),
  withAction({
    actionName: 'fetchProviders',
    action: fetchProvidersInit,
  }),
  withAction({
    actionName: 'resetProviders',
    action: resetProviders,
  }),
  connect((state) => ({
    healthJourney: healthJourneySelector(state),
    inlineLoading: inlineLoadingSelector(state),
  })),
  withAuthenticatedUser,
  withIsAuthenticated,
  withPatient,
  withFullPageLayout
)(PatientPage);

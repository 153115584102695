import React, { useEffect } from 'react';
import { isEmpty, isNull, orderBy, sortBy, toString } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ToggleButton } from 'common/components/buttons';
import { Chip, ExpandableCard, FlexBox } from 'common/components/layouts';
import { ComponentLoader } from 'common/components/loaders';
import { Form, Input } from 'common/components/form';
import {
  GOAL_PROGRESS_REVIEW_STATUSES,
  GOAL_PROGRESS_REVIEW_STATUS_LABELS,
} from 'common/constants';
import { useDebounce } from 'common/hooks';
import {
  BarrierPropType,
  GoalPropType,
  SolutionPropType,
  HealthPlanReviewPropType,
  GoalProgressReviewPropType,
  PatientPropType,
} from 'common/propTypes';
import { renderFirstName } from 'common/utils';
import GoalProgressBarrier from './components/GoalProgressBarrier';
import PreviousHealthPlanReviews from './components/PreviousHealthPlanReviews';

const GoalProgressReview = ({
  index,
  patient,
  previousHealthPlanReviews,
  goalProgressReview,
  barrierDefinitions,
  solutionDefinitions,
  values,
  values: { progress, notes },
  initialValues: { notes: initialNotes },
  setValues,
  handleSubmit,
  inlineLoading,
  readOnly,
}) => {
  // DISCUSS: debounce not working?
  const debouncedNotes = useDebounce(notes, 1000);
  useEffect(() => {
    !isNull(debouncedNotes) && handleOnChangeNotes();
    // eslint-disable-next-line
  }, [debouncedNotes]);

  const reviewComplete =
      (goalProgressReview.progress !== GOAL_PROGRESS_REVIEW_STATUSES.INITIAL &&
        !isEmpty(goalProgressReview.goalProgressBarriers)) ||
      goalProgressReview.progress === GOAL_PROGRESS_REVIEW_STATUSES.FULLY,
    showPreviousReviews = !readOnly && previousHealthPlanReviews.length > 0;

  const handleOnChangeNotes = () => {
      notes !== initialNotes && handleSubmit();
    },
    handleOnChangeStatus = (value) => () => {
      if (value !== progress) {
        setValues({ ...values, progress: value });
        handleSubmit();
      }
    };

  const filteredBarrierDefinitions = sortBy(
    goalProgressReview.goalDefinition.barriers.map(
      (barrier) => barrierDefinitions[barrier]
    ),
    ['id']
  );

  return (
    <ExpandableCard
      cardContent={
        <Box>
          <Chip
            title={`Goal #${index}`}
            variant="h5"
            color={goalProgressReview.goalDefinition.color}
          />
          <Typography variant="h2">
            {goalProgressReview.goalDefinition.name}
          </Typography>
          <Typography
            variant="h6"
            color={reviewComplete ? 'textSecondary' : 'error'}
          >
            {reviewComplete ? 'Review Completed' : 'Review Not Completed'}
          </Typography>
        </Box>
      }
    >
      <Box mb={3}>
        <Typography variant="h3" paragraph>
          Has {renderFirstName(patient)} achieved this goal?
        </Typography>
        <FlexBox>
          {orderBy(
            Object.values(GOAL_PROGRESS_REVIEW_STATUS_LABELS),
            ['id'],
            ['desc']
          ).map((status, idx) => (
            <ToggleButton
              key={idx}
              label={status.label}
              color={status.color}
              selected={goalProgressReview.progress === status.id}
              variant={
                goalProgressReview.progress === status.id
                  ? 'contained'
                  : 'outlined'
              }
              onClick={handleOnChangeStatus(status.id)}
              disabled={inlineLoading.includes(
                `${goalProgressReview.id}-progress`
              )}
              readOnly={readOnly}
            />
          ))}
        </FlexBox>
        {goalProgressReview.progress ===
          GOAL_PROGRESS_REVIEW_STATUSES.INITIAL && (
          <Typography variant="h6" color="error">
            Select a status.
          </Typography>
        )}
      </Box>
      {![
        GOAL_PROGRESS_REVIEW_STATUSES.INITIAL,
        GOAL_PROGRESS_REVIEW_STATUSES.FULLY,
      ].includes(goalProgressReview.progress) && (
        <Box mb={3}>
          <Typography variant="h3" paragraph>
            What is preventing {renderFirstName(patient)} from achieving this
            goal?
          </Typography>
          <FlexBox>
            {Object.values(filteredBarrierDefinitions).map((barrier, idx) => {
              const goalProgressBarrier = Object.values(
                goalProgressReview.goalProgressBarriers
              ).find(
                (goalProgressBarrier) =>
                  goalProgressBarrier.definition === barrier.id
              );

              return (
                <GoalProgressBarrier
                  key={idx}
                  goalProgressReview={goalProgressReview}
                  goalProgressBarrier={goalProgressBarrier}
                  barrier={barrier}
                  inlineLoading={inlineLoading}
                  disabled={inlineLoading.includes(
                    `${goalProgressReview.id}-progress`
                  )}
                  readOnly={readOnly}
                />
              );
            })}
          </FlexBox>
        </Box>
      )}
      <Box mb={showPreviousReviews ? 3 : 0}>
        <FlexBox>
          <Typography variant="h3">Clinical assessment notes</Typography>
          {inlineLoading.includes(
            toString(goalProgressReview.id) + '-notes'
          ) && <ComponentLoader size={20} />}
        </FlexBox>
        <Form>
          <Input
            name="notes"
            value={notes}
            variant="outlined"
            onBlur={handleOnChangeNotes}
            disabled={readOnly}
            multiline
            minRows={5}
          />
        </Form>
      </Box>
      {showPreviousReviews && (
        <PreviousHealthPlanReviews
          previousHealthPlanReviews={previousHealthPlanReviews}
          goalProgressReview={goalProgressReview}
          barrierDefinitions={barrierDefinitions}
          solutionDefinitions={solutionDefinitions}
        />
      )}
    </ExpandableCard>
  );
};

GoalProgressReview.defaultProps = {
  inlineLoading: [],
};
GoalProgressReview.propTypes = {
  index: PropTypes.number.isRequired,
  patient: PropTypes.shape(PatientPropType).isRequired,
  previousHealthPlanReviews: PropTypes.arrayOf(
    PropTypes.shape(HealthPlanReviewPropType)
  ).isRequired,
  goalProgressReview: PropTypes.shape({
    ...GoalProgressReviewPropType,
    goalDefinition: PropTypes.shape(GoalPropType),
  }).isRequired,
  barrierDefinitions: PropTypes.objectOf(PropTypes.shape(BarrierPropType))
    .isRequired,
  solutionDefinitions: PropTypes.objectOf(PropTypes.shape(SolutionPropType))
    .isRequired,
  values: PropTypes.shape({
    progress: PropTypes.number,
    notes: PropTypes.string,
  }).isRequired,
  initialValues: PropTypes.shape({ notes: PropTypes.string }).isRequired,
  setValues: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  inlineLoading: PropTypes.array,
  readOnly: PropTypes.bool.isRequired,
};

export default GoalProgressReview;

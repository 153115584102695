import { compose } from 'redux';
import { withFormik } from 'formik';
import { withAction } from 'common/HOCs';
import {
  mapPropsToValues,
  validationSchema,
  handleSubmit,
} from 'common/data/healthPlanActions/create';
import { createHealthPlanActionInit } from 'services/database/actions/healthPlanActions';
import HealthPlanActionAdd from './HealthPlanActionAdd';

export default compose(
  withAction({
    actionName: 'createHealthPlanAction',
    action: createHealthPlanActionInit,
  }),
  withFormik({ mapPropsToValues, validationSchema, handleSubmit })
)(HealthPlanActionAdd);

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withAction, withPropsMapping } from 'common/HOCs';
import {
  fetchHealthPlanAdminItemsInit,
  resetHealthPlanAdminItems,
  healthPlanAdminItemsSelector,
} from 'services/database/actions/healthPlanAdminItems';
import { adminItemDefinitionsSelector } from 'services/database/actions/definitions';
import HealthPlanAdminItemsTab from './HealthPlanAdminItemsTab';

export default compose(
  withAction({
    actionName: 'fetchHealthPlanAdminItems',
    action: fetchHealthPlanAdminItemsInit,
  }),
  withAction({
    actionName: 'resetHealthPlanAdminItems',
    action: resetHealthPlanAdminItems,
  }),
  connect((state) => ({
    healthPlanAdminItems: healthPlanAdminItemsSelector(state),
    adminItemDefinitions: adminItemDefinitionsSelector(state),
  })),
  withPropsMapping((props) => ({
    ...props,
    // Override these props
    healthPlanAdminItems: Object.values(props.healthPlanAdminItems).map(
      (healthPlanAdminItem) => ({
        ...healthPlanAdminItem,
        definition: props.adminItemDefinitions[healthPlanAdminItem.definition],
      })
    ),
  }))
)(HealthPlanAdminItemsTab);
